import { Flex, Box, useStyleConfig } from "@chakra-ui/react";
import React, { useEffect, useState, createContext } from 'react';
import SignageTemplateEditButtons from "components/ksin/sideContainer/SignageTemplateEditButtons";


function PanelContainer(props) {
  const { variant, children, ...rest } = props;
  const styles = useStyleConfig("PanelContainer", { variant });
  const [sampleContext, setSampleContext] = useState({wsc: "wscオブジェクトのテスト"});

  // Pass the computed styles into the `__css` prop
  return (
    <Box __css={props.isFullView ? {} : styles} {...rest}>
      <Flex id="page-wrapper">
            <Flex id="main-container" flexDirection="column" w="100%">
              {children}
            </Flex>
      </Flex>
    </Box>
  );
}

export default PanelContainer;
