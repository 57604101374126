// Chakra imports
import {
  Box,
  Flex,
  Switch,
  Text,
} from "@chakra-ui/react";
import { AppContext } from "layouts/AppCommons";
import { useCallback, useContext, useState, useEffect } from "react";
import SignageList from "../Signage/SignageList";
import { UserClient } from "components/ksin/ApiClient/UserClient";
import { AvatarChannelClient } from "components/ksin/ApiClient/AvatarChannelClient";
import ShowFacialExpresstionAppSetting from "components/ksin/Forms/parts/ShowFacialExpresstionAppSetting";

function OperatorStandby(props: any) {
  const { appContext, setAppContext } = useContext(AppContext);
  const wsc = appContext.wsc;

  const operatorStateName = {
    Standby: "受付中",
    Active: "会話中",
    Leaving: "離席中"
  }


  // 画面内で更新されるデータ
  const [operatorState, setOperatorState] = useState(appContext.operatorState ? appContext.operatorState : OperatorStateList.Standby);
  const [loginUserInfo, setLoginUserInfo] = useState(null);
  const [avatarChannelInfo, setAvatarChannelInfo] = useState(null);

  // オペレーター状態更新wsメッセージ送信
  const sendOperatorState = (nextState: string) => {
    const ws = wsc.getWs();
    ws.send(JSON.stringify({
      type: 'stateTransitionEvent',
      event: {
        type: "operatorStateTransition",
        operatorState: nextState,
        senderType: "User"
      }
    }));
  }

  // オペレーター状態切替
  const toggleOperatorState = useCallback(() => {
    setOperatorState((prevState) => {
      let nextState: string;
      if (prevState === OperatorStateList.Standby) {
        nextState = OperatorStateList.Leaving;
      } else {
        nextState = OperatorStateList.Standby;
      }
      setAppContext((pre) => {
        pre.operatorState = nextState;
        return { ...pre };
      })
      sendOperatorState(nextState);
      return nextState;
    });
  }, []);

  const getCookieArray = () => {
    let arr = new Array();
    if (document.cookie != '') {
      var tmp = document.cookie.split('; ');
      for (let i = 0; i < tmp.length; i++) {
        let data = tmp[i].split('=');
        arr[data[0]] = decodeURIComponent(data[1]);
      }
    }
    return arr;
  };


  useEffect(() => {
    const userId = getCookieArray()['user_id'];
    const userClient = new UserClient();
    userClient.get(userId)
      .then((data: any) => {
        setLoginUserInfo(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (loginUserInfo == null) return;

    const avatarChnnelClient = new AvatarChannelClient();
    avatarChnnelClient.get(loginUserInfo.avatarChId)
      .then((data: any) => {
        setAvatarChannelInfo(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [loginUserInfo]);

  return (
    <Flex
      mt="70px"
    >
      <Box
        mb={{ sm: "205px", md: "75px", xl: "70px" }}
        borderRadius="15px"
        px="0px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        width="100%"
      >
        <SignageList
          isOperatorView={true}
          isDispAllAffiliation={true}
        />
      </Box>
      <Flex
        id="standby-sidemenu"
        direction="column"
        ml="0.5rem"
      >
        <Box
          mb={{ sm: "205px", md: "75px", xl: "70px" }}
          borderRadius="15px"
          px="0px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          align="center"
          // backgroundColor="white"
          paddingTop="30px"
          paddingLeft="20px"
          paddingBottom="50px"
          width="100%"
          bgColor="white"
        >
          <Text color="black" textAlign="left" fontWeight="bold" fontSize="large"
            mb="1rem">
            マイステータス
          </Text>
          <Flex mb="1rem">
            <Text>
              現在の状態:
            </Text>
            <Text
              ml="0.5rem"
              color={operatorState === OperatorStateList.Standby ? "blue.400" : "red.400"}
              fontWeight="bold"
            >
              {operatorStateName[operatorState]}
            </Text>
          </Flex>
          <Flex>
            <Text
            >
              離席中
          </Text>
            <Switch
              ml="0.5rem"
              colorScheme="teal"
              isChecked={operatorState === OperatorStateList.Standby}
              onChange={toggleOperatorState}
            />
            <Text ml="0.5rem">
              受付中
          </Text>
          </Flex>
        </Box>
        <Box
          mb={{ sm: "205px", md: "75px", xl: "70px" }}
          borderRadius="15px"
          px="0px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          align="center"
          // backgroundColor="white"
          paddingTop="30px"
          paddingLeft="20px"
          paddingRight="20px"
          paddingBottom="50px"
          width="100%"
          bgColor="white"
        >
          <ShowFacialExpresstionAppSetting
            channelName={avatarChannelInfo && avatarChannelInfo.name != null ? avatarChannelInfo.name : 'なし'}
            ipAddress={avatarChannelInfo && avatarChannelInfo.streaming_server_ip != null ? avatarChannelInfo.streaming_server_ip : 'なし'}
            portNo={avatarChannelInfo && avatarChannelInfo.face_capture_port != null ? avatarChannelInfo.face_capture_port : 'なし'}
            subjectName={loginUserInfo && loginUserInfo.subject_name != null ? loginUserInfo.subject_name : 'なし'}
          />
        </Box>
      </Flex>
    </Flex>
  );
}

export default OperatorStandby;

export const OperatorStateList = {
  Standby: "Standby",
  Active: "Active",
  Leaving: "Leaving"
}


