// import
import Dashboard from "views/Dashboard/Dashboard.js";
import Tables from "views/Dashboard/Tables.js";
import Billing from "views/Dashboard/Billing.js";
import RTLPage from "views/RTL/RTLPage.js";
import Profile from "views/Dashboard/Profile.js";
import SignIn from "views/Pages/SignIn.js";
import SignUp from "views/Pages/SignUp.js";
import Login from "views/Pages/Login.js";
import Register from "views/Pages/Register.js";
import UserProfile from "views/Pages/UserProfile";
import PasswordSetting from "views/Pages/PasswordSetting";
import PasswordReset from "views/Pages/PasswordReset";
import UserManagement from "views/Pages/Users/List.js";
import Top from "views/Pages/Top.js";
import Home from "views/Pages/Home";
import StoreManagement from "views/Pages/Store/List.js";
import LoginRetry from "views/Pages/LoginRetry.js";
import SignageList from "views/Pages/Signage/SignageList";
import SignageAdd from "components/ksin/Forms/AddSignageForm";
import SignageUpdate from "components/ksin/Forms/UpdateSignageForm";
import StoreSignage from "views/Pages/Store/Signage.js";
import UserSignage from "views/Pages/Store/Users";
import StoreAdd from "views/Pages/Store/StoreAdd";
import StoreUpdate from "views/Pages/Store/StoreUpdate";
import UserAddEdit from "views/Pages/Users/AddEdit";
import UserUpdate from "views/Pages/Users/UserUpdate";
import UserSelectCharacter from "views/Pages/Users/SelectCharacter";
import AvatarList from "views/Pages/Avatar/List.js";
import MediaManagement from "views/Pages/Media/List.js";

import {
    HomeIcon,
    StatsIcon,
    CreditIcon,
    PersonIcon,
    DocumentIcon,
    RocketIcon,
    SupportIcon,
} from "components/Icons/Icons";
import { IoIosFolder, IoIosList, IoIosCalendar, IoIosCalculator } from "react-icons/io";
import { AiFillPicture } from "react-icons/ai";
import { MdOutlineSmartphone } from "react-icons/md";
import { BiUserPin } from "react-icons/bi";
import OperatorStandby from "views/Pages/Users/OperatorStandby";
import ServiceOperation from "views/Pages/Signage/ServiceOperation";
import SignageView from "views/Pages/Users/SignageView";
import SignageTemplateUpdate from "views/Pages/Users/SignageTemplateUpdate"
import SignageOperatorAdd from "views/Pages/Signage/SignageOperatorAdd";
import SignageOperatorList from "views/Pages/Signage/SignageOperatorList";
import Calendar from "views/Pages/Users/Schedule"
import ReservationList from "views/Pages/Users/ReservationList"
import ReservationUsageStatistics from "views/Pages/Users/ReservationUsageStatistics"
import { CalendarIcon } from "@chakra-ui/icons";
import { ListIcon } from "@chakra-ui/layout";
const isDispFullMenu = true;

const dashRoutes = [{
        path: "/users/home",
        name: "ホーム",
        rtlName: "لوحة القيادة",
        icon: < HomeIcon color = "inherit" / > ,
        component: Top,
        layout: "/app",
        isView: isDispFullMenu,
    },
    {
        path: "/store/list",
        name: "店舗管理",
        rtlName: "لوحة القيادة",
        icon: < IoIosFolder color = "inherit" / > ,
        component: StoreManagement,
        layout: "/app",
    },
    {
        path: "/store/add",
        name: "店舗追加",
        rtlName: "لوحة القيادة",
        icon: < IoIosFolder color = "inherit" / > ,
        component: StoreAdd,
        layout: "/app",
        isView: false,
    },
    {
        path: "/store/update",
        name: "店舗設定",
        rtlName: "لوحة القيادة",
        icon: < IoIosFolder color = "inherit" / > ,
        component: StoreUpdate,
        layout: "/app",
        isView: false,
    },
    {
        path: "/users/list",
        name: "ユーザー管理",
        rtlName: "لوحة القيادة",
        icon: < PersonIcon color = "inherit" / > ,
        component: UserManagement,
        layout: "/app",
        isView: isDispFullMenu,
    },
    {
        path: "/users/add",
        name: "ユーザー追加",
        rtlName: "لوحة القيادة",
        icon: < PersonIcon color = "inherit" / > ,
        component: UserAddEdit,
        layout: "/app",
        isView: false,
    },
    {
        path: "/users/update",
        name: "ユーザー設定",
        rtlName: "لوحة القيادة",
        icon: < PersonIcon color = "inherit" / > ,
        component: UserUpdate,
        layout: "/app",
        isView: false,
    },
    {
        path: "/select-character",
        name: "キャラクター選択",
        rtlName: "لوحة القيادة",
        icon: < PersonIcon color = "inherit" / > ,
        component: UserSelectCharacter,
        layout: "/app",
        isView: false,
    },
    {
        path: "/channel-operator/:ch",
        name: "アバター管理",
        rtlName: "لوحة القيادة",
        icon: < BiUserPin color = "inherit" / > ,
        component: AvatarList,
        layout: "/app",
        isView: false,
    },
    {
        path: "/signage/list",
        name: "サイネージ管理",
        rtlName: "لوحة القيادة",
        icon: < MdOutlineSmartphone color = "inherit" / > ,
        component: SignageList,
        layout: "/app",
        isView: isDispFullMenu,
    },
    {
        path: "/signage/operator/",
        name: "サイネージ担当オペレーター管理",
        rtlName: "لوحة القيادة",
        icon: < MdOutlineSmartphone color = "inherit" / > ,
        component: SignageOperatorList,
        layout: "/app",
        isView: false,
    },
    {
        path: "/signage/operatorAdd",
        name: "サイネージ担当オペレーター追加",
        rtlName: "لوحة القيادة",
        icon: < MdOutlineSmartphone color = "inherit" / > ,
        component: SignageOperatorAdd,
        layout: "/app",
        isView: false,
    },
    {
        path: "/signage/add",
        name: "サイネージ追加",
        rtlName: "لوحة القيادة",
        icon: < MdOutlineSmartphone color = "inherit" / > ,
        component: SignageAdd,
        layout: "/app",
        isView: false,
    },
    {
        path: "/signage/update",
        name: "サイネージ設定",
        rtlName: "لوحة القيادة",
        icon: < MdOutlineSmartphone color = "inherit" / > ,
        component: SignageUpdate,
        layout: "/app",
        isView: false,
    },
    {
        path: "/signage",
        name: "店舗内サイネージ一覧",
        rtlName: "لوحة القيادة",
        icon: < MdOutlineSmartphone color = "inherit" / > ,
        component: StoreSignage,
        layout: "/store",
        isView: false,
    },
    {
        path: "/users",
        name: "店舗内ユーザー一覧",
        rtlName: "لوحة القيادة",
        icon: < PersonIcon color = "inherit" / > ,
        component: UserSignage,
        layout: "/store",
        isView: false,
    },
    {
        path: "/media/list",
        name: "メディア管理",
        rtlName: "لوحة القيادة",
        icon: < AiFillPicture color = "inherit" / > ,
        component: MediaManagement,
        layout: "/app",
    },
    {
        path: "/users/profile",
        name: "プロフィール設定",
        rtlName: "لوحة القيادة",
        icon: < DocumentIcon color = "inherit" / > ,
        component: UserProfile,
        layout: "/app",
        isView: false,
    },
    {
        path: "/login",
        name: "ログイン",
        rtlName: "لوحة القيادة",
        icon: < RocketIcon color = "inherit" / > ,
        component: Login,
        layout: "/test",
        isView: false,
    },
    {
        path: "/register",
        name: "企業登録",
        rtlName: "لوحة القيادة",
        icon: < RocketIcon color = "inherit" / > ,
        component: Register,
        layout: "/test",
        isView: false,
    },
    {
        path: "/passwordReset",
        name: "パスワードリセット",
        rtlName: "لوحة القيادة",
        icon: < RocketIcon color = "inherit" / > ,
        component: PasswordReset,
        layout: "/test",
        isView: false,
    },
    {
        path: "/passwordSetting",
        name: "パスワード変更",
        rtlName: "لوحة القيادة",
        icon: < RocketIcon color = "inherit" / > ,
        component: PasswordSetting,
        layout: "/test",
        isView: false,
    },
    {
        name: "KSIN Time-Share",
        category: "account",
        rtlName: "صفحات",
        state: "pageCollapse",
        views: [{
                path: "/reservation/home",
                name: "本日の予約",
                rtlName: "لوحة القيادة",
                icon: < HomeIcon color = "inherit" / > ,
                secondaryNavbar: false,
                component: Home,
                layout: "/app",
            },
            {
                path: "/reservation/list",
                name: "予約一覧",
                rtlName: "لوحة القيادة",
                icon: < IoIosList color = "inherit" / > ,
                secondaryNavbar: false,
                component: ReservationList,
                layout: "/app"
            },
            {
                path: "/reservation/calendar",
                name: "スケジュール",
                rtlName: "لوحة القيادة",
                icon: < IoIosCalendar color = "inherit" / > ,
                secondaryNavbar: false,
                component: Calendar,
                layout: "/app",
            },
            {
                path: "/reservation/usage/statistics",
                name: "利用実績",
                rtlName: "لوحة القيادة",
                icon: < StatsIcon color = "inherit" / > ,
                secondaryNavbar: false,
                component: ReservationUsageStatistics,
                layout: "/app",
            },
            {
                path: "/reservation/signage/template",
                state: {
                    id: "01H8JM7V269NZCT3NK5HTW81D4",
                    companyName: "株式会社Test1",
                    image: ""
                },
                name: "テンプレート設定",
                rtlName: "لوحة القيادة",
                icon: < PersonIcon color = "inherit" / > ,
                secondaryNavbar: false,
                component: SignageTemplateUpdate,
                layout: "/app",
            },
        ],
    },
    {
        name: "オペレーター向け",
        category: "account",
        rtlName: "صفحات",
        state: "pageCollapse",
        views: [{
                path: "/service/standby",
                name: "接客スタンバイ",
                rtlName: "لوحة القيادة",
                icon: < PersonIcon color = "inherit" / > ,
                secondaryNavbar: false,
                component: OperatorStandby,
                layout: "/app",
            },
            {
                path: "/service/operation",
                name: "接客オペレーション",
                rtlName: "لوحة القيادة",
                icon: < PersonIcon color = "inherit" / > ,
                secondaryNavbar: false,
                component: ServiceOperation,
                layout: "/app",
                isView: false,
            },
            {
                path: "/signage/template",
                name: "表示テンプレート設定",
                rtlName: "لوحة القيادة",
                icon: < PersonIcon color = "inherit" / > ,
                secondaryNavbar: false,
                component: SignageTemplateUpdate,
                layout: "/app",
                isView: false,
            },
            {
                path: "/signage/view",
                name: "サイネージ表示",
                rtlName: "لوحة القيادة",
                icon: < PersonIcon color = "inherit" / > ,
                secondaryNavbar: false,
                component: SignageView,
                layout: "/app",
                isView: false,
            },
            {
                path: "/signin",
                name: "Sign In",
                rtlName: "لوحة القيادة",
                icon: < DocumentIcon color = "inherit" / > ,
                component: SignIn,
                layout: "/auth",
                isView: false,
            },
            {
                path: "/signup",
                name: "Sign Up",
                rtlName: "لوحة القيادة",
                icon: < RocketIcon color = "inherit" / > ,
                secondaryNavbar: true,
                component: SignUp,
                layout: "/auth",
                isView: false,
            },
        ],
    }
];
export default dashRoutes;