import { ApiClient } from './ApiClient';
export class CompanyClient implements ApiClient {
    constructor() {
    }
    async get(targetId?: string) {
        const sendData = {}
        const param = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json; charset=utf-8"
        },
        body: JSON.stringify(sendData)
        };
        return new Promise((resolve, reject) =>
        fetch(`/api/getCompany`, param)
            .then(response => {
                resolve(response.json());
            })
            .catch((error) => {
                console.error('Error:', error);
                reject(error);
            })
        );
    }
    async add() {
    }
    async update(targetId: string) {
    }
    async delete(targetId: string) {
    }
}