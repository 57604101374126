// Chakra imports
import {
  Box,
  Flex,
  Text,
  Textarea,

} from "@chakra-ui/react";
import PixelStreamer from "components/ksin/Streamer/PixelStreamer";
import { useCallback, useContext, useEffect, useState } from "react";
import { AppContext } from 'layouts/AppCommons';
import VideoCaller from "components/ksin/Streamer/VideoCaller";
import React from "react";
import { SignageStateList, SignageLayoutList } from "../Users/SignageTemplateUpdate";
import { SignageClient } from "components/ksin/ApiClient/SignageClient";
import AlertDialogBasic from "components/ksin/Common/AlertDialogBasic";
import { UserClient } from "components/ksin/ApiClient/UserClient";
import { StoreClient } from 'components/ksin/ApiClient/StoreClient';
// import { useHistory } from 'react-router-dom';

import moment from "moment"

const signageClient = new SignageClient();

// オペレーター状態管理カスタムフック
export const useOperatorStatus = () => {
  const [isStandbyComplete, setIsStandbyComplete] = useState(false);

  const [isVideoCallerStandby, setIsVideoCallerStandby] = useState(false);
  const [isIdleVideoStandby, setIsIdleVideoStandby] = useState(false);
  const [isPixelStreamerStandby, setIsPixelStreamerStandby] = useState(false);

  const changeVideoCallerStatus = (isComplete: boolean) => {
    console.log(`${new Date().toISOString()}[debug]serviceOperation changeVideoCallerStatus: ${isComplete}`);
    setIsVideoCallerStandby(isComplete);
  }

  const changePixelStreamerStatus = (isComplete: boolean) => {
    console.log(`${new Date().toISOString()}[debug]serviceOperation changePixelStreamerStatus: ${isComplete}`);
    setIsPixelStreamerStandby(isComplete);
  }

  const changeIdleVideoStatus = (isComplete: boolean) => {
    console.log(`${new Date().toISOString()}[debug]serviceOperation changeIdleVideoStatus: ${isComplete}`);
    setIsIdleVideoStandby(isComplete);
  }

  // PixelStreamerとVideoCallerの状態をチェック
  // 電話ボックス方式以降にともない、PixelStreamerのチェックは外す
  useEffect(() => {
    if (isVideoCallerStandby && isIdleVideoStandby) {
      setIsStandbyComplete(true);
      console.log(`streamerStandby: ${isPixelStreamerStandby}`);
    }
  }, [isVideoCallerStandby, isIdleVideoStandby]);

  return { isStandbyComplete, changeVideoCallerStatus, changePixelStreamerStatus, changeIdleVideoStatus };
}

let companyName: string = "";

function ServiceOperation(props: any) {
  const { appContext, setAppContext } = useContext(AppContext);
  const wsc = appContext.wsc;

  // 対象サイネージID(管理画面からの遷移時に取得)
  // const _targetId: string = props.location.state.id;
  // const companyName: string = props.location.state.companyName; // NOTE: 企業名 / 店舗名

  // 画面内で更新されるデータ
  const [signageInfo, setSignageInfo] = useState(null);
  const [signageState, setSignageState] = React.useState(appContext.signageState ? appContext.signageState : SignageStateList.Standby);
  const [signageId, setSignageId] = React.useState(props.location.state.id);
  const [templateTable, setTemplateTable] = React.useState(null);
  const [isMonitoring, setIsMonitoring] = React.useState(true);
  const [isShareScreen, setIsShareScreen] = React.useState(false);
  const [isVideoCallReConnect, setIsVideoCallReConnect] = React.useState(false);
  const [selectCharacter, setSelectCharacter] = React.useState(props.location.state.character?.name || null);
  const [storeList, setStoreList] = React.useState(null);
  const [lostSignageConnectionTime, setLostSignageConnectionTime] = React.useState(0);
  const [isLostedSignageConnection, setIsLostedSignageConnection] = React.useState(false);
  const [isAlert, setAlert] = useState(false)
  const [lostedSignageContents, setLostedSignageContents] = React.useState({
    header: "SVER-02",
    body: "サイネージとの通信が切断されました。再接続後、「呼びかけ」ボタンを押してください。"
  });
  // const history = useHistory();
  // const ref = React.useRef (null);
  const [settingButton, setSettingButton] = useState({
    data: [],
    direction: 'vertical'
  });

  const [timer, setTimer] = React.useState<number>()
  const [isDefaultBrowserCapture, setIsDefaultBrowserCapture] = useState(false);
  const [handTrackingStatus, setHandTrackingStatus] = useState(null);
  const [mainTelop, setMainTelop] = React.useState(null);
  const [theirtalk, setTheirTalk] = React.useState("");

  const convertTimeToMS = () => {
    const currTime = moment()
    const endTime = moment(props.location.state.time)
    const diff = endTime.diff(currTime)
    setTimer(diff)
    return diff
  }

  false ? convertTimeToMS() :

    useEffect(() => {
      if (props.location.state.time) {
        const result = convertTimeToMS() - 300000
        // const result2 = convertTimeToMS() 
        console.log(result, "test - use timer")
        if (result > 0) {
          setTimeout(() => {
            // alert("会議終了まで、あと５分");
            setAlert(true);
            setTimeout(() => {
              setAlert(false)
            }, 10000)
          }, result);
        }
        // result2 >  0 ?  (ref.current.closeVideoCall(false, true), setTimeout(() => {  window.location.href = "/app/user/home" }, result2)) : []
      }

    }, []);
  // オペレーター状態管理
  const { isStandbyComplete, changeVideoCallerStatus, changePixelStreamerStatus, changeIdleVideoStatus } = useOperatorStatus();

  useEffect(() => {
    if (appContext.companyName && appContext.companyName.length) {
      companyName = appContext.companyName;
    }
  }, [appContext.companyName]);

  useEffect(() => {
    if (signageId && signageId.length > 0) {
      console.log(`${new Date().toISOString()}[debug]ServiceOperation signageId: ${signageId}`);
      getTemplateData();
    }
  }, [signageId]);

  useEffect(() => {
    if (mainTelop) {
      console.log(`${new Date().toISOString()}[debug]ServiceOperation update mainTelop: ${mainTelop}`);
    }
  }, [mainTelop]);

  // wsConnectionからPixelStreamerの状態キャッチ
  useEffect(() => {
    if (appContext.streamerStandby) {
      changePixelStreamerStatus(true);
    }
  }, [appContext.streamerStandby]);

  // オペレーター状態更新wsメッセージ送信
  useEffect(() => {
    if (isStandbyComplete) {
      sendOperatorState("PossibleStandby");
    }
  }, [isStandbyComplete]);

  const sendOperatorState = (nextState: string) => {
    const ws = wsc.getWs();
    ws.send(JSON.stringify({
      type: 'stateTransitionEvent',
      event: {
        type: "operatorStateTransition",
        signageId: signageId,
        operatorState: nextState,
        senderType: "Signage",
        characterName: selectCharacter
      }
    }));
    console.log(`${new Date().toISOString()}[debug]serviceOperation sendOperatorState: ${isStandbyComplete}`);
  }

  const onConfirmLostedSignageConnection = () => {
    setIsLostedSignageConnection(false);
    setIsVideoCallReConnect(true);
  }

  const getTargetCompanyStoreName = (targetStoreId: string) => {
    let targetCompanyStoreName = companyName;
    for (let index = 0; index < storeList.length; index += 1) {
      if (storeList[index].id == targetStoreId) {
        targetCompanyStoreName = `${companyName} / ${storeList[index].name}`;
        break;
      }
    }

    return targetCompanyStoreName;
  };


  // マウント後の単発処理
  useEffect(() => {
    // サイネージIdの設定
    if (props.location.state.id) {
      setSignageId(props.location.state.id);
      setAppContext((pre) => {
        pre.signageId = props.location.state.id;
        return { ...pre };
      });
    }

    // NOTE: 店舗名表示のため店舗一覧を取得する
    const storeClient = new StoreClient();
    storeClient.get().then((data: any) => {
      setStoreList(data);
    })
      .catch((error) => {
      })


    // DBからユーザー自身のキャラクター名を取得
    const userClient = new UserClient();
    userClient.get("SELF")
      .then((data: any) => {
        if (data.characterName.length) {
          setSelectCharacter(data.characterName);
        }
        if (data.default_capture_mode === "browser") {
          setIsDefaultBrowserCapture(true);
        }
        setHandTrackingStatus(data.hand_tracking_status);
      })
      .catch((error) => {
        console.error('Error:', error);
      });

    // DBからテンプレデータの取得
    getTemplateData();

    // モニタリングモードか否か
    if (props.location.state.mode === "Monitoring") {
      setIsMonitoring(true);
    }

    setLostedSignageContents({
      header: "SVER-02",
      body: "サイネージとの通信が切断されました。再接続後、「呼びかけ」ボタンを押してください。"
    });

  }, []);

  // DBからテンプレデータを取得する関数
  const getTemplateData = () => {
    signageClient.get(signageId).then((data: any) => {
      if (data.templates.length) {
        setSignageInfo(data);
        const signageStandBy = data.templates.find((item) => item.signage_state === 'Standby');

        setSettingButton({
          data: signageStandBy.touch_buttons.map((item) => ({
            id: item.id,
            textBtn: item.text,
            color: item.bg_color,
            fontSize: item.font_size,
            visible: item.is_view
          })),
          direction: signageStandBy.row
        });
      } else {
        console.log(data);
      }
    })
      .catch((error) => {
        console.log(error);
      })
  }

  // DBからテンプレート情報を取得したとき
  useEffect(() => {
    if (signageInfo) {
      const receiveTemplates = new Map();

      const uploadImageTableBase: any = {};
      const uploadImageInfo = {
        id: "",
        logo: null,
        media: null,
      }

      signageInfo.templates.forEach(data => {
        type StateInfoType = {
          stateName: string,
          template: any
        };
        const stateInfo: StateInfoType = {
          stateName: "",
          template: {}
        }

        stateInfo.stateName = data.signage_state;
        stateInfo.template = data;
        // メディアとロゴの画像パスを設定
        if (data.contents_media) {
          stateInfo.template.media_src = data.contents_media.image_url;
        } else {
          stateInfo.template.media_src = "/images/Common/ksin-logo.png";
        }

        if (data.logo_media) {
          stateInfo.template.logo_src = data.logo_media.image_url;
        } else {
          stateInfo.template.logo_src = "/images/Common/ksin-logo.png";
        }

        // レイアウトによりメディアの表示・非表示切り替え
        if ([SignageLayoutList.onlyAvatar].includes(stateInfo.template.layout)) {
          stateInfo.template.is_view_media = false;
        } else {
          stateInfo.template.is_view_media = true;
        }

        receiveTemplates.set(stateInfo.stateName, stateInfo);

        // ロゴ画像とメディアファイル変更のための準備
        uploadImageInfo.id = data.id;
        uploadImageTableBase[stateInfo.stateName] = { ...uploadImageInfo };
      });
      setTemplateTable(new Map(receiveTemplates));
    }
  }, [signageInfo]);

  // 通信対象のサイネージ変更された場合の処理
  useEffect(() => {
    if (appContext.signageId) {
      // おそらくここで前に応対リクエスト受信したサイネージのIDに書き変わっている
      console.log(`${new Date().toISOString()}[debug]appContext.signageId: ${appContext.signageId}`);
      setSignageId(appContext.signageId);
    }
  }, [appContext.signageId]);


  useEffect(() => {
    console.log(isMonitoring);
  }, [isMonitoring]);

  const updateLostedSignageTime = useCallback((time) => {
    setLostSignageConnectionTime((preTime) => {
      console.log(`サイネージ切断通知受信: ${lostSignageConnectionTime}`);
      console.log(`${new Date().toISOString()}[debug]サイネージ切断通知受信`);
      if (time - preTime > 60000) {
        // setIsLostedSignageConnection(true);
      }
      return time;
    })
  }, [appContext.onLostedSignageTime]);

  useEffect(() => {
    if (appContext.onLostedSignageTime) {
      updateLostedSignageTime(appContext.onLostedSignageTime);
    }
  }, [appContext.onLostedSignageTime]);

  useEffect(() => {
    if (appContext.signageState) {
      setSignageState(appContext.signageState);
      console.log(`${new Date().toISOString()}[debug]ServiceOperation setSignageState: ${appContext.signageState}`);
    }
  }, [appContext.signageState]);

  useEffect(() => {
    if (appContext.isMonitoring) {
      setIsMonitoring(true);
    } else if (appContext.isMonitoring === false) {
      setIsMonitoring(false);
    }
  }, [appContext.isMonitoring]);

  useEffect(() => {
    console.log(appContext.signagePeerId);
  }, [appContext.signagePeerId]);


  return (
    <Box
      // mb={{ sm: "205px", md: "75px", xl: "70px" }}
      mb="0"
      borderRadius="15px"
      px="0px"
      // display={isPixelStreaming ? "flex" : "none"}
      flexDirection="column"
      justifyContent="center"
      align="center"
      backgroundColor="white"
      width="100%"
      mt="70px"
    >
      <Flex p="1rem">
        {isMonitoring ?
          <Text fontWeight="bold">モニタリング</Text> :
          <Text fontWeight="bold">接客オペレーション</Text>}
        <Box ml="10px">
          <Text textAlign="left">
            {signageInfo && storeList ? getTargetCompanyStoreName(signageInfo.storeId) : ""}
          </Text>
        </Box>
      </Flex>
      <Flex>
        <Flex direction="column">
          <Textarea
            id='theirTalkLog'
            value={theirtalk}
            placeholder='相手の発言'
            size='md'
          />
          <VideoCaller
            // ref={ref}
            timer={timer}
            wsc={wsc}
            inServiceOperation={true}
            theirId={appContext.signagePeerId}
            theirName={appContext.signageName}
            isViewTheirVideo={true}
            signageId={signageId}
            setSignageId={setSignageId}
            receiveCount={appContext.receiveCount}
            signageState={signageState}
            isViewSignageConfig={true}
            setIsMonitoring={setIsMonitoring}
            isMonitoring={isMonitoring}
            setIsShareScreen={setIsShareScreen}
            isShareScreen={isShareScreen}
            isVideoCallReConnect={isVideoCallReConnect}
            changeVideoCallerStatus={changeVideoCallerStatus}
            isStandbyComplete={isStandbyComplete}
            setMainTelop={setMainTelop}
            setTheirTalk={setTheirTalk}
          />
        </Flex>
        
        {templateTable ?
          <PixelStreamer
            wsc={wsc}
            inServiceOperation={true}
            isViewTheirVideo={true}
            signageState={signageState}
            createPlayerStateList={[]}
            isViewSignageConfig={false}
            templateTable={templateTable}
            setTemplateTable={setTemplateTable}
            isMonitoring={isMonitoring}
            setIsShareScreen={setIsShareScreen}
            isShareScreen={isShareScreen}
            // changePixelStreamerStatus={changePixelStreamerStatus}
            changeIdleVideoStatus={changeIdleVideoStatus}
            selectCharacter={selectCharacter}
            settingButton={settingButton}
            disableEventTouchButton={true}
            isDefaultBrowserCapture={isDefaultBrowserCapture}
            handTrackingStatus={handTrackingStatus}
            mainTelop={mainTelop}
          /> :
          <Flex>Loading...</Flex>}
      </Flex>
      <AlertDialogBasic
        isAlert={isAlert}
        isOpenConfirm={isLostedSignageConnection}
        setIsOpenConfirm={setIsLostedSignageConnection}
        setIsForceSubmit={onConfirmLostedSignageConnection}
        dialogContents={lostedSignageContents}
        isNotDispCancel={true}
      />
    </Box>

  );
}

export default ServiceOperation;