import React, { useContext, useEffect, useRef } from 'react'
import { UserClient } from '../ApiClient/UserClient';
// import { useHistory } from 'react-router-dom';

import { AppContext } from 'layouts/AppCommons';
// import { useKey } from 'react-use';
import { WsStateList } from './wsClient';
import { SignageStateList } from 'views/Pages/Users/SignageTemplateUpdate';
import AlertDialogBasic from '../Common/AlertDialogBasic';
import ReceptionRequest from '../Streamer/ReceptionRequest';
import { ObjectStateInfo } from 'views/Pages/Signage/SignageList';

// Assets
export default function WsConnection() {
  const { appContext, setAppContext } = useContext(AppContext);
  const context = appContext;
  const wsc = appContext.wsc;
  // const history = useHistory();

  const [userStatus, setUserStatus] = React.useState({});
  const [diffStatus, setDiffStatus] = React.useState({});
  const [webAppState, setWebAppState] = React.useState("Active");
  const [isOpenConnectionError, setIsOpenConnectionError] = React.useState(false);
  const [dialogContents, setDialogContents] = React.useState({
    header: "SVER-99",
    body: "未定義のサーバーエラーが発生しました",
    category: ""
  });
  const [_, setCurrentPath] = React.useState("");


  const webAppStateRef = useRef(webAppState);
  // const [signagePeerId, setSignagePeerId] = React.useState("");

  const userClient = new UserClient();

  // const moveToLogin = useCallback((params) => {
  //   setTimeout(() => {
  //     history.replace({ pathname: "/login", state: params });
  //   }, 100); 
  // }, [history]);

  // エラーメッセージが確認されたらログアウトしてログイン画面に遷移する
  const onConfirmErrorMsg = () => {
    setIsOpenConnectionError(false);
    // userClient.logout().then(() => {
    //   //moveToLogin({ isRedirectByLaterLogin: true });
    //   window.location.replace("/login") //　サイネージの方で移動する
    // window.location.replace("/login");
    // }
    // )
  }

  const informStateChange = React.useCallback((
    senderType: string,
    from: { id: string, name: string },
    state: string,
    option?: any
  ) => {
    switch (senderType) {
      case "Signage":
        if (state === "callFromSignage" && option) {
          setAppContext((pre) => {
            pre.requestId = option.requestId;
            pre.captureImageDir = option.captureImageDir;
            pre.signageId = from.id;
            pre.signageName = from.name;
            pre.receiveCount = new Date().getTime();
            pre.touchButton = option.touchButton
            return { ...pre };
          })
        } else if (state === "homeButtonOnline") {
          setAppContext((pre) => {
            pre.homeButton = pre.homeButton ? !pre.homeButton : true
            return { ...pre };
          })
        }
        break;
      case "User":
        let status = {};
        status[from.id] = state;
        if (option.mode && option.mode === "Monitoring") {
          setAppContext((pre) => {
            pre.theirPeerId = option.peerId;
            pre.userPeerId = option.peerId;
            console.log(`${new Date().toISOString()}[debug]WsConnection setAppContext.userPeerId in MonitoringStart: ${option.peerId}`);
            pre.userName = from.name;
            pre.isMonitoring = true;
            pre.receiveCallfromUser = new Date().getTime();
            return { ...pre };
          })
        }
        if (option.mode && option.characterName) {
          console.log(appContext);
          setAppContext((pre) => {
            pre.characterName = option.characterName;
            return { ...pre };
          })
        }
        if (state === "Active") {
          console.log(`${new Date().toISOString()}[debug]call removePlayer in pointA`);
          wsc.removePlayer("reCreate");

          setAppContext((pre) => {
            pre.changeActiveAfterCall = true;
            pre.receiveCallfromUser = new Date().getTime();
            return { ...pre };
          })
        }
        else if (state === "serviceClosed") {
          setAppContext((pre) => {
            pre.isCreatedPlayer = false;
            pre.onClosedCallTime = new Date().getTime();
            return { ...pre };
          });
          // wsc.restartWs(informStateChange);
        } else if (state === "shareScreen") {
          setAppContext((pre) => {
            pre.isShareScreen = option.mode === "open" ? true : false;
            return { ...pre };
          });
        } else if (option && option.mode === "talkLog") {
          setAppContext((pre) => {
            const talkLogObj = {
              speakerId: from.id,
              locale: option.locale,
              text: option.text
            }
            pre.receiveTalkLog = talkLogObj;
            return { ...pre };
          });
        }
        break;
      case "Server":
        if (option && option.msgType === "submitSignageState") {
          console.log(`${new Date().toISOString()}[debug]wsConnection submitSignageState: ${option.state}`);
          setAppContext((pre) => {
            if (option.state !== SignageStateList.Active) {
              pre.signageState = option.state;
              pre.changeActiveAfterCall = false;
            } else {
              pre.changeActiveAfterCall = true;
              pre.receiveCallfromUser = new Date().getTime();
              console.log(`${new Date().toISOString()}[debug]call removePlayer in pointB`);
            }
            return { ...pre }
          })
        } else if (state === "serviceCloseForWRU") {
          setAppContext((pre) => {
            pre.closeSignageWebReservationAvatar = true
            return { ...pre };
          });
        } else if (state === "deviceStateTransition") {
          setAppContext((pre) => {
            pre.deviceState = !pre.deviceState
            return { ...pre };
          });
        }
        const reflectStateMessageList = ["reflectSignageState", "reflectOperatorState"];
        if (option && reflectStateMessageList.includes(option.msgType)) {
          let stateInfo: ObjectStateInfo = {
            type: option.msgType === "reflectSignageState" ? "Signage" : "User",
            id: option.signageId,
            state: option.state
          };

          setAppContext((pre) => {
            const _receivedStateInfo = pre.receivedStateInfo ? pre.receivedStateInfo : [];
            pre.receivedStateInfo = [..._receivedStateInfo, stateInfo];
            pre.receivedStateInfoTime = new Date().getTime();
            return { ...pre }
          })
        }
        if (option && option.mode === "acceptRequest") {
          setAppContext((pre) => {
            pre.shouldRecept = option.shouldRecept;
            if (option.shouldRecept) {
              console.log(`${new Date().toISOString()}[debug]call removePlayer in pointD`);
            }
            return { ...pre }
          })
        } else if (option && option.mode === "informAnswerUserInfo") {
          // 応対リクエスト応答受理時に応答ユーザーのpeerIdを反映
          setAppContext((pre) => {
            pre.userPeerId = option.peerId;
            console.log(`${new Date().toISOString()}[debug]WsConnection setAppContext.userPeerId in informAnswerUserInfo: ${option.peerId}`);
            return { ...pre };
          })
        } else if (option && option.mode === "lastUseCharacter") {
          // アバターchの最終利用キャラクターを情報を反映
          setAppContext((pre) => {
            pre.lastUseCharacter = option.characterName;
            return { ...pre };
          })
        }
        if (state === "Error" || state === "alreadyExistError") {
          // alert(option.msg);
          setDialogContents({
            header: "SVER-01",
            body: option.msg,
            category: state
          });
          setIsOpenConnectionError(true);

        } else if (state === "signageDecodeError") {
          setAppContext((pre) => {
            pre.signageDecodeInfo = {
              isErrorOccured: true
            };
            return { ...pre };
          })
        } else if (state === "Warning") {
          console.log(option.msg);
          setAppContext((pre) => {
            pre.onLostedSignageTime = new Date().getTime();
            return { ...pre };
          })
        } else if (option && option.mode && option.mode === "informCallId") {
          if (option.peerId && option.peerId.length) {
            let count = 5;
            const informTheirPeerId = () => {
              // console.log(`${new Date().toISOString()}[debug]wsConnection informed theirId: ${option.peerId}`);
              count = count - 1;
              setAppContext((pre) => {
                pre.theirPeerId = option.peerId;
                return { ...pre };
              })
              if (count) {
                setTimeout(informTheirPeerId, 500);
              }
            }
            informTheirPeerId();

          }
        } else if (state === "wsInit") {
          // setIsInitVideoCaller(new Date().getTime());
          setAppContext((pre) => {
            pre.signagePeerId = "";
            pre.userPeerId = "";
            pre.receiveCount = 0;
            pre.wsState = WsStateList.Initialized;
            return { ...pre };
          })
        } else if (state === "Logouted") {
          setWebAppState("Logout");
          wsc.closeWs();
        } else if (state === "Stopped") {
          console.error("webSocketは切断されました");
          if (webAppStateRef.current !== "Logout") {
            console.log(window.location.pathname);
            if (window.location.pathname.includes("/app")) {
              setAppContext((pre) => {
                if (pre.signageState === SignageStateList.Active) {
                  console.error("会話中に切断されました");
                }
                pre.isCreatedPlayer = false;
                pre.wsState = WsStateList.Stopped;
                return { ...pre };
              });
              wsc.init(informStateChange);
            }
          }
        } else if (state === "receiveConfig") {
          setAppContext((pre) => {
            pre.wsState = WsStateList.StreamerReceived;
            return { ...pre };
          })
        } else if (state === "streamerStandby") {
          console.log(`${new Date().toISOString()}[debug]serviceOperation from wsConnection "streamerStandby"`);
          setAppContext((pre) => {
            pre.streamerStandby = true;
            return { ...pre };
          })
        }
        if (option && option.peerId && option.peerId.length > 0) {
          setAppContext((pre) => {
            pre.signagePeerId = option.peerId;
            pre.signageName = from.name;
            return { ...pre };
          })
        }
        if (option && option.mode === "Monitoring") {
          setAppContext((pre) => {
            pre.isMonitoring = true;
            return { ...pre };
          })
        }
        if (state === 'requestLogout') {
          setAppContext((pre) => {
            pre.requestLogout = true;
            return {
              ...pre,
            }
          })
        }
        break;
    }

    setDiffStatus(new Date().getTime());
  }, [diffStatus]);

  useEffect(() => {
    webAppStateRef.current = webAppState;
    if (webAppState === "Logout") {
      wsc.closeWs();
    }
  }, [webAppState]);

  // useEffect(() => {
  //   console.log(context);
  // }, [context]);

  useEffect(() => {
    setUserStatus({ ...userStatus, ...diffStatus });
    // alert("状態が変更されました")
  }, [diffStatus])

  useEffect(() => {
    setAppContext((pre) => {
      pre.receiveCount = 0;
      return { ...pre };
    });
    if (window.location.pathname.includes("/app")) {
      wsc.restartWs(informStateChange);
    }
  }, [])


  // const perfEntries = performance.getEntriesByType("navigation");

  // perfEntries.forEach((pe: any) => {
  //   /** 読み込みタイプを取得 */
  //   const type = pe.type;
  //   console.log(type);
  // });

  useEffect(() => {
    if (window.location.pathname.includes("/app")) {
      // wsc.restartWs(informStateChange);
      setCurrentPath((pre) => {
        if (pre.length && pre !== "/app/service/standby" && window.location.pathname === "/app/service/standby") {
          setTimeout(() => {
            location.reload();
          }, 500);
        } else if (pre.length && pre === "/app/service/operation" && window.location.pathname !== "/app/service/operation") {
          // 接客オペレーションからその他の画面に遷移したときリロードする
          setTimeout(() => {
            location.reload();
          }, 500);
        }
        return window.location.pathname;
      });
    }
    if (window.location.pathname.includes("/app/service/operation")) {
      console.log(`${new Date().toISOString()}[debug]wsConnection createPlayer in called by operator`);
    }
  }, [window.location.pathname])

  return (
    <>
      <AlertDialogBasic
        isOpenConfirm={isOpenConnectionError}
        setIsOpenConfirm={setIsOpenConnectionError}
        setIsForceSubmit={onConfirmErrorMsg}
        dialogContents={dialogContents}
        isNotDispCancel={true}
      />
      <ReceptionRequest />
    </>
  )
}

