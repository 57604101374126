import React, { useCallback, useEffect, useState } from "react";
// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  Spacer,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
  Icon,
  Badge,
  useColorModeValue,
  CSSReset,
  ChakraProvider
} from "@chakra-ui/react";
import {
  AdobexdLogo,
  AtlassianLogo,
  InvisionLogo,
  JiraLogo,
  SlackLogo,
  SpotifyLogo,
} from "components/Icons/Icons.js";
import { AddIcon } from '@chakra-ui/icons'
import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "components/Icons/Icons";
import { MdSettings } from "react-icons/md"
import { FaStore } from "react-icons/fa"
import { IoSettingsSharp } from "react-icons/io5"
import { useHistory } from "react-router-dom";

// Assets
import signInImage from "assets/img/signInImage.png";
import ProfileBgImage from "assets/img/ProfileBackground.png";
import StoreList from "components/ksin/List/StoreList";
import { CompanyClient } from "components/ksin/ApiClient/CompanyClient";

const demo_data=[
  { "id": 1, "storeName": "日立店"},
  { "id": 2, "storeName": "水戸店"},
  { "id": 3, "storeName": "つくば店"},
];


export default function StoreManagement() {
  // Chakra color mode
  const titleColor = useColorModeValue("teal.300", "teal.200");
  
  const history = useHistory();
  const onClickStoreAdd = useCallback((params) => history.push({pathname: "/app/store/add", state: params}), [history]);
  // TODO: 未使用のため一旦コメントアウト（別途削除）
  // const onClickStoreEdit = useCallback((params) => history.push({ pathname:"/store/edit/"+ params.id, state: params }), [history]);  // TODO：ユーザーIDを渡す
  // const onClickStoreSignage = useCallback((params) => history.push({ pathname: "/store/" + params.id + "/signage", state: params }), [history]);  // TODO：ユーザーIDを渡す
  // const onClickStoreUsers = useCallback((params) => history.push({ pathname: "/store/" + params.id + "/users", state: params }), [history]);  // TODO：ユーザーIDを渡す
  
  const [companyName, setCompanyName] = useState('');

  useEffect(() => {
    // NOTE: 企業名を取得する
    const companyClient = new CompanyClient();
    companyClient.get().then((data) => {
      if(data.companyName == '' || data.companyName == null)  return;
      setCompanyName(data.companyName);
    }).catch((error) => {
      console.log(error);
    })
  }, []);

  return (
      <Box
        mt="4rem"
        mb={{ sm: "205px", md: "75px", xl: "70px" }}
        borderRadius="15px"
        px="0px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        align="center"
        backgroundColor="white"
        paddingTop="20px"
        paddingLeft="20px"
        paddingBottom="50px"
        width="80%"
      >
        <Flex>
          <Box p="2">
            <Text
              color="black"
              fontWeight="light"
              textAlign="left"
              fontSize="large"
              fontWeight="bold"
              verticalAlign="center"
              mr="50px"
            >
              店舗一覧
            </Text>
          </Box>
          <Spacer />
          <Box>
            <Button
              fontSize="14px"
              type="submit"
              bg="teal.300"
              w="150px"
              h="30px"
              mb="10px"
              mr="8"
              color="white"
              borderRadius="5px"
              mt="20px"
              _hover={{
                bg: "teal.200",
              }}
              _active={{
                bg: "teal.400",
              }}
              onClick={() => onClickStoreAdd({companyName: companyName})}
            >
              <AddIcon color="inherit" mr="2" />
              店舗追加
            </Button>
          </Box>
        </Flex>
        <ChakraProvider>
          <CSSReset />
            <StoreList companyName={companyName}/>
        </ChakraProvider>
      </Box>
  );
}

// export default StoreManagement;
