import React, { useCallback, useEffect, useState  } from "react";
import { useHistory } from "react-router-dom";

// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Image,
  Link,
  Switch,
  Text,
  Spacer,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
  Icon,
  Badge,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  AdobexdLogo,
  AtlassianLogo,
  InvisionLogo,
  JiraLogo,
  SlackLogo,
  SpotifyLogo,
} from "components/Icons/Icons.js";
import { AddIcon } from '@chakra-ui/icons'
import { IoSettingsSharp } from "react-icons/io5"
import { FaStore } from "react-icons/fa"

// Assets
import signInImage from "assets/img/signInImage.png";
import ProfileBgImage from "assets/img/ProfileBackground.png";
import { StoreClient } from "components/ksin/ApiClient/StoreClient";
import { SignageStateList } from "../Users/SignageTemplateUpdate";
import ShowSignageOperators from "components/ksin/Forms/parts/ShowSignageOperators";

const demo_data=[
  {"place": "サービスカウンター", "store": "日立中央支店", "status": "受付中", "operater": "阿部、伊藤、宇野"},
  {"place": "キッチン家電売場", "store": "日立中央支店", "status": "混雑中", "operater": "阿部、伊藤、宇野"},
  {"place": "西館エントランス", "store": "日立中央支店", "status": "会話中", "operater": "阿部、伊藤、宇野"},
  {"place": "販促イベントエリア", "store": "日立中央支店", "status": "混雑中", "operater": "阿部、伊藤、宇野"},
]

function StoreSignage(props) {
  // Chakra color mode
  const storeId = props.location.state.id;
  const storeName = props.location.state.name;  // TODO：現状データがないため、引数で渡したデータを使用する
  const companyName = props.location.state.companyName;

  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.400", "white");

  const history = useHistory();
  const onClickStoreEdit = useCallback((params) => history.push({ pathname:"/app/store/update", state: params }), [history]);  // TODO：ユーザーIDを渡す
  const onClickStoreUsers = useCallback((params) => history.push({ pathname: "/store/users", state: params }), [history]);  // TODO：ユーザーIDを渡す
  const onClickViewTemplate = useCallback((params) => history.push({ pathname: "/app/signage/template", state: params }), [history]);
  const onClickMonitoring = useCallback((params) => history.push({ pathname: "/app/service/operation", state: params }), [history]);
  const onClickEdit = useCallback((params) => history.push({ pathname: "/app/signage/update", state: params }), [history]);
  const addSignage = useCallback((params) => history.push({pathname:"/app/signage/add", state: params}), [history]);

  const [data, setData] = useState([]);
  const [signageUsers, setSignageUsers] = useState([]);
  
  useEffect(() => {
    // NOTE: 対象店舗のサイネージ一覧を取得する
    const storeClient = new StoreClient();
    storeClient.get(storeId).then((data) => {
      console.log(data);
      if(data.signages.length > 0){
        setData(data.signages);
      }
      if(data.users.length > 0){
        setSignageUsers(data.users);
      }
    })
    .catch((error) => {
      console.log(error);
    })
  }, []);

  const getTargetCompanyStoreName = () => {
    return storeName != null ? `${companyName} / ${storeName}` : `${companyName}`;
  };

  return (
    <Flex direction="column">
      <Box
        mb={{ sm: "175px", md: "35px", xl: "30px" }}
        borderRadius="15px"
        px="0px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        align="center"
        backgroundColor="white"
        paddingTop="20px"
        paddingLeft="20px"
        paddingBottom="50px"
        width="50%"
      >
        <Flex>
          <Box p="2" mb="2">
            <Text
              color="black"
              fontWeight="light"
              textAlign="left"
              fontWeight="bold"
              // mb="40px"
              verticalAlign="center"
              mr="50px"
              fontSize="large"
            >
              店舗メニュー
            </Text>
          </Box>
        </Flex>
        <Flex align="center" minWidth="100%" flexWrap="nowrap">
          <Badge
            bg="teal.300"
            color="white"
            fontSize="20px"
            p="8px 8px"
            borderRadius="10px"
            mt="10px"
            mr="10px"
            textAlign="center"
          >
            <FaStore color="inherit" size={30} borderRadius="12px" />
          </Badge>
          <Text
            color="black"
            fontWeight="light"
            textAlign="left"
            fontWeight="bold"
            display="flex"
            alignItems="center"
            // verticalAlign="middle"
            mt="10px"
            mr="50px"
            fontSize="large"
          >
            {storeName}
          </Text>
          <Button
            fontSize="14px"
            type="submit"
            bg="teal.300"
            w="150px"
            h="30"
            mb="10px"
            mr="4"
            color="white"
            borderRadius="5px"
            mt="20px"
            _hover={{
              bg: "teal.200",
            }}
            _active={{
              bg: "teal.400",
            }}
            onClick={() => 
              onClickStoreUsers({
                "id": storeId,
                "name": storeName,
                "companyName": companyName,
              })
            }
          >
            ユーザー管理
          </Button>
          <Button
            fontSize="14px"
            type="submit"
            bg="teal.300"
            w="150px"
            h="30"
            mb="10px"
            mr="4"
            color="white"
            borderRadius="5px"
            mt="20px"
            _hover={{
              bg: "teal.200",
            }}
            _active={{
              bg: "teal.400",
            }}
            onClick={() => 
              onClickStoreEdit({
                "id": storeId,
              })
            }
          >
            店舗設定
          </Button>
        </Flex>
      </Box>
      <Box
        mb={{ sm: "205px", md: "75px", xl: "70px" }}
        borderRadius="15px"
        px="0px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        align="center"
        backgroundColor="white"
        paddingTop="20px"
        paddingLeft="20px"
        paddingBottom="50px"
      >
        <Flex>
          <Box p="2">
            <Text
              color="black"
              fontWeight="light"
              textAlign="left"
              fontWeight="bold"
              // mb="40px"
              verticalAlign="center"
              mr="50px"
              fontSize="large"
            >
              サイネージ一覧
            </Text>
          </Box>
          <Spacer />
          <Box>
            <Button
              fontSize="14px"
              type="submit"
              bg="teal.300"
              w="150px"
              h="30"
              mb="10px"
              mr="4"
              color="white"
              borderRadius="5px"
              mt="20px"
              _hover={{
                bg: "teal.200",
              }}
              _active={{
                bg: "teal.400",
              }}
              onClick={() => addSignage({
                companyName: companyName,
              })}
            >
              <AddIcon color="inherit" mr="2" />
              サイネージ追加
            </Button>
          </Box>
        </Flex>
        <Table variant="simple" color={textColor}>
          <Thead>
            <Tr my=".8rem" pl="0px">
              <Th pl="0px" color="gray.400">サイネージ</Th>
              <Th color="gray.400" textAlign="center">ステータス</Th>
              <Th color="gray.400" textAlign="center">オペレーター</Th>
              <Th color="gray.400" textAlign="center">モニタリング</Th>
              <Th color="gray.400" textAlign="center">表示</Th>
              <Th color="gray.400" textAlign="center">設定</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row, index) => 
              <Tr my=".8rem" pl="0px">
                <Td pl="0px" color="black.400">
                  <Flex align="center" minWidth="100%" flexWrap="nowrap">
                    <Avatar src={row.image ? row.image : ""} w="50px" borderRadius="12px" me="18px" />
                    <Flex direction="column">
                      <Text
                        fontSize="md"
                        color="black"
                        fontWeight="bold"
                        minWidth="100%"
                      >
                        {row.name}
                      </Text>
                      <Box  ml="0px">
                        <Text textAlign="left">
                          {getTargetCompanyStoreName()}
                        </Text>
                      </Box>
                    </Flex>
                  </Flex>
                </Td>
                <Td color="black.400"  textAlign="center">
                  <Badge
                    bg={row.status == "混雑中" ? "gray.300" : row.status == "受付中" ? "green.300" : "orange.400"}
                    color="white"
                    fontSize="16px"
                    p="3px 10px"
                    borderRadius="8px"
                  >
                    {row.status}
                  </Badge>
                </Td>
                <Td color="black.400"  textAlign="center">
                  <ShowSignageOperators
                    signageId={row.id}
                    signageUsers={signageUsers}
                    companyName={companyName}
                  />
                </Td>
                <Td color="black.400"  textAlign="center">
                  <Button
                    fontSize="14px"
                    type="submit"
                    bg="teal.300"
                    w="120px"
                    h="30"
                    color="white"
                    borderRadius="5px"
                    _hover={{
                      bg: "teal.200",
                    }}
                    _active={{
                      bg: "teal.400",
                    }}
                    onClick={() => onClickMonitoring({
                      mode: SignageStateList.Monitoring,
                      id: row.id,
                      companyName: companyName,
                      image: row.image,
                    })}
                  >
                    モニタリング
                  </Button>
                </Td>
                <Td color="black.400"  textAlign="center">
                  <Button
                    fontSize="14px"
                    type="submit"
                    bg="teal.300"
                    w="120px"
                    h="30"
                    color="white"
                    borderRadius="5px"
                    _hover={{
                      bg: "teal.200",
                    }}
                    _active={{
                      bg: "teal.400",
                    }}
                    onClick={() => onClickViewTemplate({
                      id: row.id,
                      companyName: getTargetCompanyStoreName(),
                      image: row.image,
                    })}
                  >
                    表示設定
                  </Button>
                </Td>
                <Td color="black.400"  textAlign="center">
                  <Button
                    fontSize="14px"
                    type="submit"
                    bg="teal.300"
                    width="30"
                    hight="30"
                    color="white"
                    borderRadius="5px"
                    _hover={{
                      bg: "teal.200",
                    }}
                    _active={{
                      bg: "teal.400",
                    }}
                    onClick={() => onClickEdit({
                      id: row.id,
                      companyName: companyName,
                    })}
                  >
                    <IoSettingsSharp color="inherit" size={20} />
                  </Button>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
    </Flex>
  );
}

export default StoreSignage;
