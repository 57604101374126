import { useFormContext } from 'react-hook-form'
import {
    FormErrorMessage,
    FormLabel,
    FormControl,
    Input,
    Box,
    Flex
} from '@chakra-ui/react'

export default function InputKana(props: any) {
    const {register, formState: { errors }} = useFormContext();

    return (
        <Box ml="0px" mb="24px">
            <FormControl 
            isInvalid={errors.kana}
            display="flex"
            >
                <FormLabel
                    ms="4px"
                    fontSize="sm"
                    textAlign="left"
                    fontWeight="bold"
                    display="flex"
                    width="200px"
                    alignItems="center"
                  >
                    {props.formInfo.labelName}
                </FormLabel>
                <Flex
                    flexDirection="column"
                >
                <Input
                    borderRadius="5px"
                    fontSize="sm"
                    type="text"
                    size="lg"
                    width="400px"
                    placeholder={props.formInfo.placeholder}
                    defaultValue={props.isAddMode || !props.userInfo ? "" : props.userInfo.kana}
                    {...register('kana', {
                        required: props.isAddMode ? '必須項目です' : false,
                        minLength: { value: 1, message: 'Minimum length should be 4' },
                        pattern: {
                            value: /^[ぁ-んー　]*$/,
                            message:  `ひらがなのみ入力可能です`
                        }
                    })}
                />
                <FormErrorMessage>
                    {errors.kana && errors.kana.message}
                </FormErrorMessage></Flex>
            </FormControl>
        </Box>
    )
}