import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Flex,
  Text,
} from "@chakra-ui/react";
import PopupEditSettingButton from "./PopupEditSettingButton";
import { useState } from "react";
import React from "react";
import ItemSettingButton from "./ItemSettingButton";
import {
  SettingButtonItem,
  SettingButtonState,
} from "views/Pages/Users/SignageTemplateUpdate";

import IconVertical from "assets/svg/vertical.svg";
import IconHorizontal from "assets/svg/hozirontal.svg";

const DrawerSettingButton = ({
  visible,
  onHide,
  settingButton,
  handleChange,
}: {
  visible: boolean;
  onHide: VoidFunction;
  settingButton: SettingButtonState;
  handleChange: (key: string, data: any) => void;
}) => {
  const [settingSelected, setSettingSelected] = useState(null);
  const isVertical = settingButton.direction === "vertical";

  const handleChangeSettingsItems = (item: SettingButtonItem) => {
    const _data = [...settingButton.data];
    const idxFindItemEdit = _data.findIndex((ele) => ele.id === item.id);
    _data.splice(idxFindItemEdit, 1, item);
    if (settingSelected) {
      setSettingSelected(item);
    }
    handleChange("data", _data);
  };

  return (
    <Drawer isOpen={visible} placement="right" onClose={onHide}>
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader paddingX={3}>タッチボタン設定</DrawerHeader>
        <DrawerBody paddingX={3}>
          <Flex direction="column" marginBottom={8}>
            <Flex direction="column">
              <Text fontSize="md" fontWeight="600">
                ボタンレイアウト
              </Text>
              <Flex marginTop={5} style={{ gap: "50px" }}>
                {["vertical", "horizontal"].map((item, key) => (
                  <React.Fragment key={key}>
                    <Box
                      onClick={() => {
                        handleChange("direction", item);
                      }}
                    >
                      <Box
                        padding="1"
                        display="flex"
                        justifyContent={"center"}
                        alignContent="center"
                        border="1px"
                        borderColor={"gray.300"}
                        width={45}
                        height={45}
                        background={
                          settingButton.direction === item ? "gray.200" : "none"
                        }
                        cursor='pointer'
                      >
                        <img
                          src={key === 0 ? IconVertical : IconHorizontal}
                          width="100%"
                          height="100%"
                        />
                      </Box>
                      <Text
                        textAlign={"center"}
                        fontSize={"sm"}
                        color="teal"
                        fontWeight={"semibold"}
                        marginTop={1}
                      >
                        {item === "vertical" ? "縦並" : "横並"}
                      </Text>
                    </Box>
                  </React.Fragment>
                ))}
              </Flex>
            </Flex>
            <Flex direction="column" marginTop={12}>
              <Text fontSize="md" fontWeight="600">
                タッチボタン表示
              </Text>
              <Flex
                direction={isVertical ? "column" : "row"}
                marginRight={10}
                marginTop={3}
                style={{ gap: isVertical ? "5px" : "30px" }}
                width={"full"}
              >
                {settingButton.data.map((touchButton, key) => (
                  <React.Fragment key={key}>
                    <ItemSettingButton
                      item={touchButton}
                      isEdited={settingSelected?.id === touchButton.id}
                      handleSelectedItem={(unSelected) => {
                        setSettingSelected(unSelected ? null : touchButton);
                      }}
                      handleChangeSettingsItems={handleChangeSettingsItems}
                      isVertical={isVertical}
                    />
                  </React.Fragment>
                ))}
              </Flex>
            </Flex>
          </Flex>
          <PopupEditSettingButton
            handleChangeSettingsItems={handleChangeSettingsItems}
            item={settingSelected}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default DrawerSettingButton;
