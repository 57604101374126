// Chakra Imports
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Flex,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Separator } from "components/Separator/Separator";
import React, { useEffect } from "react";
import { useState } from "react";
import InputTelop from "../Forms/parts/InputTelop";

export default function TelopConfigurator(props: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDisp, setIsDisp] = useState(true);
  const [telop, setTelop] = React.useState("");


  // メインエリアのボタンで編集メニューを選択されたとき
  useEffect(() => {
    if (props.isOpen) {
      if(props.currentTemplate) {
        setIsDisp(props.currentTemplate.is_view_telop);
        setTelop(props.currentTemplate.telop);
      }
      onOpen();
    }
  }, [props.isOpen]);

  // 閉じられたことを親コンポーネントに伝える
  useEffect(() => {
    props.setIsOpen(isOpen);
  }, [isOpen]);


  // テロップの表示・非表示切り替え
  useEffect(() => {
    if(props.currentTemplate) {
      props.templateTable.get(props.signageState).template.is_view_telop = isDisp;
      props.setTemplateTable(new Map(props.templateTable));
    }
  }, [isDisp]);

  // テロップの内容を親コンポーネントに伝える
  useEffect(() => {
    if(props.currentTemplate) {
      props.templateTable.get(props.signageState).template.telop = telop;
      props.setTemplateTable(new Map(props.templateTable));
    }
  }, [telop]);

  // 選択中のシーンが変わったらテロップ内容と表示状態はテンプレートに合わせる
  useEffect(() => {
    if(props.currentTemplate) {
      setIsDisp(props.templateTable.get(props.signageState).template.is_view_telop);
    }
  }, [props.signageState]);


  return (
    <>
      {/* <Button onClick={onOpen}>Open</Button> */}
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
      >
        <DrawerContent>
          <DrawerHeader pt="24px" px="24px">
            <DrawerCloseButton />
            <Text fontSize="xl" fontWeight="bold" mt="16px">
              テロップ設定
            </Text>
          </DrawerHeader>
          <DrawerBody w="340px" ps="24px" pe="40px">
            <Flex flexDirection="column">
              <Box>
                <Text fontSize="md" fontWeight="600">
                テロップ表示
                </Text>
                <Flex>
                  <Text fontSize="sm" mr="1rem">
                  テロップを表示する
                  </Text>
                  <Switch
                    colorScheme="teal"
                    isChecked={isDisp}
                    onChange={() => {
                      if (isDisp === true) {
                        setIsDisp(false);
                      } else {
                        setIsDisp(true);
                      }
                    }}
                  />
                </Flex>
              </Box>
              <Separator mt="1rem" mb="1rem" />
              <Box
                justifyContent="space-between "
                mb="16px"
              >
                <Text fontSize="md" fontWeight="600" mb="4px">
                  内容
                </Text>
              </Box>
              <InputTelop
                telop={telop}
                setTelop={setTelop}
              />
              <Separator mt="1rem" mb="1rem" />
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
