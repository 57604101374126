import { useHistory, useLocation  } from 'react-router-dom';
import { Flex, Box, Text  } from '@chakra-ui/react';
import ProfileBgImage from "assets/img/ProfileBackground.png";
import { useEffect, useState } from "react"
import moment from "moment"

export default function WaitingRoom() {

  const history = useHistory();
  const state = useLocation();
  const token = state.state?.token
  // console.log("date", date)

  const [date, setDate] = useState<string>()
  const convertTimeToSeconds = (dateString) => {
    const endTime = moment(dateString, 'YYYY-MM-DD HH:mm:ss');
    const currentTime = moment();
    const result = endTime.diff(currentTime, 'ms');
    return result;
  };
  const getExpTime = async () => {
    try {
      console.log("getExpTime function is called")
      if(token) {
        console.log("getExpTime function is called second time")
        const sendData = { token: token };
        const param = {
          method: "POST",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(sendData),
        };
    
        const response = await fetch(`/token/get/startTime`, param);
        const json = await response.json();

        const result =  convertTimeToSeconds(json.date)
        console.log(result)
        setTimeout(() =>{
          history.push({ pathname: "/app/signage/view", state: { date: date, token: token} }); 
        }, result)
      
        await setDate(json.date)
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const dateFormat = new Date(date)
  const formatDate = dateFormat.getDate() || "XX"
  const formatMonth = dateFormat.getMonth() + 1 || "XX"
  const formatHour = isNaN(dateFormat.getHours()) ? "XX" : dateFormat.getHours().toString().padStart(2, '0');
  const formatMinute = isNaN(dateFormat.getMinutes()) ? "XX" : dateFormat.getMinutes().toString().padStart(2, '0');
  const formattedDate = `${formatMonth}月${formatDate}日${formatHour}:${formatMinute}` 

  useEffect(()=> {
    getExpTime()
  }, [])

  return (
    <div>
      <Flex direction="column">
      <Box
        mb={{ sm: "205px", md: "75px", xl: "70px" }}
        borderRadius="15px"
        px="0px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        align="center"
      >
        <Box
          bgImage={ProfileBgImage}
          w="100%"
          h="300px"
          borderRadius="25px"
          bgPosition="50%"
          bgRepeat="no-repeat"
          position="relative"
          display="flex"
          justifyContent="center"
        >
        </Box>
        <Flex
          justify='center' 
          align='center'
          height="60vh"
          fontSize="2rem"
        >
          <Box>
            <Text>会議は{formattedDate}時から始まります</Text>
          </Box>
        </Flex>
        </Box>
      </Flex>
    </div>
  )
}
