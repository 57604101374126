// Chakra imports
import {
    Flex,
    Button,
    // CSSReset,
    // ChakraProvider
} from "@chakra-ui/react";
import { MdOutlineTouchApp } from 'react-icons/md'
function SignageTemplateEditButtons(props: any) {
    // console.log(`target_user_id: ${props.location.state.id}`);
    const { isStandBy, setIsOpenSettingButton } = props
    return (
        <Flex
            direction="column"
            bgColor="white">
            <Flex
                id="template-editor-buttons"
                direction="column"
                width="100px"
                alignItems="center"
            >
                <Button
                    fontSize="14px"
                    bg="teal.300"
                    h="4rem"
                    w="4rem"
                    mb="20px"
                    color="white"
                    mt="20px"
                    _hover={{
                        bg: "teal.200",
                    }}
                    _active={{
                        bg: "teal.400",
                    }}
                    colorScheme='teal'
                    onClick={() => { props.setIsOpenLogoConfigurator(true) }}
                // isLoading={isSubmitting}
                >
                    ロゴ
                </Button>
                <Button
                    fontSize="14px"
                    bg="teal.300"
                    h="4rem"
                    w="4rem"
                    mb="20px"
                    color="white"
                    mt="20px"
                    _hover={{
                        bg: "teal.200",
                    }}
                    _active={{
                        bg: "teal.400",
                    }}
                    colorScheme='teal'
                    onClick={() => { props.setIsOpenTelopConfigurator(true) }}
                // isLoading={isSubmitting}
                >
                    Text
                </Button>
                <Button
                    fontSize="14px"
                    bg="teal.300"
                    h="4rem"
                    w="4rem"
                    mb="20px"
                    color="white"
                    mt="20px"
                    _hover={{
                        bg: "teal.200",
                    }}
                    _active={{
                        bg: "teal.400",
                    }}
                    colorScheme='teal'
                    onClick={() => { props.setIsOpenMediaConfigurator(true) }}
                // isLoading={isSubmitting}
                >
                    メディア
                </Button>
                {isStandBy && props.showButtonWithLayoutChange && <Button
                    fontSize="14px"
                    bg="teal.300"
                    h="4rem"
                    w="4rem"
                    mb="20px"
                    color="white"
                    mt="20px"
                    _hover={{
                        bg: "teal.200",
                    }}
                    _active={{
                        bg: "teal.400",
                    }}
                    colorScheme='teal'
                    onClick={() => { setIsOpenSettingButton(true) }}
                >
                   <MdOutlineTouchApp size={'lg'} />
                </Button>}
            </Flex>
        </Flex>
    );
}

export default SignageTemplateEditButtons;
