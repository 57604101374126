import { useForm, FormProvider } from 'react-hook-form'
import React from 'react'
import {
  FormControl,
  Button,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react'
import { CheckCircleIcon } from '@chakra-ui/icons';
import InputName from './parts/InputName';
import { useHistory } from 'react-router-dom';
import { FormsContentsManager } from '../Common/contents/FormsContentsManager';

// Assets
export default function AddStoreForm(props: any) {
  const isAddMode = true;
  const userInfo = null;
  const formsInfo = new FormsContentsManager().get("Store");
  const [serverErrors, setServerErrors] = React.useState([]);
  const addServerError = (errObj: { code: string, msg: string }) => {
    setServerErrors([...serverErrors, errObj]);
    console.log(JSON.stringify(serverErrors));
  }
  const history = useHistory();
  const {
    formState: { isSubmitting },
  } = useForm()
  const methods = useForm();

  const convertFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      console.log("キー : " + key + ", 値 : " + data[key]);
      formData.append(key, data[key]);
    });

    return formData;
  }

  function onSubmit(values: any) {
    //error reset
    setServerErrors([]);

    //空文字のプロパティは削除
    Object.keys(values).forEach(function (key) {
      if(values[key] === ""){
        delete values[key];
      }
    });

    const formData = convertFormData(values);
    console.log(formData);
    const param = {
      method: 'POST',
      body: formData
    };

    return fetch(`/api/addStore`, param)
      .then(response => response.json())
      .then(json => {
        switch (json.result) {
          case "OK":
            // リスト画面へ遷移
            history.push({ pathname: "/app/store/list" });
            break;
          case "Error":
              addServerError(
                {
                  code: json.code,
                  msg: json.msg
                }
              );
              break;
          default:
            addServerError(
              {
                code: "SVER-Unexpected",
                msg: "未定義のサーバーエラーが発生しました"
              }
            );
        }
      });
  }

  return (
    <FormProvider {...methods} >
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <InputName
          isAddMode={isAddMode}
          userInfo={userInfo}
          formInfo={formsInfo.name} />
        <FormControl
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            fontSize="14px"
            bg="teal.300"
            h="30px"
            w="130px"
            mb="20px"
            color="white"
            mt="20px"
            _hover={{
              bg: "teal.200",
            }}
            _active={{
              bg: "teal.400",
            }}
            colorScheme='teal'
            isLoading={isSubmitting}
            type='submit'
          >
            <CheckCircleIcon color="inherit" mr="2" />
            確定
          </Button>
        </FormControl>
        <Alert
          status='error'
          display={serverErrors.length ? "flex" : "none"}
        >
          <AlertIcon />
          <AlertTitle mr={2}>{serverErrors.length ? serverErrors[0].code : ""}</AlertTitle>
          <AlertDescription>{serverErrors.length ? serverErrors[0].msg : ""}</AlertDescription>
        </Alert>
      </form></FormProvider>
  )
}