import { useForm, FormProvider } from 'react-hook-form'
import React, { useEffect } from 'react'
import {
  Box,
  Text,
  FormControl,
  Button,
  Spacer,
  Flex,
  AlertIcon,
  Alert,
  AlertDescription,
  AlertTitle,
} from '@chakra-ui/react'
import { CheckCircleIcon } from '@chakra-ui/icons';
import InputName from './parts/InputName';
import InputAffiliation from './parts/InputAffiliation';
import InputImage from './parts/InputImage';
import { useHistory } from 'react-router-dom';
import { FormsContentsManager } from '../Common/contents/FormsContentsManager';
import InputLoginId from './parts/InputLoginId';
import InputSignagePassword from './parts/InputSignagePassword';
import { SignageClient } from '../ApiClient/SignageClient';

// Assets
export default function AddSignageForm(props: any) {
  const companyName = props.location.state.companyName;
  const [] = React.useState(false)
  const history = useHistory();
  const {
    formState: { isSubmitting },
  } = useForm()
  const methods = useForm();

  const [serverErrors, setServerErrors] = React.useState([]);
  const [generatePassword, setGeneratePassword] = React.useState(null);

  const contents = new FormsContentsManager();

  const addServerError = (errObj: { code: string, msg: string }) => {
    setServerErrors([...serverErrors, errObj]);
    console.log(JSON.stringify(serverErrors));
  }

  const signageClient = new SignageClient();

  function onSubmit(values: any) {
    setServerErrors([]);

    signageClient.add(values)
      .then((json: any) => {
        switch (json.result) {
          case "OK":
            history.push({ pathname: "/app/users/list" });
            break;
          case "Error":
            addServerError(
              {
                code: json.code ? json.code : "SVER-99",
                msg: json.msg ? json.msg : "不明なエラーです"
              }
            );
            break;
          default:
            throw new Error();
        }
      })
      .catch((e) => {
        addServerError(
          {
            code: "SVER-Unexpected",
            msg: "未定義のサーバーエラーが発生しました"
          }
        );
    })
  }

  useEffect(() => {
    const sendData = {};
    const param = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      body: JSON.stringify(sendData)
    };

    fetch('/api/getSignageRandomPassword', param)
      .then(response => response.json())
        .then(json => {
          console.log(json);
          if(json.result == "OK"){
            setGeneratePassword(json.createPassword);
          }else{
            console.log(json.msg);
          }
        })
        .catch((error) => {
          console.log('Error:', error);
        })
  },[]);

  return (
    <Flex mt="70px" bgColor="white" borderRadius="10px" p="20px">
      <FormProvider {...methods} >
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Box>
            <Text
              color="black"
              textAlign="left"
              fontWeight="bold"
              verticalAlign="center"
              mt="20px"
              mb="20px"
              fontSize="large"
            >
              サイネージ追加
            </Text>
          </Box>
          <InputLoginId 
            isAddMode={true} 
            signageInfo={null}
            formInfo={contents.get("Signage").loginId}
          />
          <InputSignagePassword
            isInForm={false}
            generatePassword={generatePassword}
          />
          <InputName
            isAddMode={props.isAddMode}
            userInfo={props.userInfo}
            formInfo={contents.get("Signage").name} 
          />
          <InputAffiliation
            isAddMode={props.isAddMode}
            userInfo={props.userInfo}
            companyName={companyName}
          />
          <Spacer h="30px" />
          <InputImage
            isAddMode={props.isAddMode}
            userInfo={props.userInfo}
          />
          <Spacer h="30px" />
          <FormControl
            display="flex"
            justifyContent="flex-end"
          >
            <Button
              fontSize="14px"
              bg="teal.300"
              w="130px"
              h="30px"
              mb="20px"
              color="white"
              mt="20px"
              borderRadius="5px"
              _hover={{
                bg: "teal.200",
              }}
              _active={{
                bg: "teal.400",
              }}
              colorScheme='teal'
              isLoading={isSubmitting}
              type='submit'
            >
              <CheckCircleIcon color="inherit" mr="2" />
              確定
            </Button>
          </FormControl>
          <Alert
          status='error'
          display={serverErrors.length ? "flex" : "none"}
        >
          <AlertIcon />
          <AlertTitle mr={2}>{serverErrors.length ? serverErrors[serverErrors.length-1].code : ""}</AlertTitle>
          <AlertDescription>{serverErrors.length ? serverErrors[serverErrors.length-1].msg : ""}</AlertDescription>
        </Alert>
        </form>
      </FormProvider>
    </Flex>
  )
}