// Chakra imports
import {
  Box,
  Flex,
  Text,
  ChakraProvider,
  CSSReset,
} from "@chakra-ui/react";
// Assets
import UpdateProfileForm from "components/ksin/Forms/UpdateProfileForm";

function UserProfile() {
  // Chakra color mode
  return (
    <Flex direction="column">
      <Box
        mb={{ sm: "205px", md: "75px", xl: "70px" }}
        borderRadius="15px"
        px="0px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        align="center"
        backgroundColor="white"
        paddingTop="30px"
        paddingLeft="20px"
        paddingBottom="50px"
        fontSize="large"
      >
          <Text color="black" textAlign="left" fontWeight="bold" marginBottom="40px">
            プロフィール設定
          </Text>
          <ChakraProvider>
            <CSSReset />
              <UpdateProfileForm/>
          </ChakraProvider>
      </Box>
    </Flex>
  );
}

export default UserProfile;
