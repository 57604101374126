// Copyright 2023 The MediaPipe Authors.
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//      http://www.apache.org/licenses/LICENSE-2.0
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
import { drawConnectors, drawLandmarks } from "@mediapipe/drawing_utils";
import { HAND_CONNECTIONS } from "@mediapipe/hands";
import { DrawingUtils, FaceLandmarker, FilesetResolver, HandLandmarker, PoseLandmarker } from "@mediapipe/tasks-vision";
import { emitUIInteraction } from "./wsClient";

let faceLandmarker;
let handLandmarker;
let poseLandmarker;
let runningMode: any = "VIDEO"
let enableWebcamButton;
let webcamRunning = false;
const videoWidth = 480;
let handTrackingStatus = "notParmitted";
let usingIPhone = false;
// Before we can use HandLandmarker class we must wait for it to finish
// loading. Machine Learning models can be large and take a moment to
// get everything needed to run.

export class BrowserFaceCapture {
    handTrackingStatus: string;
    constructor() {
    }
    async init() {
        console.log(`[debug]BrowserFaceCapture init() called.`);
        this.runDemo();
        // this.setIPhoneControl();
    }
    setHandTrackingStatus(config: string) { // "enable" || "disable" || "notParmitted"
        handTrackingStatus = config;
    }
    async runDemo() {
        // Read more `CopyWebpackPlugin`, copy wasm set from "https://cdn.skypack.dev/node_modules" to `/wasm`
        const filesetResolver = await FilesetResolver.forVisionTasks("https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0/wasm");
        faceLandmarker = await FaceLandmarker.createFromOptions(filesetResolver, {
            baseOptions: {
                modelAssetPath: "/images/modelAsset/face_landmarker.task",
                delegate: "GPU"
            },
            outputFaceBlendshapes: true,
            runningMode: runningMode,
            numFaces: 1
        });
        console.log(`[debug]BrowserFaceCapture faceLandmarker created.`);
        handLandmarker = await HandLandmarker.createFromOptions(filesetResolver, {
            baseOptions: {
                modelAssetPath: `/images/modelAsset/hand_landmarker.task`,
                delegate: "GPU"
            },
            runningMode: runningMode,
            numHands: 2,
            // min_hand_detection_confidence: 0.7,
            // min_hand_presence_confidence: 0.7,
            // min_tracking_confidence: 0.7,
        });
        poseLandmarker = await PoseLandmarker.createFromOptions(filesetResolver, {
            baseOptions: {
                // modelAssetPath: `scripts/pose_landmarker_full.task`,
                modelAssetPath: `/images/modelAsset/pose_landmarker_full.task`,
                delegate: "GPU"
            },
            runningMode: runningMode,
            numPoses: 1
        });
        this.runDemo2();
    }


    /********************************************************************
    // Demo 2: Continuously grab image from webcam stream and detect it.
    ********************************************************************/
    async runDemo2() {
        const video: any = document.getElementById("webcam");
        const canvasElement: any = document.getElementById("output_canvas");
        const canvasCtx = canvasElement.getContext("2d");

        // If webcam supported, add event listener to button for when user
        // wants to activate it.
        if (hasGetUserMedia()) {
            enableWebcamButton = document.getElementById("webcamButton");
            enableWebcamButton.addEventListener("click", enableCam);
        }
        else {
            console.warn("getUserMedia() is not supported by your browser");
        }

        // Check if webcam access is supported.
        function hasGetUserMedia() {
            return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
        }

        // Enable the live webcam view and start detection.
        function enableCam(event: any) {
            if (!faceLandmarker) {
                console.log("[debug]BrowserFaceCapture Wait! faceLandmarker not loaded yet.");
                return;
            }
            if (webcamRunning === true) {
                webcamRunning = false;
                enableWebcamButton.innerText = "ENABLE PREDICTIONS";
            }
            else {
                webcamRunning = true;
                console.log(`[debug]BrowserFaceCapture webcamRunning set true.`);
                enableWebcamButton.innerText = "DISABLE PREDICTIONS";
            }
            // getUsermedia parameters.
            const constraints = {
                video: true
            };
            // Activate the webcam stream.
            navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
                video.srcObject = stream;
                video.addEventListener("loadeddata", predictWebcam);
            });
        }
        let lastVideoTime = -1;
        let results_face = undefined;
        let results_hand = undefined;
        let results_body = undefined;

        let nx = undefined; // nose position in camera space
        let ny = undefined;
        // let nz = undefined;
        let scale = undefined;
        const interPupilDistance = 7 // length betweeen eyes in [cm]
        const noseHeight = 147;
        const zScaleMagic = 2;
        let failureCountHandL = 0;
        let failureCountHandR = 0;

        const handJumpThreshold = 10; // threshold [cm] for hand position jump check
        let lastWrist_l_x = -128;
        let lastWrist_l_y = -128;
        let lastWrist_r_x = -128;
        let lastWrist_r_y = -128;

        const drawingUtils = new DrawingUtils(canvasCtx);
        async function predictWebcam() {
            const radio = video.videoHeight / video.videoWidth;
            video.style.width = videoWidth + "px";
            video.style.height = videoWidth * radio + "px";
            canvasElement.style.width = videoWidth + "px";
            canvasElement.style.height = videoWidth * radio + "px";
            canvasElement.width = video.videoWidth;
            canvasElement.height = video.videoHeight;
            // Now let's start detecting the stream.
            // if (runningMode === "IMAGE") {
            //     runningMode = "VIDEO";
            //     await faceLandmarker.setOptions({ runningMode: runningMode });
            // }
            let nowInMs = Date.now();
            if (lastVideoTime !== video.currentTime) {
                lastVideoTime = video.currentTime;
                if (!usingIPhone) results_face = faceLandmarker.detectForVideo(video, nowInMs); // Stop webcam facial capture when iPhone is enabled
                results_hand = handLandmarker.detectForVideo(video, nowInMs);
            }

            // EDIT by Akiya Souken START
            // drawBlendShapes(videoBlendShapes, results.faceBlendshapes);
            if (!usingIPhone && results_face.faceLandmarks.length) { // Stop webcam facial capture when iPhone is enabled
                // vertical vector to define the rotation of the head
                let vx = results_face.faceLandmarks[0][10].x - results_face.faceLandmarks[0][152].x;
                let vy = results_face.faceLandmarks[0][10].y - results_face.faceLandmarks[0][152].y;
                let vz = results_face.faceLandmarks[0][10].z - results_face.faceLandmarks[0][152].z;
                // horiyontal vector to define the rotation of the head
                let hx = results_face.faceLandmarks[0][33].x - results_face.faceLandmarks[0][263].x;
                let hy = results_face.faceLandmarks[0][33].y - results_face.faceLandmarks[0][263].y;
                let hz = results_face.faceLandmarks[0][33].z - results_face.faceLandmarks[0][263].z;
                // position of nose in image space
                nx = results_face.faceLandmarks[0][0].x;
                ny = results_face.faceLandmarks[0][0].y;
                // nz = results_face.faceLandmarks[0][0].z;
                scale = interPupilDistance / (Math.sqrt(hx ** 2 + hy ** 2 + hz ** 2) + 1e-6); // pupil distance = 7

                // Neck vertical angle tweak
                // let v_scale = Math.sqrt(vx ** 2 + vy ** 2 + vz ** 2 + 1e-6) * 0.1;
                // vx /= v_scale;
                // vy /= v_scale;
                // vz /= v_scale;
                // vz += parseFloat(document.getElementById('neckVerticalAngleSlider').value); // shift vertical vector forward/backward

                let scores = [
                    "Face",
                    results_face.faceBlendshapes[0]["categories"][3]["score"], // browInnerUp
                    results_face.faceBlendshapes[0]["categories"][1]["score"], // browDownLeft
                    results_face.faceBlendshapes[0]["categories"][2]["score"], // browDownRight
                    results_face.faceBlendshapes[0]["categories"][4]["score"], // browOuterUpLeft
                    results_face.faceBlendshapes[0]["categories"][5]["score"], // browOuterUpRight
                    results_face.faceBlendshapes[0]["categories"][17]["score"], // eyeLookUpLeft
                    results_face.faceBlendshapes[0]["categories"][18]["score"], // eyeLookUpRight
                    results_face.faceBlendshapes[0]["categories"][11]["score"], // eyeLookDownLeft
                    results_face.faceBlendshapes[0]["categories"][12]["score"], // eyeLookDownRight
                    results_face.faceBlendshapes[0]["categories"][13]["score"], // eyeLookInLeft
                    results_face.faceBlendshapes[0]["categories"][14]["score"], // eyeLookInRight
                    results_face.faceBlendshapes[0]["categories"][15]["score"], // eyeLookOutLeft
                    results_face.faceBlendshapes[0]["categories"][16]["score"], // eyeLookOutRight
                    results_face.faceBlendshapes[0]["categories"][9]["score"], // eyeBlinkLeft
                    results_face.faceBlendshapes[0]["categories"][10]["score"], // eyeBlinkRight
                    results_face.faceBlendshapes[0]["categories"][19]["score"], // eyeSquintLeft
                    results_face.faceBlendshapes[0]["categories"][20]["score"], // eyeSquintRight
                    results_face.faceBlendshapes[0]["categories"][21]["score"], // eyeWideLeft
                    results_face.faceBlendshapes[0]["categories"][22]["score"], // eyeWideRight
                    results_face.faceBlendshapes[0]["categories"][6]["score"], // cheekPuff
                    results_face.faceBlendshapes[0]["categories"][7]["score"], // cheekSquintLeft
                    results_face.faceBlendshapes[0]["categories"][8]["score"], // cheekSquintRight
                    results_face.faceBlendshapes[0]["categories"][50]["score"], // noseSneerLeft
                    results_face.faceBlendshapes[0]["categories"][51]["score"], // noseSneerRight
                    results_face.faceBlendshapes[0]["categories"][25]["score"], // jawOpen
                    results_face.faceBlendshapes[0]["categories"][23]["score"], // jawForward
                    results_face.faceBlendshapes[0]["categories"][24]["score"], // jawLeft
                    results_face.faceBlendshapes[0]["categories"][26]["score"], // jawRight
                    results_face.faceBlendshapes[0]["categories"][32]["score"], // mouthFunnel
                    results_face.faceBlendshapes[0]["categories"][38]["score"], // mouthPucker
                    results_face.faceBlendshapes[0]["categories"][33]["score"], // mouthLeft
                    results_face.faceBlendshapes[0]["categories"][39]["score"], // mouthRight
                    results_face.faceBlendshapes[0]["categories"][41]["score"], // mouthRollUpper
                    results_face.faceBlendshapes[0]["categories"][40]["score"], // mouthRollLower
                    results_face.faceBlendshapes[0]["categories"][43]["score"], // mouthShrugUpper
                    results_face.faceBlendshapes[0]["categories"][42]["score"], // mouthShrugLower
                    results_face.faceBlendshapes[0]["categories"][27]["score"], // mouthClose
                    results_face.faceBlendshapes[0]["categories"][44]["score"], // mouthSmileLeft
                    results_face.faceBlendshapes[0]["categories"][45]["score"], // mouthSmileRight
                    results_face.faceBlendshapes[0]["categories"][30]["score"], // mouthFrownLeft
                    results_face.faceBlendshapes[0]["categories"][31]["score"], // mouthFrownRight
                    results_face.faceBlendshapes[0]["categories"][28]["score"], // mouthDimpleLeft
                    results_face.faceBlendshapes[0]["categories"][29]["score"], // mouthDimpleRight
                    results_face.faceBlendshapes[0]["categories"][48]["score"], // mouthUpperUpLeft
                    results_face.faceBlendshapes[0]["categories"][49]["score"], // mouthUpperUpRight
                    results_face.faceBlendshapes[0]["categories"][34]["score"], // mouthLowerDownLeft
                    results_face.faceBlendshapes[0]["categories"][35]["score"], // mouthLowerDownRight
                    results_face.faceBlendshapes[0]["categories"][36]["score"], // mouthPressLeft
                    results_face.faceBlendshapes[0]["categories"][37]["score"], // mouthPressRight
                    results_face.faceBlendshapes[0]["categories"][46]["score"], // mouthStretchLeft
                    results_face.faceBlendshapes[0]["categories"][47]["score"], // mouthStretchRight
                    vx,
                    -vz,
                    -vy,
                    hx,
                    -hz,
                    -hy
                ];
                emitUIInteraction(scores);
            }

            if (handTrackingStatus === "enable" && nx !== undefined && results_hand.handednesses) {
                let isDetectedHandL = false;
                let isDetectedHandR = false;
                let isBodyEstimated = false;

                // for each detected hand
                for (let k = 0; k < results_hand.handednesses.length; k++) {
                    let payload = Array(21 * 3 + 1);
                    if (results_hand.handednesses[k][0].categoryName === "Right") {
                        // if the wrist position jumped
                        if (Math.abs(lastWrist_l_x - results_hand.landmarks[k][0].x) * scale > handJumpThreshold
                            || Math.abs(lastWrist_l_y - results_hand.landmarks[k][0].y) * scale > handJumpThreshold) {
                            // estimate body position once
                            if (!isBodyEstimated) {
                                results_body = poseLandmarker.detectForVideo(video, Date.now());
                            }

                            // if body landmark found
                            if (results_body.landmarks) {
                                // if wrist positions from body and hand models are far apart
                                if (Math.abs(results_body.landmarks[0][15].x - results_hand.landmarks[k][0].x) * scale > handJumpThreshold
                                    || Math.abs(results_body.landmarks[0][15].y - results_hand.landmarks[k][0].y) * scale > handJumpThreshold) {
                                    // hand detection failed
                                    continue;
                                }
                            }
                        }
                        isDetectedHandL = true;
                        failureCountHandL = 0
                        payload[0] = "HandL";
                        lastWrist_l_x = results_hand.landmarks[k][0].x
                        lastWrist_l_y = results_hand.landmarks[k][0].y
                    }
                    else {
                        // if the wrist position jumped
                        if (Math.abs(lastWrist_r_x - results_hand.landmarks[k][0].x) * scale > handJumpThreshold
                            || Math.abs(lastWrist_r_y - results_hand.landmarks[k][0].y) * scale > handJumpThreshold) {
                            // estimate body position once
                            if (!isBodyEstimated) {
                                results_body = poseLandmarker.detectForVideo(video, Date.now());
                            }

                            // if body landmark found
                            if (results_body.landmarks) {
                                // if wrist positions from body and hand models are far apart
                                if (Math.abs(results_body.landmarks[0][16].x - results_hand.landmarks[k][0].x) * scale > handJumpThreshold
                                    || Math.abs(results_body.landmarks[0][16].y - results_hand.landmarks[k][0].y) * scale > handJumpThreshold) {
                                    // hand detection failed
                                    continue;
                                }
                            }
                        }
                        isDetectedHandR = true;
                        failureCountHandR = 0
                        payload[0] = "HandR";
                        lastWrist_r_x = results_hand.landmarks[k][0].x
                        lastWrist_r_y = results_hand.landmarks[k][0].y
                    }
                    if (results_hand.landmarks[k].length) {
                        for (let i = 0; i < 21; i++) {
                            payload[3 * i + 1] = (results_hand.landmarks[k][i].x - nx) * scale; //X in UE
                            payload[3 * i + 3] = (results_hand.landmarks[k][i].y - ny) * -scale + noseHeight; //鼻の位置が高さ157cm. Z in UE
                            payload[3 * i + 2] = (results_hand.landmarks[k][i].z) * -scale * zScaleMagic; //Y in UE
                        }
                        //////////////////////////////////////////////////////
                        // 2023/11/21 Limit hand position within half screen
                        //////////////////////////////////////////////////////
                        // If Left wrist X < Nose X
                        if (payload[0] == "HandL" && payload[1] < 0) {
                            // Shift all joints of the hand
                            for (let i = 0; i < 21; i++) {
                                payload[3 * i + 1] -= payload[1]; //X in UE
                            }
                        }
                        // If Right wrist X > Nose X
                        else if (payload[0] == "HandR" && payload[1] > 0) {
                            // Shift all joints of the hand
                            for (let i = 0; i < 21; i++) {
                                payload[3 * i + 1] -= payload[1]; //X in UE
                            }
                        }
                        ////////////////////////////////////////////////
                        emitUIInteraction(payload);
                    }
                }

                if (!isDetectedHandL) {
                    failureCountHandL = failureCountHandL + 1;
                    emitUIInteraction(["FailHandL", failureCountHandL]);
                }
                if (!isDetectedHandR) {
                    failureCountHandR = failureCountHandR + 1;
                    emitUIInteraction(["FailHandR", failureCountHandR]);
                }
            }
            // // EDIT by Akiya Souken END

            canvasCtx.save();
            canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
            if (handTrackingStatus === "enable" && results_hand.landmarks) {
                for (const landmarks of results_hand.landmarks) {
                    drawConnectors(canvasCtx, landmarks, HAND_CONNECTIONS, {
                        color: "#00FF00",
                        lineWidth: 5
                    });
                    drawLandmarks(canvasCtx, landmarks, { color: "#FF0000", lineWidth: 2 });
                }
            }

            if (handTrackingStatus === "enable" && results_body && results_body.landmarks) {
                for (const landmark of results_body.landmarks) {
                    drawingUtils.drawLandmarks(landmark, {
                        radius: (data) => DrawingUtils.lerp(data.from.z, -0.15, 0.1, 5, 1)
                    });
                    drawingUtils.drawConnectors(landmark, PoseLandmarker.POSE_CONNECTIONS);
                }
            }
            canvasCtx.restore();

            if (results_face.faceLandmarks) {
                for (const landmarks of results_face.faceLandmarks) {
                    drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_TESSELATION, { color: "#C0C0C070", lineWidth: 1 });
                    drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_RIGHT_EYE, { color: "#FF3030" });
                    drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_RIGHT_EYEBROW, { color: "#FF3030" });
                    drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_LEFT_EYE, { color: "#30FF30" });
                    drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_LEFT_EYEBROW, { color: "#30FF30" });
                    drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_FACE_OVAL, { color: "#E0E0E0" });
                    drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_LIPS, { color: "#E0E0E0" });
                    drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_RIGHT_IRIS, { color: "#FF3030" });
                    drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_LEFT_IRIS, { color: "#30FF30" });
                }
            }


            // // Call this function again to keep predicting when the browser is ready.
            if (webcamRunning === true) {
                window.requestAnimationFrame(predictWebcam);
            }
        }
        // function drawBlendShapes(el, blendShapes) {
        //     if (!blendShapes.length) {
        //         return;
        //     }
        //     let htmlMaker = "";
        //     blendShapes[0].categories.map((shape) => {
        //         htmlMaker += `
        //   <li class="blend-shapes-item">
        //     <span class="blend-shapes-label">${shape.displayName || shape.categoryName}</span>
        //     <span class="blend-shapes-value" style="width: calc(${+shape.score * 100}% - 120px)">${(+shape.score).toFixed(4)}</span>
        //   </li>
        // `;
        //     });
        //     el.innerHTML = htmlMaker;
        // }

        // Auto Test.
        setTimeout(() => {
            enableCam({});
        }, 500);
    }


    ////////////////////////////////////
    // iPhone on / off control
    setIPhoneControl() {
        usingIPhone = false;
        let useIPhoneButton = document.getElementById("iPhoneButton");
        let liveLinkSubjectName: any = document.getElementById("liveLinkSubjectName");
        useIPhoneButton.addEventListener("click", useIPhone);
        function useIPhone(event) {
            if (usingIPhone === true) {
                usingIPhone = false;
                useIPhoneButton.innerText = "Use iPhone";
                emitUIInteraction(["FaceUseWebcam", liveLinkSubjectName.value]);
            }
            else {
                usingIPhone = true;
                useIPhoneButton.innerText = "Stop using iPhone";
                emitUIInteraction(["FaceUseIPhone", liveLinkSubjectName.value]);
            }
        }
    }

    //////////////////////////////////
}

