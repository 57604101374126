// Chakra imports
import { Flex, Box, Text, ChakraProvider, Portal, useDisclosure } from "@chakra-ui/react";
import Configurator from "components/Configurator/Configurator";
import Footer from "components/Footer/Footer.js";
// Layout components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminNavbarSample from "components/Navbars/AdminNavbarSample.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import React, { useState, createContext, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "routes.js";
// Custom Chakra theme
import theme from "theme/theme.js";
import FixedPlugin from "../components/FixedPlugin/FixedPlugin";
// Custom components
import MainPanel from "../components/Layout/MainPanel";
import PanelContainer from "../components/Layout/PanelContainer";
import PanelContent from "../components/Layout/PanelContent";
import StoreAdd from "views/Pages/Store/StoreAdd";
import InputUserStatus from "components/ksin/Status/InputUserStatus"
import PixelStreamer from "components/ksin/Streamer/PixelStreamer"
import WsConnection from "components/ksin/wsClient/WsConnection";
import { wsClient, WsStateType } from 'components/ksin/wsClient/wsClient';
import { UserClient } from "components/ksin/ApiClient/UserClient";

export const AppContext = createContext();
const wsc = new wsClient();
const wsContext = {
    wsc: wsc,
    wsState: "Unset",
    signageDecodeInfo : {
        isErrorOccured: false,
        lastErrorOccuredSignage: ""
    }
}

export default function Dashboard(props) {
    // app共通のcontext
    // webSocketClientのインスタンスを格納
    
    const [appContext, setAppContext] = useState(wsContext);
    const userClient = new UserClient();

    // 全画面表示フラグ
    const [isFullView, setIsFullView] = React.useState(false);
    const isFullViewPageList = [
        "/app/signage/view"
    ];

    useEffect(() => {
        setAppContext(wsContext);
    }, [])

    useEffect(() => {
        if (isFullViewPageList.indexOf(window.location.pathname) >= 0) {
            setIsFullView(true);
        } else {
            setIsFullView(false);
        }
    }, [window.location.pathname])

    useEffect(()=>{
        if(appContext.requestLogout){
            userClient.logout().then(()=>{
            window.location.href = '/login';
            setAppContext((prev)=>{
                prev.requestLogout = false;
                return {
                ...prev
            }
            })
        });
        }
    },[appContext.requestLogout])

    const { ...rest } = props;
    // states and functions
    const [sidebarVariant, setSidebarVariant] = useState("transparent");
    const [fixed, setFixed] = useState(false);
    // ref for main panel div
    const mainPanel = React.createRef();
    // functions for changing the states from components
    const getRoute = () => {
        return window.location.pathname !== "/admin/full-screen-maps";
    };
    const getActiveRoute = (routes) => {
        let activeRoute = "Default Brand Text";
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = getActiveRoute(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else if (routes[i].category) {
                let categoryActiveRoute = getActiveRoute(routes[i].views);
                if (categoryActiveRoute !== activeRoute) {
                    return categoryActiveRoute;
                }
            } else {
                if (
                    window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
                ) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    };
    // This changes navbar state(fixed or not)
    const getActiveNavbar = (routes) => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].category) {
                let categoryActiveNavbar = getActiveNavbar(routes[i].views);
                if (categoryActiveNavbar !== activeNavbar) {
                    return categoryActiveNavbar;
                }
            } else {
                if (
                    window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
                ) {
                    if (routes[i].secondaryNavbar) {
                        return routes[i].secondaryNavbar;
                    }
                }
            }
        }
        return activeNavbar;
    };
    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return getRoutes(prop.views);
            }
            if (prop.category === "account") {
                return getRoutes(prop.views);
            }
            if (prop.layout === "/app") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };
    const { isOpen, onOpen, onClose } = useDisclosure();
    document.documentElement.dir = "ltr";
    // Chakra Color Mode
    return (
        <ChakraProvider
            theme={theme}
            resetCss={false} >
            <AppContext.Provider value={{appContext, setAppContext}}>
                <WsConnection />
                {isFullView ?
                    <>
                        <PanelContainer isFullView={true}>
                            <Switch>
                                {getRoutes(routes)}
                            </Switch>
                        </PanelContainer>
                    </>
                    :
                    <>
                        <Sidebar routes={routes}
                            logoText={"アバター遠隔接客 KSIN"}
                            display="none"
                            sidebarVariant={sidebarVariant} {...rest}
                        />
                        <MainPanel ref={mainPanel}
                            // w={
                            //     {
                            //         base: "100%",
                            //         xl: "calc(100% - 275px)",
                            //     }
                            // }
                            w="calc(100% - 275px)"
                            overflow="auto"
                            >
                            <Portal >
                                <AdminNavbarSample onOpen={onOpen}
                                    logoText={"PURITY UI DASHBOARD"}
                                    brandText={getActiveRoute(routes)}
                                    secondary={getActiveNavbar(routes)}
                                    fixed={fixed} {...rest}
                                /> </Portal>
                            {getRoute() ? (
                                <PanelContent >
                                    <PanelContainer>
                                        <Switch >
                                            {getRoutes(routes)}
                                            {/* <Redirect from="/app" to="/app/users/list" /> */}
                                        </Switch>
                                    </PanelContainer >
                                </PanelContent>
                            )
                                : null
                            }
                            <Portal>
                                </Portal > 
                        </MainPanel>
                    </>}
            </AppContext.Provider>
        </ChakraProvider>
    );
}
