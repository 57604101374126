import { Flex, Box, Text  } from '@chakra-ui/react'
import ProfileBgImage from "assets/img/ProfileBackground.png"

export default function Logout() {
    return (
        <div>
            <Flex direction="column">
            <Box
            mb={{ sm: "205px", md: "75px", xl: "70px" }}
            borderRadius="15px"
            px="0px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            align="center"
            >
            <Box
                bgImage={ProfileBgImage}
                w="100%"
                h="300px"
                borderRadius="25px"
                bgPosition="50%"
                bgRepeat="no-repeat"
                position="relative"
                display="flex"
                justifyContent="center"
            >
            </Box>
            <Flex
                justify='center' 
                align='center'
                height="60vh"
                fontSize="2rem"
            >
                <Box>
                <Text>会議は終了されました</Text>
                </Box>
            </Flex>
            </Box>
            </Flex>
    </div>
    )
}
