// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  Button,
  Text,
  Spacer,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
  useColorModeValue
} from "@chakra-ui/react";
import { AddIcon } from '@chakra-ui/icons'
import { IoSettingsSharp } from "react-icons/io5"
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SignageClient } from "components/ksin/ApiClient/SignageClient";
import { CompanyClient } from "components/ksin/ApiClient/CompanyClient";
import ShowAffiliation from "components/ksin/Forms/parts/ShowAffiliation";
import { StoreClient } from "components/ksin/ApiClient/StoreClient";
import ShowSignageOperators from "components/ksin/Forms/parts/ShowSignageOperators";
import { SignageStateList } from "../Users/SignageTemplateUpdate";
import { AppContext } from "layouts/AppCommons";
import ShowStatusBadge from "components/ksin/List/parts/ShowStatusBadge";
const signageClient = new SignageClient();
const storeClient = new StoreClient();

function SignageList(props: any) {
  const { appContext, setAppContext } = useContext(AppContext);
  const isOperator = props.isOperator ? props.isOperator : false;
  console.log(isOperator);
  // Chakra color mode
  const textColor = useColorModeValue("gray.400", "white");
  const history = useHistory();
  // クリックイベント
  const onClickEdit = useCallback((params) => history.push({ pathname: "/app/signage/update", state: params }), [history]);
  // const onClickOperators = useCallback((params) => history.push({ pathname: "/app/signage/operator", state: params }), [history]);
  const onClickViewTemplate = useCallback((params) => history.push({ pathname: "/app/signage/template", state: params }), [history]);
  const onClickMonitoring = useCallback((params) => {
    history.push({ pathname: "/app/service/operation", 
    state: params }) }, 
    [history]);
  const addSignage = useCallback((params) => history.push({pathname:"/app/signage/add", state: params}), [history]);

  // サーバーから取得するデータ
  const [signageList, setSignageList] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [signageStateTable, setSignageStateTable] = useState(new Map());

  // サイネージ状態テーブルに受信したstateを追加
  useEffect(() => {
    if(appContext.receivedStateInfo) {
      let map = new Map();
      
      appContext.receivedStateInfo.map(element => {
        if(element.type === "Signage") {
          map.set(element.id, element.state)
        }
      });
      setSignageStateTable(new Map(map));
    }
  }, [appContext.receivedStateInfoTime]);

  const getSuspendState = (signageId: string) => {
    if(signageStateTable.get(signageId) === "Suspended") {
      return "再開する";
    } else {
      return "休止する";
    }
  }

  const shouldMonitoring = (id) => {
    const state = signageStateTable.get(id);
    const allowMonitoringStatus = ["Standby", "Suspended"];
    if(allowMonitoringStatus.includes(state)) {
      return true;
    } else {
      return false;
    }
  }

  const toggleSuspendState = (signageId: string) => {
    let nextState;
    if(signageStateTable.get(signageId) === "Suspended") {
      // signageStateTable.delete(signageId);
      nextState = "restart";
    } else {
      // signageStateTable.set(signageId, "Suspended");
      nextState = "Suspended";
    }
    setAppContext((pre) => {
      console.log(appContext.signageId);
      pre.signageId = signageId;
      pre.signageSuspendState = nextState;
      return { ...pre };
    });
    // setSignageStateTable(new Map(signageStateTable));
  }

  // マウント後の単発処理
  useEffect(() => {
    // サイネージリストを取得する
    signageClient.get().then((data: any) => {
      if(props.isDispAllAffiliation) {
        // サイネージの所属に関係なくすべて表示(接客スタンバイ用)
        setSignageList(data);
      } else {
        // 企業直下のサイネージを抽出
        // const notAffiliations = data.filter(s => s.storeId === null);
        setSignageList(data);
      }
    })
      .catch((error) => {
      })
    // 店舗リストを取得する
    storeClient.get().then((data: any) => {
      setStoreList(data);
    })
      .catch((error) => {
      })

    // NOTE: 企業名を取得する
    const companyClient = new CompanyClient();
    companyClient.get().then((data: any) => {
      if(data.companyName == '' || data.companyName == null)  return;
      setCompanyName(data.companyName);
    }).catch((error) => {
      console.log(error);
    })

    //test
    // setSignageStateTable((pre) => {
    //   pre.set("01G01AADQEVD4AGX8PFTV5B969", "Standby");
    //   return pre;
    // })
  }, []);

  const getTargetCompanyStoreName = (targetStoreId: string) => {
    let targetCompanyStoreName = companyName;
    for(let index=0; index<storeList.length; index+=1){
      if(storeList[index].id == targetStoreId){
        targetCompanyStoreName = `${companyName} / ${storeList[index].name}`;
        break;
      }
    }

    return targetCompanyStoreName;
  };

  return (
    <Flex 
    direction="column" 
    mt={props.isOperatorView ? "0px" : "70px"}>
      <Box
        mb={{ sm: "205px", md: "75px", xl: "70px" }}
        borderRadius="15px"
        px="0px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        align="center"
        backgroundColor="white"
        paddingTop="20px"
        paddingLeft="20px"
        paddingBottom="50px"
      >
        <Flex>
          <Box p="2">
            <Text
              color="black"
              textAlign="left"
              fontWeight="bold"
              // mb="40px"
              verticalAlign="center"
              mr="50px"
              fontSize="large"
            >
              サイネージ一覧
            </Text>
          </Box>
          <Spacer />
          {props.isOperatorView ? <></> : 
          <Box>
            <Button
              fontSize="14px"
              type="submit"
              bg="teal.300"
              w="150px"
              h="30"
              mb="10px"
              mr="4"
              color="white"
              borderRadius="5px"
              mt="20px"
              _hover={{
                bg: "teal.200",
              }}
              _active={{
                bg: "teal.400",
              }}
              onClick={() => addSignage({
                companyName: companyName,
              })}
            >
              <AddIcon 
              color="inherit" 
              mr="2" />
              サイネージ追加
            </Button>
          </Box>}
        </Flex>
        <Table variant="simple" color={textColor}>
          <Thead>
            <Tr my=".8rem" pl="0px">
              <Th pl="0px" color="gray.400">サイネージ</Th>
              <Th color="gray.400" textAlign="center">ステータス</Th>
              <Th color="gray.400" textAlign="center">オペレーター</Th>
              <Th color="gray.400" textAlign="center">モニタリング</Th>
              {props.isOperatorView ? <></> :
              <>
                <Th color="gray.400" textAlign="center">表示</Th>
                <Th color="gray.400" textAlign="center">休止</Th>
                <Th color="gray.400" textAlign="center">設定</Th>
              </>}
            </Tr>
          </Thead>
          <Tbody>
            {signageList.map((row) =>
              <Tr my=".8rem" pl="0px">
                <Td pl="0px" color="black.400">
                  <Flex align="center" minWidth="100%" flexWrap="nowrap">
                    <Avatar src={row.image ? row.image : ""} w="50px" borderRadius="12px" me="18px" />
                    <Flex direction="column">
                      <Text
                        fontSize="md"
                        color="black"
                        fontWeight="bold"
                        minWidth="100%"
                      >
                        {row.name}</Text>
                      <ShowAffiliation
                        storeId={row.storeId}
                        storeList={storeList}
                        companyName={companyName}
                      />
                    </Flex>
                  </Flex>
                </Td>
                <Td color="black.400" textAlign="center">
                  <ShowStatusBadge
                    state={signageStateTable.get(row.id) ? signageStateTable.get(row.id) : "Logout"}
                    dispInfo={SignageStateDispTable}
                  />
                </Td>
                <Td color="black.400" textAlign="center">
                  <ShowSignageOperators
                    signageId={row.id}
                    signageUsers={row.users}
                    companyName={companyName}
                  />
                </Td>
                <Td color="black.400" textAlign="center">
                  <Button
                    fontSize="14px"
                    type="submit"
                    bg="teal.300"
                    w="120px"
                    h="30"
                    color="white"
                    borderRadius="5px"
                    _hover={{
                      bg: "teal.200",
                    }}
                    _active={{
                      bg: "teal.400",
                    }}
                    onClick={() => onClickMonitoring({
                      mode: SignageStateList.Monitoring,
                      id: row.id,
                      companyName: companyName,
                    })}
                    disabled={!shouldMonitoring(row.id)}
                  >
                    モニタリング
                  </Button>
                </Td>
                {props.isOperatorView ? <></> : 
                <>
                  <Td color="black.400" textAlign="center">
                    <Button
                      fontSize="14px"
                      type="submit"
                      bg="teal.300"
                      w="120px"
                      h="30"
                      color="white"
                      borderRadius="5px"
                      _hover={{
                        bg: "teal.200",
                      }}
                      _active={{
                        bg: "teal.400",
                      }}
                      onClick={() => onClickViewTemplate({
                        id: row.id,
                        companyName: getTargetCompanyStoreName(row.storeId),
                        image: row.image,
                      })}
                    // isDisabled={row.status === "受付中" ? false : true}
                    >
                      表示設定
                    </Button>
                  </Td>
                  <Td color="black.400" textAlign="center">
                    <Button
                      fontSize="14px"
                      type="submit"
                      bg="teal.300"
                      w="70px"
                      h="30"
                      color="white"
                      borderRadius="5px"
                      _hover={{
                        bg: "teal.200",
                      }}
                      _active={{
                        bg: "teal.400",
                      }}
                      onClick={() => toggleSuspendState(row.id)}
                    >
                      {getSuspendState(row.id)}
                    </Button>
                  </Td>
                  <Td color="black.400" textAlign="center">
                    <Button
                      fontSize="14px"
                      type="submit"
                      bg="teal.300"
                      width="30"
                      hight="30"
                      color="white"
                      borderRadius="5px"
                      _hover={{
                        bg: "teal.200",
                      }}
                      _active={{
                        bg: "teal.400",
                      }}
                      onClick={() => onClickEdit({
                        id: row.id,
                        companyName: companyName,
                      })}
                    >
                      <IoSettingsSharp color="inherit" size={20} />
                    </Button>
                  </Td>
                </>}
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
    </Flex>
  );
}

export default SignageList;

export const SignageStateDispTable = {
    Standby: {
      dispName: "受付中",
      bgColor: "blue.400" 
    },
    Calling: {
      dispName: "呼出中",
      bgColor: "orange.400" 
    },
    Active: {
      dispName: "会話中",
      bgColor: "cyan.400" 
    },
    Monitoring: {
      dispName: "監視中",
      bgColor: "green.400" 
    },
    Crowded: {
      dispName: "混雑中",
      bgColor: "red.400" 
    },
    Suspended: {
      dispName: "休止中",
      bgColor: "gray.800" 
    },
    Logout: {
      dispName: "オフライン",
      bgColor: "gray.400" 
    }
}

export type ObjectStateInfo = {
  type: "Signage" | "User",
  id: "string",
  state: string,
  isMonitoring?: boolean
}
