export class ConvertFormData {
    constructor() {
    }
    valuesToFormData(data: any) :FormData {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });
        return formData;
    }
}
