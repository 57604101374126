const signageContents = {
    vertical: {
        mediaAndAvatar: {
            avatar: {
                isDisp: true,
                src: `/images/Characters/monitoringCharacter/monitoringCharacter_vertical_andMedia.mp4`,
                width: -1,
                height: 0.56,
                top: "",
                bottom: "0%",
                left: "",
                right: ""
            },
            logo: {
                isDisp: true,
                src: "/images/Common/ksin-logo.png",
                width: "30%",
                top: "3%",
                bottom: "",
                left: "3%",
                right: ""
            },
            media: {
                isDisp: true,
                zIndex: "10",
                src: "/images/Default/demo.mp4",
                width: "100%",
                height: "",
                top: "15%",
                bottom: "",
                left: "",
                right: "",
                arr: []
            },
            text: {
                isDisp: true,
                msg: "",
                width: "42%",
                top: -1,
                bottom: 0.25,
                left: 0.03,
                right: -1,
                padding: "1em",
                fontSize: 0.03,
                bgColor: "rgba(0,0,0,0.6)",
                color: "white"
            }
        },
        onlyAvatar: {
            avatar: {
                isDisp: true,
                src: `/images/Characters/monitoringCharacter/monitoringCharacter_vertical_onlyAvatar.mp4`,
                width: "100%",
                height: 1,
                top: "0px",
                bottom: "0px",
                left: "0px",
                right: "0px"
            },
            logo: {
                isDisp: true,
                src: "/images/Common/ksin-logo.png",
                width: "30%",
                top: "3%",
                bottom: "",
                left: "3%",
                right: ""
            },
            media: {
                isDisp: false,
                src: "",
                width: "100%",
                height: "",
                top: "3%",
                bottom: "",
                left: "3%",
                right: "",
                arr: []
            },
            text: {
                isDisp: true,
                msg: "ご用事の方はボタンを押してください",
                width: "100%",
                top: 0.1,
                bottom: -1,
                left: -1,
                right: -1,
                padding: "1em",
                fontSize: 0.03,
                bgColor: "rgba(0,0,0,0.6)",
                color: "white"
            }
        },
        wipe: {
            avatar: {
                isDisp: true,
                zIndex: "10",
                src: `/images/Characters/monitoringCharacter/monitoringCharacter_vertical_onlyAvatar.mp4`,
                width: 0.2,
                height: 0.2,
                top: "3%",
                bottom: "",
                left: "",
                right: "3%"
            },
            logo: {
                isDisp: true,
                zIndex: "10",
                src: "/images/Common/ksin-logo.png",
                width: "30%",
                top: "3%",
                bottom: "",
                left: "3%",
                right: ""
            },
            media: {
                isDisp: true,
                src: "/images/Default/demo.mp4",
                width: "100%",
                height: "100%",
                top: "",
                bottom: "",
                left: "",
                right: "",
                arr: []
            },
            text: {
                isDisp: true,
                msg: "",
                width: "50%",
                top: 0.09,
                bottom: -1,
                left: -1,
                right: 0.25,
                padding: "1em",
                fontSize: 0.025,
                bgColor: "rgba(0,0,0,0.6)",
                color: "white"
            }
        },
        onlyMedia: {
            avatar: {
                isDisp: false,
                zIndex: "10",
                src: `/images/Characters/monitoringCharacter/monitoringCharacter_vertical_onlyAvatar.mp4`,
                width: 0.2,
                height: 0.2,
                top: "3%",
                bottom: "",
                left: "",
                right: "3%"
            },
            logo: {
                isDisp: true,
                zIndex: "10",
                src: "/images/Common/ksin-logo.png",
                width: "30%",
                top: "3%",
                bottom: "",
                left: "3%",
                right: ""
            },
            media: {
                isDisp: true,
                src: "/images/Default/demo.mp4",
                width: "100%",
                height: "100%",
                top: "",
                bottom: "",
                left: "",
                right: "",
                arr: []
            },
            text: {
                isDisp: true,
                msg: "ただいま準備中です",
                width: "100%",
                top: 0.1,
                bottom: -1,
                left: -1,
                right: -1,
                padding: "1em",
                fontSize: 0.03,
                bgColor: "rgba(0,0,0,0.6)",
                color: "white"
            }
        } 
    },
    wide: {
        mediaAndAvatar: {
            avatar: {
                isDisp: true,
                zIndex: "0",
                src: `/images/Characters/monitoringCharacter/monitoringCharacter_wide_andMedia.mp4`,
                width: "100%",
                height: "100%",
                top: "0%",
                bottom: "0%",
                left: "0%",
                right: "0%"
            },
            logo: {
                isDisp: true,
                src: "/images/Common/ksin-logo.png",
                width: "30%",
                top: "3%",
                bottom: "",
                left: "3%",
                right: ""
            },
            media: {
                isDisp: true,
                zIndex: "10",
                src: "/images/Default/demo.mp4",
                width: "70%",
                height: "",
                top: "15%",
                bottom: "",
                left: "",
                right: "",
                arr: []
            },
            text: {
                isDisp: true,
                msg: "",
                width: "20%",
                top: 0.15,
                bottom: -1,
                left: -1,
                right: 0.12,
                padding: "1em",
                fontSize: 0.04,
                bgColor: "rgba(0,0,0,0.6)",
                color: "white"
            }
        },
        onlyAvatar: {
            avatar: {
                isDisp: true,
                src: `/images/Characters/monitoringCharacter/monitoringCharacter_wide_onlyAvatar.mp4`,
                width: "100%",
                height: "100%",
                top: "0px",
                bottom: "0px",
                left: "0px",
                right: "0px"
            },
            logo: {
                isDisp: true,
                src: "/images/Common/ksin-logo.png",
                width: "30%",
                top: "3%",
                bottom: "",
                left: "",
                right: "3%"
            },
            media: {
                isDisp: false,
                src: "",
                width: "100%",
                height: "",
                top: "3%",
                bottom: "",
                left: "3%",
                right: "",
                arr: []
            },
            text: {
                isDisp: true,
                msg: "",
                width: "30%",
                top: 0.4,
                bottom: -1,
                left: 0.1,
                right: -1,
                padding: "1em",
                fontSize: 0.05,
                bgColor: "rgba(0,0,0,0.6)",
                color: "white"
            }
        },
        wipe: {
            avatar: {
                isDisp: true,
                zIndex: "10",
                src: `/images/Characters/monitoringCharacter/monitoringCharacter_vertical_onlyAvatar.mp4`,
                width: 0.3,
                height: 0.3,
                top: "3%",
                bottom: "",
                left: "",
                right: "3%"
            },
            logo: {
                isDisp: true,
                zIndex: "10",
                src: "/images/Common/ksin-logo.png",
                width: "30%",
                top: "3%",
                bottom: "",
                left: "3%",
                right: ""
            },
            media: {
                isDisp: true,
                src: "/images/Default/demo.mp4",
                width: "100%",
                height: "100%",
                top: "",
                bottom: "",
                left: "",
                right: "",
                arr: []
            },
            text: {
                isDisp: true,
                msg: "",
                width: "30%",
                top: 0.1,
                bottom: -1,
                left: -1,
                right: 0.4,
                padding: "1em",
                fontSize: 0.04,
                bgColor: "rgba(0,0,0,0.6)",
                color: "white"
            }
        },
        onlyMedia: {
            avatar: {
                isDisp: false,
                zIndex: "10",
                src: `/images/Characters/monitoringCharacter/monitoringCharacter_vertical_onlyAvatar.mp4`,
                width: 0.2,
                height: 0.2,
                top: "3%",
                bottom: "",
                left: "",
                right: "3%"
            },
            logo: {
                isDisp: true,
                zIndex: "10",
                src: "/images/Common/ksin-logo.png",
                width: "30%",
                top: "3%",
                bottom: "",
                left: "3%",
                right: ""
            },
            media: {
                isDisp: true,
                src: "/images/Default/demo.mp4",
                width: "100%",
                height: "100%",
                top: "",
                bottom: "",
                left: "",
                right: "",
                arr: []
            },
            text: {
                isDisp: true,
                msg: "",
                width: "100%",
                top: -1,
                bottom: 0,
                left: -1,
                right: -1,
                padding: "1em",
                fontSize: 0.04,
                bgColor: "rgba(0,0,0,0.6)",
                color: "white"
            }
        } 
    }
}

export class SignageContentsManager {
    constructor() {
    }
    get(ratioMode: string, layoutName: string) {
        return signageContents[ratioMode][layoutName];
    }
}