import React from "react";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
  CSSReset,
  ChakraProvider
} from "@chakra-ui/react";
// Assets
import signInImage from "assets/img/signInImage.png";
import ProfileBgImage from "assets/img/ProfileBackground.png";
import RegisterForm from 'components/ksin/Forms/RegisterForm.js'
import HookForm from 'components/HookForm/HookForm.js'

function Register() {
  // Chakra color mode
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.400", "white");
  return (
    <Flex direction="column">
      <Box
        mb={{ sm: "205px", md: "75px", xl: "70px" }}
        borderRadius="15px"
        px="0px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        align="center"
      >
        <Box
          bgImage={ProfileBgImage}
          w="100%"
          h="300px"
          borderRadius="25px"
          bgPosition="50%"
          bgRepeat="no-repeat"
          position="relative"
          display="flex"
          justifyContent="center"
        >
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          align="center"
          position="absolute"
          top="50px"
          left="200px"
        >
          <Text color="white" fontWeight="light" textAlign="left">
            アバター接客 KSIN
          </Text>
        </Box>
        <Box
          w="400px"
          // h="500px"
          borderRadius="15px"
          px="0px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          align="center"
          position="absolute"
          top="150px"
          left="0"
          right="0"
          backgroundColor="white"
          margin="auto"
        >
          <ChakraProvider>
            <CSSReset />
            <Box p={4}>
              <RegisterForm />
            </Box>
          </ChakraProvider>
        </Box>
      </Box>
    </Flex>
  );
}

export default Register;
