import { useForm, FormProvider } from 'react-hook-form'
import {
  FormControl,
  Button,
  Text,
  Flex
} from '@chakra-ui/react'
import InputPasswordMain from './parts/InputPasswordMain';
import { ConvertFormData } from '../util/ConvertFormData';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { UserClient } from '../ApiClient/UserClient';

export default function PasswordSettingForm() {
  const history = useHistory();
  const [serverErrors, setServerErrors] = React.useState([]);
  const {
    formState: { isSubmitting },
  } = useForm()
  const methods = useForm();
    // memo: "targetUserId: "SELF""で自分のユーザーデータを取得する
    const targetUserId: string = "SELF";

  // const [isPasswordChange, setIsPasswordChange] = React.useState(false);
  const setIsPasswordChange = () => { };

  function onSubmit(values) {
    // 対象ユーザーの指定
    values.targetUserId = targetUserId;

    const formData = new ConvertFormData().valuesToFormData(values);
    const client = new UserClient();

    const param = {
      method: 'POST',
      body: formData
    };

    const addServerError = (errObj: { code: string, msg: string }) => {
      setServerErrors([...serverErrors, errObj]);
      console.log(JSON.stringify(serverErrors));
    }

    return fetch(`/api/updateUser`, param)
      .then(response => response.json())
      .then(json => {
        switch (json.result) {
          case "OK":
            console.log(`Update Success.`);
            // ログイン情報変更時はログアウト
            client.logout().then((result: string) => {
              if (result === "OK") {
                history.push({ pathname: "/login" });
              }
            })
            break;
          case "Error":
            if (parseInt(json.code.replace("SVER-", "")) >= 100) {
              break;
            } else {
              addServerError(
                {
                  code: json.code,
                  msg: json.msg
                }
              );
              break;
            }
          default:
            addServerError(
              {
                code: "SVER-Unexpected",
                msg: "未定義のサーバーエラーが発生しました"
              }
            );
        }
      })
    }

  return (
      <FormProvider {...methods}>
        <Flex padding="1rem">
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Text fontSize="lg" fontWeight="bold">
              パスワード変更
          </Text>
            <Text fontSize="sm">
              利用開始前にパスワードを変更してください
          </Text>
            <FormControl mt="2rem">
              <InputPasswordMain
                setIsPasswordChange={setIsPasswordChange}
                isInForm={false}
              />
              <Button
                fontSize="14px"
                bg="teal.300"
                w="400px"
                h="45"
                mb="20px"
                color="white"
                mt="20px"
                _hover={{
                  bg: "teal.200",
                }}
                _active={{
                  bg: "teal.400",
                }}
                colorScheme='teal'
                isLoading={isSubmitting}
                type='submit'
              >
                変更
            </Button>
  
            </FormControl>
          </form>
        </Flex>
      </FormProvider>
    )
  }