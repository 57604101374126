import { useFormContext } from 'react-hook-form'
import {
    FormErrorMessage,
    FormLabel,
    FormControl,
    Input,
    Box,
    Flex
} from '@chakra-ui/react'

export default function InputLoginIdMail(props: any) {
    const { register, formState: { errors } } = useFormContext();

    return (
        <Box ml="0px" mb="24px">
            <FormControl
                isInvalid={errors.login_id}
                display="flex"
                justifyContent={props.isDispLabel === false ? "center" : "flex-start"}
            >
                <FormLabel
                    ms="4px"
                    fontSize="sm"
                    textAlign="left"
                    fontWeight="bold"
                    display={props.isDispLabel === false ? "none" : "inline-block"}
                    width="200px"
                >
                    メールアドレス<br />(ログインID)
                </FormLabel>
                <Flex
                    flexDirection="column"
                >
                    <Input
                        id="login_id"
                        borderRadius="5px"
                        fontSize="sm"
                        type="text"
                        size="lg"
                        width="400px"
                        placeholder="Email"
                        defaultValue={props.isAddMode || !props.userInfo ? "" : props.userInfo.loginId}
                        // defaultValue={loginId}
                        {...register('login_id', {
                            required: props.isAddMode ? '必須項目です' : false,
                            minLength: { value: 1, message: 'Minimum length should be 4' },
                        })}
                    />
                    <FormErrorMessage>
                        {/* {errors.login_id && errors.login_id.message} */}
                        {errors.login_id && errors.login_id.message}
                    </FormErrorMessage></Flex>
            </FormControl>
        </Box>
    )
}