import React from "react";
import { useParams, useHistory } from "react-router-dom";

// Chakra imports
import {
  Avatar,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Select,
  Text,
  Spacer,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
  Icon,
  Badge,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  AdobexdLogo,
  AtlassianLogo,
  InvisionLogo,
  JiraLogo,
  SlackLogo,
  SpotifyLogo,
} from "components/Icons/Icons.js";
import { AddIcon } from '@chakra-ui/icons'
import { IoSettingsSharp } from "react-icons/io5"

// Assets
import signInImage from "assets/img/signInImage.png";
import ProfileBgImage from "assets/img/ProfileBackground.png";

const demo_data=[
  {"name": "阿部", "role": "管理者", "store": "日立店", "avatorCh": "なし", "ch": "なし", "status": "離席中"},
  {"name": "加納", "role": "管理者", "store": "日立店", "avatorCh": "1", "ch": AdobexdLogo, "status": "離席中"},
  {"name": "伊藤", "role": "", "store": "日立店", "avatorCh": "1", "ch": AdobexdLogo, "status": "受付中"},
  {"name": "上野", "role": "", "store": "つくばサポートセンター", "avatorCh": "2", "ch": AdobexdLogo, "status": "会話中"},
]

function OperatorList(props) {
  // Chakra color mode
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.400", "white");
  const { ch } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const { 
    isOpen: isOpenRescissionModal, 
    onOpen: onOpenRescissionModal, 
    onClose: onCloseRescissionModal
  } = useDisclosure();
  const cancelModalRef = React.useRef();

  return (
    <Flex direction="column">
      <Box
        mb={{ sm: "205px", md: "75px", xl: "70px" }}
        borderRadius="15px"
        px="0px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        align="center"
        backgroundColor="white"
        paddingTop="20px"
        paddingLeft="20px"
        paddingBottom="50px"
      >
        <Flex mb="20px">
          <Box p="2">
            <Text
              color="black"
              fontWeight="light"
              textAlign="left"
              fontWeight="bold"
              // mb="40px"
              verticalAlign="center"
              mr="50px"
              fontSize="large"
            >
              アバター{ch}使用オペレーター一覧
            </Text>
          </Box>
          <Spacer />
          <Box display="flex">
            <Button
              fontSize="14px"
              type="submit"
              bg="teal.300"
              w="200px"
              h="30"
              mb="10px"
              mr="4"
              color="white"
              borderRadius="5px"
              mt="20px"
              _hover={{
                bg: "teal.200",
              }}
              _active={{
                bg: "teal.400",
              }}
              onClick={onOpen}
            >
              表情アプリ設定確認
            </Button>
            <AlertDialog
              motionPreset="slideInBottom"
              leastDestructiveRef={cancelRef}
              onClose={onClose}
              isOpen={isOpen}
              isCentered
              size="xs"
            >
              <AlertDialogOverlay />
              <AlertDialogContent borderRadius="10px">
                <AlertDialogHeader>表示アプリ設定情報</AlertDialogHeader>
                <AlertDialogCloseButton />
                <AlertDialogBody>
                  <Flex mb="10px">
                    <Text fontSize="sm" fontWeight="normal">
                      使用ch：2
                    </Text>
                  </Flex>
                  <Flex flexDirection="column">
                    <Box mt="20px">
                      <Text fontSize="md" fontWeight="bold" color="blue.500">
                        ①サブジェクト名：
                      </Text>
                    </Box>
                    <Box ml="20px">
                      <Text fontSize="md" fontWeight="bold" color="blue.500">
                        K351N-face
                      </Text>
                    </Box>
                    <Box mt="20px">
                      <Text fontSize="md" fontWeight="bold" color="blue.500">
                        ②IPアドレス：
                      </Text>
                    </Box>
                    <Box ml="20px">
                      <Text fontSize="md" fontWeight="bold" color="blue.500">
                        192.168.100.1
                      </Text>
                    </Box>
                    <Box mt="20px">
                      <Text fontSize="md" fontWeight="bold" color="blue.500">
                        ③ポート番号：
                      </Text>
                    </Box>
                    <Box ml="20px">
                      <Text fontSize="md" fontWeight="bold" color="blue.500">
                        20895
                      </Text>
                    </Box>
                  </Flex>
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button ml={3} onClick={onClose} width="100px">
                    OK
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
            {/* </Box> */}
          </Box>
        </Flex>
        <Table variant="simple" color={textColor} width="95%" ml="5" >
          <Thead>
            <Tr my=".8rem" pl="0px">
              <Th pl="0px" color="gray.400">
                ユーザー
              </Th>
              <Th color="gray.400" textAlign="center">キャラクター</Th>
              <Th color="gray.400" textAlign="center">ステータス</Th>
              <Th color="gray.400" textAlign="center">ステータス変更</Th>
              <Th color="gray.400" textAlign="center">解除</Th>
            </Tr>
          </Thead>
          <Tbody>
            {demo_data.map((row, index) => 
              <Tr my=".8rem" pl="0px">
                <Td pl="0px" color="black.400">
                  <Flex align="center" minWidth="100%" flexWrap="nowrap">
                    <Avatar src={AdobexdLogo} w="50px" borderRadius="12px" me="18px" />
                    <Flex direction="column">
                      <Text
                        fontSize="md"
                        color="black"
                        fontWeight="bold"
                        minWidth="100%"
                      >
                        {row.name}
                        {row.role === "管理者" ?
                          <Badge
                            bg="red.600"
                            color="white"
                            fontSize="8px"
                            p="0px 12px"
                            borderRadius="3px"
                            ml="2"
                          >
                            {row.role}
                          </Badge> :
                          <></>
                        }
                      </Text>
                      <Text fontSize="sm" color="gray.400" fontWeight="normal">
                        {row.store}
                      </Text>
                    </Flex>
                  </Flex>
                </Td>
                <Td color="black.400"  textAlign="center">
                  <Link color="teal.500" href="#" textDecoration="underline">
                    {row.ch == "なし" ? 
                      "なし" : <Avatar src={row.ch} w="50px" borderRadius="12px"/>
                    }
                  </Link>
                </Td>
                <Td color="black.400"  textAlign="center">
                  <Badge
                    bg={row.status == "離席中" ? "gray.300" : row.status == "受付中" ? "green.300" : "orange.400"}
                    color="white"
                    fontSize="16px"
                    p="3px 10px"
                    borderRadius="8px"
                  >
                    {row.status}
                  </Badge>
                </Td>
                <Td color="black.400"  textAlign="center">
                  <Button
                    fontSize="14px"
                    type="submit"
                    bg="teal.300"
                    w="120px"
                    h="30"
                    color="white"
                    borderRadius="5px"
                    _hover={{
                      bg: "teal.200",
                    }}
                    _active={{
                      bg: "teal.400",
                    }}
                    isDisabled={row.status === "受付中" ? false : true}
                  >
                    離席中にする
                  </Button>
                </Td>
                <Td color="black.400"  textAlign="center">
                  <Button
                    fontSize="14px"
                    type="submit"
                    bg="red.600"
                    w="120px"
                    h="30"
                    color="white"
                    borderRadius="5px"
                    _hover={{
                      bg: "red.200",
                    }}
                    _active={{
                      bg: "red.400",
                    }}
                    isDisabled={row.status === "会話中" ? true : false}
                    onClick={onOpenRescissionModal}
                  >
                    解除
                  </Button>
                  <AlertDialog
                    motionPreset="slideInBottom"
                    leastDestructiveRef={cancelModalRef}
                    onClose={onCloseRescissionModal}
                    isOpen={isOpenRescissionModal}
                    isCentered
                    size="xs"
                  >
                    <AlertDialogOverlay />
                    <AlertDialogContent borderRadius="10px">
                      <AlertDialogHeader>表示アプリ設定情報</AlertDialogHeader>
                      <AlertDialogCloseButton />
                      <AlertDialogBody>
                        <Text fontSize="md" fontWeight="normal">
                          選択したユーザーを解除しますか？
                        </Text>
                      </AlertDialogBody>
                      <AlertDialogFooter>
                        <Button ref={cancelModalRef} onClick={onCloseRescissionModal} colorScheme="red">
                          キャンセル
                        </Button>
                        <Button ml={3} onClick={onCloseRescissionModal} width="100px">
                          OK
                        </Button>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialog>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
    </Flex>
  );
}

export default OperatorList;
