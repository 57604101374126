import { useForm, FormProvider } from 'react-hook-form'
import React, { useEffect, useState } from 'react'
import {
  FormControl,
  Button,
  Spacer,
  Flex,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Text,
} from '@chakra-ui/react'
import { CheckCircleIcon } from '@chakra-ui/icons';
import InputName from './parts/InputName';
import InputAffiliation from './parts/InputAffiliation';
import InputImage from './parts/InputImage';
import { useHistory } from 'react-router-dom';
import { FormsContentsManager } from '../Common/contents/FormsContentsManager';
import InputLoginId from './parts/InputLoginId';
import { SignageClient } from '../ApiClient/SignageClient';
import InputPassword from './parts/InputPassword';
import { StoreClient } from '../ApiClient/StoreClient';
import AlertDialogConfirmPassword from '../Common/AlertDialogConfirmPassword';
import AlertDialogBasic from '../Common/AlertDialogBasic';
import { AppContext } from 'layouts/AppCommons';
import { useContext } from 'react'
import ShowCharacter from './parts/ShowCharacter';
const signageClient = new SignageClient();
const storeClient = new StoreClient();

// Assets
export default function UpdateSignageForm(props: any) {
  const { appContext } = useContext(AppContext);
  const wsc = appContext.wsc;
  const ws = wsc.getWs();
  const history = useHistory();
  const {
    formState: { isSubmitting },
  } = useForm()
  const methods = useForm();

  // 対象サイネージID(管理画面からの遷移時に取得)
  const targetId: string = props.location.state.id;
  const companyName: string = props.location.state.companyName;
  const targetUserId: string = "SELF";
  // ラベル名など固定値
  const contents = new FormsContentsManager();
  const dialogTemplate = {
    header: "",
    body: "",
  }

  // 画面内で更新されるデータ
  const [isPasswordChange, setIsPasswordChange] = React.useState(false);
  const [serverErrors, setServerErrors] = React.useState([]);
  const [signageInfo, setSignageInfo] = useState(null);
  const [storeList, setStoreList] = useState([]);
  const [dialogContents, setDialogContents] = React.useState(dialogTemplate);

  // パスワード再入力ダイアログの状態管理
  const [isOpenConfirm, setIsOpenConfirm] = React.useState(false);
  const [isOpenConfirmPassword, setIsOpenConfirmPassword] = React.useState(false);
  const [passwordConfirmError, setPasswordConfirmError] = React.useState(null);
  const [characterId, setCharacterId] = React.useState("");
  const [userInfo, setUserInfo] = React.useState(null);

  const getUserCurrent = async () => {
    const sendData = {
      targetUserId: targetUserId
    };

    const param = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      body: JSON.stringify(sendData)
    };

    return new Promise((resolve, reject) =>
      fetch(`/api/getUser`, param)
        .then(response => {
          return response.json()
        })
        .then(json => {
          console.log(json);
          resolve(json);
        })
        .catch((error) => {
          console.error('Error:', error);
          reject(error);
        })
    )
  }
  
  // マウント直後の単発処理
  useEffect(() => {
    // サイネージ情報を取得する
    signageClient.get(targetId).then((data: any) => {
      setSignageInfo(data);
      setUserInfo((prev)=>{
        return {
          ...prev,
          characterId: data.default_character.id,
          characterName: data.default_character.name
        }
      })
    })
      .catch((error) => {
      })
    // 店舗リストを取得する
    storeClient.get().then((data: any) => {
      setStoreList(data);
      console.log(storeList);
    })
      .catch((error) => {
      })

    // getUserCurrent().then((data: any) => {
    //   setUserInfo(data)
    // })
  }, [])

  // サーバーからエラーが返されたときに表示する
  const addServerError = (errObj: { code: string, msg: string }) => {
    setServerErrors([...serverErrors, errObj]);
    console.log(JSON.stringify(serverErrors));
  }

  // 確認ダイアログ
  const openConfirm = (data: {
    header: string,
    msg: string
  }) => {
    setDialogContents({
      header: data.header,
      body: data.msg
    });
    setIsOpenConfirm(true);
  }

  // 確定(サーバー送信)
  function onSubmit(values: any) {

    //error reset
    setServerErrors([]);
    setPasswordConfirmError(null);

    // 対象IDの指定
    values.targetId = targetId;
    if(signageInfo.default_character.id !== characterId){
      values.default_character_id = characterId;
    }
    // ログイン情報の変更時は現在のパスワードを再入力させる
    if (!isOpenConfirmPassword) {
      if (isPasswordChange || (values.login_id.length > 0 && values.login_id !== signageInfo.login_id)) {
        setIsOpenConfirmPassword(true);
        return null;
      }
    }

    // 空文字または変更されていないプロパティは削除
    if (values.login_id === signageInfo.login_id || values.login_id === "") {
      delete values.login_id;
    }
    if (values.password === "" || !isPasswordChange) {
      delete values.password;
    }
    if (!values.currentPassword || values.currentPassword === "") {
      delete values.currentPassword;
    }
    if (values.name === signageInfo.userName || values.name === "") {
      delete values.name;
    }
    if (values.role === signageInfo.role) {
      delete values.role;
    }
    if (values.image === "") {
      delete values.image;
    }

    signageClient.update(values)
      .then((json: any) => {
        switch (json.result) {
          case "OK":
            if (isOpenConfirmPassword) {              
              ws.send(JSON.stringify({
                type: 'requestLogout',
                objectId: signageInfo.id
              }));
            }
            history.goBack();
            break;
          case "Error":
            if (parseInt(json.code.replace("SVER-", "")) >= 100) {
              setPasswordConfirmError(json.msg);
              break;
            } else {
              addServerError(
                {
                  code: json.code,
                  msg: json.msg
                }
              );
              break;
            }
          default:
            throw new Error();
        }
      })
      .catch((e) => {
        addServerError(
          {
            code: "SVER-Unexpected",
            msg: "未定義のサーバーエラーが発生しました"
          }
        );
      })
  }

  // 削除確定時
  const onSubmitDelete = () => {
    signageClient.delete(targetId).then((json: any) => {
      switch (json.result) {
        case "OK":
          history.goBack();
          break;
        case "Error":
          if (parseInt(json.code.replace("SVER-", "")) >= 100) {
            setPasswordConfirmError(json.msg);
            break;
          } else {
            addServerError(
              {
                code: json.code,
                msg: json.msg
              }
            );
            break;
          }
        default:
          throw new Error();
      }
    }).catch((error) => {
      addServerError(
        {
          code: "SVER-Unexpected",
          msg: "未定義のサーバーエラーが発生しました"
        }
      );
    })
  }

  return (
    <Flex mt="70px" bgColor="white" borderRadius="10px" p="20px">
      <FormProvider {...methods} >
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <AlertDialogBasic
            id="delete-dialog"
            isOpenConfirm={isOpenConfirm}
            setIsOpenConfirm={setIsOpenConfirm}
            setIsForceSubmit={onSubmitDelete}
            dialogContents={dialogContents}
          />
          <Box>
            <Text
              color="black"
              textAlign="left"
              fontWeight="bold"
              verticalAlign="center"
              mt="20px"
              mb="20px"
              fontSize="large"
            >
              サイネージ設定
            </Text>
          </Box>
          <InputLoginId
            isAddMode={false}
            userInfo={signageInfo}
            formInfo={contents.get("Signage").loginId}
          />
          <InputPassword
            setIsPasswordChange={setIsPasswordChange}
            isInForm={true}
          />
          <InputName
            isAddMode={false}
            userInfo={signageInfo}
            formInfo={contents.get("Signage").name} />
          <InputAffiliation
            isAddMode={false}
            userInfo={signageInfo}
            companyName={companyName}
          />
          <Spacer h="30px" />
          <InputImage
            isAddMode={false}
            userInfo={signageInfo}
            fileType={"image"}
          />
          <Spacer h="30px" />
          <ShowCharacter
            row={userInfo}
            isForm={true}
            isAddMode={false}
            setFormCharacterId={setCharacterId}
            companyName={companyName}
            isSignage={true}
          />
          <FormControl
            display="flex"
            position="relative"
            height="60px"
          >
            <Button
              position="absolute"
              right="0"
              fontSize="14px"
              bg="teal.300"
              h="30px"
              w="130px"
              mb="20px"
              color="white"
              mt="20px"
              borderRadius="5px"
              _hover={{
                bg: "teal.200",
              }}
              _active={{
                bg: "teal.400",
              }}
              colorScheme='teal'
              isLoading={isSubmitting}
              type='submit'
            >
              <CheckCircleIcon color="inherit" mr="2" />
              確定
            </Button>
            <Button
              position="absolute"
              left="0"
              fontSize="14px"
              bg="red.600"
              w="130px"
              h="30px"
              mb="10px"
              mr="8"
              color="white"
              borderRadius="5px"
              mt="20px"
              _hover={
                {
                  bg: "red.200",
                }
              }
              _active={{
                bg: "red.400",
              }}
              onClick={() => {
                openConfirm({ header: "確認", msg: "本当に削除しますか?" });
              }}
              isDisabled={false}
            >
              サイネージ削除
            </Button>
          </FormControl>
          <AlertDialogConfirmPassword
            isOpen={isOpenConfirmPassword}
            setIsOpen={setIsOpenConfirmPassword}
            clickSubmit={methods.handleSubmit(onSubmit)}
            serverError={passwordConfirmError}
          />
          <Alert
            status='error'
            display={serverErrors.length ? "flex" : "none"}
          >
            <AlertIcon />
            <AlertTitle mr={2}>{serverErrors.length ? serverErrors[0].code : ""}</AlertTitle>
            <AlertDescription>{serverErrors.length ? serverErrors[0].msg : ""}</AlertDescription>
          </Alert>
        </form>
      </FormProvider>
    </Flex>
  )
}