import { useFormContext } from 'react-hook-form'
import {
    FormErrorMessage,
    FormLabel,
    FormControl,
    Input,
    InputGroup,
    InputRightElement,
    IconButton,
    Box,
    Flex
} from '@chakra-ui/react'
import { IoIosCopy } from 'react-icons/io';

export default function InputLoginId(props: any) {
    const { register, watch, formState: { errors } } = useFormContext();
    const watchSignageId = watch("login_id");
    const handleClickCopy = () => {
        navigator.clipboard.writeText(watchSignageId)
            .then(function() {
                console.log('Copying to clipboard was successful!');
            }, function(err) {
                console.error('Could not copy text: ', err);
            });
    };
    return (
        <Box ml="0px" mb="24px">
            <FormControl
                isInvalid={errors.login_id}
                display="flex"
                justifyContent="flex-start"
            >
                <FormLabel
                    ms="4px"
                    marginBottom="none"
                    fontSize="sm"
                    textAlign="left"
                    fontWeight="bold"
                    display="flex"
                    width="200px"
                    alignItems="center"
                >
                    {props.formInfo ? props.formInfo.labelName : "ログインID"}
                </FormLabel>
                <Flex
                    flexDirection="column"
                >
                    <InputGroup alignItems="center" width="400px">
                        <Input
                            id="login_id"
                            borderRadius="5px"
                            fontSize="sm"
                            type="text"
                            size="lg"
                            width="400px"
                            placeholder={props.formInfo ? props.formInfo.placeholder : ""}
                            defaultValue={props.isAddMode || !props.userInfo ? "" : props.userInfo.login_id}
                            // defaultValue={loginId}
                            {...register('login_id', {
                                required: props.isAddMode ? '必須項目です' : false,
                                minLength: { value: 1, message: 'Minimum length should be 4' },
                            })}
                        />
                        <InputRightElement width='2.5rem' h="100%" display="flex">
                            <IconButton
                                // p="-1"
                                h="20px"
                                w="10px"
                                _focus={{ boxShadow: "none" }}
                                variant='ghost'
                                onClick={() => handleClickCopy()}
                                colorScheme='teal'
                                aria-label='Call Segun'
                                size='lg'
                                minWidth="2rem"
                                icon={<IoIosCopy />}
                            />
                        </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>
                        {/* {errors.login_id && errors.login_id.message} */}
                        {errors.login_id && errors.login_id.message}
                    </FormErrorMessage></Flex>
            </FormControl>
        </Box>
    )
}