import {
    FormLabel,
    Box,
    Button} from '@chakra-ui/react'
import { useState } from 'react';
import InputPasswordMain from './InputPasswordMain';

export default function InputPassword(props: any) {
    const [isChangePasswordMode, setIsChangePasswordMode] = useState(false);

    return (
        <Box ml="0px" mb="24px" display="flex" justifyContent="flex-start">
            
                <FormLabel
                    ms="4px"
                    fontSize="sm"
                    textAlign="left"
                    fontWeight="bold"
                    display="inline-block"
                    width="200px"
                >
                    パスワード
                </FormLabel>
                {isChangePasswordMode ?
                    <InputPasswordMain 
                        setIsPasswordChange={props.setIsPasswordChange}
                        isInForm={props.isInForm}
                        setIsChangePasswordMode={setIsChangePasswordMode}
                    /> :
                    <Button
                        fontSize="14px"
                        bg="teal.300"
                        pl="2rem"
                        pr="2rem"
                        h="30px"
                        borderRadius="5px"
                        color="white"
                        _hover={{
                            bg: "teal.200",
                        }}
                        _active={{
                            bg: "teal.400",
                        }}
                        colorScheme='teal'
                        onClick={() => {
                            setIsChangePasswordMode(true);
                            props.setIsPasswordChange(true);
                        }}
                    >
                        パスワード変更
                </Button>
                }
        </Box>
    )
}