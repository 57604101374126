import { useFormContext } from 'react-hook-form'
import {
    FormErrorMessage,
    FormControl,
    Box,
    Flex,
    Textarea
} from '@chakra-ui/react'

export default function InputTelop(props: any) {
    const {setValue, formState: { errors }} = useFormContext();
    const onChangeTelop = (e) => {
        console.log(e.target.value);
        setValue("telop", e.target.value);
        props.setTelop(e.target.value);
    }

    return (
        <Box ml="0px" mb="24px">
            <FormControl 
            isInvalid={errors.name}
            display="flex"
            >
                <Flex
                    flexDirection="column"
                >
                <Textarea
                    borderRadius="5px"
                    fontSize="sm"
                    type="text"
                    size="lg"
                    width="100%"
                    height="200px"
                    placeholder={"表示するメッセージを入力"}
                    onChange={onChangeTelop}
                    defaultValue={props.telop ? props.telop : ""}
                />
                <FormErrorMessage>
                    {errors.name && errors.name.message}
                </FormErrorMessage></Flex>
            </FormControl>
        </Box>
    )
}