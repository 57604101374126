import {
  Box,
  Text,
} from "@chakra-ui/react";

function ShowFacialExpresstionAppSetting(props: Props) {

  return (
    <>
      <Box>
        <Text color="black" textAlign="left" fontWeight="bold" fontSize="large">
          表情キャプチャアプリ設定情報
        </Text>
      </Box>
      <Box mt="20px"  color="blue.600">
        <Box>
          <Text display="flex" fontWeight="bold" textAlign="left" fontSize="sm">
            {"サブジェクト名："}
          </Text>
          <Text fontWeight="bold" textAlign="left" fontSize="sm" ml="1rem">
            {props.subjectName ? props.subjectName : 'なし'}
          </Text>
        </Box>
      </Box>
    </>
  );
}

export default ShowFacialExpresstionAppSetting;

type Props = {
  channelName: string,
  ipAddress: string,
  portNo: string,
  subjectName: string,
}
