/*!

=========================================================
* Purity UI Dashboard - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/purity-ui-dashboard/blob/master/LICENSE.md)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Link, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import RTLLayout from "layouts/RTL.js";
import Sample from "layouts/Sample.js";
import Test from "layouts/Test.js";
import Signage from "layouts/Signage.js";
import Store from "layouts/Store.js";
import AppCommons from "layouts/AppCommons";
import Login from "views/Pages/Login";
import WaitingRoom from "views/Pages/Users/WaitingRoom"
import Logout from "views/Pages/Users/Logout"
import Calendar from "views/Pages/Users/Schedule"


class Root extends Component {
  render() {
    return (
    <div>
      <h1>rootです</h1>
      <Link to='/PageA'>PageA</Link>
      <Link to='/addUser'>ユーザー追加</Link>
    </div>
    );

  }
}

class PageA extends Component {
  render() {
    return <h2>PageAです</h2>;
  }
}

class PageB extends Component {
  render() {
    return <h2>PageBです</h2>;
  }
}

class NotFound extends Component {
  render() {
    return <h2>404 NOT FOUND</h2>;
  }
}

export default class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path={`/PageA`} component={PageA} />
          <Route path={`/auth`} component={AuthLayout} />
          <Route path={`/admin`} component={AdminLayout} />
          <Route path={`/rtl`} component={RTLLayout} />
          <Route path={`/sample`} component={Sample} />
          <Route path={`/store`} component={Store} />
          <Route path={`/app`} component={AppCommons} />
          <Route path={`/test`} component={Test} />
          <Route path={`/signage`} component={Signage} />
          <Route path={`/login`} component={Login} />
          <Route path={`/waiting-room`} component={WaitingRoom} />
          <Route path={`/logout`} component={Logout} />
          <Route path={`/calendar`} component={Calendar} />
          <Redirect from="/test/login" to="/login" />
          <Redirect from="/" to="/login" />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    );
  }
}
