
import { 
  Box, 
  Button, 
  Text, 
  Flex, 
  Input, 
  FormControl, 
  FormErrorMessage,
  Badge, 
  Spacer, 
  Image, 
  } from '@chakra-ui/react';
  
import { useForm } from 'react-hook-form'
import { useState, useEffect } from "react"
import { CharacterClient } from "../../../components/ksin/ApiClient/CharacterClient";
import { Reservation } from "../../../components/ksin/ApiClient/ReservationClient";
import { PersonIcon } from "../../../components/Icons/Icons";
import { useHistory } from 'react-router-dom';

type Timestamp = {
  date: string, 
  time: string
}

const ReservationForm = (props) => {

  const [selectAvatar, setSelectAvatar] = useState<string>(props.selectAvatar);
  const [isDelete] = useState<boolean>(props.isDelete)
  const [characters, setCharacters] = useState([])
  const [titleForm, setTitle] = useState<string>(props.title)
  const [startDateTime, setStartDateTime] = useState<Timestamp>({date: props.startDate, time: props.startTime})
  const [expDateTime, setExpDateTime] = useState<Timestamp>({date: props.expDate, time: props.expTime})
  const characterClient = new CharacterClient();
  const reservationClient = new Reservation()
  const history = useHistory();

  //handleSubmit reset errors
  const handleStartDate = (event) => {
    setStartDateTime({date: event.target.value, time: props.startTime})
    props.setStartDateTime(startDateTime)
  }

  const handleStartTime = (event) => {
    setStartDateTime({date: props.startDate, time: event.target.value})
    props.setStartDateTime(startDateTime)
  }
  
  const handleEndDate = (event) => {
    setExpDateTime({date: event.target.value, time: props.expTime})
    props.setExpDateTime(expDateTime)
  }

  const handleEndTime = (event) => {
    setExpDateTime({date: props.expDate, time: event.target.value})
    props.setExpDateTime(expDateTime)
  }
  
  const handleClick = (value: string) => {
    setSelectAvatar(value);
    setValue("character", value, {
      shouldValidate: true})
  }

  useEffect(() => {
    if (props.auth || false ) {
      characterClient.get().then((data: any) => {
        setCharacters(data);
      })
    } else {
      characterClient.getAll().then((data: any) => {
        setCharacters(data);
      })
    }
  }, [])

  const { handleSubmit, register, reset, setValue, formState: { errors, isSubmitting } } = useForm()

  const onSubmit = (data, event) => {
    
    event.preventDefault()

    const sendData = {
      title: data.title,
      startDate: data.startDate,
      startTime: data.startTime , 
      endDate: data.endDate,
      endTime: data.endTime,
      characterID: selectAvatar
    }

    if (props.auth && !isDelete) {
      reservationClient.create(sendData)
      props.sendCalendarState("test")
    } else if (isDelete && props.auth) {
      reservationClient.delete(props.reserv)
    } else {
      // localStorage.setItem('reservationData', JSON.stringify(sendData));
      localStorage.setItem('reservationDataJSON', JSON.stringify(sendData));
      const result = confirm("予約するため、ログインしてください")
      result ?  history.push('/login') : ""
    }
    reset()
    props.title && props.setTitle ? props.setTitle("") : setTitle("")
    props.closeForm()
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Предотвращаем отправку формы при нажатии Enter
    }
  };
  return (
  <form  onSubmit={handleSubmit(onSubmit)}>
    <FormControl 
      overflowY="auto"
      isInvalid={ errors.title ||  errors.startDate ||  errors.startTime ||  errors.endDate ||  errors.endTime || errors.character}
      position="absolute"
      zIndex="9999"
      left="50%"
      top="50%"
      transform="translate(-50%, -50%)"
      border="solid 1px #e2e8f0"
      borderRadius= "15px"
      width="50%"
      minWidth="350px"
      maxWidth="550px"
      maxHeight="700px"
      height="80%"
      display="grid "
      flexDirection="column"
      placeItems="center"
      backgroundColor="white"
      padding="20px"
      // onSubmit={handleClickForm}
    >
      <Text
        display="flex"
        justifyContent="center"
        fontSize="x-large"
        top="0px"
      >
        {isDelete ? `予約No. ${parseInt(props.reservID) + 1}` : "予約フォーム"}
      </Text>
      <Flex
        display="grid"
        // width="inherit"
        w="100%"
        margin="20px"
        gap="15px"
        // justifyContent="space-between"
        // height="inherit"
      >
        <Text 
          fontFamily="sans-serif"
        >
          会議名
        </Text>
        <Box
          paddingLeft="10px"
          marginTop="10px"
          display="flex"
          flexDirection="row"
          marginBottom="30px"
          marginLeft="inherit"
        >
          <Box
            width="43%"
            display="flex"
            flexDirection="column"
          >
            <Input 
              onKeyDown={handleKeyDown}
              isReadOnly={props.isDelete}
              id="title"
              borderRadius="15px"
              type="text" 
              size="sm"
              placeholder="会議名"
              value={titleForm}
              {...register('title', {
                required: '会議名を入力してください', 
                minLength: { value: 2, message: '２文字以上入力してください' }
              })}
            /> 
            <FormErrorMessage fontSize="xs">
              { errors.title &&  errors.title.message}
            </FormErrorMessage>
          </Box>
        </Box>
        <Text>開始時間  </Text>
        <Box
          marginTop="10px"
          display="flex"
          flexDirection="row"
          marginBottom="30px"
          justifyContent="space-evenly"
        >
          <Box
            w="40%"
            display="flex"
            flexDirection="column"
            marginLeft="inherit"
          >
            <Input 
              id="startDate"
              onKeyDown={handleKeyDown}
              isReadOnly={true}
              borderRadius="15px"
              type="date" 
              size="sm"
              min={props.currentDate}
              value={props.startDate}
              {...register('startDate', {
                required: '開始日付を入力してください'
              })}
              onChange={(
                  event: React.ChangeEvent<HTMLInputElement>) => 
                  handleStartDate(event)
                }
            /> 
            <FormErrorMessage fontSize="xs">
              { errors.startDate &&  errors.startDate.message}
            </FormErrorMessage>
          </Box>
          <Box
            w="40%"
            display="flex"
            flexDirection="column"
            marginLeft="inherit"
          >
            <Input 
              id="startTime"
              onKeyDown={handleKeyDown}
              isReadOnly={true}
              borderRadius="15px"
              type="time" 
              size="sm"
              min={props.currentTime}
              value={props.startTime}
              {...register('startTime', {
                required: '開始時間を入力してください'
              })}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement>) => 
                handleStartTime(event)
              }  
            />
            <FormErrorMessage fontSize="xs">
              { errors.startTime &&  errors.startTime.message}
            </FormErrorMessage>
          </Box>
        </Box>
        <Text>終了時間</Text>
        <Box
          marginTop="10px"
          display="flex"
          flexDirection="row"
          marginBottom="30px"
          justifyContent="space-evenly"
        >
          <Box
            w="40%"
            display="flex"
            flexDirection="column"
            marginLeft="inherit"
          >
            <Input 
              id="endDate"
              onKeyDown={handleKeyDown}
              isReadOnly={true}
              borderRadius="15px"
              type="date"
              size="sm" 
              value={props.expDate}
              min={props.currentDate}
              {...register('endDate', {
                required: '終了日付を入力してください'
              })}
              onChange={(event: React.ChangeEvent<HTMLInputElement>)=> handleEndDate(event)}
            />
            <FormErrorMessage fontSize="xs">
              { errors.endDate &&  errors.endDate.message}
            </FormErrorMessage>
          </Box>
          <Box
            w="40%"
            display="flex"
            flexDirection="column"
            marginLeft="inherit"
          >
            <Input 
              id="endTime"
              onKeyDown={handleKeyDown}
              isReadOnly={true}
              borderRadius="15px"
              type="time"
              size="sm" 
              value={props.expTime}
              min={props.currentTime}
              {...register('endTime', {
                
              })}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleEndTime(event)}
            /> 
            <FormErrorMessage fontSize="xs">
              { errors.endTime && errors.endTime.message}
            </FormErrorMessage>
          </Box>
        </Box>
        <Box
          margin="10px">
          <Text>
            キャラクター選択
          </Text>
          <FormErrorMessage fontSize="xs">
            { errors.character && errors.character.message}
          </FormErrorMessage>
        </Box>
        <Input
          value={props.selectAvatar}
          type="hidden"
          {...register('character', {
            // required: 'キャラクター選択してください'
          })}
          >
        </Input>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="center"
        >
          {characters.map((row, index) =>
            <Flex pl="5" flexDirection="column">
              {selectAvatar === row.id ?
                <Badge
                  w="50px"
                  h="20px"
                  p="0px"
                  bg="white"
                  fontSize="md"
                  color="red.300"
                  // border="2px solid"
                  borderColor="teal.300"
                  borderRadius="5px"
                  textAlign="left"
                >
                  選択中
                </Badge> :
                <Spacer h="30px" />
              }
              <Flex>
                <Button
                  disabled={props.isDelete}
                  id="avatar"
                  w="80px"
                  h="80px"
                  p="0px"
                  border={selectAvatar === row.id ? "4px solid" : "none"}
                  borderColor="red.300"
                  borderRadius="12px"
                  bg={selectAvatar === row.id ? "red.300" : "transparent"}
                  _hover={
                    { opacity: 0.5, }
                  }
                  onClick={() => handleClick(row.id)}
                >
                  {row.image_url !== null && row.image_url !== '' ?
                    <Image src={row.image_url} objectFit="cover" w="100%" height="100%" borderRadius="10px" onClick={() => {}} /> :
                    <PersonIcon color="inherit" h="90px" w="90px" borderRadius="10px" />
                  }
                </Button>

              </Flex>
              <Flex pl="2" mt="2" display="block" textAlign="left">
                <Text fontSize="xs" color="gray.400" fontWeight="normal" textAlign="left">
                  No.{index + 1}
                </Text>
                <Text fontSize="md" color="black" fontWeight="bold" textAlign="left">
                  {row.name}
                </Text>
              </Flex>
            </Flex>
          )}
        </Box>
      </Flex>
      <Box
        w="80%"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Button 
          type="submit" 
          width="150px" 
          backgroundColor="#9B9898" 
          color="white" 
          _hover={{color: "#4fd1c5", backgroundColor: "#e2e8f0"}} 
          onClick={() => {props.closeForm()}
          }  
        >
          戻る
        </Button>
        <Button 
          type='submit' 
          width="150px" 
          backgroundColor= { isDelete ? "crimson" : "#4fd1c5" }
          color="white" 
          _hover={{color: "#4fd1c5", backgroundColor: "#e2e8f0"}} 
          isLoading={isSubmitting} 
        >
          { isDelete ? "キャンセル" : "予約" }
        </Button>
      </Box>
      
      <Button
        type='submit'
        position="absolute"
        top="0px"
        onClick={() => {props.closeForm()}
        }
        right="0px"
        fontSize="2xl"
        margin="15px"
        backgroundColor="white"
      >
        x</Button>
  </FormControl>
  </form>
  )
}


export default  ReservationForm