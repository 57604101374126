// Chakra imports
import {
  Avatar,
  Box,
  Button,
  Flex,
  Text,
  Spacer,
  useColorModeValue,
  Table,
  Thead,
  Tr,
  Th,
  Link,
} from "@chakra-ui/react";
import { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
// import { useHistory } from "react-router-dom";
import { SignageClient } from "components/ksin/ApiClient/SignageClient";
import UserList from "components/ksin/List/UserList";
import { UserClient } from "components/ksin/ApiClient/UserClient";
const signageClient = new SignageClient();
const userClient = new UserClient();

function SignageOperatorAdd(props: any) {
  // 対象サイネージID(管理画面からの遷移時に取得)
  const targetId: string = props.location.state.id;
  const companyName: string = props.location.state.companyName;
  const storeList: any = props.location.state.storeList;

  // Chakra color mode
  const textColor = useColorModeValue("gray.400", "white");

  // stateデータ
  const [signageInfo, setSignageInfo] = useState(null);
  const [addOperatorId, setAddOperatorId] = useState("");
  const [operatorList, setOperatorList] = useState([]);
  const [showOperatorList, setShowOperatorList] = useState([]); // NOTE: 担当候補のオペレータ一覧
  const [signageOperatersText, setSignageOperatersText] = useState("");

  const history = useHistory();
  const onClickOperators = useCallback((params) => {
    history.push({ pathname: "/app/signage/operator", state: params })
  }, [history]);
  const onClickSignageEdit = useCallback((params) => {
    history.push({ pathname: "/app/signage/update", state: params })
  }, [history]);
  const onClickViewTemplate = useCallback((params) => {
    history.push({ pathname: "/app/signage/template", state: params })
  }, [history]);

  const getSignage = () => {
    // サイネージ情報を取得する
    signageClient.get(targetId).then((data: any) => {
      setSignageInfo(data);
    })
    .catch(() => {
    })
  };

  const getTargetCompanyStoreName = (targetStoreId: string) => {
    let targetCompanyStoreName = companyName;
    for(let index=0; index<storeList.length; index+=1){
      if(storeList[index].id == targetStoreId){
        targetCompanyStoreName = `${companyName} / ${storeList[index].name}`;
        break;
      }
    }

    return targetCompanyStoreName;
  };

  // マウント後の単発処理
  useEffect(() => {
    // サイネージ情報を取得する
    getSignage();

    // ユーザー一覧を取得する
    userClient.get().then((data: any) => {
      setOperatorList(data);
    })
    .catch(() => {
    })
  }, [])

  // 追加オペレーターIdを受け取ったら
  useEffect(() => {
    if (addOperatorId.length) {
      // サイネージ情報を更新する
      const values = {
        targetId: targetId,
        userId: addOperatorId
      }
      signageClient.update(values).then((data: any) => {
        console.log(data);
        getSignage();
      })
      .catch(() => {
      })
      
    }
  }, [addOperatorId])

  useEffect(() => {
    if(signageInfo == null || signageInfo.users == null || operatorList.length == 0) return;
    const signageUsers = signageInfo.users;

    // NOTE: 担当ユーザー先頭10人のリスト
    const operatersNameList = signageUsers
      .filter((f: any, index: number) => {
        return index < 10;
      })
      .map((operater: any) => {
        return operater.name
      });
    
    const addText = signageUsers.length > 10 ? '...' : '';
    setSignageOperatersText(operatersNameList.join('、') + addText);

    // NOTE: 担当候補のユーザーリスト
    const candidateAddOperaters = operatorList.filter((targetUser: any) => {
      const getOperators = signageUsers
        .filter((f: any) => f.userId == targetUser.userId);
      return getOperators.length == 0;
    });
    
    setShowOperatorList(candidateAddOperaters);

  },[signageInfo, operatorList]);

  return (
    <Flex
      direction="column"
      mt={props.isOperatorView ? "0px" : "70px"}>
      <Box
        mb={{ sm: "205px", md: "75px", xl: "70px" }}
        borderRadius="15px"
        px="0px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        align="center"
        backgroundColor="white"
        paddingTop="20px"
        paddingLeft="20px"
        paddingBottom="50px"
      >
        <Box>
          <Box p="2">
            <Text
              color="black"
              textAlign="left"
              fontWeight="bold"
              // mb="40px"
              verticalAlign="center"
              mr="50px"
              mb="10px"
              fontSize="large"
            >
              サイネージメニュー
            </Text>
            <Box mb="10px">
              <Flex minWidth="100%" flexWrap="nowrap">
                <Avatar src={signageInfo ? signageInfo.image : ""} w="50px" borderRadius="12px" me="18px" />
                <Flex direction="column" mr="50px">
                  <Box ml="0px">
                    <Text
                      fontSize="md"
                      color="black"
                      fontWeight="bold"
                      minWidth="100%"
                      textAlign="left"
                    >
                      {signageInfo ? signageInfo.name : ""}
                    </Text>
                  </Box>
                  <Box ml="0px">
                    <Text textAlign="left">
                      {signageInfo ? getTargetCompanyStoreName(signageInfo.storeId) : ""}
                    </Text>
                  </Box>
                </Flex>
                <Flex  mr="50px">
                  {/* TODO: サイネージ状態のバッチを表示する */}
                  <Box></Box>
                </Flex>
                <Flex>
                  <Flex>
                    <Box>
                      <Text
                        color="gray.400"
                        textAlign="left"
                        mr="20px"
                        fontSize="md"
                      >
                        {"担当: "}
                      </Text>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box width="500px" textAlign="left">
                      <Link 
                        color="teal.500" 
                        textDecoration="underline" 
                        onClick={() => onClickOperators({
                          "id": targetId,
                          "companyName": companyName,
                        })}
                      >
                        {signageOperatersText}
                      </Link>
                    </Box>
                  </Flex>
                </Flex>
                
                <Flex>
                  <Button
                    fontSize="14px"
                    bg="teal.300"
                    w="120px"
                    h="30"
                    color="white"
                    borderRadius="5px"
                    mr="30px"
                    _hover={{
                      bg: "teal.200",
                    }}
                    _active={{
                      bg: "teal.400",
                    }}
                    onClick={() => onClickSignageEdit({
                      id: signageInfo.id,
                      companyName: companyName,
                    })}
                  >
                    サイネージ設定
                  </Button>
                  <Button
                    fontSize="14px"
                    bg="teal.300"
                    w="120px"
                    h="30"
                    color="white"
                    borderRadius="5px"
                    _hover={{
                      bg: "teal.200",
                    }}
                    _active={{
                      bg: "teal.400",
                    }}
                    onClick={() => onClickViewTemplate({
                      id: signageInfo.id,
                      companyName: companyName,
                      image: signageInfo.image,
                    })}
                  >
                    表示設定
                  </Button>
                </Flex>
              </Flex>
            </Box>
          </Box>
          <Spacer />
        </Box>
        <Text
          color="black"
          textAlign="left"
          fontWeight="bold"
          // mb="40px"
          verticalAlign="center"
          mr="50px"
          fontSize="large"
        >
          担当オペレーター追加
        </Text>
        <Table variant="simple"
          color={textColor} >
          <Thead >
            <Tr
              my=".8rem"
              pl="0px" >
              <Th pl="0px"
                color="gray.400" >
                ユーザー </Th>
              <Th color="gray.400"
                textAlign="center" > 追加 </Th>
              <Th color="gray.400"
                textAlign="center" > キャラクター </Th>
              <Th color="gray.400" textAlign="center" > ステータス </Th>
              <Th color="gray.400"
                textAlign="center" > ステータス変更 </Th>
              <Th color="gray.400"
                textAlign="center" > 設定 </Th>
            </Tr >
          </Thead>
          <UserList
            inSignageOperatorAdd={true}
            setAddOperatorId={setAddOperatorId}
            companyName={companyName}
            operatorList={showOperatorList}
          />
        </Table>
      </Box>
    </Flex>
  );
}

export default SignageOperatorAdd;
