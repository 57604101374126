import React, { useEffect, useRef } from "react";
// Chakra imports
import {
  Box,
  Button,
  Flex,
} from "@chakra-ui/react";
import { AddIcon } from '@chakra-ui/icons'
import { MediaClient } from "components/ksin/ApiClient/MediaClient";
import { checkSize } from "components/ksin/util/CalculationMediaSize";


let uploadVideoSize = {
  w: 640,
  h: 360
}
// 動画サムネイルを生成してサーバー送信するメソッド
const saveThumbnail = async (imageUrl: string) => {
  const myVideo: any = document.getElementById("upload-video");
  const myVideoCanvas: any = document.getElementById("upload-video-canvas");
  const canvasCtx = myVideoCanvas.getContext('2d');
  canvasCtx.drawImage(myVideo, 0, 0, uploadVideoSize.w, uploadVideoSize.h);

  // メディアライブラリ登録済み動画のパスからファイル名を抽出(拡張子無し)
  const fileNameArray = imageUrl.split("/");
  const fulllFileName = fileNameArray[fileNameArray.length - 1];
  const fileNameWithExt = fulllFileName.split(".");
  const savedName = fileNameWithExt[0];

  // サーバー送信
  return new Promise((resolve, reject) =>
    myVideoCanvas.toBlob(blob => {
      const values = {
        filename: savedName,
        isThumbnail: true,
        image: blob
      }
      new MediaClient().add(values)
        .then(json => {
          resolve(json);
        })
        .catch((error) => {
          reject(error);
        });
    })
  )
}

// サムネイル生成用video要素にsrcをセットする

// const setVideoSrc = (file :any) => {
//   const myVideo: any = document.getElementById("upload-video");
//   myVideo.src = file;
// }

// Assets
const MediaAdd = (props: PropsAddMedia) => {

  const mediaClient = new MediaClient();

  const inputRef = React.useRef(null);
  const fileUpload = () => {
    inputRef.current.click();
  };

  const [serverErrors, setServerErrors] = React.useState([]);
  const [videoSrc, setVideoSrc] = React.useState("");

  const addServerError = (errObj: { code: string, msg: string }) => {
    setServerErrors([...serverErrors, errObj]);
    console.log(JSON.stringify(serverErrors));
  }

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    videoRef.current?.load();
    videoRef.current?.play();
}, [videoSrc]);

  const onFileInputChange = (e) => {
    const targetFile = e.target.files[0];
    const allowFileTypes = ["image/png", "image/jpeg", "video/mp4"]
    if (targetFile !== undefined && allowFileTypes.includes(targetFile.type)) {
      // 動画ならサムネイル登録用にvideo要素にsetする
      if (targetFile.type === "video/mp4") {
        setVideoSrc(URL.createObjectURL(targetFile));
      }

      // システムに登録する
      let targetFileSize = props.totalSize + targetFile.size;
      if (checkSize(targetFileSize) === true) {
        let values: any = {};
        values.image = targetFile;

        mediaClient.add(values)
          .then((json: any) => {
            console.log(json);
            if (json.result == 'OK') {
              // 動画ならサムネイルを生成してサーバー送信
              if (targetFile.type === "video/mp4") {
                saveThumbnail(json.image_url);
              }
                props.setIsReload(true);
            } else {
              // NOTE: ファイル名が100文字を超える場合、名称の変更はできない
              //　アラートでエラーメッセージを出す
              alert(json.msg);
            }
          })
          .catch((e) => {
            addServerError(
              {
                code: "SVER-Unexpected",
                msg: "未定義のサーバーエラーが発生しました"
              }
            );
          });
      } else {
        alert("ファイルサイズが上限を超えてしまうため、メディアを追加できません。");
      }

    } else {
      // TODO: 未実装
      return;
    }
  };

  // Chakra color mode
  return (
    <Box>
      <Button
        fontSize="14px"
        type="submit"
        bg="teal.300"
        w="150px"
        h="30px"
        mb="10px"
        mr="8"
        color="white"
        borderRadius="5px"
        mt="20px"
        _hover={{
          bg: "teal.200",
        }}
        _active={{
          bg: "teal.400",
        }}
        onClick={fileUpload}
      >
        <AddIcon color="inherit" mr="2" />
        メディア追加
      </Button>
      <input
        hidden
        ref={inputRef}
        type="file"
        accept="image/png,image/jpeg,video/mp4"
        onChange={onFileInputChange}
        onClick={(e: any) => {
          //  ※valueを初期化しないとイベントが発火しない
          e.target.value = '';
        }}
        name="image"
      />
      <Flex
        id="make-thumbnail-space"
        display="none"
        >
        <video id="upload-video" autoPlay loop ref={videoRef} style={{ objectFit: 'cover', width: uploadVideoSize.w, height: uploadVideoSize.h }}>
            <source src={videoSrc} type="video/mp4" />
        </video>
        <canvas id="upload-video-canvas" width={uploadVideoSize.w} height={uploadVideoSize.h} />
      </Flex>
    </Box>
  );
}

export default MediaAdd;

export type PropsAddMedia = {
  setIsReload: (isReload: boolean) => void,
  totalSize: number,
};
