// Chakra imports
import {
  Box,
  Flex,
  Text,
  CSSReset,
  ChakraProvider
} from "@chakra-ui/react";
// Assets
import ProfileBgImage from "assets/img/ProfileBackground.png";
import PasswordResetForm from 'components/ksin/Forms/PasswordResetForm'

function PasswordReset() {
  // Chakra color mode
  return (
    <Flex direction="column">
      <Box
        mb={{ sm: "205px", md: "75px", xl: "70px" }}
        borderRadius="15px"
        px="0px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        align="center"
      >
        <Box
          bgImage={ProfileBgImage}
          w="100%"
          h="300px"
          borderRadius="25px"
          bgPosition="50%"
          bgRepeat="no-repeat"
          position="relative"
          display="flex"
          justifyContent="center"
        >
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          align="center"
          position="absolute"
          top="50px"
          left="200px"
        >
          <Text color="white" fontWeight="light" textAlign="left">
            アバター接客 KSIN
          </Text>
        </Box>
        <Box
          w="500px"
          borderRadius="15px"
          px="0px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          align="center"
          position="absolute"
          top="150px"
          left="0"
          right="0"
          backgroundColor="white"
          margin="auto"
          boxShadow="lg"
        >
            <ChakraProvider>
              <CSSReset />
              <Box p={4}>
                <PasswordResetForm />
              </Box>
            </ChakraProvider>
        </Box>
      </Box>
    </Flex>
  );
}

export default PasswordReset;
