import { ApiClient } from './ApiClient';
export class StoreClient implements ApiClient {
    constructor() {
    }
    async get(targetId?: string) {
        const sendData: any = {}
        if (targetId) {
            sendData.targetStoreId = targetId;
        }

        const param = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(sendData)
        };
        return new Promise((resolve, reject) =>
            fetch(`/api/getStore`, param)
                .then(response => response.json())
                .then(json => {
                    resolve(json);
                })
                .catch((error) => {
                    console.error('Error:', error);
                    reject(error);
                })
        )
    }
    async add() {
    }
    async update(targetId: string) {
    }
    async delete(targetId: string) {
    }
}