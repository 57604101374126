import React, { useCallback, useState, useContext, useEffect } from "react";
// Chakra imports
import {
    Avatar,
    Box,
    Flex,
    Button,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Link,
    Switch,
    Text,
    Spacer,
    Table,
    Tbody,
    Th,
    Thead,
    Tr,
    Td,
    Icon,
    Badge,
    useColorModeValue,
    CSSReset,
    ChakraProvider
} from "@chakra-ui/react";
import {
    AdobexdLogo,
    AtlassianLogo,
    InvisionLogo,
    JiraLogo,
    SlackLogo,
    SpotifyLogo,
} from "components/Icons/Icons.js";
import { AddIcon } from '@chakra-ui/icons'
import { IoSettingsSharp } from "react-icons/io5"
import { useHistory } from "react-router-dom";
import UserList from 'components/ksin/List/UserList'
import StoreList from "components/ksin/List/StoreList"
import { CompanyClient } from "components/ksin/ApiClient/CompanyClient";

// Assets
import signInImage from "assets/img/signInImage.png";
import ProfileBgImage from "assets/img/ProfileBackground.png";

const demo_data = [
    { "userId": 1, "name": "阿部", "role": "管理者", "store": "日立店", "avatorCh": "なし", "ch": "なし", "chName": "", "status": "離席中" },
    { "userId": 2, "name": "加納", "role": "管理者", "store": "日立店", "avatorCh": "1", "ch": AdobexdLogo, "chName": "Yukika", "status": "離席中" },
    { "userId": 3, "name": "伊藤", "role": "", "store": "日立店", "avatorCh": "1", "ch": AdobexdLogo, "chName": "Emiri", "status": "受付中" },
    { "userId": 4, "name": "上野", "role": "", "store": "つくばサポートセンター", "avatorCh": "2", "ch": AdobexdLogo, "chName": "Kento", "status": "会話中" },
]

function UserManagement() {
    // Chakra color mode
    const titleColor = useColorModeValue("teal.300", "teal.200");
    const textColor = useColorModeValue("gray.400", "white");
    const [data, setData] = useState(demo_data);
    const [isChecked, setChecked] = useState(true);
    const [companyName, setCompanyName] = useState('');

    const history = useHistory();
    const onClickUserAdd = useCallback((params) => history.push({pathname: "/app/users/add", state: params}), [history]);
    // TODO: 未使用のため一旦コメントアウト（別途削除）
    // const onClickUserEdit = useCallback((params) => history.push({ pathname: "/app/users/edit/" + params.id, state: params }), [history]); // TODO：ユーザーIDを渡す
    // const onClickAssignmentCh = useCallback((params) => history.push({ pathname: "/app/users/assignment", state: params }), [history]); // TODO：チャンネルIDを渡す
    // const onClickSelectCharacter = useCallback((params) => history.push({ pathname: "/app/users/select-character", state: params }), [history]);
    const onChangeSwitch = (event) => {
        setChecked(event.target.checked);
    }
    const onChanegeStatus = (param) => {
        const newData = data.map(e => {
            if (e.userId === param.userId) {
                e.status = "離席中";
            }
            return e;
        }).filter(e => e);
        setData(newData);
    }

    useEffect(() => {
        // NOTE: 企業名を取得する
        const companyClient = new CompanyClient();
        companyClient.get().then((data) => {
        if(data.companyName == '' || data.companyName == null)  return;
        setCompanyName(data.companyName);
        }).catch((error) => {
        console.log(error);
        })
    },[]);

    return (
    <Flex direction="column" mt="4rem" >
        <Box
            mb={{
                sm: "205px",
                md: "75px",
                xl: "70px",
            }}
            borderRadius="15px"
            px="0px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            align="center"
            backgroundColor="white"
            paddingTop="20px"
            paddingLeft="20px"
            paddingBottom="50px"
        >
            <Flex >
                <Box p="2" >
                    <Text color="black"
                        fontWeight="light"
                        textAlign="left"
                        fontWeight="bold"
                        // mb="40px"
                        verticalAlign="center"
                        mr="50px"
                        fontSize="large" >
                        ユーザー管理
                    </Text>
                </Box > 
                <Switch
                    id="avater-channel"
                    colorScheme="teal"
                    me="5px"
                    defaultIsChecked="true"
                    p="2"
                    onChange={onChangeSwitch}
                />
                <Text
                    color="black"
                    fontWeight="light"
                    textAlign="left"
                    // fontWeight="bold"
                    // mb="40px"
                    verticalAlign="center"
                    mr="50px"
                    p="1.5"
                >
                    アバターch未使用ユーザーを表示
                </Text>
                <Spacer />
                <Box>
                    <Button
                        fontSize="14px"
                        type="submit"
                        bg="teal.300"
                        w="150px"
                        h="30"
                        mb="10px"
                        mr="4"
                        color="white"
                        borderRadius="5px"
                        mt="20px"
                        _hover={{
                            bg: "teal.200",
                        }}
                        _active={{
                            bg: "teal.400",
                        }}
                        onClick={() => onClickUserAdd({
                            companyName: companyName,
                        })}
                    >
                        <AddIcon color="inherit" mr="2" />
                        ユーザー追加
                    </Button>
                </Box >
            </Flex>
            <Table variant="simple" color={textColor}>
                <Thead>
                    <Tr my=".8rem" pl="0px">
                        <Th pl="0px" color="gray.400">
                            ユーザー
                        </Th>
                        <Th color="gray.400" textAlign="center">
                            キャラクター
                        </Th>
                        <Th color="gray.400" textAlign="center">
                            ステータス
                        </Th>
                        <Th color="gray.400" textAlign="center">
                            ステータス変更
                        </Th>
                        <Th color="gray.400" textAlign="center">
                            設定
                        </Th>
                    </Tr >
                </Thead>
                <UserList
                    isChecked={isChecked}
                    companyName={companyName}
                />
            </Table>
        </Box > 
    </Flex>
    );
}

export default UserManagement;