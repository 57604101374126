import { useEffect, useState } from "react";
// Chakra imports
import {
  Image,
  Flex,
  Box,
  Text,
  Tbody,
  Tr,
  Td,
  Table,
  Th,
  Thead,
  useColorModeValue,
  Button,
  Input,
} from "@chakra-ui/react";
import { BiEdit, BiCheckCircle } from "react-icons/bi";
import { MediaClient } from "components/ksin/ApiClient/MediaClient";
import { getFileSize } from "components/ksin/util/CalculationMediaSize";
import AlertDialogBasic from '../Common/AlertDialogBasic';


export default function MediaList(props: any) {
  const dialogTemplate = {
    header: "",
    body: "",
  }

  const textColor = useColorModeValue("gray.400", "white");
  const [editId, setEditId] = useState(null);
  const [deleteId, setDeleteId] = useState(null); 
  const [data, setData] = useState([]);
  const [name, setName] = useState(null);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [dialogContents, setDialogContents] = useState(dialogTemplate);

  const mediaClient = new MediaClient();

  const getVideoThumbnailUrl = (image_url) => {
    const changeDir = image_url.replace("/signageMedia/", "/signageMediaThumbnail/");
    const pathWithOriginalExt = changeDir.split(".");
    const thumbnailUrl = `${pathWithOriginalExt[0]}.png`;
    return thumbnailUrl;
  }

  const checkMediaType = (image_url: string) => {
    const allowImageTypes = ["png", "jpeg", "jpg"];
    const allowVideoTypes = ["mp4"];
    let extension = image_url.split('.').pop();

    if(allowImageTypes.indexOf(extension) != -1){
      return <Image src={image_url} objectFit="contain" w="100%" height="100%" borderRadius="10px" />;

    }else if(allowVideoTypes.indexOf(extension) != -1){
      // return <FaVideo color="inherit" height="30px" />;
      return <Image src={getVideoThumbnailUrl(image_url)} objectFit="contain" w="100%" height="100%" borderRadius="10px" />;

    }else{
      return <></>;

    }
  };

  const getCreateDateTime  = (createdAt: string) => {
    let date = new Date(createdAt);
    return date.toLocaleString("ja");
  };


  const updateMediaName = (id: string, mediaName: string) => {
    let values: any = {};
    values.targetId = id;
    values.name = mediaName;
    mediaClient.update(values)
      .then((json: any) => {
        console.log(json);
        if(json.result == 'OK'){
          props.setIsReload(true);
        }else{
          // NOTE: ファイル名が100文字を超える場合、名称の変更はできない
          //　アラートでエラーメッセージを出し、変更前に名称を戻す
          alert(json.msg);
          const previousMediaName = getPreviousMediaName(id);
          if(previousMediaName != null){
            setName({...name, [id]: previousMediaName});
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 削除確定時
  const deleteMedia = (id?: string) => {
    if(id == null)  return;
    mediaClient.delete(id)
    .then((json: any) => {
      if(json.result == 'OK'){
        props.setIsReload(true);
        setIsOpenConfirm(false);
      }
    })
    .catch((error) => {
      console.log(error);
    });
    setDeleteId(null);
  }

  // 確認ダイアログ
  const openConfirm = (data: {
    header: string,
    msg: string,
    id: string,
  }) => {
    setDialogContents({
      header: data.header,
      body: data.msg
    });
    setDeleteId(data.id);
  }

  const getPreviousMediaName = (id: string) => {
    for(let index = 0; index < data.length; index+=1){
      if(data[index].id == id){
        return data[index].name;
      }
    }
    return null;
  }

  useEffect(() => {
    if(deleteId == null)  return;
    setIsOpenConfirm(true);
  }, [deleteId]);

  // NOTE: メディア一覧を取得する.
  useEffect(() => {
    mediaClient.get()
      .then((data: any) => {
        setData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if(data == null || data.length == 0)  return;
    let names = {};
    let totalByteSize = 0;
    data.map((d) => {
      names[d.id] = d.name;
      totalByteSize += d.size;
    });
    setName(names);
    props.setTotalSize(totalByteSize);

  },[data]);

  // NOTE: メディアを更新後、再読み込みする.
  useEffect(() => {
    if(props.isReload === false)  return;

    mediaClient.get()
      .then((data: any) => {
        setData(data);
      })
      .catch((error) => {
        console.log(error);
      });

    props.setIsReload(false);

  },[props.isReload]);

  return (
    <Flex>
      <Table variant="simple" color={textColor} width="100%">
        <Thead>
          <Tr my=".8rem" pl="0px">
            <Th pl="0px" color="gray.400">メディア</Th>
            <Th color="gray.400" textAlign="center">拡張子</Th>
            <Th color="gray.400" textAlign="center">登録日時</Th>
            <Th color="gray.400" textAlign="center">サイズ</Th>
            <Th color="gray.400" textAlign="center">削除</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((row) =>
            <Tr my=".8rem" pl="0px">
              <Td pl="0px" pr="0px" color="black.400">
                <Flex align="center" flexWrap="nowrap">
                  <Box
                    w="50px"
                    h="50px"
                    minWidth="50px"
                    bg="teal.300"
                    color="white"
                    borderRadius="12px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {checkMediaType(row.image_url)}
                  </Box>
                  {row.id == editId ?
                    <Flex>
                      <Input
                        display="flex"
                        borderRadius="5px"
                        fontSize="sm"
                        size="lg"
                        id='mediaName'
                        placeholder='メディア名称'
                        color="black"
                        defaultValue={row.name}
                        value={name[row.id]}
                        ml="2"
                        mr="2"
                        // autoFocus
                        // onBlur={() => {
                        //   setEditId(null);
                        // }}
                        onChange={(event) => {
                          setName({...name, [row.id]: event.target.value});
                        }}
                      />
                      <Button
                        fontSize="14px"
                        bg="none"
                        width="30"
                        hight="30"
                        color="white"
                        borderRadius="5px"
                        onClick={() => {
                          // NOTE: 入力内容に変更がない場合は何もしない
                          if(name[row.id] != row.name){
                            // NOTE: 入力が空の場合は入力前の値に戻す
                            if(name[row.id] != "" ){
                              updateMediaName(row.id, name[row.id]);
                            }else{
                              setName({...name, [row.id]: row.name});
                            }
                          } 
                          setEditId(null);
                        }}
                      >
                        <BiCheckCircle color="#008080" size={30}/>
                      </Button>
                    </Flex>
                    :
                    <Flex>
                      <Flex maxWidth="16rem" overflow="hidden" alignItems="center">
                        <Text
                          fontSize="medium"
                          color="black"
                          fontWeight="bold"
                          ml="4"
                          overflow="hidden"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                        >
                          {name != null ? name[row.id] : row.name}
                        </Text>
                      </Flex>
                      <Flex>
                        <Button
                          fontSize="14px"
                          bg="none"
                          width="30"
                          hight="30"
                          color="white"
                          borderRadius="5px"
                          onClick={() => {
                            if(editId == row.id){
                              setEditId(null)
                            }else{
                              if(editId != null){
                                // NOTE: 対象メディア編集中のとき
                                //  保存前に他のメディア名を編集しようとした場合、前回編集対象のメディア名を元に戻す
                                const previousMediaName = getPreviousMediaName(editId);
                                if(previousMediaName != null){
                                  setName({...name, [editId]: previousMediaName});
                                }
                              }
                              setEditId(row.id);
                            }
                          }}
                        >
                          <BiEdit color="#008080" size={20}/>
                        </Button>
                      </Flex>
                    </Flex>
                  }
                </Flex>
              </Td>
              <Td color="black"  textAlign="center">{'.' + row.image_url.split('.').pop().slice(0, 8)}</Td>
              <Td color="black"  textAlign="center">
                {
                  row.createdAt != null
                    ? getCreateDateTime(row.createdAt)
                    : "no data"
                }
              </Td>
              <Td color="black"  textAlign="center">
                {
                  row.size != null
                    ? getFileSize(row.size)
                    : 'no data'
                }
              </Td>
  
              <Td color="black"  textAlign="center">
                <Button
                  fontSize="14px"
                  bg="red.500"
                  w="100px"
                  h="30px"
                  color="white"
                  borderRadius="5px"
                  _hover={{
                    bg: "red.200",
                  }}
                  // NOTE: ダイアログを閉じた後に枠線が残ったままになるのを解消する
                  _focus={{
                    boxShadow: "none"
                  }}
                  onClick={() => openConfirm({ header: "確認", msg: "本当に削除しますか?", id: row.id})}
                >
                  削除
                </Button>
              </Td>
            </Tr>
          )}
          <AlertDialogBasic
            id="delete-dialog"
            isOpenConfirm={isOpenConfirm}
            setIsOpenConfirm={setIsOpenConfirm}
            setIsForceSubmit={() => deleteMedia(deleteId)}
            dialogContents={dialogContents}
            isMedia={true}
          />
        </Tbody>
      </Table>
    </Flex>
  )
}
