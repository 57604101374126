import { useFormContext } from 'react-hook-form'
import {
    FormErrorMessage,
    FormLabel,
    FormControl,
    Input,
    Box,
    Flex
} from '@chakra-ui/react'

export default function InputName(props: any) {
    const {register, formState: { errors }} = useFormContext();

    return (
        <Box ml="0px" mb="24px">
            <FormControl 
                isInvalid={errors.name}
                display="flex"
                justifyContent="flex-start"
            >
                <FormLabel
                    ms="4px"
                    marginBottom="none"
                    fontSize="sm"
                    textAlign="left"
                    fontWeight="bold"
                    display="flex"
                    width="200px"
                    alignItems="center"
                  >
                    {props.formInfo.labelName}
                </FormLabel>
                <Flex
                    flexDirection="column"
                >
                <Input
                    borderRadius="5px"
                    fontSize="sm"
                    type="text"
                    size="lg"
                    width="400px"
                    placeholder={props.formInfo.placeholder}
                    defaultValue={props.isAddMode || !props.userInfo ? "" : props.userInfo.name}
                    {...register('name', {
                        required: props.isAddMode ? '必須項目です' : false,
                        minLength: { value: 1, message: 'Minimum length should be 4' },
                    })}
                />
                <FormErrorMessage>
                    {errors.name && errors.name.message}
                </FormErrorMessage></Flex>
            </FormControl>
        </Box>
    )
}