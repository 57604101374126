import { Text, Image, FormControl, Box, FormLabel, Badge, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, Flex } from '@chakra-ui/react'
import { PersonIcon } from 'components/Icons/Icons'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form';
import { AiFillPicture } from 'react-icons/ai';

const InputImage = (props: any) => {
    const { setValue } = useFormContext();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const defaultImage = props.isAddMode || !props.userInfo ? null : props.userInfo.image;
    const [img, setImg] = React.useState(defaultImage);
    const defaultFileType = props.fileType ? props.fileType : "";
    const [fileType, setFileType] = React.useState(defaultFileType);
    const [errors, setErrors] = React.useState([]);
    const inputRef = React.useRef(null);
    const fileUpload = () => {
        inputRef.current.click();
    };

    // 登録許可する静止画像タイプ
    const allowImageTypes = ["image/png", "image/jpeg"];

    // 登録許可するビデオタイプ
    const allowVideoTypes = ["video/mp4"];

    const checkFileType = (mimeType) => {
        if (allowImageTypes.indexOf(mimeType) >= 0) {
            return "image";
        } else if (allowVideoTypes.indexOf(mimeType) >= 0) {
            return "video";
        } else {
            return "";
        }
    }

    const onFileInputChange = (e: any) => {
        console.log(e.target.files.length);
        const targetFile = e.target.files[0];
        if (targetFile) {

            let allowFileTypes;
            let errorMsg;
            if (props.inMediaConfigurator) {
                allowFileTypes = [...allowImageTypes, ...allowVideoTypes];
                errorMsg = "登録できるファイルの拡張子は「.png」「.jpg」「.mp4」です";
            } else {
                allowFileTypes = allowImageTypes;
                errorMsg = "拡張子が「.png」または「.jpg」の画像のみ登録できます";
            }
            if (allowFileTypes.includes(targetFile.type)) {
                const imgObj = URL.createObjectURL(targetFile);
                setImg(imgObj);
                setFileType(checkFileType(targetFile.type));
                if (props.setImg) {
                    props.setImg(imgObj);
                }
                setValue("image", targetFile)
                if (props.inLogoConfigurator || props.inMediaConfigurator) {
                    props.setUploadImgFile(targetFile);
                }
            } else {
                setErrors([...errors, { msg: errorMsg }]);
            }
        }
    };

    useEffect(() => {
        if (props.userInfo != null) {
            if (props.userInfo.hasOwnProperty('image')) {
                setImg(props.userInfo.image);
            }
        }
    }, [props.userInfo]);

    return (
        <Box ml="0px">
            <FormControl
                display="flex"
                alignItems='flex-start'
            >
                <FormLabel
                    ms="4px"
                    fontSize="sm"
                    textAlign="left"
                    fontWeight="bold"
                    display={props.inDrawer ? "none" : "inline-block"}
                    width="200px"
                >
                    サムネイル
              </FormLabel>
                <Box display="flex" flexDirection="column">
                    <Flex>
                        <Badge
                            bg="teal.300"
                            color="white"
                            fontSize="20px"
                            p={img === null ? "8px 8px" : "0px"}
                            borderRadius="10px"
                            mr="50px"
                            width="100px"
                            height="100px"
                            textAlign="center"
                        >
                            {img !== null && img !== '' && fileType === "image" ?
                                <Image
                                    src={img}
                                    objectFit={props.inDrawer ? "contain" : "cover"}
                                    width="100%"
                                    height="100%"
                                    borderRadius="10px"
                                    onClick={onOpen} /> :
                                props.inDrawer ?
                                    <Flex
                                        h="100%"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <AiFillPicture size="4rem" />
                                    </Flex> :
                                    <PersonIcon color="inherit" h="90px" w="90px" borderRadius="12px" />
                            }
                        </Badge>

                        <Modal
                            isOpen={isOpen}
                            onClose={onClose}
                            size="xl"
                        >
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader></ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <Image src={img} objectFit="cover" height="100%" />
                                </ModalBody>
                                <ModalFooter>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>
                        <Flex alignItems="flex-start">
                            <Button
                                fontSize="14px"
                                bg="teal.300"
                                w="120px"
                                h="30px"
                                mb="10px"
                                mr="8"
                                color="white"
                                borderRadius="5px"
                                // mt="20px"
                                _hover={{
                                    bg: "teal.200",
                                }}
                                _active={{
                                    bg: "teal.400",
                                }}
                                onClick={fileUpload}
                            >
                                アップロード
                    </Button>
                        </Flex>
                    </Flex>
                    <input
                        hidden
                        ref={inputRef}
                        type="file"
                        accept={props.inMediaConfigurator ?
                            [...allowImageTypes, ...allowVideoTypes].join(',') :
                            allowImageTypes.join(',')
                        }
                        onChange={onFileInputChange}
                        name="image"
                    />
                    <Text
                        color="red.400"
                        display={errors.length ? "flex" : "none"}
                        mb="2rem"
                    >
                        {errors.length ? errors[0].msg : ""}
                    </Text>
                </Box>
            </FormControl>
        </Box >
    )
}

export default InputImage