import webRtcPlayer from "./webRtcPlayer";
import wsConfig from "../../../ws_config.json"

let webRtcPlayerObj = null;
const getCookieArray = () => {
    let arr = new Array();
    if(document.cookie != ''){
        var tmp = document.cookie.split('; ');
        for(let i=0;i<tmp.length;i++){
            let data = tmp[i].split('=');
            arr[data[0]] = decodeURIComponent(data[1]);
        }
    }
    return arr;
};

const role = getCookieArray()['user_role'];
// console.log("role-", role)
const WS_OPEN_STATE = 1;
let VideoEncoderQP = "N/A";

// Must be kept in sync with PixelStreamingProtocol::EToUE4Msg C++ enum.
const MessageType = {

    /**********************************************************************/

    /*
     * Control Messages. Range = 0..49.
     */
    IFrameRequest: 0,
    RequestQualityControl: 1,
    MaxFpsRequest: 2,
    AverageBitrateRequest: 3,
    StartStreaming: 4,
    StopStreaming: 5,
    LatencyTest: 6,
    RequestInitialSettings: 7,

    /**********************************************************************/

    /*
     * Input Messages. Range = 50..89.
     */

    // Generic Input Messages. Range = 50..59.
    UIInteraction: 50,
    Command: 51,

    // Keyboard Input Message. Range = 60..69.
    KeyDown: 60,
    KeyUp: 61,
    KeyPress: 62,

    // Mouse Input Messages. Range = 70..79.
    MouseEnter: 70,
    MouseLeave: 71,
    MouseDown: 72,
    MouseUp: 73,
    MouseMove: 74,
    MouseWheel: 75,

    // Touch Input Messages. Range = 80..89.
    TouchStart: 80,
    TouchEnd: 81,
    TouchMove: 82,

    // Gamepad Input Messages. Range = 90..99
    GamepadButtonPressed: 90,
    GamepadButtonReleased: 91,
    GamepadAnalog: 92

    /**************************************************************************/
};

export class wsClient {
    ws: WebSocket;
    informer: any;
    config: any;
    isInitialized: boolean;
    heartbeatSender: any;
    isReceivedIceCandidate: boolean;
    constructor() {
        this.isInitialized = false;
        this.isReceivedIceCandidate = false;
    }
    init(informer: any) {
        // if(!this.isInitialized){// ws接続

        this.informer = informer;
        this.connect(informer);
        this.start();
        // }
    }
    getWs() {
        return this.ws;
    }
    closeWs() {
        if (this.ws) this.ws.close();
    }
    restartWs(informer) {
        if (this.ws && this.ws.readyState === 1) {
            this.ws.close();
        } else {
            if (this.ws) {
                this.removeWs();
            }
            this.init(informer);
        }
    }
    removeWs() {
        this.ws = null;
    }

    userStandby() {
        this.ws.send(JSON.stringify({
            type: 'stateTransitionEvent',
            event: {
                type: 'Standby',
                senderType: 'User'
            },
        }));
    }
    userLeaving() {
        this.ws.send(JSON.stringify({
            type: 'stateTransitionEvent',
            event: {
                type: 'Leaving',
                senderType: 'User'
            },
        }));
    }

    invalidateFreezeFrameOverlay(informerFn) {
        if (webRtcPlayerObj) {
            webRtcPlayerObj.setVideoEnabled(true);
            webRtcPlayerObj.video.play();

            // informerFn("User", "SELF", "PixelStreamingStart");
        } else {
            setTimeout(() => {
                this.invalidateFreezeFrameOverlay(informerFn)
            }, 500);
        }
    }
    start() {
        // update "quality status" to "disconnected" state
        let qualityStatus = document.getElementById("qualityStatus");
        if (qualityStatus) {
            qualityStatus.className = "grey-status";
        }


        let statsDiv = document.getElementById("stats");
        if (statsDiv) {
            statsDiv.innerHTML = 'Not connected';
        }

        // this.invalidateFreezeFrameOverlay(informer);
    }

    connect(informer) {
        const invalidateFreezeFrameOverlay = this.invalidateFreezeFrameOverlay;
        // 設定ファイルws_config.jsonでwsの接続情報を設定
        let wsDest;
        if (wsConfig) {
            // console.log(JSON.stringify(wsConfig));
            const protocol = wsConfig[wsConfig.mode]["isHTTPS"] ? "wss" : "ws";

            if (wsConfig.mode === "demo") {
                let portKey = wsConfig[wsConfig.mode]["wsPortKey"];
                wsDest = `${protocol}://${window.location.hostname}${portKey}`;
            } else {
                let playerServerPort = wsConfig[wsConfig.mode]["wsPort"];
                wsDest = `${protocol}://${window.location.hostname}:${playerServerPort}`;
            }

        } else {
            wsDest = `wss://${window.location.hostname}`
            console.log("wsconfig File is not find.");
            console.log(`[wsDest] ${wsDest}`);
        }

        window.WebSocket = window.WebSocket;

        if (!window.WebSocket) {
            alert('Your browser doesn\'t support WebSocket');
            return;
        }

        let ws = new WebSocket(wsDest);
        this.ws = ws;
        informer("Server", null, "wsInit", null);
        this.isInitialized = true;

        const saveConfig = (config) => {
            this.config = config;
        }

        const informStateMessageList = ["submitSignageState", "reflectSignageState", "reflectOperatorState"];

        let isReceivedIceCandidate = this.isReceivedIceCandidate;

        ws.onmessage = function (event) {
            console.log(`<- SS: ${event.data}`);
            let msg = JSON.parse(event.data);
            console.log(`${new Date().toISOString()}[debug]receive ws messageType: ${msg.type}`);
            if (msg.type === 'config') {
                saveConfig(msg);
                informer("Server", null, "receiveConfig", null);
            } else if (msg.type === 'playerCount') {
                updateKickButton(msg.count - 1);
            } else if (msg.type === 'answer') {
                onWebRtcAnswer(msg);
                // invalidateFreezeFrameOverlay(informer);
            } else if (msg.type === 'iceCandidate') {
                if (!isReceivedIceCandidate) {
                    isReceivedIceCandidate = true;
                    onWebRtcIce(msg.candidate);
                }
            } else  if (msg.type === 'deviceStateTransition') {
                informer(msg.senderType, msg.from, "deviceStateTransition", {mode: "Monitoring"});
            } else  if (msg.type === 'homeButtonOnline') {
                informer(msg.senderType, msg.from, "homeButtonOnline", {mode: "Online"});
            } else if (msg.type === 'stateTransitionMsg') {
                informer(msg.event.senderType, msg.from, msg.event.type);
            } else if (informStateMessageList.includes(msg.type)) {
                console.log(`${new Date().toISOString()}[debug]wsClient received ${msg.type} to ${msg.state}`);
                informer(msg.senderType, {}, msg.state, {
                    msgType: msg.type,
                    signageId: msg.id,
                    state: msg.state
                });
            } else if (msg.type === 'operatorStateTransition') {
                informer(msg.senderType, msg.from, "operatorStateTransition",
                    {
                        requestId: "tmpRequestId",
                        mode: msg.operatorState,
                        peerId: msg.peerId,
                        characterName: msg.characterName
                    });
            } else if (msg.type === 'acceptRequest') {
                informer(msg.senderType, msg.from, "acceptRequest",
                    {
                        requestId: "tmpRequestId",
                        mode: "acceptRequest",
                        shouldRecept: msg.shouldRecept
                    });
            } else if (msg.type === 'callFromSignage') {
                informer(msg.senderType, msg.from, "callFromSignage",
                    {
                        mode: "callFromSignage",
                        peerId: msg.peerId,
                        requestId: msg.requestId,
                        captureImageDir: msg.captureImageDir,
                        touchButton: msg.touchButton
                    });
            } else if (msg.type === 'submitReceptionUser') {
                informer(msg.senderType, msg.from, "Active",
                    { avatarChannelId: msg.avatarChannelId });
            } else if (msg.type === 'submitCall') {
                informer(msg.senderType, msg.from, "KeepState",
                    { requestId: "tmpRequestId", mode: "AcceptedRequest" });
            } else if (msg.type === 'informCallId') {
                console.log(`received informed theirId: ${msg.peerId}`);
                informer(msg.senderType, msg.from, "KeepState",
                    { requestId: "tmpRequestId", mode: "informCallId", peerId: msg.peerId });
            } else if (msg.type === 'monitoringRequest') {
                informer(msg.senderType, msg.from, "KeepState",
                    { requestId: "tmpRequestId", mode: "Monitoring", peerId: msg.peerId });
            } else if (msg.type === 'monitoringStart') {
                informer(msg.senderType, msg.from, "KeepState",
                    { requestId: "tmpRequestId", mode: "Monitoring", peerId: msg.peerId });
            } else if (msg.type === 'callFromUser') {
                informer(msg.senderType, msg.from, "Active",
                    { requestId: "tmpRequestId", mode: "Active", peerId: msg.peerId });
            } else if (msg.type === 'tmpMessage') {
                if (msg.peerId && msg.peerId.length) {
                    console.log(`${new Date().toISOString()}[debug]wsClient received theirPeerId: ${msg.peerId}`);
                    informer(msg.senderType, msg.from, "KeepState",
                        { requestId: "tmpRequestId", mode: "informCallId", peerId: msg.peerId });
                }
            } else if (msg.type === 'serviceClosed') {
                // role === "webReservationUser" ? confirm("会議は終了されました") : ""
                informer(msg.senderType, msg.from, "serviceClosed", 
                    { requestId: "tmpRequestId", mode: "reload" });
            } else if (msg.type === 'serviceCloseForWRU') {
                informer(msg.senderType, msg.from, "serviceCloseForWRU", 
                    { mode: "logout" });
            } else if (msg.type === 'shareScreen') {
                informer(msg.senderType, msg.from, "shareScreen",
                    { requestId: "tmpRequestId", mode: "open" });
            } else if (msg.type === 'closeShareScreen') {
                informer(msg.senderType, msg.from, "shareScreen",
                    { requestId: "tmpRequestId", mode: "close" });
            } else if (msg.type === 'signageDecodeError') {
                // サイネージ側のデコードエラーが発生したとき
                informer("Server", null, "signageDecodeError", {
                    signageId: msg.id,
                    errorMsg: msg.errorMsg
                });
            } else if (msg.type === 'alreadyExist') {
                informer("Server", null, "alreadyExistError",
                    { msg: "他のブラウザでログインされました" });
                ws.close();
            } else if (msg.type === 'signageConnectionLosted') {
                informer("Server", null, "Warning",
                    { msg: "サイネージとの通信が切断されました" });
            } else if (msg.type === 'logout') {
                // 正規の手順によりログアウトされたとき
                informer("Server", null, "Logouted", null);
            } else if (msg.type === 'requestLogout') {
                informer('Server', msg.from, 'requestLogout')
            } else if (msg.type === 'informAnswerUserInfo') {
                // 応対リクエスト応答受理時に応答ユーザー情報を受信する
                informer(msg.senderType, msg.from, "KeepState",
                    { mode: msg.type, peerId: msg.userPeerId });
            }
            else if (msg.type === 'lastUseCharacter') {
                // アバターchの最終利用キャラクターを情報を受信する
                informer(msg.senderType, msg.from, "KeepState",
                    { mode: msg.type, characterName: msg.characterName });
            }
            else if (msg.type === 'talkLog') {
                // 翻訳された会話内容
                console.log(`[debug][wsClient]msg.from: ${msg.from}`);
                console.log(`[debug][wsClient]msg.locale: ${msg.locale}`);
                console.log(`[debug][wsClient]msg.text: ${msg.text}`);
                msg.senderType = "User";
                informer(msg.senderType, msg.from, "KeepState",
                    { mode: msg.type, locale: msg.locale, text: msg.text });
            }
            else {
                console.log(`invalid SS message type: ${msg.type}`);
            }
        };

        ws.onerror = function (event) {
            console.log(`WS error: ${JSON.stringify(event)}`);
            informer("Server", null, "Error",
                { msg: "サーバーとの通信に問題が発生しました。再ログインしてください" });
        };

        const setIsInitialized = (flag) => {
            this.isInitialized = flag;
        }

        let hbSender = this.heartbeatSender;

        ws.onclose = function (event) {
            console.log(`WS closed: ${JSON.stringify(event.code)} - ${event.reason}`);
            ws = undefined;
            setIsInitialized(false);
            // let is_reconnection = true;

            // destroy `webRtcPlayerObj` if any
            let playerDiv = document.getElementById('player');
            if (webRtcPlayerObj && playerDiv) {
                while (playerDiv.lastChild) {
                    playerDiv.removeChild(playerDiv.lastChild);
                }
                webRtcPlayerObj.close();
                webRtcPlayerObj = undefined;
            }
            clearInterval(hbSender);

            informer("Server", null, "Stopped", null);
        };

        this.playerServerKeepAlive();

        function updateKickButton(playersCount) {
        }

        // デコードフレーム数をサーバーに報告するメソッド
        const sendDecodeFrames = (frameNum: string) => {
            ws.send(JSON.stringify({
                type: 'stateTransitionEvent',
                event: {
                    type: "decodeFrames",
                    decodeFrames: frameNum,
                    decodeTime: new Date().toISOString(),
                }
            }));
        }

        function onWebRtcAnswer(webRTCData) {
            webRtcPlayerObj.receiveAnswer(webRTCData);

            let printInterval = 5 * 60 * 1000; /*Print every 5 minutes*/
            let nextPrintDuration = printInterval;

            webRtcPlayerObj.onAggregatedStats = (aggregatedStats) => {
                let numberFormat = new Intl.NumberFormat(window.navigator.language, {
                    maximumFractionDigits: 0
                });
                let timeFormat = new Intl.NumberFormat(window.navigator.language, {
                    maximumFractionDigits: 0,
                    minimumIntegerDigits: 2
                });

                // Calculate duration of run
                let runTime = (aggregatedStats.timestamp - aggregatedStats.timestampStart) / 1000;
                let timeValues = [];
                let timeDurations = [60, 60];
                for (let timeIndex = 0; timeIndex < timeDurations.length; timeIndex++) {
                    timeValues.push(runTime % timeDurations[timeIndex]);
                    runTime = runTime / timeDurations[timeIndex];
                }
                timeValues.push(runTime);

                let runTimeSeconds = timeValues[0];
                let runTimeMinutes = Math.floor(timeValues[1]);
                let runTimeHours = Math.floor(timeValues[2]);

                let receivedBytesMeasurement = 'B';
                let receivedBytes = aggregatedStats.hasOwnProperty('bytesReceived') ? aggregatedStats.bytesReceived : 0;
                let dataMeasurements = ['kB', 'MB', 'GB'];
                for (let index = 0; index < dataMeasurements.length; index++) {
                    if (receivedBytes < 100 * 1000)
                        break;
                    receivedBytes = receivedBytes / 1000;
                    receivedBytesMeasurement = dataMeasurements[index];
                }

                let qualityStatus: any = document.getElementById("qualityStatus");
                if (!qualityStatus) {
                    return;
                }

                // "blinks" quality status element for 1 sec by making it transparent, speed = number of blinks
                let blinkQualityStatus = function (speed) {
                    let iter = speed;
                    let opacity = 1; // [0..1]
                    let tickId = setInterval(
                        function () {
                            opacity -= 0.1;
                            // map `opacity` to [-0.5..0.5] range, decrement by 0.2 per step and take `abs` to make it blink: 1 -> 0 -> 1
                            qualityStatus.style = `opacity: ${Math.abs((opacity - 0.5) * 2)}`;
                            if (opacity <= 0.1) {
                                if (--iter == 0) {
                                    clearInterval(tickId);
                                } else { // next blink
                                    opacity = 1;
                                }
                            }
                        },
                        100 / speed // msecs
                    );
                };

                const orangeQP = 26;
                const redQP = 35;

                let statsText = '';

                let color = "lime";
                if (parseInt(VideoEncoderQP) > redQP) {
                    color = "red";
                    blinkQualityStatus(2);
                    statsText += `<div style="color: ${color}">Bad network connection</div>`;
                } else if (parseInt(VideoEncoderQP) > orangeQP) {
                    color = "orange";
                    blinkQualityStatus(1);
                    statsText += `<div style="color: ${color}">Spotty network connection</div>`;
                }

                qualityStatus.className = `${color}Status`;

                statsText += `<div>Duration: ${timeFormat.format(runTimeHours)}:${timeFormat.format(runTimeMinutes)}:${timeFormat.format(runTimeSeconds)}</div>`;
                statsText += `<div>Video Resolution: ${aggregatedStats.hasOwnProperty('frameWidth') && aggregatedStats.frameWidth && aggregatedStats.hasOwnProperty('frameHeight') && aggregatedStats.frameHeight ?
                    aggregatedStats.frameWidth + 'x' + aggregatedStats.frameHeight : 'Chrome only'
                    }</div>`;
                statsText += `<div>Received (${receivedBytesMeasurement}): ${numberFormat.format(receivedBytes)}</div>`;
                statsText += `<div id="stats-frames-decoded">Frames Decoded: ${aggregatedStats.hasOwnProperty('framesDecoded') ? numberFormat.format(aggregatedStats.framesDecoded) : 'Chrome only'}</div>`;

                // デコードフレーム数をサーバーに送信
                // console.log(`[debug]Frames Decoded: ${aggregatedStats.hasOwnProperty('framesDecoded') ? numberFormat.format(aggregatedStats.framesDecoded) : 'Chrome only'}`);
                sendDecodeFrames(aggregatedStats.hasOwnProperty('framesDecoded') ? numberFormat.format(aggregatedStats.framesDecoded) : '-1');

                statsText += `<div>Packets Lost: ${aggregatedStats.hasOwnProperty('packetsLost') ? numberFormat.format(aggregatedStats.packetsLost) : 'Chrome only'}</div>`;
                statsText += `<div style="color: ${color}">Bitrate (kbps): ${aggregatedStats.hasOwnProperty('bitrate') ? numberFormat.format(aggregatedStats.bitrate) : 'Chrome only'}</div>`;
                statsText += `<div>Framerate: ${aggregatedStats.hasOwnProperty('framerate') ? numberFormat.format(aggregatedStats.framerate) : 'Chrome only'}</div>`;
                statsText += `<div>Frames dropped: ${aggregatedStats.hasOwnProperty('framesDropped') ? numberFormat.format(aggregatedStats.framesDropped) : 'Chrome only'}</div>`;
                statsText += `<div>Net RTT (ms): ${aggregatedStats.hasOwnProperty('currentRoundTripTime') ? numberFormat.format(aggregatedStats.currentRoundTripTime * 1000) : 'Can\'t calculate'}</div>`;
                statsText += `<div>Browser receive to composite (ms): ${aggregatedStats.hasOwnProperty('receiveToCompositeMs') ? numberFormat.format(aggregatedStats.receiveToCompositeMs) : 'Chrome only'}</div>`;
                statsText += `<div style="color: ${color}">Video Quantization Parameter: ${VideoEncoderQP}</div>`;

                let statsDiv = document.getElementById("stats");
                statsDiv.innerHTML = statsText;

                let print_stats = false;
                if (print_stats) {
                    if (aggregatedStats.timestampStart) {
                        if ((aggregatedStats.timestamp - aggregatedStats.timestampStart) > nextPrintDuration) {
                            if (ws && ws.readyState === WS_OPEN_STATE) {
                                console.log(`-> SS: stats\n${JSON.stringify(aggregatedStats)}`);
                                ws.send(JSON.stringify({
                                    type: 'stats',
                                    data: aggregatedStats
                                }));
                            }
                            nextPrintDuration += printInterval;
                        }
                    }
                }
            };

            webRtcPlayerObj.aggregateStats(1 * 1000 /*Check every 1 second*/);

            webRtcPlayerObj.latencyTestTimings.OnAllLatencyTimingsReady = function (timings) {

                if (!timings.BrowserReceiptTimeMs) {
                    return;
                }

                let latencyExcludingDecode = timings.BrowserReceiptTimeMs - timings.TestStartTimeMs;
                let uePixelStreamLatency = timings.UEPreEncodeTimeMs == 0 || timings.UEPreCaptureTimeMs == 0 ? "???" : timings.UEPostEncodeTimeMs - timings.UEPreCaptureTimeMs;
                let captureLatency = timings.UEPostCaptureTimeMs - timings.UEPreCaptureTimeMs;
                let encodeLatency = timings.UEPostEncodeTimeMs - timings.UEPreEncodeTimeMs;
                let ueLatency = timings.UETransmissionTimeMs - timings.UEReceiptTimeMs;
                let networkLatency = latencyExcludingDecode - ueLatency;
                let browserSendLatency = latencyExcludingDecode - networkLatency - ueLatency;

                //these ones depend on FrameDisplayDeltaTimeMs
                let endToEndLatency = null;
                let browserSideLatency = null;

                if (timings.FrameDisplayDeltaTimeMs && timings.BrowserReceiptTimeMs) {
                    endToEndLatency = timings.FrameDisplayDeltaTimeMs + latencyExcludingDecode;
                    browserSideLatency = endToEndLatency - networkLatency - ueLatency;
                }

                let latencyStatsInnerHTML = '';
                latencyStatsInnerHTML += `<div>Net latency RTT (ms): ${networkLatency}</div>`;
                latencyStatsInnerHTML += `<div>UE Capture+Encode (ms): ${uePixelStreamLatency}</div>`;
                latencyStatsInnerHTML += `<div>UE Capture (ms): ${captureLatency}</div>`;
                latencyStatsInnerHTML += `<div>UE Encode (ms): ${encodeLatency}</div>`;
                latencyStatsInnerHTML += `<div>Total UE latency (ms): ${ueLatency}</div>`;
                latencyStatsInnerHTML += `<div>Browser send latency (ms): ${browserSendLatency}</div>`
                latencyStatsInnerHTML += timings.FrameDisplayDeltaTimeMs && timings.BrowserReceiptTimeMs ? `<div>Browser receive latency (ms): ${timings.FrameDisplayDeltaTimeMs}</div>` : "";
                latencyStatsInnerHTML += browserSideLatency ? `<div>Total browser latency (ms): ${browserSideLatency}</div>` : "";
                latencyStatsInnerHTML += `<div>Total latency (excluding browser) (ms): ${latencyExcludingDecode}</div>`;
                latencyStatsInnerHTML += endToEndLatency ? `<div>Total latency (ms): ${endToEndLatency}</div>` : "";
                document.getElementById("LatencyStats").innerHTML = latencyStatsInnerHTML;
            }
            invalidateFreezeFrameOverlay(informer);
        }

        function onWebRtcIce(iceCandidate) {
            if (webRtcPlayerObj)
                webRtcPlayerObj.handleCandidateFromServer(iceCandidate);
        }

    }
    pausePlayer() {
        if (webRtcPlayerObj) webRtcPlayerObj.setVideoEnabled(false);
    }
    restartPlayer() {
        this.invalidateFreezeFrameOverlay(this.informer);
    }
    createPlayer() {
        console.log(`${new Date().toISOString()}[debug]wsClient createPlayer()`);
        let playerDiv = document.getElementById('player');
        if (playerDiv) {
            this.setupWebRtcPlayer(playerDiv, this.config, this.ws);
        }
    }
    removePlayer(mode?: string) {
        let playerDiv = document.getElementById('player');
        if (webRtcPlayerObj) {
            webRtcPlayerObj.close();
            webRtcPlayerObj = undefined;

        }
        if (playerDiv) {
            while (playerDiv.lastChild) {
                playerDiv.removeChild(playerDiv.lastChild);
            }
        }
        if (mode === "reCreate") {
            this.createPlayer();
        }
    }

    createWebRtcOffer() {
        if (webRtcPlayerObj) {
            console.log('Creating offer');
            // showTextOverlay('Starting connection to server, please wait');
            webRtcPlayerObj.createOffer();
        } else {
            console.log('WebRTC player not setup, cannot create offer');
            // showTextOverlay('Unable to setup video');
        }
    }

    setupWebRtcPlayer(htmlElement, config, ws) {
        const informerFn = this.informer;
        // if (!webRtcPlayerObj) {
        console.log("WebRtcPlayerのセットアップを実行...");
        console.log(`${new Date().toISOString()}[debug]wsClient setupWebRtcPlayer`);
        webRtcPlayerObj = new webRtcPlayer(config);
        // }
        htmlElement.appendChild(webRtcPlayerObj.video);

        webRtcPlayerObj.onWebRtcOffer = function (offer) {
            if (ws && ws.readyState === WS_OPEN_STATE) {
                let offerStr = JSON.stringify(offer);
                console.log(`-> SS: offer:\n${offerStr}`);
                ws.send(offerStr);
            }
        };

        webRtcPlayerObj.onWebRtcCandidate = function (candidate) {
            if (ws && ws.readyState === WS_OPEN_STATE) {
                console.log(`-> SS: iceCandidate\n${JSON.stringify(candidate, undefined, 4)}`);
                ws.send(JSON.stringify({
                    type: 'iceCandidate',
                    candidate: candidate
                }));
            }
        };

        webRtcPlayerObj.onVideoInitialised = function () {
        };

        webRtcPlayerObj.onDataChannelConnected = function () {
            if (ws && ws.readyState === WS_OPEN_STATE) {

                if (webRtcPlayerObj.video && webRtcPlayerObj.video.srcObject && webRtcPlayerObj.onVideoInitialised) {
                    webRtcPlayerObj.onVideoInitialised();

                    console.log(`${new Date().toISOString()}[debug]wsClient onDataChannelConnected()`);
                    // PixelStreamer準備完了フラグの更新
                    console.log(`${new Date().toISOString()}[debug]serviceOperation from wsClient "streamerStandby"`);
                    informerFn("Server", null, "streamerStandby", null);

                }
            }
        };



        // For webRtcPlayerObj.onDataChannelMessage
        const ToClientMessageType = {
            QualityControlOwnership: 0,
            Response: 1,
            Command: 2,
            FreezeFrame: 3,
            UnfreezeFrame: 4,
            VideoEncoderAvgQP: 5,
            LatencyTest: 6,
            InitialSettings: 7
        };
        let qualityControlOwnershipCheckBox: any;

        qualityControlOwnershipCheckBox = document.getElementById('quality-control-ownership-tgl');
        if (qualityControlOwnershipCheckBox !== null) {
            qualityControlOwnershipCheckBox.onchange = function (event) {
                // requestQualityControl();
            };
        }
        let responseEventListeners: any = new Map();
        // function addResponseEventListener(name, listener) {
        //     responseEventListeners.set(name, listener);
        // }

        // function removeResponseEventListener(name) {
        //     responseEventListeners.remove(name);
        // }

        webRtcPlayerObj.onDataChannelMessage = function (data) {
            let view = new Uint8Array(data);

            if (view[0] === ToClientMessageType.QualityControlOwnership) {
                let ownership = view[1] === 0 ? false : true;
                console.log("Received quality controller message, will control quality: " + ownership);
                // If we own the quality control, we can't relenquish it. We only loose
                // quality control when another peer asks for it
                if (qualityControlOwnershipCheckBox !== null) {
                    qualityControlOwnershipCheckBox.disabled = ownership;
                    qualityControlOwnershipCheckBox.checked = ownership;
                }
            } else if (view[0] === ToClientMessageType.Response) {
                let response = new TextDecoder("utf-16").decode(data.slice(1));
                for (let listener of responseEventListeners.values()) {
                    listener(response);
                }
            } else if (view[0] === ToClientMessageType.Command) {
                let commandAsString = new TextDecoder("utf-16").decode(data.slice(1));
                console.log(commandAsString);
                let command = JSON.parse(commandAsString);
                if (command.command === 'onScreenKeyboard') {
                    // showOnScreenKeyboard(command);
                }
            } else if (view[0] === ToClientMessageType.FreezeFrame) {
                // processFreezeFrameMessage(view);
            } else if (view[0] === ToClientMessageType.UnfreezeFrame) {
                this.invalidateFreezeFrameOverlay(this.informer);
            } else if (view[0] === ToClientMessageType.VideoEncoderAvgQP) {
                // let VideoEncoderQP = "N/A";
                VideoEncoderQP = new TextDecoder("utf-16").decode(data.slice(1));
                //console.log(`received VideoEncoderAvgQP ${VideoEncoderQP}`);
            } else if (view[0] == ToClientMessageType.LatencyTest) {
                let latencyTimingsAsString = new TextDecoder("utf-16").decode(data.slice(1));
                console.log("Got latency timings from UE.")
                console.log(latencyTimingsAsString);
                let latencyTimingsFromUE = JSON.parse(latencyTimingsAsString);
                if (webRtcPlayerObj) {
                    webRtcPlayerObj.latencyTestTimings.SetUETimings(latencyTimingsFromUE);
                }
            } else if (view[0] == ToClientMessageType.InitialSettings) {
                // reminder bitrates are sent in bps but displayed in kbps

            } else {
                console.error(`unrecognized data received, packet ID ${view[0]}`);
            }
        };

        // setTimeout(createWebRtcOffer, 2000);
        this.createWebRtcOffer();

        return webRtcPlayerObj.video;
    }
    // A locked mouse works by the user clicking in the browser player and the
    // cursor disappears and is locked. The user moves the cursor and the camera
    // moves, for example. The user presses escape to free the mouse.
    registerLockedMouseEvents(playerElement) {
        // let x = playerElement.width / 2;
        // let y = playerElement.height / 2;

        playerElement.requestPointerLock = playerElement.requestPointerLock || playerElement.mozRequestPointerLock;
        document.exitPointerLock = document.exitPointerLock;

        playerElement.onclick = function () {
            playerElement.requestPointerLock();
        };
    }

    playerServerKeepAlive() {
        const ws = this.ws;
        // const reConnect = () => {
        //     this.init(this.informer);
        // }
        this.heartbeatSender = setInterval(function () {
            let message = {
                type: 'ping',
                memo: 'ping from client.'
            };
            // console.log(`ping send to playerServer.`);
            if (ws.readyState === 1) {
                ws.send(JSON.stringify(message));
            } else {

                // WebSocketが切断されているので、再接続
                // reConnect();
            }
            // statusManageWS.send(JSON.stringify(message));
        }, 50000);
    }

    emitUIInteraction(descriptor) {
        // exportしているemitUIInteraction関数に中継
        emitUIInteraction(descriptor);
    }
    

    
};

// UE4へのコマンド送信
const sendInputData = (data) => {
    if (webRtcPlayerObj) {
        // this.resetAfkWarningTimer();
        webRtcPlayerObj.send(data);
    }
}

// WebアプリからUE4アプリへのコマンド送信I/F
const emitDescriptor = (messageType, descriptor) => {
    // Convert the dscriptor object into a JSON string.
    let descriptorAsString = JSON.stringify(descriptor);

    // Add the UTF-16 JSON string to the array byte buffer, going two bytes at
    // a time.
    let data = new DataView(new ArrayBuffer(1 + 2 + 2 * descriptorAsString.length));
    let byteIdx = 0;
    data.setUint8(byteIdx, messageType);
    byteIdx++;
    data.setUint16(byteIdx, descriptorAsString.length, true);
    byteIdx += 2;
    for (let i = 0; i < descriptorAsString.length; i++) {
        data.setUint16(byteIdx, descriptorAsString.charCodeAt(i), true);
        byteIdx += 2;
    }
    sendInputData(data.buffer);
}
export const emitUIInteraction = (descriptor) => {
    // console.log(`input from Web UI: ${descriptor}`);
    if(descriptor[1] === "iPhone") {
        // console.log(`input from Web UI: ${descriptor[0]}`);
    }
    emitDescriptor(MessageType.UIInteraction, descriptor);
}

export const WsStateList = {
    Unset: "Unset",
    Stopped: "Stopped",
    Initialized: "Initialized",
    VideoCallerStandby: "VideoCallerStandby",
    StreamerReceived: "StreamerReceived",
    Streaming: "Streaming",
};

// export type WsStateType = typeof WsStateList[keyof typeof WsStateList];