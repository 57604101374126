import React from "react";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
  CSSReset,
  ChakraProvider,
  Center
} from "@chakra-ui/react";
// Assets
import signInImage from "assets/img/signInImage.png";
import ProfileBgImage from "assets/img/ProfileBackground.png";
import LoginForm from 'components/ksin/Forms/LoginForm'
import HookForm from 'components/HookForm/HookForm.js'
import { useHistory, useLocation  } from 'react-router-dom';

function Login(props) {
  const history = useHistory();
  const [date, setDate] = React.useState()
  const [time, setTime] = React.useState()
  const location = useLocation();
  //URLトークンを開く
  const searchParams = new URLSearchParams(location.search);
  const urlToken = searchParams.get('token');
  
  // //URLトークンを保存する
  // localStorage.setItem("token", urlToken)
  
  const register = location.state?.register ? location.state?.register : false
  const [isRegister, setRegister] = React.useState(register)
  isRegister ? setTimeout(() => setRegister(false), 5000) : ""
  function fetchToken(token){
    console.log("[webReservation]called fetchToken.");
    const sendData = { token: token };
    const param = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      body: JSON.stringify(sendData)
    };
    return fetch('/token/login', param)
      .then(response => { 
        return response.json()})
      .then(json => {
        if (json) {
          const result = json.result
          const reservDate = new Date(json.date)
          const currDate = new Date();
          if (result == "OK") {
            console.log("[webReservation]/token/login result OK.");
            if (reservDate > currDate) {
              console.log("[webReservation]move to /waiting-room");
              history.push({ 
                pathname: "/waiting-room",
                state: { token: urlToken }
                });
            } else if ( currDate >= reservDate ) {
              console.log("[webReservation]move to /app/signage/view");
              history.push({ 
                pathname: "/app/signage/view",
                state:{ date: reservDate, token: urlToken }
              });
            }
          } else if (json.msg === "jwt expired") {
            const con = confirm("トークンの有効期限が切れました")
            con ? history.push({ pathname: "/login" }) : ""
          }
        } else {
          console.log(json)
        }
      }
    )
  }
  setTimeout(() => {
    // urlToken ? fetchToken(urlToken) : ""
  }, 1000)      

  let isAutoLogin = true;
  if (props.location && props.location.state && props.location.state.isRedirectByLaterLogin) {
    isAutoLogin = false;
  } 
  // Chakra color mode
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.400", "white");
  return (
    <Flex direction="column">
      <Box
        mb={{ sm: "205px", md: "75px", xl: "70px" }}
        borderRadius="15px"
        px="0px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        align="center"
      >
        <Box
          bgImage={ProfileBgImage}
          w="100%"
          h="300px"
          borderRadius="25px"
          bgPosition="50%"
          bgRepeat="no-repeat"
          position="relative"
          display="flex"
          justifyContent="center"
        >
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          align="center"
          position="absolute"
          top="50px"
          left="200px"
        >
          <Text color="white" fontWeight="light" textAlign="left">
            アバター接客 KSIN
          </Text>
        </Box>
        <Box
          w="400px"
          h="500px"
          borderRadius="15px"
          px="0px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          align="center"
          position="absolute"
          top="150px"
          left="0"
          right="0"
          backgroundColor="white"
          margin="auto"
        >
          <ChakraProvider>
            <CSSReset />
            <Box p={4}>
              <LoginForm isAutoLogin={isAutoLogin} />
            </Box>
          </ChakraProvider>
        </Box>
        { isRegister ?
          <Center
            height="0vh"
          >         
            <Box
              zIndex="9999"
              w="500px"
              h="120px"
              backgroundColor="#81E6D9"
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="10px"
              >
              <Text
                color="white"
                fontSize="1.5rem"
                fontWeight="bold"
                >
                アカウント登録済です
              </Text>
            </Box>
          </Center>
        : ""}
      </Box>
    </Flex>
  );
}

export default Login;
