import { Flex, Input, Text, Textarea, Box, Slider, SliderTrack, SliderFilledTrack, Tooltip, SliderThumb } from "@chakra-ui/react";
import React from "react";
import { SettingButtonItem } from "views/Pages/Users/SignageTemplateUpdate";

const PopupEditSettingButton = ({
  handleChangeSettingsItems,
  item,
}: {
  item: SettingButtonItem;
  handleChangeSettingsItems: (data: SettingButtonItem) => void;
}) => {
  if (!item) return null;
  const [showTooltip, setShowTooltip] = React.useState(false)
  const { textBtn, color, fontSize } = item;

  return (
    <Flex
      flexDirection={"column"}
      padding="5px"
      borderWidth={1}
      borderColor="#C3C3C3"
      boxShadow={"lg"}
      width="full"
    >
      <Flex marginBottom={5}>
        <Box
          width={"40px"}
          height={"40px"}
          border="1px"
          borderColor="gray.300"
          rounded="md"
          textAlign="center"
          display="flex"
          justifyContent="center"
          alignItems="center"
          fontWeight={"semibold"}
          color="gray.600"
          marginRight={3}
        >
          T
        </Box>

        <Textarea
          value={textBtn}
          onChange={(e) => {
            const value = e.target.value;
            if (value.length > 10) return;
            handleChangeSettingsItems({
              ...item,
              textBtn: value,
            });
          }}
          errorBorderColor="red.300"
          maxLength={10}
          width='auto'
        />
      </Flex>
      <Flex marginBottom={5}>
        <Box
          width={"40px"}
          height={"40px"}
          border="1px"
          borderColor="gray.300"
          rounded="md"
          textAlign="center"
          display="flex"
          justifyContent="center"
          alignItems="center"
          fontWeight={"semibold"}
          color="gray.600"
          marginRight={3}
        >
          S
        </Box>
        <Slider
          id='slider'
          value={fontSize}
          min={8}
          max={40}
          colorScheme='teal'
          onChange={(value) => handleChangeSettingsItems({
            ...item,
            fontSize: value,
          })}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <SliderTrack >
            <SliderFilledTrack />
          </SliderTrack>
          <Tooltip
            hasArrow
            bg='teal.400'
            color='white'
            placement='top'
            isOpen={showTooltip}
            label={`${fontSize}px`}
          >
            <SliderThumb />
          </Tooltip>
        </Slider>
      </Flex>
      <Flex alignItems="center" justifyContent="space-between">
        <Text fontWeight={"bold"}> 塗りつぶしの色</Text>
        <Input
          type="color"
          value={color}
          width={20}
          padding="0"
          cursor={"pointer"}
          onChange={(e) => {
            handleChangeSettingsItems({
              ...item,
              color: e.target.value,
            });
          }}
        />
      </Flex>
    </Flex>
  );
};

export default PopupEditSettingButton;
