// Chakra Imports
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Flex,
  Switch,
  Text,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import { Separator } from "components/Separator/Separator";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { MediaClient } from "../ApiClient/MediaClient";
import InputImage from "../Forms/parts/InputImage";
import { checkSize } from "components/ksin/util/CalculationMediaSize";

export default function LogoConfigurator(props: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDisp, setIsDisp] = useState(true);
  const [isAllScene, setIsAllScene] = useState(true);

  const [uploadImg, setUploadImg] = React.useState(null);
  const [uploadImgFile, setUploadImgFile] = React.useState(null);

  const [media, setMedia] = useState([]);
  const [totalMediaSize, setTotalMediaSize] = useState(0);
  const [isaddMedia, setIsaddMedia] = useState(false);
  const [serverErrors, setServerErrors] = React.useState([]);

  const addServerError = (errObj: { code: string, msg: string }) => {
    setServerErrors([...serverErrors, errObj]);
    console.log(JSON.stringify(serverErrors));
  }

  const mediaClient = new MediaClient();
  useEffect(() => { getMedia(); }, []);
  
  // メインエリアのボタンで編集メニューを選択されたとき
  useEffect(() => {
    if (props.isOpen) {
      if (props.currentTemplate) {
        setIsDisp(props.currentTemplate.is_view_logo);
        // setUploadImg(props.currentTemplate.telop);
      }
      onOpen();
    }
  }, [props.isOpen]);

  // 閉じられたことを親コンポーネントに伝える
  useEffect(() => {
    props.setIsOpen(isOpen);
  }, [isOpen]);

  // ロゴがアップロードされたら
  useEffect(() => {
    if(uploadImgFile == null
      || checkSize(uploadImgFile.size + totalMediaSize) === false
      || uploadImg == null
      || props.templateTable == null) return;

    setShowFlieImage(uploadImg);    // 画面表示用のBlobURL
    updateMedia(uploadImgFile);
  }, [uploadImg, uploadImgFile]);

  useEffect(() => {
    if(media.length == 0 || isaddMedia == false) return;

    let totalByteSize = 0;
    media.map((d) => {
      totalByteSize += d.size;
    });
    setTotalMediaSize(totalByteSize);

    setSendServerFileImage(media[0].id, "newUpload");
    setIsaddMedia(false);

  },[media]);




  useEffect(() => {
    if(media.length == 0 || isaddMedia == false) return;

    let totalByteSize = 0;
    media.map((d) => {
      totalByteSize += d.size;
    });
    setTotalMediaSize(totalByteSize);

    setSendServerFileImage(media[0].id, "newUpload");
    setIsaddMedia(false);

  },[media]);

  const getMedia = () => {
    mediaClient.get()
    .then((data: any) => {
      const staticImages = [];
      data.forEach((media) => {
        let extension = media.image_url.split('.').pop();
        const allowImageTypes = ["png", "jpeg", "jpg"];

        if(allowImageTypes.indexOf(extension) != -1){
          staticImages.push(media);
        }
      })
      setMedia([...staticImages]);
    })
    .catch((error) => {
      console.log(error);
    });
  };

  const updateMedia = (targetFile: any) => {
    let values: any = {};
    values.image = targetFile;
    mediaClient.add(values)
      .then((json: any) => {
        console.log(json);
        if(json.result == 'OK'){
          setIsaddMedia(true);
          getMedia();
        }
      })
      .catch((e) => {
        addServerError(
          {
            code: "SVER-Unexpected",
            msg: "未定義のサーバーエラーが発生しました"
          }
        );
    });
  };

  const checkMediaType = (image_url: string) => {
    const allowImageTypes = ["png", "jpeg",  "jpg"];
    // const allowVideoTypes = [];
    let extension = image_url.split('.').pop();

    if(allowImageTypes.indexOf(extension) != -1){
      return <Image src={image_url} objectFit="contain" w="100%" height="100%" borderRadius="10px" />;

    }else{
      return <></>;

    }
  };

  const selectedMedia = (selectedMediadata: any) => {
    setShowFlieImage(selectedMediadata.image_url);
    setSendServerFileImage(selectedMediadata.id, "libraryMedia");
  };

  const setShowFlieImage = (uploadImg: string) => {
    if (isAllScene) {
      for (let state of props.templateTable.values()) {
        state.template.logo_src = uploadImg;
      }
    } else {
      props.templateTable.get(props.signageState).template.logo_src = uploadImg;
    }
    props.setTemplateTable(new Map(props.templateTable));
  };

  const setSendServerFileImage = (uploadImgFile: any, mode: "newUpload" | "libraryMedia") => {
    if (isAllScene) {
      for (let state of props.templateTable.values()) {
        props.uploadImageTable[state.stateName]["logo"] = `Uploaded_${props.signageState}`;
      }
    }
    // NOTE: ライブラリから選択したのかアップロードしたファイルかを
    props.uploadImageTable[props.signageState]["logo"] = {image: uploadImgFile, mode: mode};
    props.setUploadImageTable({...props.uploadImageTable});
  };


  // ロゴの表示・非表示切り替え
  useEffect(() => {
    if (props.templateTable) {
      props.templateTable.get(props.signageState).template.is_view_logo = isDisp;
      props.setTemplateTable(new Map(props.templateTable));
    }
  }, [isDisp]);

  // 選択中のシーンが変わったらswitchの選択はテンプレートに合わせる
  useEffect(() => {
    if (props.templateTable) {
      setIsDisp(props.templateTable.get(props.signageState).template.is_view_logo);
    }
  }, [props.signageState]);

  return (
    <>
      {/* <Button onClick={onOpen}>Open</Button> */}
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
      >
        <DrawerContent>
          <DrawerHeader pt="24px" px="24px">
            <DrawerCloseButton />
            <Text fontSize="xl" fontWeight="bold" mt="16px">
              ロゴ設定
            </Text>
          </DrawerHeader>
          <DrawerBody w="340px" ps="24px" pe="40px">
            <Flex flexDirection="column">
              <Box>
                <Text fontSize="md" fontWeight="600">
                  ロゴ表示
                </Text>
                <Flex>
                  <Text fontSize="sm" mr="1rem">
                    ロゴを表示する
                  </Text>
                  <Switch
                    colorScheme="teal"
                    isChecked={isDisp}
                    onChange={() => {
                      if (isDisp === true) {
                        setIsDisp(false);
                      } else {
                        setIsDisp(true);
                      }
                    }}
                  />
                </Flex>
              </Box>
              <Separator mt="1rem" mb="1rem" />
              <Box
                justifyContent="space-between "
                mb="16px"
              >
                <Text fontSize="md" fontWeight="600" mb="4px">
                  ロゴ選択
                </Text>
                <Flex>
                  <Text fontSize="sm" mr="1rem">
                    すべてのシーンに反映
                  </Text>
                  <Switch
                    colorScheme="teal"
                    isChecked={isAllScene}
                    onChange={() => {
                      if (isAllScene === true) {
                        setIsAllScene(false);
                      } else {
                        setIsAllScene(true);
                      }
                    }}
                  />
                </Flex>
              </Box>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                mb="24px"
              >
                <InputImage
                  inLogoConfigurator={true}
                  setUploadImgFile={setUploadImgFile}
                  inDrawer={true}
                  setImg={setUploadImg}
                />
              </Flex>
              
              <Separator mt="1rem" mb="1rem" />

              <Accordion width="100%">
                <Flex mb="10px">
                  <Text
                    color="gray.400"
                    fontWeight="bold"
                  >
                    メディアライブラリ
                  </Text>
                </Flex>
                {media.map((row) => 
                  <AccordionItem>
                    <h2>
                      <AccordionButton
                          p="1"
                          backgroundClip="content-box"
                          _expanded={{
                              bg: "teal.200",
                          }}
                          _focus={{
                            outline: "none"
                          }}
                          onClick={() => selectedMedia(row)}
                      >
                        <Box
                          w="50px"
                          h="50px"
                          minWidth="50px"
                          bg="teal.300"
                          color="white"
                          borderRadius="12px"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          {checkMediaType(row.image_url)}
                        </Box>
                        <Flex maxWidth="16rem" overflow="hidden" alignItems="center">
                          <Text
                            fontSize="medium"
                            color="black"
                            ml="4"
                            overflow="hidden"
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                          >
                            {row.name}{'.' + row.image_url.split('.').pop()}
                          </Text>
                        </Flex>
                      </AccordionButton>
                    </h2>
                  </AccordionItem>
                )}
              </Accordion>
              <Separator mt="1rem" mb="1rem" />
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
