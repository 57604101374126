import { useForm } from 'react-hook-form'
import React from 'react'
import {
  Box,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Link,
  Switch,
  Text,
  Flex,
  Checkbox,
  Textarea,
  UnorderedList,
  ListItem,
  Select
} from '@chakra-ui/react'
import { Link as RouteLink, useHistory } from "react-router-dom";
import { UserClient } from '../ApiClient/UserClient';

export default function RegisterForm() {
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors, isSubmitting },
  } = useForm()
  const history = useHistory();
  // 規約等の同意チェック確認用
  const [isAgreedRules, setIsAgreedRules] = React.useState(false);
  const [isAgreedPayment, setIsAgreedPayment] = React.useState(false);
  // const [isAllowRegister, setIsAllowRegister] = React.useState(false);
  const [isCompleted, setIsCompleted] = React.useState(false);
  const [locale, setLocale] = React.useState("en");

  const localeLabel = {
    "formTitle": {
      "ja": "KSIN TimeShareアカウント登録",
      "en": "KSIN TimeShare Sign Up"
    },
    "teamName": {
      "ja": "団体名",
      "en": "Organization name"
    },
    "contactName": {
      "ja": "ご担当者氏名",
      "en": "Name of person in charge",
    },
    "email": {
      "ja": "メールアドレス",
      "en": "email address",
    },
    "password": {
      "ja": "パスワード",
      "en": "password",
    },
    "passwordSettingRules": {
      "title": {
        "ja": "パスワード設定ルール",
        "en": "Password Setting Rules",
      },
      "rule01": {
        "ja": "8文字以上を設定してください",
        "en": "Please set 8 characters or more",
      },
      "rule02": {
        "ja": "半角英大文字、半角英小文字、半角数字、使用できる記号の中から3種類以上を使用してください",
        "en": "Please use at least three types of characters from the following: half-width uppercase letters, half-width lowercase letters, half-width numbers, and symbols.",
      },
      "rule03": {
        "ja": "使用できる記号は!#%&*+-./<=>?@^_~のみです",
        "en": "The only symbols allowed are !#%&*+-./<=>?@^_~",
      },
      "rule04": {
        "ja": "全角文字は使用できません",
        "en": "Double-byte characters are not allowed.",
      },
    },
    "phone": {
      "ja": "電話番号",
      "en": "telephone number",
    },
    "organizationAddress": {
      "ja": "団体住所",
      "en": "Organization Address",
    },
    "agreement": {
      "message": {
        "ja": "アカウント登録に際し下記内容のご確認・同意チェックをお願いいたします。",
        "en": "When registering your account, please confirm and agree to the following contents.",
      },
      "TermsOfService": {
        "ja": "サービス利用規約",
        "en": "Terms of Service",
      },
      "FeesAndBilling": {
        "ja": "料金およびご請求に関して",
        "en": "Fees and Billing",
      },
      "agree": {
        "ja": "同意する",
        "en": "agree",
      }
    },
    "signUp": {
      "ja": "アカウント作成",
      "en": "Sign Up",
    },
    "complete": {
      "ja": "アカウント作成が完了しました",
      "en": "Sign Up is Successfull!",
    },
    "linkToLogin": {
      "ja": "アカウントをお持ちの方はこちら",
      "en": "If you have an account, click here",
    }
  }

  // 規約等のpdfファイルURL
  // 利用規約
  const linkToRules = "http://unicast-robotics.com/wp-content/uploads/2024/04/KSIN_TimeShare_利用規約_20240405.pdf";
  // お支払いについて
  const linkToPayment = "http://unicast-robotics.com/wp-content/uploads/2024/04/KSIN_TimeShare_ご利用料金_20240405.pdf";

  const patternReg = new RegExp(/^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])|(?=.*[a-z])(?=.*[A-Z])(?=.*[!#%&*+-./<=>?@^_~])|(?=.*[A-Z])(?=.*[0-9])(?=.*[!#%&*+-./<=>?@^_~])|(?=.*[a-z])(?=.*[0-9])(?=.*[!#%&*+-./<=>?@^_~]))/);

  function onSubmit(values) {
    const client = new UserClient();
    const sendData = {
      user: {
        name: values.name,
        login_id: values.email,
        password: values.password,
        kana: values.kana && values.kana.length > 0 ? values.kana : values.name,
        phone: values.phone,
        address: values.address
      },
      company: {
        name: values.company && values.company.length > 0 ? values.company : "None",
      },
    }
    console.log(sendData);
    const param = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      body: JSON.stringify(sendData)
    };

    return fetch(`/registerNewAccount`, param)
      .then(response => response.json())
      .then(json => {
        console.log(json.msg);
        if (!json.success) {
          setError("email", {
            type: "alreadyRegistered",
            message: "このメールアドレスは既に登録されています",
          });
        } else if (json.success) {
          // history.push({ pathname: "/login", state: { register: true } })
          // メッセージを表示
          setIsCompleted(true);
          // ログイン情報変更時はログアウト
          client.logout();
          // 2秒後に自動ログイン
          setTimeout(() => {
            history.push({ pathname: "/login", state: { register: true } });
          }, 2000)
        }
      })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Text fontSize="lg" fontWeight="bold" mb="20px">
        {localeLabel["formTitle"][locale]}
      </Text>
      <Select 
      placeholder='Language' 
      mb="1rem"
      onChange={(e) => {
        setLocale(e.target.value);
    }}
      >
        <option value='en'>English</option>
        <option value='ja'>日本語</option>
      </Select>
      <FormControl isInvalid={errors.company}>
        <FormLabel ms="4px" mt="4px" fontSize="sm" fontWeight="normal" htmlFor='company'>
          {localeLabel["teamName"][locale]}
        </FormLabel>
        <Input
          borderRadius="15px"
          // mb="24px"
          fontSize="sm"
          type="text"
          size="lg"
          id='company'
          placeholder='company'
          {...register('company', {
            required: false,
            minLength: { value: 1, message: 'Minimum length should be 4' },
          })}
        />
        <FormErrorMessage>
          {errors.company && errors.company.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={errors.name}>
        <FormLabel ms="4px" mt="4px" fontSize="sm" fontWeight="normal" htmlFor='name' >
          {/* ご担当者氏名 */}
          {localeLabel["contactName"][locale]}
          <Text color="red" ml="0.25rem" display="inline">*</Text>
        </FormLabel>
        <Input
          borderRadius="15px"
          // mb="24px"
          fontSize="sm"
          type="text"
          size="lg"
          id='name'
          placeholder='Your name'
          {...register('name', {
            required: '必須入力です',
            minLength: { value: 2, message: 'Minimum length should be 2' },
          })}
        />
        <FormErrorMessage>
          {errors.name && errors.name.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={errors.kana}
        display={locale === "ja" ? "" : "none"}>
        <FormLabel ms="4px" mt="4px" fontSize="sm" fontWeight="normal" htmlFor='kana'>
          氏名フリガナ
        </FormLabel>
        <Input
          borderRadius="15px"
          // mb="24px"
          fontSize="sm"
          type="text"
          size="lg"
          id='kana'
          placeholder='Your Kana'
          {...register('kana', {
            required: false,
            minLength: { value: 2, message: 'Minimum length should be 2' },
          })}
        />
        <FormErrorMessage>
          {errors.kana && errors.kana.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={errors.email}>
        <FormLabel ms="4px" mt="4px" fontSize="sm" fontWeight="normal" htmlFor='email'>
          {/* メールアドレス */}
          {localeLabel["email"][locale]}
          <Text color="red" ml="0.25rem" display="inline">*</Text>
        </FormLabel>
        <Input
          borderRadius="15px"
          // mb="24px"
          fontSize="sm"
          type="text"
          size="lg"
          id='email'
          placeholder='Your email'
          {...register('email', {
            required: '必須入力です',
            minLength: { value: 1, message: 'Minimum length should be 4' },
          })}
        />
        <FormErrorMessage>
          {errors.email && errors.email.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={errors.password}>
        <FormLabel ms="4px" mt="4px" fontSize="sm" fontWeight="normal" htmlFor='password'>
          {localeLabel["password"][locale]}
          <Text color="red" ml="0.25rem" display="inline">*</Text>
        </FormLabel>
        <Input
          borderRadius="15px"
          // mb="24px"
          fontSize="sm"
          type="password"
          size="lg"
          id='password'
          placeholder='Your password'
          {...register('password', {
            required: false,
            minLength: { value: 8, message: 'パスワードは8文字以上を設定してください' },
            maxLength: { value: 128, message: 'パスワードは128文字以内で設定してください' },
            pattern: {
              value: patternReg,
              message: `パスワード設定ルールをご確認ください`
            },
            validate: {
              notAllowedMark: (value) => {
                let marks = value.replace(/[0-9A-Za-z!#%&*+-./<=>?@^_~]/g, "");
                return marks.length === 0 ? true : false
              }
            }
          })}
        />
        <FormErrorMessage>
          {errors.password && errors.password.message}
        </FormErrorMessage>
      </FormControl>
      <Box
        flexDirection="column"
        textAlign="left"
        mt="1rem"
        border="1px"
        borderRadius='md'
        borderColor="gray.400"
        p="1rem"
        mb="1rem"
      >
        <Text fontSize="0.875rem">
          {/* パスワード設定ルール */}
          {localeLabel["passwordSettingRules"]["title"][locale]}
        </Text>
        <UnorderedList fontSize="0.75rem">
          <ListItem>{localeLabel["passwordSettingRules"]["rule01"][locale]}</ListItem>
          <ListItem>{localeLabel["passwordSettingRules"]["rule02"][locale]}</ListItem>
          <ListItem>{localeLabel["passwordSettingRules"]["rule03"][locale]}</ListItem>
          <ListItem>{localeLabel["passwordSettingRules"]["rule04"][locale]}</ListItem>
        </UnorderedList>
      </Box>
      {/* 電話番号 */}
      <FormControl isInvalid={errors.name}>
        <FormLabel ms="4px" mt="4px" fontSize="sm" fontWeight="normal" htmlFor='phone' >
          {/* 電話番号 */}
          {localeLabel["phone"][locale]}
        </FormLabel>
        <Input
          borderRadius="15px"
          // mb="24px"
          fontSize="sm"
          type="text"
          size="lg"
          // id='name'
          placeholder='Your phone'
          {...register('phone', {
            required: false,
            minLength: { value: 7, message: 'Minimum length should be 2' },
          })}
        />
        <FormErrorMessage>
          {errors.name && errors.name.message}
        </FormErrorMessage>
      </FormControl>
      {/* 法人住所 */}
      <FormControl isInvalid={errors.name}>
        <FormLabel ms="4px" mt="4px" fontSize="sm" fontWeight="normal" htmlFor='address' >
          {/* 団体住所 */}
          {localeLabel["organizationAddress"][locale]}
        </FormLabel>
        <Textarea
          borderRadius="15px"
          // mb="24px"
          fontSize="sm"
          type="text"
          size="lg"
          // id='name'
          placeholder='Your address'
          {...register('address', {
            required: false,
            minLength: { value: 2, message: 'Minimum length should be 2' },
          })}
        />
        <FormErrorMessage>
          {errors.name && errors.name.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl mt="1rem">
        <Text fontSize="small" textAlign="left">
          {/* アカウント登録に際し下記内容のご確認・同意チェックをお願いいたします。 */}
          {localeLabel["agreement"]["message"][locale]}
        </Text>
        <Flex
          direction="row">
          <Link color="teal.500" href={linkToRules} target="_blank" _hover={{ textDecoration: "underline" }}>
            {/* サービス利用規約 */}
            {localeLabel["agreement"]["TermsOfService"][locale]}
          </Link>
          <Checkbox
            ml="1rem"
            onChange={(e) => setIsAgreedRules(e.target.checked)}
          >{localeLabel["agreement"]["agree"][locale]}</Checkbox>
        </Flex>
        <Flex
          direction="row">
          <Link color="teal.500" href={linkToPayment} target="_blank" _hover={{ textDecoration: "underline" }}>
            {localeLabel["agreement"]["FeesAndBilling"][locale]}
          </Link>
          <Checkbox
            ml="1rem"
            onChange={(e) => setIsAgreedPayment(e.target.checked)}
          >{localeLabel["agreement"]["agree"][locale]}</Checkbox>
        </Flex>
        <Button
          fontSize="14px"
          bg="teal.300"
          w="100%"
          h="45"
          mb="20px"
          color="white"
          mt="20px"
          _hover={{
            bg: "teal.200",
          }}
          _active={{
            bg: "teal.400",
          }}
          colorScheme='teal'
          isLoading={isSubmitting}
          type='submit'
          disabled={!(isAgreedRules && isAgreedPayment)}
        >
          {/* アカウント登録 */}
          {localeLabel["signUp"][locale]}
        </Button>
        <Text
          fontSize="sm"
          display={isCompleted ? "block" : "none"}
        >
          {localeLabel["complete"][locale]}
        </Text>
        {/* <Link as={RouteLink} color="teal.500" to='/test/login' _hover={{ textDecoration: "underline" }}
          display={isCompleted ? "block" : "none"}
        >
          ログイン画面に進む
        </Link> */}
        <Link as={RouteLink} color="teal.500" to='/test/login' _hover={{ textDecoration: "underline" }}>
          {/* アカウントをお持ちの方はこちら */}
          {localeLabel["linkToLogin"][locale]}
        </Link>
      </FormControl>
    </form>
  )
}