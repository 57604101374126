import React, { useEffect, useRef } from "react";
// Chakra imports
import {
  Image,
  Avatar,
  Box,
  Flex,
  Button,
  Text,
  Badge,
  FormControl,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  AspectRatio,
  useDisclosure,
  Spacer,
} from "@chakra-ui/react";
import { CharacterClient } from "components/ksin/ApiClient/CharacterClient";
import { UserClient } from "components/ksin/ApiClient/UserClient";
import { PersonIcon } from "components/Icons/Icons";
import { useForm } from "react-hook-form";
import {
  AiOutlineCheckCircle
} from "react-icons/ai";
import ShowAffiliation from "components/ksin/Forms/parts/ShowAffiliation";


function SelectCharacter(props: any) {
  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm()

  const [serverErrors, setServerErrors] = React.useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Chakra color mode
  const [select, setSelect] = React.useState("");
  const [standbyImg, setStandbyImg] = React.useState("");
  const [characters, setCharacters] = React.useState([]);
  let userData = props.row;
  const handleClick = (value) => {
    setSelect(value);
    setValue("character_id", value);
  }

  const getCharacterStandbyImg = (characterId: string) => {
    const selectCharacter = characters.filter(c => c.id === characterId);
    if (selectCharacter) {
      return selectCharacter[0].standby_image_url;
    } else {
      return "";
    }
  }

  const onClickPreview = (characterId: string) => {
    setStandbyImg(getCharacterStandbyImg(characterId));
    onOpen();
  }
  const userClient = new UserClient();

  const characterClient = new CharacterClient();

  useEffect(() => {
    characterClient.get().then((data: any) => {
      setCharacters(data);
      setSelect(props.currentCharacterId);
    })
  }, [])

  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    videoRef.current?.play();
  }, []);

  const addServerError = (errObj: { code: string, msg: string }) => {
    setServerErrors([...serverErrors, errObj]);
    console.log(JSON.stringify(serverErrors));
  }

  function onModalSubmit(values: any) {
    props.selectCharacterById(select);
    if (props.isForm) {
      props.onClose();
    } else {
      // error reset.
      setServerErrors([]);

      // 対象ユーザーの指定
      values.targetUserId = userData.userId;

      userClient.update(values).then(json => {
        switch (json.result) {
          case "OK":
            console.log(`Update Success.`);
            // // 1つ前の画面へ遷移
            // history.goBack()
            props.onClose();
            break;
          case "Error":
            addServerError(
              {
                code: json.code,
                msg: json.msg
              }
            );
            break;
          default:
            addServerError(
              {
                code: "SVER-Unexpected",
                msg: "未定義のサーバーエラーが発生しました"
              }
            );
        }
      })
    }
  }

    return (
      <Flex direction="column">
        {/* <form onSubmit={handleSubmit(onModalSubmit)}> */}
        <form>
          <FormControl height="auto">
            <Box
              // mb={{ sm: "205px", md: "75px", xl: "70px" }}
              mb="0px"
              borderRadius="15px"
              px="0px"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              align="center"
              backgroundColor="white"
              padding="0px"
              width="100%"
            >
              {props.isAddMode ? <></> :
                <Flex display="inline-block" id="ksin-user-info">
                  <Box p="5" pt="8" pb="8" pr="0">
                    <Flex align="center" minWidth="100%" flexWrap="nowrap">
                      <Avatar src={userData.image ? userData.image : ""} w="50px" borderRadius="12px" me="18px" />
                      <Flex direction="column" width="200px">
                        <Text
                          fontSize="md"
                          color="black"
                          fontWeight="bold"
                          minWidth="100%"
                          textAlign="left"
                        >
                          {userData.name}
                          {userData.role === "companyAdmin" ?
                            <Badge
                              bg="red.600"
                              color="white"
                              fontSize="8px"
                              p="2px 12px"
                              borderRadius="3px"
                              ml="2"
                            >
                              管理者
                            </Badge> :
                            <></>
                          }
                        </Text>
                        <ShowAffiliation
                          storeId={userData.storeId}
                          storeList={props.storeData}
                          companyName={props.companyName}
                        />
                      </Flex>
                    </Flex>
                  </Box>
                </Flex>}
              <Flex>
                {characters.map((row, index) =>
                  <Flex pl="5" flexDirection="column">
                    {select === row.id ?
                      <Badge
                        w="80px"
                        h="30px"
                        p="0px"
                        bg="white"
                        fontSize="md"
                        color="red.300"
                        // border="2px solid"
                        borderColor="teal.300"
                        borderRadius="5px"
                        textAlign="left"
                      >
                        選択中
                  </Badge> :
                      <Spacer h="30px" />
                    }
                    <Flex>
                      <Button
                        w="100px"
                        h="100px"
                        p="0px"
                        border={select === row.id ? "4px solid" : "none"}
                        borderColor="red.300"
                        borderRadius="12px"
                        bg={select === row.id ? "red.300" : "transparent"}
                        _hover={
                          { opacity: 0.5, }
                        }
                        onClick={() => handleClick(row.id)}
                      >
                        {row.image_url !== null && row.image_url !== '' ?
                          <Image src={row.image_url} objectFit="cover" w="100%" height="100%" borderRadius="10px" onClick={() => { }} /> :
                          <PersonIcon color="inherit" h="90px" w="90px" borderRadius="10px" />
                        }
                      </Button>
                    </Flex>
                    <Flex pl="2" mt="2" display="block" textAlign="left" position="relative" left="-9px">
                      <Text fontSize="xs" color="gray.400" fontWeight="normal" textAlign="left" paddingLeft="3px">
                        No.{index + 1}
                      </Text>
                      <Text fontSize="md" color="black" fontWeight="bold" textAlign="left" paddingLeft="3px">
                        {row.name}
                      </Text>
                      <Button
                        fontSize="14px"
                        bg="teal.300"
                        w="98.066%"
                        h="38px"
                        mb="20px"
                        color="white"
                        mt="20px"
                        _hover={{
                          bg: "teal.200",
                        }}
                        _active={{
                          bg: "teal.400",
                        }}
                        colorScheme='teal'
                        onClick={() => onClickPreview(row.id)}
                      >
                        プレビュー
                  </Button>
                      <Modal
                        isOpen={isOpen}
                        onClose={onClose}
                        size="4xl"
                        id="character-preview"
                      >
                        <ModalOverlay />
                        <ModalContent>
                          <ModalHeader>キャラクタープレビュー</ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                            <AspectRatio maxW='100%' ratio={16 / 9}>
                              <React.StrictMode>
                                <video muted autoPlay loop ref={videoRef} >
                                  <source src={standbyImg} type="video/mp4" />
                                </video>
                              </React.StrictMode>
                            </AspectRatio>
                          </ModalBody>
                          <ModalFooter>
                          </ModalFooter>
                        </ModalContent>
                      </Modal>

                    </Flex>
                  </Flex>
                )}
              </Flex>

              <Flex width="100%" justifyContent="center" margin="0 auto" >
                <Button
                  display="grid"
                  gridTemplateColumns="repeat(2, max-content)"
                  leftIcon={<AiOutlineCheckCircle size="1.25rem"/>}
                  fontSize="16px"
                  bg="teal.300"
                  w="7.5rem"
                  color="white"
                  mt="20px"
                  _hover={{
                    bg: "teal.200",
                  }}
                  _active={{
                    bg: "teal.400",
                  }}
                  colorScheme='teal'
                  isLoading={isSubmitting}
                  // type='submit'
                  onClick={handleSubmit(onModalSubmit)}
                >
                  確定
                </Button>
              </Flex>
            </Box>
          </FormControl>
          <Alert
            status='error'
            display={serverErrors.length ? "flex" : "none"}
          >
            <AlertIcon />
            <AlertTitle mr={2}>{serverErrors.length ? serverErrors[0].code : ""}</AlertTitle>
            <AlertDescription>{serverErrors.length ? serverErrors[0].msg : ""}</AlertDescription>
          </Alert>
        </form>
      </Flex>
    );
  }

  export default SelectCharacter;
