// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  Text,
  Spacer,
  Table,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { SignageClient } from "components/ksin/ApiClient/SignageClient";
import { StoreClient } from "components/ksin/ApiClient/StoreClient";
import UserList from "components/ksin/List/UserList";
import { AddIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";
const signageClient = new SignageClient();
const storeClient = new StoreClient();

function SignageOperatorList(props: any) {
  // 対象サイネージID(管理画面からの遷移時に取得)
  const targetId: string = props.location.state.id;
  const companyName: string = props.location.state.companyName;

  // Chakra color mode
  const textColor = useColorModeValue("gray.400", "white");

  // stateデータ
  const [signageInfo, setSignageInfo] = useState(null);
  const [operatorList, setOperatorList] = useState([]);
  const [storeList, setStoreList] = useState(null);
  const [role, setRole] = useState('');

  // click event
  const history = useHistory();
  const onClickOperatorAdd = useCallback((params) => history.push({ pathname: "/app/signage/operatorAdd", state: params }), [history]);

  // マウント後の単発処理
  useEffect(() => {
    let arr = new Array();
    if(document.cookie != ''){
        var tmp = document.cookie.split('; ');
        for(let i=0;i<tmp.length;i++){
        let data = tmp[i].split('=');
        arr[data[0]] = decodeURIComponent(data[1]);
        }
    }
    setRole(arr['user_role']);

    // サイネージ情報を取得する
    signageClient.get(targetId).then((data: any) => {
      setSignageInfo(data);
      setOperatorList(data.users);
    })
    .catch(() => {
    })

    // NOTE: 店舗名表示のために店舗リストを取得する
    storeClient.get().then((data: any) => {
      setStoreList(data);
    })
    .catch((error) => {
    })
  }, [])

  const getTargetCompanyStoreName = (targetStoreId: string) => {
    let targetCompanyStoreName = companyName;
    for(let index=0; index<storeList.length; index+=1){
      if(storeList[index].id == targetStoreId){
        targetCompanyStoreName = `${companyName} / ${storeList[index].name}`;
        break;
      }
    }

    return targetCompanyStoreName;
  };

  return (
    <Flex
      direction="column"
      mt={props.isOperatorView ? "0px" : "70px"}>
      <Box
        mb={{ sm: "205px", md: "75px", xl: "70px" }}
        borderRadius="15px"
        px="0px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        align="center"
        backgroundColor="white"
        paddingTop="20px"
        paddingLeft="20px"
        paddingBottom="50px"
      >
        <Flex>
          <Box p="2">
            <Text
              color="black"
              textAlign="left"
              fontWeight="bold"
              mb="10px"
              verticalAlign="center"
              mr="50px"
              fontSize="large"
            >
              担当オペレーター管理
            </Text>
            <Flex minWidth="100%" flexWrap="nowrap" mb="10px">
              <Avatar src={signageInfo ? signageInfo.image : ""} w="50px" borderRadius="12px" me="18px" />
              <Flex direction="column" mr="50px">
                <Box ml="0px">
                  <Text
                    fontSize="md"
                    color="black"
                    fontWeight="bold"
                    minWidth="100%"
                    textAlign="left"
                  >
                    {signageInfo ? signageInfo.name : ""}
                  </Text>
                </Box>
                <Box ml="0px">
                  <Text textAlign="left">
                    {signageInfo != null && storeList != null ? getTargetCompanyStoreName(signageInfo.storeId) : ""}
                  </Text>
                </Box>
              </Flex>
            </Flex>
          </Box>
          <Spacer />
          {role != "operator" && 
            <Box>
              <Button
                fontSize="14px"
                type="submit"
                bg="teal.300"
                w="150px"
                h="30"
                mb="10px"
                mr="4"
                color="white"
                borderRadius="5px"
                mt="20px"
                _hover={{
                  bg: "teal.200",
                }}
                _active={{
                  bg: "teal.400",
                }}
                onClick={() => {onClickOperatorAdd({
                  "id": targetId,
                  "storeList": storeList,
                  "companyName": companyName,
                })}}
              >
                <AddIcon 
                color="inherit" 
                mr="2" />
                オペレーター追加
              </Button>
            </Box>
          }
        </Flex>
        <Text
          color="black"
          textAlign="left"
          fontWeight="bold"
          // mb="40px"
          verticalAlign="center"
          mr="50px"
          fontSize="large"
        >
          担当オペレーター一覧
        </Text>
        <Table variant="simple"
          color={textColor} >
          <Thead >
            <Tr my=".8rem" pl="0px">
              <Th pl="0px" color="gray.400" > ユーザー</Th>
              {role != "operator" && <Th color="gray.400"textAlign="center" > キャラクター </Th>}
              <Th color="gray.400" textAlign="center" > ステータス </Th> 
              {role != "operator" && <Th color="gray.400" textAlign="center" > ステータス変更 </Th>}
              {role != "operator" && <Th color="gray.400" textAlign="center" > 設定 </Th>}
            </Tr >
          </Thead>
          <UserList 
            inSignageOperatorList={true}
            operatorList={operatorList}
            companyName={companyName}
          />
        </Table>
      </Box>
    </Flex>
  );
}

export default SignageOperatorList;
