import { Alert, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, Button } from '@chakra-ui/react'
import React from 'react'
import { useEffect } from 'react'

const AlertDialogBasic = (props: any) => {
    // const [isOpen, onOpen, onClose ] = useDisclosure()
    const [isOpen, setIsOpen] = React.useState(false)
    const onClose = () => {
        setIsOpen(false);
        props.setIsOpenConfirm(false);
    }
    const cancelRef = React.useRef()
    const onConfirm = () => {
        if (props.setIsForceSubmit) {
            props.setIsForceSubmit(true);
            if (props.isMedia) setIsOpen(false);
        } else {
            onClose();
        }
    }

    // 周期的に再ログインを試みる
    const tryLogin = async () => {
        console.log(`[debug]called tryLogin.`)
        // health APIを呼んで復旧していたらリロード
        const res = await fetch('/health')
        if(res.ok) {
            // Note: 暫定的にap1正常復旧時のみリロードを試みる
            try{
                const health = await res.json();
                console.log(`[debug]health: OK, ServerName: ${health.ServerName}`);
                if(health && health.ServerName === "ap1") {
                    console.log(`[debug]Server "ap1" health: OK, try reload.`);
                    location.reload();
                } else {
                    setTimeout(tryLogin, 10000);
                }
            } catch(e) {
                console.error(`[ERROR]inHealth API response.json: ${e}`);
                setTimeout(tryLogin, 10000);
            }
        } else {
            setTimeout(tryLogin, 10000);
        }
    }

    useEffect(() => {
        if (props.isOpenConfirm) {
            setIsOpen(true);
            if (props.dialogContents.category === "Error") {
                setTimeout(tryLogin, 1000);
            } else {
                // 乗っ取りログイン(他のブラウザでログイン)されたとき
            }
        }
    }, [props.isOpenConfirm])


    return (
        <>
            { props.isAlert
                ?
                <Alert
                    position="absolute"
                    left="50%"
                    top="20px"
                    zIndex="999"
                    width=""
                >
                    会議終了まで、あと５分
            </Alert>
                :
                ""
            }
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                closeOnOverlayClick={false}
            >

                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {props.dialogContents.header}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {`${props.dialogContents.body}\nエラー種別: ${props.dialogContents.category}`}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button
                                ref={cancelRef}
                                onClick={onClose}
                                display={props.isNotDispCancel ? "none" : ""}
                            >
                                キャンセル
              </Button>
                            <Button colorScheme='blue' onClick={onConfirm} ml={3}>
                                はい
              </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default AlertDialogBasic