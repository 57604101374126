import { DELETETIME } from "../../../config"
//予約に関する関数
export class Reservation {
    constructor() {
    }

    async get(id, title = "id") {
      const sendData = {
        targetID: id,
        title: title
      }
      const param = {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(sendData)
      }
      return fetch("/token/get/reservation", param)
      .then(response => response.json())
      .then(json => {
        const data = json.result
        return data
      })
    }
//本社の予約を表示
    async getReservations() {
      const param = {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        }
      };
      return fetch("/token/get/all", param)
        .then(response => response.json())
        .then(json => {
          return json;
        })
        .catch((error) => {
          console.error('Error:', error);
        })
    }

//他社の予約を表示
    async getAnonymousReservations() {
      const param = {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        }
      };
      return  fetch("/token/get/all/company", param)
        .then(response => response.json())
        .then(json => {
          return json.result;
        })
        .catch((error) => {
          console.error('Error:', error);
        })
    }
//予約を作成
    async create(data) {
      try {
        const sendData = {
          title: data.title,
          start: data.startDate + "T" + data.startTime + "+09:00", 
          end: data.endDate + "T" + data.endTime + "+09:00",
          characterID: data.characterID
        }
        const param = {
          method: 'POST',
          headers: {
            "Content-Type": "application/json; charset=utf-8"
          },
          body: JSON.stringify(sendData)
        };
  
        return fetch("/token/create", param)
          .then(response => response.json())
          .then(json => {
            if (json.check === 'succeed') {
              console.log(json, "token is created");
              alert("予約を完了しました")
            } else if (json.check === 'failed') {
              alert("予約に失敗しました。他の予約と重複しています。")
              console.log(json, "token is not created");
            }
          }
        )
      } catch (e) {
        return console.error(e)
      }
    }
//予約を削除
    async delete(reserv) {
      const currDate = new Date().getTime() - DELETETIME
      const reservDate = new Date(reserv.oldStartDate + "T" + reserv.timeStart).getTime() 
      const differece = reservDate > currDate
      if (differece) { 
      const result = confirm("予約削除してもよろしいでしょうか？")
        if (result) { const sendData = { id: reserv.reservID };
          const param = {
            method: "POST",
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(sendData),
          };
          const response = await fetch("/reservation/delete", param);
          const json = await response.json();
          console.log(json.result, "deleted");
          alert("予約キャンセルは完了されました") 
        }
      } else {
        alert("予約キャンセル受付時間終了になります。予約のキャンセルできません")
      }
    }
    //sudo apt-get install docker-ce=5:19.03.10~3-0~ubuntu-focal docker-ce-cli=5:19.03.10~3-0~ubuntu-focal containerd.io docker-compose-plugin
//TODO:予約を更新
    async update() {
    }

    async logout() {
        return fetch(`/api/logout`)
            .then(response => response.json())
            .then(json => {
                return json.result;
            })
            .catch((error) => {
            })
    }
}