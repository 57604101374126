import { Text, Image, Flex, FormLabel, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react'
import { CharacterClient } from 'components/ksin/ApiClient/CharacterClient';
import React, { useEffect, useReducer, useState } from 'react'
import SelectCharacter from 'views/Pages/Users/SelectCharacter';

const characterReducer = (state, action) => {
    if (action.type === 'set') {
        return state.concat(action.data);
    } else {
        throw `Invalid type: ${action.type}`;
    }
}

const ShowCharacter = (props: any) => {
    // const history = useHistory();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [userDataRow, setUserDataRow] = useState(props.row);

    const styles = {
        inForm: {
            w: "100px"
        },
        inList: {
            w: "50px"
        },
    }

    let [characters, characterDispatch] = useReducer(characterReducer, []);
    const [selectCharacter, setSelectCharacter] = React.useState(null);
    const characterClient = new CharacterClient();

    useEffect(() => {
        characterClient.get().then((data: any) => {
            // setCharacters([...data]);
            characterDispatch({
                type: "set",
                data: data
            })

        })
    }, [])

    useEffect(() => {
        selectCharacterById(props.row ? props.row.characterId : 'undecided')
    }, [props.row, characters])

    const selectCharacterById = (characterId: string) => {
        if (characters.length === 0) {
            setSelectCharacter(null);
            return;
        }
        if (characterId === 'undecided') {
            setSelectCharacter(characters[0]);
            return;
        }
        const select = characters.filter(c => c.id === characterId);
        if (select.length > 0) {
            setSelectCharacter(select[0]);
            if (props.isForm) props.setFormCharacterId(characterId);
            return;
        } else {
            setSelectCharacter(characters[0]);
            return;
        }
    }

    const onClickSelectCharacter = () => {
        if (!props.isAddMode) {
            setUserDataRow(props.row)
        } else {
            setUserDataRow({ characterId: selectCharacter.id })
        }
        onOpen();
    };

    return (
        <Flex
            justifyContent={props.isForm ? "flex-start" : "center"}>
            <FormLabel
                ms="4px"
                fontSize="sm"
                textAlign="left"
                fontWeight="bold"
                display={props.isForm ? "inline-block" : "none"}
                width="200px"
            >
                {props.isSignage ? '初期キャラクター' : 'キャラクター'}
              </FormLabel>
            <Flex
                justifyContent="center"
                onClick={() => onClickSelectCharacter()}
                cursor="pointer"
            >
                {selectCharacter ?
                    <Image
                        src={selectCharacter.image_url}
                        w={props.isForm ? styles.inForm.w : styles.inList.w}
                        borderRadius="12px" /> :
                    <Text>Loading...</Text>
                }
            </Flex>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                size="xl"
                id="character-preview"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>キャラクター選択</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex justifyContent="center">
                            <SelectCharacter
                                row={userDataRow}
                                storeData={props.storeData}
                                currentCharacterId={selectCharacter ? selectCharacter.id : ""}
                                isAddMode={props.isAddMode}
                                isForm={props.isForm}
                                selectCharacterById={selectCharacterById}
                                onClose={onClose}
                                companyName={props.companyName}
                            />
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex>
    )
}
export default ShowCharacter