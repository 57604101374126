export class CallingAlert {
    soundFile: string;
    audioObj: HTMLAudioElement;
    constructor() {
        this.soundFile = '/images/Sound/KSIN_callingAlert.wav';
        this.audioObj = new Audio(this.soundFile);
    }
    start() {
        this.audioObj.play();
        this.audioObj.loop = true;
    }
    stop() {
        this.audioObj.pause();
        this.audioObj.currentTime = 0;
        this.audioObj.loop = false;
    }
}
