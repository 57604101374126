import { useForm, FormProvider } from 'react-hook-form'
import {
  FormControl,
  Button,
  Text,
  Flex
} from '@chakra-ui/react'
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { UserClient } from '../ApiClient/UserClient';
import InputLoginIdMail from './parts/InputLoginIdMail';

export default function PasswordResetForm() {
  const history = useHistory();
  const [serverErrors, setServerErrors] = React.useState([]);
  const [isCompleted, setIsCompleted] = React.useState(false);
  const {
    formState: { isSubmitting },
  } = useForm()
  const methods = useForm();
  // memo: "targetUserId: "SELF""で自分のユーザーデータを取得する
  const targetUserId: string = "SELF";

  const addServerError = (errObj: { code: string, msg: string }) => {
    setServerErrors([...serverErrors, errObj]);
    console.log(JSON.stringify(serverErrors));
  }

  const tryAutoLogin = async () => {
    const sendData = {};

    const param = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      body: JSON.stringify(sendData)
    };
    return new Promise((resolve, reject) =>
      fetch(`/autoLogin`, param)
        .then(response => response.json())
        .then(json => {
          console.log(json);
          resolve(json);
        })
        .catch((error) => {
          console.error('Error:', error);
          reject(error);
        })
    )
  }

  useEffect(() => {

    // 自動ログイン試行
    tryAutoLogin().then((res: any) => {
      switch (res.result) {
        case "OK":
          // ホーム画面へ遷移
          history.push({ pathname: "/app/users/home" });
          break;
        case "Temporary":
          // パスワード変更画面へ遷移
          history.push({ pathname: "/test/passwordSetting" });
          break;
        case "Error":
          addServerError(
            {
              code: res.code,
              msg: res.msg
            }
          );
          break;
        default:
          addServerError(
            {
              code: "SVER-Unexpected",
              msg: "未定義のサーバーエラーが発生しました"
            }
          );
      }
    }).catch((error) => {
      console.error('Error:', error);
    })
}, [])

  function onSubmit(values) {
    setServerErrors([]);

    // 対象ユーザーの指定
    values.targetUserId = targetUserId;

    const client = new UserClient();

    const resetPassword = () => {
      return client.passwordReset(
        {
          targetUserId: targetUserId,
          targetUserLoginId: values.login_id,
          passwordReset: true
        }
      );
    }

    resetPassword().then(json => {
      switch (json.result) {
        case "OK":
          // メッセージを表示
          setIsCompleted(true);
          // ログイン情報変更時はログアウト
          client.logout();
          break;
        case "Error":
          if (parseInt(json.code.replace("SVER-", "")) >= 100) {
            break;
          } else {
            addServerError(
              {
                code: json.code,
                msg: json.msg
              }
            );
            break;
          }
        default:
          addServerError(
            {
              code: "SVER-Unexpected",
              msg: "未定義のサーバーエラーが発生しました"
            }
          );
      }
    })
  }

  return (
    <FormProvider {...methods}>
      <Flex padding="1rem" flexDirection="column">
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Text fontSize="lg" fontWeight="bold">
            パスワードリセット
          </Text>
          <Text fontSize="sm" mt="1rem">
            リセットしたいユーザーID(メールアドレス)を入力してください
          </Text>
          <Text fontSize="xs" mt="1rem" textAlign="left">
            ※サイネージのパスワードを忘れた場合は、企業管理者に連絡してください。<br />
            本画面からはリセットできません。
          </Text>
          <FormControl mt="2rem">
            <InputLoginIdMail
              isAddMode={true}
              isDispLabel={false}
            />
            <Button
              fontSize="14px"
              bg="teal.300"
              w="400px"
              h="45"
              mb="20px"
              color="white"
              mt="20px"
              _hover={{
                bg: "teal.200",
              }}
              _active={{
                bg: "teal.400",
              }}
              colorScheme='teal'
              isLoading={isSubmitting}
              type='submit'
            >
              送信
            </Button>

          </FormControl>
        </form>
        <Text
          color="red.400"
          display={serverErrors.length ? "flex" : "none"}
          mb="2rem"
        >
          {serverErrors.length ? serverErrors[0].msg : ""}
        </Text>
        <Text
          fontSize="sm"
          display={isCompleted ? "block" : "none"}
        >
          ご登録のメールアドレスに仮パスワードを送信しました。<br />
              仮パスワードでログイン後、パスワードを再設定してください。<br />
        </Text>
      </Flex>
    </FormProvider>
  )
}