const formsContents = {
    User: {
        common: {
            name: {
                labelName: "ユーザー名",
                placeholder: "氏名"
            },
            kana: {
                labelName: "ユーザー名（ふりがな）",
                placeholder: "ふりがな"
            }
        }
    },
    Store: {
        common: {
            name: {
                labelName: "店舗名",
                placeholder: "店舗名"
            }
        }
    },
    Signage: {
        common: {
            loginId: {
                labelName: "サイネージID",
                placeholder: "ID"
            },
            name: {
                labelName: "サイネージ名",
                placeholder: "設置場所など"
            }
        }
    },
    Sample: {
        common: {
            name: {
                labelName: "項目名",
                placeholder: "プレースホルダを設定"
            }
        }
    }
}

export class FormsContentsManager {
    sampleData: Object;
    constructor() {
        this.sampleData = formsContents["Sample"]["common"];
    }
    get(entity: string, operation?: string) {
        let entityData = formsContents[entity];
        let formData = operation ? entityData[operation] : entityData["common"];

        return formData ? formData : this.sampleData;
    }
}