import { useState, useEffect, useCallback } from "react";
// Chakra imports
import {
  Flex,
  Button,
  Text,
  Tbody,
  Tr,
  Td,
  Badge,
  Table,
  Th,
  Thead,
  useColorModeValue,
} from "@chakra-ui/react";
import { IoSettingsSharp } from "react-icons/io5"
import { FaStore } from "react-icons/fa"
import { useHistory } from "react-router-dom";
import { StoreClient } from "../ApiClient/StoreClient";

// Assets

export default function StoreList(props) {
  const textColor = useColorModeValue("gray.400", "white");
  const history = useHistory();
  const onClickStoreEdit = useCallback((params) => history.push({ pathname: "/app/store/update/", state: params }), [history]);
  const onClickStoreSignage = useCallback((params) => history.push({ pathname: "/store/signage", state: params }), [history]);  // TODO：ユーザーIDを渡す
  const onClickStoreUsers = useCallback((params) => history.push({ pathname: "/store/users", state: params }), [history]);

  const [data, setData] = useState([]);

  const client = new StoreClient();
  useEffect(() => {
    client.get().then((data: any) => {
      console.log(`Call getStores().then`);
      setData(data);
    })

  }, [])

  return (
    <Table 
      variant="simple" 
      color={textColor} 
      width={props.inSignageList || props.inUserList ? "50%" : ""}
      bgColor="white"
      >
      <Thead>
        <Tr my=".8rem" pl="0px">
          <Th pl="0px" color="gray.400">
            店舗
              </Th>
          <Th color="gray.400" textAlign="center"
            display={props.inUserList ? "none" : ""}>サイネージ</Th>
          <Th color="gray.400" textAlign="center"
            display={props.inSignageList ? "none" : ""}>オペレーター</Th>
          <Th color="gray.400" textAlign="center"
            display={props.inSignageList || props.inUserList ? "none" : ""}>設定</Th>
        </Tr>
      </Thead>

      <Tbody>
        {data.map((row) =>
          <Tr my=".8rem" pl="0px">
            <Td pl="0px" color="black.400">
              <Flex align="center" minWidth="100%" flexWrap="nowrap">
                <Badge
                  bg="teal.300"
                  color="white"
                  fontSize="20px"
                  p="8px 8px"
                  borderRadius="10px"
                  mr="10px"
                >
                  <FaStore color="inherit" height="30px" />
                </Badge>
                <Flex direction="column">
                  <Text
                    fontSize="md"
                    color="black"
                    fontWeight="bold"
                    minWidth="100%"
                  >
                    {row.name}
                  </Text>
                </Flex>
              </Flex>
            </Td>
            <Td color="black.400" textAlign="center" display={props.inUserList ? "none" : ""}>
              <Button
                fontSize="14px"
                type="submit"
                bg="teal.300"
                w="120px"
                h="30px"
                color="white"
                borderRadius="5px"
                _hover={{
                  bg: "teal.200",
                }}
                _active={{
                  bg: "teal.400",
                }}
                onClick={() => onClickStoreSignage({
                  "id": row.id,
                  "name": row.name,
                  "companyName": props.companyName ? props.companyName : '',
                })}
              >
                サイネージ一覧
              </Button>
            </Td>
            <Td color="black.400" textAlign="center" display={props.inSignageList ? "none" : ""}>
              <Button
                fontSize="14px"
                type="submit"
                bg="teal.300"
                w="150px"
                h="30px"
                color="white"
                borderRadius="5px"
                _hover={{
                  bg: "teal.200",
                }}
                _active={{
                  bg: "teal.400",
                }}
                onClick={() => 
                  onClickStoreUsers({
                    "id": row.id,
                    "name": row.name,
                    "companyName": props.companyName ? props.companyName : '',
                  })
                }
              >
                オペレーター一覧
              </Button>
            </Td>
            <Td color="black.400" textAlign="center" display={props.inSignageList || props.inUserList ? "none" : ""}>
              <Button
                fontSize="14px"
                type="submit"
                bg="teal.300"
                width="30"
                hight="30"
                color="white"
                borderRadius="5px"
                _hover={{
                  bg: "teal.200",
                }}
                _active={{
                  bg: "teal.400",
                }}
                onClick={() => onClickStoreEdit({ "id": row.id })}
              >
                <IoSettingsSharp color="inherit" size={20} />
              </Button>
            </Td>
          </Tr>
        )}
      </Tbody></Table>
  )
}

// export default StoreList;
