import { useForm, FormProvider } from 'react-hook-form'
import React, { useEffect } from 'react'
import {
  Text,
  FormControl,
  Button,
  Spacer,
 } from '@chakra-ui/react'
 import { CheckCircleIcon } from '@chakra-ui/icons';
import InputLoginIdMail from './parts/InputLoginIdMail';
import InputName from './parts/InputName';
import InputAffiliation from './parts/InputAffiliation';
import InputRole from './parts/InputRole';
import InputImage from './parts/InputImage';
import { useHistory } from 'react-router-dom';
import { FormsContentsManager } from '../Common/contents/FormsContentsManager';
import ShowCharacter from './parts/ShowCharacter';
import InputKana from './parts/InputKana';
import AlertDialogBasic from 'components/ksin/Common/AlertDialogBasic';

// Assets
export default function AddUserForm(props: any) {
  const [] = React.useState(false)
  const history = useHistory();
  const {
    formState: { isSubmitting },
  } = useForm()
  const methods = useForm();

  const [serverErrors, setServerErrors] = React.useState([]);

  const addServerError = (errObj: { code: string, msg: string }) => {
    setServerErrors([...serverErrors, errObj]);
    console.log(JSON.stringify(serverErrors));
  }

  const convertFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      console.log("キー : " + key + ", 値 : " + data[key]);
      formData.append(key, data[key]);
    });

    return formData;
  }

  const dialogTemplate = {
    header: "",
    body: "",
  };

  const [characterId, setCharacterId] = React.useState("");
  const [role, setRole] = React.useState(null);
  const [dialogContents, setDialogContents] = React.useState(dialogTemplate);
  const [isOpenConfirm, setIsOpenConfirm] = React.useState(false)
  const [isForceSubmit, setIsForceSubmit] = React.useState(false);
  const [formValues, setFormValues] = React.useState(null);

  useEffect(() => {
    if (isForceSubmit) {
      onSubmit(formValues);
    }
  }, [isForceSubmit])

  function onSubmit(values: any) {
    setServerErrors([]);
    if(characterId !== "") values.character_id = characterId;
    if(role != null)  values.role = role;

    // saveValues
    setFormValues(values);

    // 確認ダイアログからリトライしたときのフラグ設定
    values.isForceSubmit = isForceSubmit ? 1 : 0;

    const formData = convertFormData(values);
    console.log(formData);
    const param = {
      method: 'POST',
      body: formData
    };    

    return fetch(`/api/addUser`, param)
      .then(response => response.json())
      .then(json => {
        switch (json.result) {
          case "OK":
            setIsForceSubmit(false);
            setIsOpenConfirm(false);
            history.push({ pathname: "/app/users/list" });
            break;
          case "Confirm":
              console.log(`Update Retry Confirm.`);
              setDialogContents({
                header: json.code,
                body: json.msg
              });
              setIsOpenConfirm(true);
              break;
          case "Error":
              addServerError(
                {
                  code: "SVER-99",
                  msg: "このメールアドレスはすでに使用されています"
                }
              );
              break;
          default:
            addServerError(
              {
                code: "SVER-Unexpected",
                msg: "未定義のサーバーエラーが発生しました"
              }
            );
        }
      });
  }

  return (
    <FormProvider {...methods} >
      <AlertDialogBasic
        isOpenConfirm={isOpenConfirm}
        setIsOpenConfirm={setIsOpenConfirm}
        setIsForceSubmit={setIsForceSubmit}
        dialogContents={dialogContents}
      />
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <InputLoginIdMail
          isAddMode={props.isAddMode}
          userInfo={props.userInfo}
        />
        <InputName 
          isAddMode={props.isAddMode} 
          userInfo={props.userInfo} 
          formInfo={new FormsContentsManager().get("User").name}
        />
        <InputKana
          isAddMode={props.isAddMode} 
          userInfo={props.userInfo} 
          formInfo={new FormsContentsManager().get("User").kana}
        />
        <InputAffiliation
          isAddMode={props.isAddMode}
          userInfo={props.userInfo}
          companyName={props.companyName}
        />
        <InputRole
          isAddMode={props.isAddMode}
          userInfo={props.userInfo}
          role={role}
          setRole={setRole}
        />
        <Spacer h="30px" />
        <ShowCharacter 
          isForm={true} 
          isAddMode={true} 
          setFormCharacterId={setCharacterId} /> 
        <Spacer h="30px" />
        <Spacer h="30px" />
        <InputImage
          isAddMode={props.isAddMode}
          userInfo={props.userInfo}
        /> 
        <Spacer h="30px" />
        <FormControl
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            fontSize="14px"
            bg="teal.300"
            w="130px"
            h="30px"
            mb="20px"
            color="white"
            mt="20px"
            _hover={{
              bg: "teal.200",
            }}
            _active={{
              bg: "teal.400",
            }}
            colorScheme='teal'
            isLoading={isSubmitting}
            type='submit'
          >
            <CheckCircleIcon color="inherit" mr="2" />
            確定
          </Button>
        </FormControl>
      </form>
      <Text
        color="red.400"
        display={serverErrors.length ? "flex" : "none"}
        mb="2rem"
      >
        {serverErrors.length ? serverErrors[0].msg : ""}
      </Text>
    </FormProvider>
  )
}