import {
    Box, FormControl, FormLabel, FormErrorMessage, Button,
    Text,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Accordion,
    AccordionButton,
    AccordionItem,
    Flex,
    // IdProvider,
    // Input,
    // Input,
} from '@chakra-ui/react'
import { StoreClient } from 'components/ksin/ApiClient/StoreClient';
import { useEffect } from 'react';
import { useState } from 'react';
// import { useRef } from 'react';
// import { useForm, useFormContext } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import ShowAffiliation from './ShowAffiliation';

const InputAffiliation = (props: any) => {
    const { setValue } = useFormContext();
    const {
        formState: { errors },
    } = useForm()
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [storeData, setStoreData] = useState([]);
    const [storeId, setStoreId] = useState("");


    const storeClient = new StoreClient();
    useEffect(() => {
        storeClient.get().then((data: any) => {
            setStoreData(data);
        })
        .catch((error) => {
        })
    }, [])

    useEffect(() => {
        if(props.userInfo && props.userInfo.storeId) {
            setStoreId(props.userInfo.storeId)
        }
    }, [props.userInfo])

    const onStoreSelectChange = (storeId: string) => {
        // registerStoreId.ref = () => storeId;
        setStoreId(storeId);
        setValue('storeId', storeId);
        // setAffiliation(getAffiliation(storeId));
        onClose();
    }

    return (
        <Box
            ml="0px"
        >
            <FormControl
                isInvalid={errors.storeId}
                display="flex"
                alignItems='flex-start'
            >
                <FormLabel
                    ms="4px"
                    fontSize="sm"
                    textAlign="left"
                    fontWeight="bold"
                    display="inline-block"
                    width="200px"
                >
                    所属
                </FormLabel>
                <Box
                    display="flex"
                    width="400px"
                    flexDirection="column"
                >
                    <ShowAffiliation 
                        storeId={storeId}
                        storeList={storeData}
                        companyName={props.companyName}
                    />
                    <Button
                        textAlign="left"
                        fontSize="14px"
                        bg="teal.300"
                        w="100px"
                        h="30px"
                        mb="20px"
                        mr="8"
                        color="white"
                        borderRadius="5px"
                        mt="20px"
                        _hover={{
                            bg: "teal.200",
                        }}
                        _active={{
                            bg: "teal.400",
                        }}
                        onClick={onOpen}
                    >
                        所属変更
                    </Button>
                </Box>
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>店舗一覧</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Accordion width="100%">
                                <Flex mb="10px">
                                    <Text
                                        _hover={{
                                            bg: "teal.200",
                                        }}
                                        onClick={() => onStoreSelectChange("")}
                                    >
                                        企業直下
                                    </Text>
                                </Flex>
                                {storeData.map((row) =>
                                    <AccordionItem>
                                        <h2>
                                            <AccordionButton
                                                p="1"
                                                backgroundClip="content-box"
                                                _expanded={{
                                                    bg: "teal.200",
                                                }}
                                                onClick={() => onStoreSelectChange(row.id)}
                                            >
                                                <Box flex="1" textAlign="left" pl="2" pt="2" pb="2">
                                                    {row.name}
                                                </Box>
                                            </AccordionButton>
                                        </h2>
                                    </AccordionItem>
                                )}
                            </Accordion>
                        </ModalBody>
                        <ModalFooter>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
                <FormErrorMessage>
                    {errors.registerStoreId && errors.registerStoreId.message}
                </FormErrorMessage>
            </FormControl>
        </Box>
    )
}

export default InputAffiliation
