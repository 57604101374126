import React, { useContext, useEffect, useState } from 'react'
import { Text, Image, Button, useDisclosure, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box } from '@chakra-ui/react';
import { AppContext } from 'layouts/AppCommons';
import { SignageStateList } from 'views/Pages/Users/SignageTemplateUpdate';
import { CallingAlert } from '../util/CallingAlert';
import { useHistory } from 'react-router-dom';
import AlertDialogBasic from '../Common/AlertDialogBasic';
import { CompanyClient } from "components/ksin/ApiClient/CompanyClient";

// 呼び出しアラート
const callingAlert = new CallingAlert();

// Assets
export default function ReceptionRequest(props: any) {
    const { appContext, setAppContext } = useContext(AppContext);
    const wsc = appContext.wsc;

    const history = useHistory();

    // 画面内で更新されるデータ
    const [currentRequestId, setCurrentRequestId] = React.useState("");
    const [captureUrl, setCaptureUrl] = React.useState("");
    const [requestTable, setRequestTable] = React.useState(new Map());
    const [isOpenNotRequiredReception, setIsOpenNotRequiredReception] = React.useState(false);
    // const onConfirmNotRequiredReception = () => {
    //     setIsOpenNotRequiredReception(false);
    // }
    const [companyName, setCompanyName] = useState('');

    const notRequiredReceptionContents = {
        header: "SVER-100",
        body: "他のオペレーターが応答したため応答不要です。"
    }

    const [_, setRequestReceiveTime] = React.useState(0);

    // 応対リクエストダイアログの状態管理
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef();

    // マウント直後の単発処理
    useEffect(() => {
        // NOTE: 接客画面に企業名を表示するため企業情報を取得する
        const companyClient = new CompanyClient();
        companyClient.get().then((data: any) => {
            if (data.companyName == '' || data.companyName == null) return;
            setCompanyName(data.companyName);
        }).catch((error) => {
            console.log(error);
        })
    }, []);

    useEffect(() => {
        if (appContext.receiveCount) {
            console.log(`${new Date().toISOString()}[debug]onReceiveCount appContext.signageState: ${appContext.signageState}`);
            setRequestReceiveTime(appContext.receiveCount);
            setRequestTable((preTable) => {
                preTable.set(appContext.requestId, {
                    signageId: appContext.signageId,
                    name: appContext.signageName
                });
                return new Map(preTable);
            })

            if (!isOpen) {
                setCurrentRequestId(appContext.requestId);
            }
        }
    }, [appContext.receiveCount]);

    useEffect(() => {
        const openRequest = () => {
            if (currentRequestId.length > 0) {
                if (requestTable.get(currentRequestId)) {
                    setCaptureUrl(`${appContext.captureImageDir}/${currentRequestId}.png`);
                    callingAlert.start();
                    onOpen();
                } else {
                    setTimeout(() => {
                        if (requestTable.get(currentRequestId)) {
                            openRequest();
                        }
                    }, 200);
                }
            }
        }
        openRequest();
    }, [currentRequestId]);


    // 応答キャンセル
    const sendRejectRequest = () => {
        setAppContext((pre) => {
            pre.shareStream = null;
            pre.signagePeerId = "";
            pre.userPeerId = "";
            pre.receiveCount = 0;
            pre.receiveCallfromUser = 0;
            pre.signageState = SignageStateList.Standby;
            return { ...pre };
        })

        // サイネージに通知
        const ws = wsc.getWs();
        const request = requestTable.get(currentRequestId);
        ws.send(JSON.stringify({
            type: 'stateTransitionEvent',
            event: {
                type: "rejectRequest",
                senderType: "User",
                signageId: request.signageId,
            }
        }));
        console.log(`${new Date().toISOString()}[debug]ReceptionRequest skipped signageId: ${request.signageId}`);

        requestTable.delete(currentRequestId);
        viewNextRequest();
    }

    const viewNextRequest = () => {
        console.log(`${new Date().toISOString()}[debug]ReceptionRequest viewNextRequest()`);
        // 他のリクエストがあれば表示
        if (requestTable.size) {
            let count = 0;
            for (const [key, value] of requestTable) {
                if (count === 0) {
                    console.log(`${new Date().toISOString()}[debug]ReceptionRequest nextRequest: ${key}: ${value}`);
                    setCurrentRequestId(key);
                }
                count++;
            }
        } else {
            callingAlert.stop();
            onClose();
            if (window.location.pathname.includes("/app/service/operation")) {
                history.push({ pathname: "/app/service/standby" });
            }
        }
    }


    // 応対リクエスト応答
    const callOfferAccept = () => {
        callingAlert.stop();
        onClose();

        const request = requestTable.get(currentRequestId);

        // ただちにオペレーション画面に遷移せず応対確定通知を待つ
        const ws = wsc.getWs();
        ws.send(JSON.stringify({
            type: 'stateTransitionEvent',
            event: {
                type: "acceptRequest",
                senderType: "User",
                signageId: request.signageId,
            }
        }));
    }

    // 応対リクエスト応答が受理されたとき
    const startReceptionService = () => {
        const request = requestTable.get(currentRequestId);

        if (appContext.monitoringSignageId !== request.signageId) {
            if (!(window.location.pathname.includes("/app/service/operation"))) {
                history.push({
                    pathname: "/app/service/operation", state: {
                        id: request.signageId,
                        companyName: companyName,
                    }
                });
            }
        }
        setAppContext((pre) => {
            pre.isStartByReceprionRequest = true;
            pre.signageId = request.signageId;
            pre.companyName = companyName;
            return { ...pre };
        });
    }

    useEffect(() => {
        console.log(`${new Date().toISOString()}[debug]ReceptionRequest changed appContext.shouldRecept`);
        if (currentRequestId.length) {
            console.log(`${new Date().toISOString()}[debug]ReceptionRequest appContext.shouldRecept: ${appContext.shouldRecept}`);

            if (appContext.shouldRecept) {
                setIsOpenNotRequiredReception(false);
                startReceptionService();

                // setAppContext((pre) => {
                //     pre.shouldRecept = false;
                //     return { ...pre };
                //   })
            } else if (appContext.shouldRecept === false && appContext.signageState !== SignageStateList.Active) {
                setIsOpenNotRequiredReception(true);
                requestTable.delete(currentRequestId);
                setCurrentRequestId("");
                viewNextRequest();
                setAppContext((pre) => {
                    pre.shouldRecept = null;
                    pre.signageId = null;
                    return { ...pre };
                });
            }
        }
    }, [appContext.shouldRecept]);

    const callOfferCancel = () => {
        // callingAlert.stop();
        // onClose();
        sendRejectRequest();
        // if (window.location.pathname.includes("/app/service/operation")) {
        //     history.push({ pathname: "/app/service/standby" });
        // }

    }

    // 休止中にする
    const suspendSignage = () => {
        const ws = wsc.getWs();
        ws.send(JSON.stringify({
            type: 'stateTransitionEvent',
            event: {
                type: "suspend",
                senderType: "Signage",
                signageId: appContext.signageId,
            }
        }));
    }

    // 休止中から再開する
    const restartSignage = () => {
        const ws = wsc.getWs();
        ws.send(JSON.stringify({
            type: 'stateTransitionEvent',
            event: {
                type: "restart",
                senderType: "Signage",
                signageId: appContext.signageId,
            }
        }));
    }

    useEffect(() => {
        if (appContext.signageSuspendState) {
            if (appContext.signageSuspendState === "Suspended") {
                suspendSignage();
            } else if (appContext.signageSuspendState === "restart") {
                restartSignage();
            }
            setAppContext((pre) => {
                pre.signageSuspendState = null;
                pre.signageId = null;
                return { ...pre };
            });
        }
    }, [appContext.signageSuspendState]);

    return (
        <>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={() => { callOfferCancel() }}
                closeOnOverlayClick={false}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            応対リクエスト
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            <Text>
                                {requestTable.get(currentRequestId) ? requestTable.get(currentRequestId).name : ""}から呼び出しです。応答しますか？
                            </Text>
                            <Box display='flex'>
                                <Text>内容:</Text>  
                                <Text color='#FF0000'>{appContext.touchButton}</Text>
                            </Box>
                            {captureUrl.length ?
                                <Image src={captureUrl} />
                                : <></>}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={() => callOfferCancel()}>
                                キャンセル
                            </Button>
                            <Button colorScheme='blue' onClick={() => callOfferAccept()} ml={3}>
                                はい
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            <AlertDialogBasic
                isOpenConfirm={isOpenNotRequiredReception}
                setIsOpenConfirm={setIsOpenNotRequiredReception}
                setIsForceSubmit={false}
                dialogContents={notRequiredReceptionContents}
                isNotDispCancel={true}
            />
        </>
    )
}