import { useCallback, useState, useEffect, useContext } from "react";
// Chakra imports
import {
  Image,
  Flex,
  Button,
  Text,
  Tbody,
  Tr,
  Td,
  Badge,
  Box,
} from "@chakra-ui/react";
import { IoSettingsSharp } from "react-icons/io5"
import { useHistory } from "react-router-dom";
import { StoreClient } from "../ApiClient/StoreClient";
import { PersonIcon } from "components/Icons/Icons";
import ShowCharacter from "../Forms/parts/ShowCharacter";
import ShowAffiliation from "../Forms/parts/ShowAffiliation";
import ShowStatusBadge from "./parts/ShowStatusBadge";
import { AppContext } from "layouts/AppCommons";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
} 
// Assets
export default function UserList(props: any) {
  const { appContext, } = useContext(AppContext);
  const history = useHistory();
  const onClickUserEdit = useCallback((params) => history.push({ pathname: "/app/users/update", state: params }), [history]);

  const [storeList, setStoreList] = useState([]);
  const [role, setRole] = useState('');
  const [userStateTable, setUserStateTable] = useState(new Map());

  // ユーザー状態テーブルに受信したstateを追加
  useEffect(() => {
    if(appContext.receivedStateInfo) {
      let map = new Map();
      appContext.receivedStateInfo.map(element => {
        if(element.type === "User") {

          map.set(element.id, element.state)

        }
      });

      setUserStateTable(new Map(map));

    }
  }, [appContext.receivedStateInfoTime]);

  const getUserState = (id) => {
    const state = userStateTable.get(id) ? userStateTable.get(id) : "Logout";
    return state;
  }

  const storeClient = new StoreClient();
  useEffect(() => {
    let arr = new Array();
    if(document.cookie != ''){
        var tmp = document.cookie.split('; ');
        for(let i=0;i<tmp.length;i++){
        let data = tmp[i].split('=');
        arr[data[0]] = decodeURIComponent(data[1]);
        }
    }
    setRole(arr['user_role']);

    // 店舗リストを取得する
    storeClient.get().then((data: any) => {
      setStoreList(data);
    })
    .catch((error) => {
    })
  }, [])

  const onChanegeStatus = (param) => {
    const newData = data.map(e => {
      if (e.userId === param.userId) {
        e.status = "離席中";
      }
      return e;
    }).filter(e => e);
    setData(newData);
  }

  // サイネージ担当オペレーター追加
  const onClickSignageOperatorAdd = (row) => {
    console.log(row);
    props.setAddOperatorId(row.userId);
  }

  const [data, setData] = useState([]);
  // サーバからユーザー情報を取得する
  // function getUsers() {
  const getUsers = async () => {
    console.log(`Call getUsers`);
    const sendData = {}

    console.log(sendData);
    const param = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      body: JSON.stringify(sendData)
    };
    return new Promise((resolve, reject) =>
      fetch(`/api/getUser`, param)
        .then(response => response.json())
        .then(json => {
          resolve(json);
        })
        .catch((error) => {
          console.error('Error:', error);
          reject(error);
        })
    )
  }

  const getTargetCompanyStoreName = (targetStoreId: string) => {
    let targetCompanyStoreName = props.companyName;
    for(let index=0; index<storeList.length; index+=1){
      if(storeList[index].id == targetStoreId){
        targetCompanyStoreName = `${props.companyName} / ${storeList[index].name}`;
        break;
      }
    }

    return targetCompanyStoreName;
  };

  // マウント直後の処理
  useEffect(() => {
    console.log(`Call useEffect()`);
    console.log(data);
    if (props.targetStoreId) {
      const client = new StoreClient();
      client.get(props.targetStoreId).then((data: any) => {
        console.log(`Call getStores().then`);
        setData(data.users);
      })
    } else if(props.operatorList) {
      setData(props.operatorList);
    } else {
      getUsers().then((data: any) => {
        // const notAffiliationUsers = data.filter(u => u.storeId === null);
        let map = new Map();
        setData(data);        
        data?.forEach(element => {
          map.set(element.userId, capitalizeFirstLetter(element.status.toLowerCase()))
        });
        setUserStateTable(new Map(map));
      })
    }
  }, []);


  useEffect(() => {
    if (props.operatorList && props.operatorList.length) {
      setData(props.operatorList);
    }
  }, [props.operatorList]);

  return (
    <Tbody>
      {data.map((row) =>
        <Tr my=".8rem" pl="0px">
          <Td pl="0px" color="black.400">
            <Flex align="center" minWidth="100%" flexWrap="nowrap">
              <Box
                w="50px"
                h="50px"
                min="50px"
                bg="teal.300"
                color="white"
                borderRadius="12px"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {row.image.length > 0 ?
                  <Image src={row.image} w="100%" h="100%" objectFit="cover" borderRadius="12px" /> :
                  <PersonIcon color="inherit" w="90%" h="90%" borderRadius="12px" />}
              </Box>
              <Flex direction="column" ml="1rem">
                <Text
                  fontSize="md"
                  color="black"
                  fontWeight="bold"
                  minWidth="100%"
                >
                  {row.name}
                  {row.role === "companyAdmin" ?
                    <Badge
                      bg="red.600"
                      color="white"
                      fontSize="8px"
                      p="0px 12px"
                      borderRadius="3px"
                      ml="2"
                    >
                      管理者
                    </Badge> :
                    <></>
                  }
                </Text>
                <ShowAffiliation
                  storeId={row.storeId}
                  storeList={storeList}
                  companyName={props.companyName}
                />
              </Flex>
            </Flex>
          </Td>
          {role != "operator" && props.inSignageOperatorAdd ? 
          <Td>
            <Button
              fontSize="14px"
              type="submit"
              bg="teal.300"
              w="120px"
              h="30"
              color="white"
              borderRadius="5px"
              _hover={{
                bg: "teal.200",
              }}
              _active={{
                bg: "teal.400",
              }}
              // isDisabled={row.status === "受付中" ? false : true}
              onClick={() => onClickSignageOperatorAdd(row)}
            >
              追加
                  </Button>
          </Td> :
           <></>}
          {role != "operator" &&
            <Td color="black.400" textAlign="center">
              <ShowCharacter
                row={row}
                storeList={storeList}
                companyName={props.companyName ? getTargetCompanyStoreName(row.storeId) : ''}
              />
            </Td>
          }
          <Td color="black.400" textAlign="center">
            <ShowStatusBadge
                    state={getUserState(row.userId)}
                    dispInfo={userStateDispTable}
            />
          </Td>
          {role != "operator" &&
            <Td color="black.400" textAlign="center">
              <Button
                fontSize="14px"
                type="submit"
                bg="teal.300"
                w="120px"
                h="30"
                color="white"
                borderRadius="5px"
                _hover={{
                  bg: "teal.200",
                }}
                _active={{
                  bg: "teal.400",
                }}
                isDisabled={row.status === "受付中" ? false : true}
                onClick={() => onChanegeStatus(row)}
              >
                離席中にする
              </Button>
            </Td>
          }
          {role != "operator" &&
            <Td color="black.400" textAlign="center">
              <Button
                fontSize="14px"
                type="submit"
                bg="teal.300"
                width="30"
                hight="30"
                color="white"
                borderRadius="5px"
                _hover={{
                  bg: "teal.200",
                }}
                _active={{
                  bg: "teal.400",
                }}
                onClick={() => onClickUserEdit({ 
                  id: row.userId,
                  companyName: props.companyName ? props.companyName : '',
                })}
              >
                <IoSettingsSharp color="inherit" size={20} />
              </Button>
            </Td>
          }
        </Tr>
      )}
    </Tbody>
  )
}

export const userStateDispTable = {
  Standby: {
    dispName: "受付中",
    bgColor: "blue.400" 
  },
  Active: {
    dispName: "会話中",
    bgColor: "cyan.400" 
  },
  Leaving: {
    dispName: "離席中",
    bgColor: "red.400" 
  },
  Logout: {
    dispName: "オフライン",
    bgColor: "gray.400" 
  }
}
