
import {useCallback, useEffect, useState} from "react"
// import * as react from "react"
import { ChevronDownIcon, CopyIcon  } from '@chakra-ui/icons'
import { IoSettingsSharp } from "react-icons/io5"
import { IconButton } from '@chakra-ui/react'
import { 
  Box, 
  Button,
  Menu, 
  MenuButton, 
  MenuList, 
  MenuItem,
  Text, 
  Flex
  } from '@chakra-ui/react';

import ReservationForm from "./ReservationForm"
import { Reservation } from "../../../components/ksin/ApiClient/ReservationClient";
import { SignageStateList } from "./SignageTemplateUpdate";
import moment from "moment";
import { useContext } from "react";
import { AppContext } from "../../../layouts/AppCommons";
import { useHistory } from 'react-router-dom';
import { CompanyClient } from "components/ksin/ApiClient/CompanyClient";

const ReservationList = () => {

  const history = useHistory()
  const onClickMonitoring = useCallback((params) => {
  history.push({ pathname: "/app/service/operation", state: params })
  console.log(params)
  },
  [history]);

  const currMonth = new Date().getMonth()
  const currYear = new Date().getFullYear()
  const [reservs, setReservs] = useState([])
  const [formState, setFormState] = useState({state: false, id: -1, reserv: {}})
  const [filtredReservers, setFiltredReservs] = useState([])
  const [selectedMonth, setSelectedMonth] = useState<number>(currMonth);
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [isCopied, setIsCopied] = useState({state: false, id: -1})
  const [signageStateTable, setSignageStateTable] = useState(new Map());
  const { appContext } = useContext(AppContext);
  const [companyName, setCompanyName] = useState('');
  const itemPages = 5
  const pageNumber  = Math.ceil(filtredReservers.length / itemPages)
  const startItem = (currentPage - 1) * itemPages;
  const endItem = startItem + itemPages;

  useEffect(() => {
    if(appContext.receivedStateInfo) {
      let map = new Map();
      
      appContext.receivedStateInfo.map(element => {
        if(element.type === "Signage") {
          map.set(element.id, element.state)
        }
      });
      setSignageStateTable(new Map(map));
    }
  }, [appContext.receivedStateInfoTime, appContext.homeButton]);

  const shouldMonitoring = (id: string, data: any) => {
    // console.log(filtredReservers)
    const allowMonitoringStatus = ["Standby", "Suspended"];
    const state = signageStateTable.get(id);
    // const result = true
    const currentTime = moment()
    const startTime = moment(data.startTime, 'YYYY-MM-DD HH:mm:ss');
    const endTime = moment(data.endTime, 'YYYY-MM-DD HH:mm:ss');
    const result = currentTime.isSameOrAfter(startTime) && currentTime.isBefore(endTime);
  
    if (result) {
      if (allowMonitoringStatus.includes(state)) {
        return true;
      } else {
        return false
      }
    } else {
      return false
    }
  };
  
  const reservationClient = new Reservation()
  const handleMonth = (month) => {
    setSelectedMonth(month)
    setCurrentPage(1)
  }

  const companyClient = new CompanyClient();
  companyClient.get().then((data: any) => {
    if(data.companyName == '' || data.companyName == null)  return;
    setCompanyName(data.companyName);
  }).catch((error) => {
    console.log(error);
  })
  
  const closeForm = () => {
    setFormState({state: false, id: -1, reserv: {}})
    localStorage.removeItem("reservationData")
  }

  const handleCopyClick = (buttonContent, id) => {
    navigator.clipboard.writeText(buttonContent);
    setIsCopied({state: true, id: id});
    setTimeout(() => {
      setIsCopied({state: false, id: -1});
    }, 5000)
  };

  const getCurrentDate = () => {
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = (now.getMonth() + 1).toString().padStart(2, "0");
    const currentDay = now.getDate().toString().padStart(2, "0");
    return `${currentYear}-${currentMonth}-${currentDay}`;
  }
  const currentDate = getCurrentDate();

  const filterByMonth = (data) => {

    const slitMonth = (data) => {
      const [date] = data.start.split(" ")
      const [year, month] = date.split("-")
      return selectedMonth === (parseInt(month) - 1) && currYear === (parseInt(year))
    }
    
    const newFormat = (data) => {
      const [startDate, startTime] = data.start.split(" ")
      const [endDate, endTime] = data.end.split(" ")
      const [year, month, day] = startDate.split("-")
      const formattedDate = year +  "年" + month + "月" + day + "日"
      return {reservID: data.reservID, id: data.id, oldStartDate: startDate, oldEndDate: endDate, characterID: data.characterID, startDate: formattedDate, timeStart: startTime.slice(0, 5), timeEnd: endTime.slice(0, 5), title: data.title, link: data.link, signageID: data.signageID}
    }

    const result = data ? data.filter(slitMonth) : []
    const formattedResult = result ? result.map(x => newFormat(x)) : []
    setFiltredReservs(formattedResult)

    return formattedResult
  }

  useEffect(() => {
    reservationClient.getReservations().then(data => setReservs(data.userResult))
  }, [,formState])

  useEffect(() => {
    filterByMonth(reservs)
  }, [selectedMonth, reservs])


  return (
    <>
      <Box
        mt="50px"
        display="inline-flex"
        flex-wrap="wrap"
        gridGap={19}
        alignItems="center"
      >
        <Text fontSize="2xl">予約一覧</Text> 
        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />} fontSize="xl">
            { currYear.toString() + "年"+ (selectedMonth + 1).toString() + "月" } 
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => handleMonth(0)}>1月</MenuItem>  
            <MenuItem onClick={() => handleMonth(1)}>2月</MenuItem>  
            <MenuItem onClick={() => handleMonth(2)}>3月</MenuItem>  
            <MenuItem onClick={() => handleMonth(3)}>4月</MenuItem>  
            <MenuItem onClick={() => handleMonth(4)}>5月</MenuItem>  
            <MenuItem onClick={() => handleMonth(5)}>6月</MenuItem>  
            <MenuItem onClick={() => handleMonth(6)}>7月</MenuItem>  
            <MenuItem onClick={() => handleMonth(7)}>8月</MenuItem>  
            <MenuItem onClick={() => handleMonth(8)}>9月</MenuItem>  
            <MenuItem onClick={() => handleMonth(9)}>10月</MenuItem>  
            <MenuItem onClick={() => handleMonth(10)}>11月</MenuItem>  
            <MenuItem onClick={() => handleMonth(11)}>12月</MenuItem>  
          </MenuList>
        </Menu>
      </Box>
    <Flex display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" justifyContent="space-between" marginBottom="20px" marginTop="20px" >
        <Flex width="30px"  >
          <Text color="#A0AEC0">No.</Text>
        </Flex>
        <Flex width="110px" justifyContent="center">
            <Text color="#A0AEC0">会議名</Text>
        </Flex>
        <Flex width="150px" justifyContent="center">
          <Text color="#A0AEC0">日付</Text>
        </Flex>
        <Flex width="140px" justifyContent="center">
          <Text color="#A0AEC0">時間</Text>
        </Flex>
        <Flex width="220px" justifyContent="center">
          <Text color="#A0AEC0">リンク</Text>
        </Flex>
        <Flex width="120px" justifyContent="center">
            <Text color="#A0AEC0">会議</Text>
        </Flex>
        <Flex width="70px" justifyContent="center">
          <Text color="#A0AEC0">設定</Text>
        </Flex>
      </Box>
      <Box
      height="600px">
        { filtredReservers.length > 0  ?
          filtredReservers.slice(startItem, endItem).map( x => 
          <>
            <Flex display="flex" flexDirection="row"  marginBottom="50px" justifyContent="space-between" borderBottom="1px" borderColor="#A0AEC0" marginX="auto" paddingBottom="30px" >
              <Box
                display="flex"
                alignItems="center"
              >
                <Text width="30px">{x.id + 1}</Text>
              </Box>
              <Box 
                  display="flex" 
                  alignItems="center"  
                  width="110px" 
                  justifyContent="center"
                  >
                    <Text fontSize="medium">
                      { x.title }
                    </Text>
              </Box>
              <Box 
                border="1px" 
                borderColor="#DBDBDB" 
                borderRadius="10px" 
                display="flex" 
                alignItems="center"  
                width="150px" 
                justifyContent="center"
                >
                  <Text fontSize="medium">
                    {x.startDate}
                  </Text>
              </Box>
              <Box 
                border="1px" 
                borderColor="#DBDBDB" 
                borderRadius="10px" 
                display="flex" 
                alignItems="center"  
                width="140px" 
                justifyContent="center"
              >
                <Text  fontSize="medium">
                  {x.timeStart} - {x.timeEnd}
                </Text>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                gridGap="10px"
                justifyContent="center"
                alignItems="center"
              >
                <Box 
                  height="40px"
                  border="1px" 
                  borderColor="#DBDBDB" 
                  borderRadius="10px" 
                  display="flex" 
                  alignItems="center"  
                  width="220px" 
                  justifyContent="center"
                >
                  <Text fontSize="unset" paddingLeft="5px">
                    {x.link.length > 10 ? x.link.substring(0, 23) + '...' : x.link}
                  </Text>
                  <IconButton size="sm" icon={<CopyIcon />} aria-label='Copy link' variant='link'  fontSize="15px" onClick={() => handleCopyClick(x.link, x.id)}/>
                </Box>
                {isCopied.id === x.id && isCopied.state && <Text fontSize="12px">Link copied to clipboard!</Text>}
              </Box>
              <Button 
                  fontSize="14px"
                  type="submit"
                  width="120px"
                  hight="30"
                  borderRadius="5px"
                  bg="teal.300"
                  color="white"
                  _hover={{
                    bg: "teal.200",
                  }}
                  _active={{
                    bg: "teal.400",
                  }}
                  disabled={!shouldMonitoring(x.signageID, {startTime: x.oldStartDate + " " + x.timeStart, endTime: x.oldEndDate + " " + x.timeEnd})}
                  onClick={() => onClickMonitoring({
                    mode: SignageStateList.Monitoring,
                    id: x.signageID,
                    companyName: companyName,
                    character: x.characterID,
                    time: x.oldEndDate + " " + x.timeEnd

                  })}
                >
                  入室
                </Button>
              <Button 
                fontSize="14px"
                type="submit"
                width="70px"
                hight="30"
                borderRadius="5px"
                bg="teal.300"
                color="white"
                _hover={{
                  bg: "teal.200",
                }}
                _active={{
                  bg: "teal.400",
                }}
                onClick={() => setFormState({state: true, id: x.id, reserv: x})}
              >
                <IoSettingsSharp color="inherit" size={20} />
              </Button>
            </Flex>
            { formState.id == x.id && formState.state ? (
              <ReservationForm 
                reservID={x.id}
                auth={true}
                selectAvatar={x.characterID}
                closeForm={closeForm}
                title={x.title}
                currDate={currentDate}
                currentTime={"00:00"}
                startDate={x.oldStartDate}
                startTime={x.timeStart}
                expDate={x.oldEndDate}
                expTime={x.timeEnd}
                isDelete={true}
                reserv={formState.reserv}
              />
              )
              :
              <></>
            }
          </>
          )
          :
          <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="200px"
          >
            <Text
              fontFamily="sans-serif"
              fontSize="2xl"
            >
              {selectedMonth + 1}月の利用実績はありません
            </Text>
          </Box>
        }
      </Box>
      {filtredReservers.length > 5 ?
        <Box display="flex" flexDirection="row" justifyContent="space-around" marginTop="20px" alignItems="center">
          <Button 
            bg="teal.300"
            color="white"
            _hover={{
              bg: "teal.200",
            }}
            _active={{
              bg: "teal.400",
            }}
            width="100px" 
            disabled={currentPage === 1}  
            onClick={() => setCurrentPage(currentPage - 1)} 
            variant={currentPage === 1 ? "outline" : "solid"} 
          >
            前
          </Button>
          <Box
            width="30%"
            height="10px"
            backgroundColor="white"
            position="relative"
            boxSizing="border-box"
            border="2px #DBDBDB solid"
          >
            <Box
            position="absolute"
            top="0"
            left="0"
            height="100%"
            backgroundColor="#81e6d9"
            transition="width 0.3s ease-in-out"
            width={`${(currentPage / pageNumber) * 100}%`}
            >
            </Box>
          </Box>
          <Button 
            bg="teal.300"
            color="white"
            _hover={{
              bg: "teal.200",
            }}
            _active={{
              bg: "teal.400",
            }}
            width="100px" 
            disabled={currentPage === pageNumber} 
            variant={currentPage === pageNumber ? "outline" : "solid"} 
            onClick={() =>   setCurrentPage(currentPage + 1)}
          >
            次
          </Button>
        </Box>
      : <></>}
    </Flex>
    </>
  )
}

export default ReservationList