import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  Switch,
  Text,
  Spacer,
  Table,
  Th,
  Thead,
  Tr,
  Badge,
  useColorModeValue,
  CSSReset,
  ChakraProvider
} from "@chakra-ui/react";
import { AddIcon } from '@chakra-ui/icons'
import { FaStore } from "react-icons/fa"

// Assets
import UserList from "components/ksin/List/UserList";

function UserSignage(props) {
  // Chakra color mode
  const textColor = useColorModeValue("gray.400", "white");
  const [isChecked, setChecked] = useState(true);
  const history = useHistory();
  const onClickStoreEdit = useCallback((params) => history.push({ pathname:"/app/store/update", state: params }), [history]);  // TODO：ユーザーIDを渡す
  const onClickStoreSignage = useCallback((params) => history.push({ pathname: "/store/signage", state: params }), [history]);
  const onClickUserAdd = useCallback((params) => history.push({pathname: "/app/users/add", state: params}), [history]);
  const storeId = props.location.state.id; 
  const storeName = props.location.state.name;
  const companyName = props.location.state.companyName;

  const onChangeSwitch = (event) => {
    setChecked(event.target.checked);
  }

  return (
    <Flex direction="column">
      <Box
        mb={{ sm: "175px", md: "35px", xl: "30px" }}
        borderRadius="15px"
        px="0px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        align="center"
        backgroundColor="white"
        paddingTop="20px"
        paddingLeft="20px"
        paddingBottom="50px"
        width="50%"
      >
        <Flex>
          <Box p="2" mb="2">
            <Text
              color="black"
              textAlign="left"
              fontWeight="bold"
              // mb="40px"
              verticalAlign="center"
              mr="50px"
              fontSize="large"
            >
              店舗メニュー
            </Text>
          </Box>
        </Flex>
        <Flex align="center" minWidth="100%" flexWrap="nowrap">
          <Badge
            bg="teal.300"
            color="white"
            fontSize="20px"
            p="8px 8px"
            borderRadius="10px"
            mt="10px"
            mr="10px"
            textAlign="center"
          >
            <FaStore color="inherit" size={30} />
          </Badge>
          <Text
            color="black"
            textAlign="left"
            fontWeight="bold"
            display="flex"
            alignItems="center"
            // verticalAlign="middle"
            mt="10px"
            mr="50px"
            fontSize="large"
          >
            {storeName}
          </Text>
          <Button
            fontSize="14px"
            type="submit"
            bg="teal.300"
            w="150px"
            h="30"
            mb="10px"
            mr="4"
            color="white"
            borderRadius="5px"
            mt="20px"
            _hover={{
              bg: "teal.200",
            }}
            _active={{
              bg: "teal.400",
            }}
            onClick={() => onClickStoreSignage({
              "id": storeId,
              "name": storeName,
              "companyName": companyName
            })}
          >
            サイネージ管理
          </Button>
          <Button
            fontSize="14px"
            type="submit"
            bg="teal.300"
            w="150px"
            h="30"
            mb="10px"
            mr="4"
            color="white"
            borderRadius="5px"
            mt="20px"
            _hover={{
              bg: "teal.200",
            }}
            _active={{
              bg: "teal.400",
            }}
            onClick={() => onClickStoreEdit({
              "id": storeId,
            })}
          >
            店舗設定
          </Button>
        </Flex>
      </Box>
      <Box
        mb={{ sm: "205px", md: "75px", xl: "70px" }}
        borderRadius="15px"
        px="0px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        align="center"
        backgroundColor="white"
        paddingTop="20px"
        paddingLeft="20px"
        paddingBottom="50px"
      >
        <Flex>
          <Box p="2">
            <Text
              color="black"
              textAlign="left"
              fontWeight="bold"
              // mb="40px"
              verticalAlign="center"
              mr="50px"
              fontSize="large"
            >
              ユーザー管理
            </Text>
          </Box>
          <Switch id="avater-channel" colorScheme="teal" me="5px" defaultIsChecked={true} p="2" onChange={onChangeSwitch}/>
          <Text
              color="black"
              fontWeight="light"
              textAlign="left"
              // fontWeight="bold"
              // mb="40px"
              verticalAlign="center"
              mr="50px"
              p="1.5"
            >
              アバターch未使用ユーザーを表示
            </Text>
          <Spacer />
          <Box>
            <Button
              fontSize="14px"
              type="submit"
              bg="teal.300"
              w="150px"
              h="30"
              mb="10px"
              mr="4"
              color="white"
              borderRadius="5px"
              mt="20px"
              _hover={{
                bg: "teal.200",
              }}
              _active={{
                bg: "teal.400",
              }}
              onClick={() => onClickUserAdd({companyName: companyName})}
            >
              <AddIcon color="inherit" mr="2" />
              ユーザー追加
            </Button>
          </Box>
        </Flex>
        <Table variant="simple" color={textColor}>
          <Thead>
            <Tr my=".8rem" pl="0px">
              <Th pl="0px" color="gray.400">
                ユーザー
              </Th>
              <Th color="gray.400" textAlign="center">キャラクター</Th>
              <Th color="gray.400" textAlign="center">ステータス</Th>
              <Th color="gray.400" textAlign="center">ステータス変更</Th>
              <Th color="gray.400" textAlign="center">設定</Th>
            </Tr>
          </Thead>
          <ChakraProvider>
            <CSSReset />
              <UserList
                targetStoreId={props.location.state.id}
                isChecked={isChecked}
                companyName={companyName}
              />
          </ChakraProvider>
        </Table>
      </Box>
    </Flex>
  );
}

export default UserSignage;
