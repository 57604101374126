import {
    FormLabel,
    FormControl,
    Box,
    Select
} from '@chakra-ui/react'
import { useEffect } from 'react'

const InputRole = (props: any) => {
    const defaultRole = props.isAddMode || !props.userInfo ? "operator" : props.userInfo.role;
    const onChangeUserRole = (e) => {
        props.setRole(e.target.value);
    }

    useEffect(() => {
        props.setRole(defaultRole);
    },[props.userInfo]);

    return (
        <Box ml="0px">
            <FormControl
                display="flex"
            // justifyContent="flex-start"
            >
                <FormLabel
                    ms="4px"
                    fontSize="sm"
                    textAlign="left"
                    fontWeight="bold"
                    display="flex"
                    width="200px"
                    alignItems="center"
                >
                    ユーザー権限
                </FormLabel>
                <Box display="flex">
                    <Select
                        size="md"
                        width="400px"
                        id="userRoleSelecter"
                        onChange={(e) => onChangeUserRole(e)}
                        value={props.role}
                    >
                        <option value="operator">オペレーター</option>
                        <option value="companyAdmin">管理者</option>
                        <option value="webReservationUser">利用者</option>
                    </Select>
                </Box>
            </FormControl>
        </Box>
    )
}

export default InputRole