import {
    Box, Text,
} from '@chakra-ui/react'
import React, { useEffect } from 'react';


const ShowAffiliation = (props: {
    storeId: string,
    storeList: any[],
    companyName: string,
    options?: any
}) => {

    const [affiliation, setAffiliation] = React.useState("");

    const getAffiliation = (storeId: string) => {
        let affiliation = props.companyName
        if(storeId && props.storeList) {
            const store = props.storeList.find(s => s.id === storeId);
            if (store) {
                affiliation = `${affiliation} / ${store.name}`;
            }
        }
        return affiliation;
    }

    useEffect(() => {
        setAffiliation(getAffiliation(props.storeId))
    }, [props.storeId, props.storeList, props.companyName])

    return (
        <Box  ml="0px">
            <Text textAlign="left">
                {affiliation}
            </Text>
        </Box>
    )
}

export default ShowAffiliation
