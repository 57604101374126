import React, { useCallback, useState } from "react";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  Text,
  Spacer,
  Icon,
  useColorModeValue,
  CSSReset,
  ChakraProvider
} from "@chakra-ui/react";
import { AddIcon } from '@chakra-ui/icons'
import { useHistory } from "react-router-dom";

// Assets
import signInImage from "assets/img/signInImage.png";
import MediaList from "components/ksin/List/MediaList";
import MediaAdd from "components/ksin/Forms/parts/AddMedia";
import { MediaClient } from "components/ksin/ApiClient/MediaClient";
import { maxGByte, maxSize, getFileSize } from "components/ksin/util/CalculationMediaSize";


export default function MediaManagement() {
  const mediaClient = new MediaClient();
  const titleColor = useColorModeValue("teal.300", "teal.200");

  const [isReload, setIsReload] = useState(false);
  const [totalSize, setTotalSize] = useState(0);


  return (
    <Flex direction="column" mt="4rem" id="media-list-wrapper">
      <Box mb={
          { sm: "205px", md: "75px", xl: "70px" }
      }
        borderRadius="15px"
        px="0px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        align="center"
        backgroundColor="white"
        paddingTop="20px"
        paddingLeft="20px"
        paddingBottom="50px"
        width="100%"
      >
        <Flex>
          <Box p="2">
            <Text
              color="black"
              fontWeight="light"
              textAlign="left"
              fontSize="large"
              fontWeight="bold"
              verticalAlign="center"
              mr="50px"
            >
              メディア一覧
            </Text>
          </Box>
          <Spacer />
          <MediaAdd
            setIsReload={setIsReload}
            totalSize={totalSize}
          />
        </Flex>
        <Flex>
          <Box p="2">
            <Text
              color="blue.600"
              fontWeight="light"
              textAlign="left"
              fontSize="medium"
              fontWeight="bold"
              verticalAlign="center"
            >
              使用中: {getFileSize(totalSize)} / {maxGByte}GB
            </Text>
            <Text
              color="blue.600"
              fontWeight="light"
              textAlign="left"
              fontSize="medium"
              fontWeight="bold"
              verticalAlign="center"
            >
              残り{getFileSize(maxSize - totalSize)}アップロードできます
            </Text>
          </Box>
        </Flex>
        <MediaList
          isReload={isReload}
          setIsReload={setIsReload}
          totalSize={totalSize}
          setTotalSize={setTotalSize}
        />
      </Box>
    </Flex>
  );
}
