import {
    Badge,
} from '@chakra-ui/react'


const ShowStatusBadge = (props: {
    state: string,
    dispInfo: any
}) => {
    return (
        <Badge
            w="6rem"
            bg={props.dispInfo[props.state].bgColor}
            color="white"
            fontSize="14px"
            p="3px 10px"
            borderRadius="8px"
        >
            {props.dispInfo[props.state].dispName}
        </Badge>
    )
}

export default ShowStatusBadge


