import React from "react";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import signInImage from "assets/img/signInImage.png";
import ProfileBgImage from "assets/img/ProfileBackground.png";

function LoginRetry() {
  // Chakra color mode
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.400", "white");
  return (
    <Flex direction="column">
      <Box
        mb={{ sm: "205px", md: "75px", xl: "70px" }}
        borderRadius="15px"
        px="0px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        align="center"
      >
        <Box
          bgImage={ProfileBgImage}
          w="100%"
          h="300px"
          borderRadius="25px"
          bgPosition="50%"
          bgRepeat="no-repeat"
          position="relative"
          display="flex"
          justifyContent="center"
        >
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          align="center"
          position="absolute"
          top="50px"
          left="200px"
        >
          <Text color="white" fontWeight="light" textAlign="left">
            アバター接客 KSIN
          </Text>
        </Box>
        <Box
          w="400px"
          h="500px"
          borderRadius="15px"
          px="0px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          align="center"
          position="absolute"
          top="150px"
          left="0"
          right="0"
          backgroundColor="white"
          margin="auto"
        >
          <FormControl padding="50px">
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal" textAlign="center" fontSize="16px" fontWeight="bold">
              パスワード再発行
            </FormLabel>
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              新しいパスワード
            </FormLabel>
            <Input
              borderRadius="15px"
              mb="36px"
              fontSize="sm"
              type="password"
              placeholder="New password"
              size="lg"
            />
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              新しいパスワード(再入力)
            </FormLabel>
            <Input
              borderRadius="15px"
              mb="36px"
              fontSize="sm"
              type="password"
              placeholder="New password"
              size="lg"
            />
            <Button
              fontSize="14px"
              type="submit"
              bg="teal.300"
              w="100%"
              h="45"
              mb="20px"
              color="white"
              mt="20px"
              _hover={{
                bg: "teal.200",
              }}
              _active={{
                bg: "teal.400",
              }}
            >
              設定
            </Button>
          </FormControl>
        </Box>
      </Box>
    </Flex>
  );
}

export default LoginRetry;
