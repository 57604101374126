import { useFormContext } from 'react-hook-form'
import {
    FormControl,
    FormErrorMessage,
    Input,
    Box,
    Flex,
    Button,
    InputGroup,
    InputRightElement,
    UnorderedList,
    ListItem,
    Text,
    FormLabel
} from '@chakra-ui/react'
import { IconButton } from '@chakra-ui/react'
import { useState } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

export default function InputPasswordMain(props: any) {
    const { register, watch, formState: { errors } } = useFormContext();
    const [show, setShow] = useState(false);
    // const [password, setPassword] = useState("");
    const watchPassword = watch("password");
    const handleClick = () => setShow(!show);
    const patternReg = new RegExp(/^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])|(?=.*[a-z])(?=.*[A-Z])(?=.*[!#%&*+-./<=>?@^_~])|(?=.*[A-Z])(?=.*[0-9])(?=.*[!#%&*+-./<=>?@^_~])|(?=.*[a-z])(?=.*[0-9])(?=.*[!#%&*+-./<=>?@^_~]))/);

    return (
        <Box
            display="inline-block"
            flexDirection="column"
            width={props.isInForm ? "800px" : "400px"}
        >
            <FormControl
                isInvalid={errors.password}
                flexDirection="column"
                display="inline-block"
                justifyContent="flex-start"
            >
                <FormLabel
                    ms="4px"
                    fontSize="sm"
                    textAlign="left"
                    fontWeight="bold"
                    display={props.isInForm ? "none" : "block"}
                >
                    パスワード
                </FormLabel>
                <Flex 
                    alignItems="center" 
                    // display="inline-block"
                >
                    <InputGroup alignItems="center" width="400px">
                        <Input
                            pr='4.5rem'
                            id="password"
                            borderRadius="5px"
                            fontSize="sm"
                            type={show ? 'text' : 'password'}
                            size="lg"
                            width="400px"
                            placeholder="新しいパスワード"
                            defaultValue=""
                            // defaultValue={loginId}
                            {...register('password', {
                                required: false,
                                minLength: { value: 8, message: 'パスワードは8文字以上を設定してください' },
                                maxLength: { value: 128, message: 'パスワードは128文字以内で設定してください' },
                                pattern: {
                                    value: patternReg,
                                    message: `パスワード設定ルールをご確認ください`
                                },
                                validate: {
                                    notAllowedMark: (value) => {
                                        let marks = value.replace(/[0-9A-Za-z!#%&*+-./<=>?@^_~]/g, "");
                                        return marks.length === 0 ? true : false
                                    }
                                }
                            })}
                        />
                        <InputRightElement width='4.5rem' h="100%">
                            {show ?
                                <IconButton
                                    // p="-1"
                                    h="20px"
                                    w="20px"
                                    _focus={{ boxShadow: "none" }}
                                    variant='ghost'
                                    onClick={handleClick}
                                    colorScheme='teal'
                                    aria-label='Call Segun'
                                    size='lg'
                                    icon={<AiFillEyeInvisible />}
                                /> :
                                <IconButton
                                    // p="-1"
                                    h="20px"
                                    w="20px"
                                    _focus={{ boxShadow: "none" }}
                                    variant='ghost'
                                    onClick={handleClick}
                                    colorScheme='teal'
                                    aria-label='Call Segun'
                                    size='lg'
                                    icon={<AiFillEye />}
                                />
                            }
                        </InputRightElement>
                    </InputGroup>
                    <Button
                        display={props.isInForm ? "inline" : "none"}
                        fontSize="14px"
                        bg="teal.300"
                        ml="1rem"
                        w="120px"
                        h="30px"
                        // mb="10px"
                        // mr="8"
                        color="white"
                        borderRadius="5px"
                        // mt="20px"
                        _hover={{
                            bg: "teal.200",
                        }}
                        _active={{
                            bg: "teal.400",
                        }}
                        onClick={() => {
                            props.setIsPasswordChange(false);
                            if(props.isInForm) {
                                props.setIsChangePasswordMode(false)
                            }
                        }}
                    >
                        変更しない
                            </Button>
                </Flex>
                <FormErrorMessage>
                    {errors.password && errors.password.message}
                    {errors.password?.type === "notAllowedMark" && `使用できない記号が含まれています`}
                </FormErrorMessage>
            </FormControl>
            <FormControl
                mt="2rem"
                isInvalid={errors.passwordConfirm}
                flexDirection="column"
                display="inline-block"
                justifyContent="flex-start"
            >
                <FormLabel
                    ms="4px"
                    fontSize="sm"
                    textAlign="left"
                    fontWeight="bold"
                    display={props.isInForm ? "none" : "block"}
                >
                    パスワード(再入力)
                </FormLabel>
                <Flex alignItems="center">
                    <InputGroup alignItems="center" width="400px">
                        <Input
                            pr='4.5rem'
                            id="passwordConfirm"
                            borderRadius="5px"
                            fontSize="sm"
                            type={show ? 'text' : 'password'}
                            size="lg"
                            width="400px"
                            placeholder="新しいパスワード(再入力)"
                            defaultValue=""
                            // defaultValue={loginId}
                            {...register('passwordConfirm', {
                                required: '確認のため新しいパスワードを再入力してください',
                                validate: {
                                    isEqualFirstInputPassword: (value) => {
                                        return value === watchPassword ? true : false
                                    }
                                }
                            })}
                        />
                        <InputRightElement width='4.5rem' h="100%">
                            {show ?
                                <IconButton
                                    // p="-1"
                                    h="20px"
                                    w="20px"
                                    _focus={{ boxShadow: "none" }}
                                    variant='ghost'
                                    onClick={handleClick}
                                    colorScheme='teal'
                                    aria-label='Call Segun'
                                    size='lg'
                                    icon={<AiFillEyeInvisible />}
                                /> :
                                <IconButton
                                    // p="-1"
                                    h="20px"
                                    w="20px"
                                    _focus={{ boxShadow: "none" }}
                                    variant='ghost'
                                    onClick={handleClick}
                                    colorScheme='teal'
                                    aria-label='Call Segun'
                                    size='lg'
                                    icon={<AiFillEye />}
                                />
                            }
                        </InputRightElement>
                    </InputGroup>
                </Flex>
                <FormErrorMessage>
                    {errors.passwordConfirm && errors.passwordConfirm.message}
                    {errors.passwordConfirm?.type === "isEqualFirstInputPassword" && `再入力したパスワードが一致しません`}
                </FormErrorMessage>
            </FormControl>
            <Box
                flexDirection="column"
                textAlign="left"
                mt="1rem"
            >
                <Text fontSize="1rem">パスワード設定ルール</Text>
                <UnorderedList fontSize="0.75rem">
                    <ListItem>8文字以上を設定してください</ListItem>
                    <ListItem>半角英大文字、半角英小文字、半角数字、使用できる記号の中から3種類以上を使用してください</ListItem>
                    <ListItem>使用できる記号は{`!#%&*+-./<=>?@^_~`}のみです</ListItem>
                    <ListItem>全角文字は使用できません</ListItem>
                </UnorderedList>
            </Box>
        </Box>
    )
}