import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import esLocale from '@fullcalendar/core/locales/ja'
import { ChakraProvider, Box, Flex, Text, Image } from '@chakra-ui/react'
import { useState, useEffect, useContext } from "react"
// import { useLocation } from 'react-router-dom'
import { useForm, } from 'react-hook-form'
import { slotMinTimeRange, slotMaxTimeRange } from "../../../config"
import ReservationForm from "./ReservationForm"
import { Reservation } from "../../../components/ksin/ApiClient/ReservationClient"
import moment from 'moment'
import { AppContext } from "layouts/AppCommons";
import wsConfig from "../../../ws_config.json"

export default function Calendar() {
  const reservationClient = new Reservation()
  type Timestamp = {
    date: string,
    time: string
  }

  type Event = {
    reservID: string,
    id: string,
    start: Date,
    end: Date,
    title: string,
    characterID: string,
    user: {},
    link: string
  }

  // const location = useLocation()
  const [formState, setFormState] = useState<boolean>()
  const [title, setTitle] = useState<string>("")
  const [startDateTime, setStartDateTime] = useState<Timestamp>({ date: "", time: "" })
  const [expDateTime, setExpDateTime] = useState<Timestamp>({ date: "", time: "" })
  const [selectAvatar, setSelectAvatar] = useState<string>("01FYK29GZ9Q3W895TKZ5T58CPZ")
  const [auth, setAuth] = useState<boolean>(false)
  const [events, setEvents] = useState<Event[]>([])


  const isAuth = async () => {
    const param = {
      method: "GET",
      headers: {
        "Content-Type": "application/json charset=utf-8",
      }
    }
    const response = await fetch('/api/check-auth', param)
    const json = await response.json()
    setAuth(json.auth)
    return json.auth
  }

  const [update, setUpdate] = useState<Boolean>(false)
  isAuth()
  let wsc
  // if (auth) {

  //   const { appContext } = useContext(AppContext);
  //   wsc = appContext.wsc;
  //   let wsDest;
  //   if (wsConfig) {
  //     console.log(JSON.stringify(wsConfig));
  //     const protocol = wsConfig[wsConfig.mode]["isHTTPS"] ? "wss" : "ws";

  //     if (wsConfig.mode === "demo") {
  //       let portKey = wsConfig[wsConfig.mode]["wsPortKey"];
  //       wsDest = `${protocol}://${window.location.hostname}${portKey}`;
  //     } else {
  //       let playerServerPort = wsConfig[wsConfig.mode]["wsPort"];
  //       wsDest = `${protocol}://${window.location.hostname}:${playerServerPort}`;
  //     }

  //   } else {
  //     wsDest = `wss://${window.location.hostname}`
  //     console.log("wsconfig File is not find.");
  //     console.log(`[wsDest] ${wsDest}`);
  //   }

  //   let ws = new WebSocket(wsDest);
  //   ws.onmessage = (event) => {
  //     const msg = JSON.parse(event.data);
  //     if (msg.type === 'calendarStateTransition') {
  //       const result = msg.result;
  //       console.log(`Result: ${result}`);
  //       if (result === "succeed") {
  //         setUpdate(prevState => !prevState)
  //       }
  //     } 
  //   };

  // }
  const sendCalendarState = (nextState: string) => {
    // const ws = wsc.getWs();
    // ws.send(JSON.stringify({
    //   type: 'stateCalendarEvent',
    //   event: {
    //     type: "calendarStateTransition",
    //     operatorState: nextState,
    //     senderType: "User"
    //   }
    // }));
  }

  useEffect(() => {
    try {
      const prevState = localStorage.getItem("reservationDataJSON")
      const updatedFormState = prevState ? true : false
      const updatedFormData = prevState ? JSON.parse(prevState) : {}
      if (updatedFormData && updatedFormData) {
        setFormState(updatedFormState)
        setTitle(updatedFormData.title)
        setStartDateTime({ date: updatedFormData.startDate, time: updatedFormData.startTime })
        setExpDateTime({ date: updatedFormData.endDate, time: updatedFormData.endTime })
        setSelectAvatar(updatedFormData.characterID)
        const res = updatedFormData.startDate
        console.log("res", res)
        localStorage.removeItem("reservationDataJSON")
      }
    } catch (e) {
      console.error("error", e)
    }
  }, [])

  //日付のフォーマットを変更する
  const formattingDate = (rawDate) => {
    const [date, time] = rawDate.split("T")
    const formattedTime = time.split("+")[0].slice(0, 8)
    const timestamp = { date: date, time: formattedTime }
    return timestamp
  }

  useEffect(() => {
    const fetchData = async () => {
      reservationClient.getAnonymousReservations().then(data => setEvents(data))
      // const authenticated = await isAuth()
      // if (authenticated) {
      //   reservationClient.getAnonymousReservations().then(data => {
      //     setEvents(data)
      //   })
      // } else {
      //   reservationClient.getAnonymousReservations().then(data => setEvents(data))
      // }
    }
    fetchData()
  }, [, formState, title, update])

  //Check is reservation time in range in every event 
  const timeRangeValidation = (events, timestamp) => {
    let isValid = true;
    let beforeEventArray = [];
    let afterEventArray = [];

    const startTime = moment(timestamp.start);
    let endTime = moment(timestamp.end);
    for (const event of events) {
      const eventStart = moment(event.start);
      const eventEnd = moment(event.end);
      if (startTime.isSame(endTime, 'day')) {
        isValid = true
      } else if (!startTime.isSame(endTime)) {
        endTime = moment(startTime).set({
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 999
        });
        if (startTime.isSame(eventStart) || startTime.isSame(eventEnd)) {
          isValid = false;
        } else if (startTime.isAfter(eventStart)) {
          if (startTime.isBefore(eventEnd)) {
            if (endTime.isBefore(eventStart)) {
              beforeEventArray.push(eventStart);
              // isValid = false;
            } else if (endTime.isAfter(eventEnd)) {
              afterEventArray.push(eventEnd);
              // isValid = false;
            }
          } else if (startTime.isAfter(eventEnd)) {
            isValid = true;
          }
        } else if (endTime.isAfter(eventStart)) {
          if (endTime.isBefore(eventEnd)) {
            beforeEventArray.push(eventStart);
            // isValid = false;
          } else if (endTime.isAfter(eventEnd) && startTime.isBefore(eventStart) && startTime.isSame(eventStart)) {
            beforeEventArray.push(eventStart);
            // isValid = false;
          } else if (endTime.isAfter(eventEnd) && startTime.isBefore(eventEnd)) {
            afterEventArray.push(eventEnd);
            // isValid = false;
          } else if (endTime.isAfter(eventStart)) {
            isValid = true
          }
        }
      }

    }
    const beforeEventStart = beforeEventArray.length > 0 ? moment.min(beforeEventArray).toDate() : null;
    const afterEventStart = afterEventArray.length > 0 ? moment.max(afterEventArray).toDate() : null

    return [isValid, afterEventStart, beforeEventStart];
  };

  function getTimezoneOffset(offset) {
    const sign = offset >= 0 ? '+' : '-'
    const absOffset = Math.abs(offset)
    const hours = ('0' + absOffset).slice(-2)
    const minutes = '00' // If you need minutes for the offset, you can add them here
    return `${sign}${hours}:${minutes}`
  }

  const calcRange = (newEventReservTime, eventOldTime, state = "start") => {
    const eventTime = moment(eventOldTime)
    const newReservTime = moment(newEventReservTime)
    let difference

    if (state === "start") {
      difference = newReservTime.diff(eventTime)
    } else if (state === "end") {
      difference = eventTime.diff(newReservTime)
    }

    const result = moment(newReservTime).add(difference)
    const formattedDate = result.format(`YYYY-MM-DDTHH:mm:ss${getTimezoneOffset(result.utcOffset() / -60)}`)
    return formattedDate
  }

  const { reset } = useForm()
  const closeForm = () => {
    // currentTime
    reset()
    setFormState(false)
    localStorage.removeItem("reservationData")
  }
  const getCurrentDate = () => {
    const now = new Date()
    const currentYear = now.getFullYear()
    const currentMonth = (now.getMonth() + 1).toString().padStart(2, "0")
    const currentDay = now.getDate().toString().padStart(2, "0")
    return `${currentYear}-${currentMonth}-${currentDay}`
  }
  const getCurrentTime = () => {
    const currDate = new Date().getTime()
    const startDate = new Date(startDateTime.date)
    startDate.setHours(0, 0, 0, 0)
    if (startDate.getTime() === currDate) {
      const now = new Date()
      const currentHour = now.getHours().toString().padStart(2, "0")
      const currentMinute = now.getMinutes().toString().padStart(2, "0")
      return `${currentHour}:${currentMinute}`
    } else {
      return `00:00`
    }
  }
  const currentDate = getCurrentDate()
  const currentTime = getCurrentTime()



  return (
    <ChakraProvider>
      <Box
        margin="80px"
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          mb="2rem"
          borderBottom="6px solid"
          borderColor="green.100"
        >
        <Image 
            src="/images/Common/ksin-icon.png"
            w="40px"
            display="inline-block"
            mr="0.5rem"
            ></Image>
          <Text fontSize="30px">KSIN TimeShare</Text>
        </Flex>
        {/* https://fullcalendar.io/docsに関する書類 */}
        <FullCalendar
          height="auto"
          locale={esLocale}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'timeGridDay,timeGridWeek,dayGridMonth'
          }}
          initialView="timeGridWeek"
          selectable={true}
          nowIndicator={true}
          navLinks={true}
          dragScroll={true}
          selectMirror={true}
          selectOverlap={false}
          weekends={false}
          slotDuration={'00:10'}
          slotLabelInterval={'00:10'}
          events={events}
          eventColor='#3788D8'
          eventMouseEnter={
            function (info) {
              if (info.event.title !== "予約済み") {
                info.event.setProp('backgroundColor', 'crimson')
              }
            }
          }
          eventMouseLeave={
            function (info) {
              if (info.event.title !== "予約済み") {
                info.event.setProp('backgroundColor', '#3788D8')
              }
            }
          }
          allDaySlot={false}
          select={
            function (info) {
              if (events) {
                let [valid, start, end] = timeRangeValidation(events, info)
                console.log(valid)
                //条件チェック：開始時間が重ならいため
                if (start && info.start) {
                  const res = calcRange(info.start, start, "start")
                  setStartDateTime(formattingDate(res))
                  setExpDateTime(formattingDate(info.endStr))
                  //条件チェック：終了時間が重ならいため
                } else if (end && info.startStr) {
                  const res = calcRange(info.start, end, "end")
                  setStartDateTime(formattingDate(info.startStr))
                  setExpDateTime(formattingDate(res))
                  //そのまま設定する
                } else if (info.startStr && info.endStr) {
                  setStartDateTime(formattingDate(info.startStr))
                  setExpDateTime(formattingDate(info.endStr))
                }
              }
              setFormState(true)
            }
          }

          selectAllow={
            function (info) {
              const currTime = new Date().getTime() - 18000000
              const startTime = info.start.getTime()
              if (formState) {
                return false
              }
              else if (startTime <= currTime) {
                return false
              } else if (events.length > 0) {
                let [i, start, end] = timeRangeValidation(events, info)
                console.log(i, start, end)
                if (i)
                  return true
                else {
                  return true
                }
              } else {
                return true
              }
            }
          }
          aspectRatio={1.8}
          //営業時間
          businessHours={{
            daysOfWeek: [1, 2, 3, 4, 5], // 月 - 金
            // startTime: '07:00', // 開始時間
            // endTime: '23:00', // 終了時間
            startTime: slotMinTimeRange, // 開始時間
            endTime: slotMaxTimeRange // 終了時間
          }}
          //setting file config.js 
          slotMinTime={slotMinTimeRange}
          slotMaxTime={slotMaxTimeRange}
        />
        {formState ?
          <ReservationForm
            sendCalendarState={sendCalendarState}
            setTitle={setTitle}
            auth={auth}
            setEvents={setEvents}
            selectAvatar={selectAvatar}
            closeForm={closeForm}
            title={title}
            currDate={currentDate}
            currentTime={currentTime}
            startDate={startDateTime.date}
            startTime={startDateTime.time}
            setStartDateTime={setStartDateTime}
            expDate={expDateTime.date}
            expTime={expDateTime.time}
            setExpDateTime={setExpDateTime}
            isEdit={false}
          />
          :
          <></>
        }
      </Box>
    </ChakraProvider>
  )
}
