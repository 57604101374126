import { useEffect, useState } from "react"
import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  Flex
} from '@chakra-ui/react';

import { Reservation } from "../../../components/ksin/ApiClient/ReservationClient";
import { PRICE } from "../../../config"

const ReservationUsageStatistics = () => {

  const reservationClient = new Reservation()
  const currMonth = new Date().getMonth()
  const currYear = new Date().getFullYear()
  const [reservs, setReservs] = useState([])

  const [filtredReservers, setFiltredReservs] = useState([])
  const [selectedMonth, setSelectedMonth] = useState<number>(currMonth);
  const [currentPage, setCurrentPage] = useState<number>(1)
  const itemPages = 5
  const pageNumber = Math.ceil(filtredReservers.length / itemPages)
  const startItem = (currentPage - 1) * itemPages;
  const endItem = startItem + itemPages;

  const filterByMonth = (data) => {
    const slitMonth = (data) => {
      const [date] = data.start.split(" ")
      const [year, month] = date.split("-")
      return selectedMonth === (parseInt(month) - 1) && currYear === (parseInt(year))
    }

    const newFormat = (data) => {
      const [startDate, startTime] = data.start.split(" ")
      const [endDate, endTime] = data.end.split(" ")
      const [year, month, day] = startDate.split("-")
      const formattedDate = year + "年" + month + "月" + day + "日"
      return { reservID: data.reservID, id: data.id, oldStartDate: startDate, oldEndDate: endDate, characterID: data.characterID, startDate: formattedDate, timeStart: startTime.slice(0, 5), timeEnd: endTime.slice(0, 5), title: data.title, link: data.link }
    }

    const result = data ? data.filter(slitMonth) : []
    const formattedResult = result ? result.map(x => newFormat(x)) : []
    setFiltredReservs(formattedResult)
    return formattedResult
  }

  const handleMonth = (month) => {
    setSelectedMonth(month)
    total = 0
    setCurrentPage(1)
  }

  useEffect(() => {
    reservationClient.getReservations().then(data => setReservs(data.userResult));
  }, [])

  useEffect(() => {
    filterByMonth(reservs)
  }, [selectedMonth, reservs])

  const getMS = (time) => {
    const [hours, minutes] = time.split(":").map(Number)
    const result = new Date(1970, 0, 1, hours, minutes)
    return result.getTime()

  }
  let total = 0

  const calcTime = (dateStart = "2020-10-10", dateEnd = "2020-10-10", start: string, end: string, type: string) => {
    const startDate = new Date(dateStart).getTime()
    const endDate = new Date(dateEnd).getTime()
    if (startDate === endDate) {
      const startTime = getMS(start)
      const endTime = getMS(end)
      const diff = (endTime - startTime) / 60000
      const cost = Math.floor(diff * PRICE / 10)
      type == "total" ? total += cost : ""
      return type === "diff" ? diff.toString() + "分" : cost.toString()
    } else {
      const startTime = new Date(dateStart + "T" + start).getTime()
      const endTime = new Date(dateEnd + "T" + end).getTime()
      const diff = (endTime - startTime) / 60000
      const cost = Math.floor(diff * PRICE / 10)
      type == "total" ? total += cost : ""
      return type === "diff" ? diff.toString() + "分" : cost.toString()
    }
  }
  const calcTotal = () => {
    total = 0
    filtredReservers.map(x => calcTime(x.oldStartDate, x.oldEndDate, x.timeStart, x.timeEnd, "total"))
    return total
  }

  return (
    <Box
      marginTop="50px"
      display="flex"
      flexDirection="column"
      maxHeight="inherit"
      width="950px"
    >
      <Box
        display="inline-flex"
        flex-wrap="wrap"
        gridGap={19}
        alignItems="center"
        // overflow-y="auto"
        maxWidth="950px"
      >
        <Text fontSize="2xl">利用実績</Text>
        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />} fontSize="xl">
            {currYear.toString() + "年" + (selectedMonth + 1).toString() + "月"}
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => handleMonth(0)}>1月</MenuItem>
            <MenuItem onClick={() => handleMonth(1)}>2月</MenuItem>
            <MenuItem onClick={() => handleMonth(2)}>3月</MenuItem>
            <MenuItem onClick={() => handleMonth(3)}>4月</MenuItem>
            <MenuItem onClick={() => handleMonth(4)}>5月</MenuItem>
            <MenuItem onClick={() => handleMonth(5)}>6月</MenuItem>
            <MenuItem onClick={() => handleMonth(6)}>7月</MenuItem>
            <MenuItem onClick={() => handleMonth(7)}>8月</MenuItem>
            <MenuItem onClick={() => handleMonth(8)}>9月</MenuItem>
            <MenuItem onClick={() => handleMonth(9)}>10月</MenuItem>
            <MenuItem onClick={() => handleMonth(10)}>11月</MenuItem>
            <MenuItem onClick={() => handleMonth(11)}>12月</MenuItem>
          </MenuList>
        </Menu>
      </Box>
      <Flex display="flex" flexDirection="column">
        <Box
          display="flex"
          flexDirection="row"
          // justifyContent="space-between" 
          marginBottom="20px"
          marginTop="20px"
        >
          <Flex
            width="30px"
            mr="1rem"
          >
            <Text color="#A0AEC0">No.</Text>
          </Flex>
          <Flex width="300px" justifyContent="center" mr="1rem">
            <Text color="#A0AEC0">会議名</Text>
          </Flex>
          <Flex width="150px" justifyContent="center" mr="1rem">
            <Text color="#A0AEC0">日付</Text>
          </Flex>
          <Flex width="140px" justifyContent="center" mr="1rem">
            <Text color="#A0AEC0">時間</Text>
          </Flex>
          <Flex width="140px" justifyContent="center" mr="1rem">
            <Text color="#A0AEC0">利用費</Text>
          </Flex>
          <Flex width="100px" justifyContent="center" mr="1rem">
            <Text color="#A0AEC0">金額</Text>
          </Flex>
        </Box>
        <Box
          maxHeight="600px"

        >
          {filtredReservers.length > 0 ?
            filtredReservers.slice(startItem, endItem).map(x =>
              <>
                <Flex
                  display="flex"
                  flexDirection="row"
                  marginBottom="50px"
                  // justifyContent="space-between" 
                  borderBottom="1px"
                  borderColor="#A0AEC0"
                  marginX="auto"
                  paddingBottom="30px"
                >
                  <Box
                    // height="20px"
                    display="flex"
                    alignItems="center"
                    mr="1rem"
                  >
                    <Text width="30px">{x.id + 1}</Text>
                  </Box>
                  <Box
                    height="50px"
                    display="flex"
                    alignItems="center"
                    width="300px"
                    justifyContent="center"
                    mr="1rem"
                  >
                    <Text
                      fontSize="unset"
                      paddingLeft="5px"
                    >
                      {x.title}
                    </Text>
                  </Box>
                  <Box
                    height="50px"
                    border="1px"
                    borderColor="#DBDBDB"
                    borderRadius="10px"
                    display="flex"
                    alignItems="center"
                    width="150px"
                    justifyContent="center"
                    mr="1rem"
                  >
                    <Text fontSize="medium">
                      {x.startDate}
                    </Text>
                  </Box>
                  <Box
                    height="50px"
                    border="1px"
                    borderColor="#DBDBDB"
                    borderRadius="10px"
                    display="flex"
                    alignItems="center"
                    width="140px"
                    justifyContent="center"
                    mr="1rem"
                  >
                    <Text fontSize="medium">
                      {x.timeStart} - {x.timeEnd}
                    </Text>
                  </Box>
                  <Box
                    height="50px"
                    border="1px"
                    borderColor="#DBDBDB"
                    borderRadius="10px"
                    display="flex"
                    alignItems="center"
                    width="140px"
                    justifyContent="center"
                    mr="1rem"
                  >
                    <Text fontSize="medium">
                      10分/{PRICE}円
                </Text>
                  </Box>
                  <Box
                    backgroundColor="blue.200"
                    textColor="white"
                    height="50px"
                    border="1px"
                    borderColor="#DBDBDB"
                    borderRadius="10px"
                    display="flex"
                    alignItems="center"
                    width="100px"
                    justifyContent="center"
                  >
                    <Text fontSize="medium">
                      {`${parseInt(calcTime(x.oldStartDate, x.oldEndDate, x.timeStart, x.timeEnd, "cost")).toLocaleString()}円`}
                    </Text>
                  </Box>
                </Flex>
              </>
            )
            :
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="200px"
            >
              <Text
                fontFamily="sans-serif"
                fontSize="2xl"
              >
                {selectedMonth + 1}月の利用実績はありません
          </Text>
            </Box>
          }
        </Box>
        {currentPage == pageNumber || filtredReservers.length < 5 && filtredReservers.length > 0 ?
          <>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              marginBottom="20px"
              marginTop="20px"
            >
              <Flex>
                <Text fontSize="2xl" mr="2rem">合計</Text>
              </Flex>
              <Box
                backgroundColor="#91BAEE"
                textColor="white"
                height="50px"
                border="1px"
                borderColor="#DBDBDB"
                borderRadius="10px"
                display="flex"
                alignItems="center"
                width="100px"
                justifyContent="center"
                mr="1rem"
              >
                <Text fontSize="medium">
                  {calcTotal().toLocaleString() + "円"}
                </Text>
              </Box>
            </Box>
            <Text 
              fontSize="small"
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
            >
              ※10分以内のご利用は最初の15回分まで無料としご請求時に除外します。
          </Text>
          </>
          : <Box height="50px" margin="20px"></Box>}
        {filtredReservers.length > 5 ?
          <Box
            display="flex"
            flexDirection="row"
            // justifyContent="space-around" 
            marginTop="20px"
            alignItems="center"
          >
            <Button
              bg="teal.300"
              color="white"
              _hover={{
                bg: "teal.200",
              }}
              _active={{
                bg: "teal.400",
              }}
              width="100px"
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(currentPage - 1)}
              variant={currentPage === 1 ? "outline" : "solid"}
            >
              前
          </Button>
            <Box
              width="30%"
              height="10px"
              backgroundColor="white"
              position="relative"
              boxSizing="border-box"
              border="2px #DBDBDB solid"
            >
              <Box
                position="absolute"
                top="0"
                left="0"
                height="100%"
                backgroundColor="#81e6d9"
                transition="width 0.3s ease-in-out"
                width={`${(currentPage / pageNumber) * 100}%`}
              >
              </Box>
            </Box>
            <Button
              bg="teal.300"
              color="white"
              _hover={{
                bg: "teal.200",
              }}
              _active={{
                bg: "teal.400",
              }}
              width="100px"
              disabled={currentPage === pageNumber}
              variant={currentPage === pageNumber ? "outline" : "solid"}
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              次
          </Button>
          </Box>
          : <></>}
      </Flex>
    </Box>
  )
}


export default ReservationUsageStatistics