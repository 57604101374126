const CommandList: CmdObj[] = [
    {
        cmd: "motion-greeting",
        dispName: "おじぎ",
        bgImage: "/images/Common/motion-greeting.png"
    },
    {
        cmd: "avatar-NE_custom",
        dispName: "NEXCO様",
        bgImage: ""
    },
    {
        cmd: "avatar-Lin",
        dispName: "Linh",
        bgImage: ""
    },
    {
        cmd: "avatar-Yukika",
        dispName: "Yukika",
        bgImage: ""
    },
    {
        cmd: "avatar-Emiri",
        dispName: "Emiri",
        bgImage: ""
    },
    {
        cmd: "avatar-Kento",
        dispName: "Kento",
        bgImage: ""
    },
    {
        cmd: "camera-wide_onlyAvatar",
        dispName: "wide_onlyAvatar",
        bgImage: ""
    },
    {
        cmd: "camera-wide_andMedia",
        dispName: "wide_andMedia",
        bgImage: ""
    },
    {
        cmd: "camera-vertical_onlyAvatar",
        dispName: "vertical_onlyAvatar",
        bgImage: ""
    },
    {
        cmd: "camera-vertical_onlyAvatar_rotate",
        dispName: "vertical_rotate",
        bgImage: ""
    },
    {
        cmd: "camera-vertical_andMedia",
        dispName: "vertical_andMedia",
        bgImage: ""
    },
    {
        cmd: "bg_custom",
        dispName: "カスタム背景",
        bgImage: ""
    },
    {
        cmd: "bg_default",
        dispName: "標準背景",
        bgImage: ""
    }
]

export class StreamerCommandManager {
    constructor() {
    }
    get() {
        return CommandList;
    }
}

export type CmdObj = {
    cmd: string,
    dispName: string,
    bgImage: string
}