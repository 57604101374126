import { useParams } from "react-router-dom";
// Chakra imports
import {
  Box,
  Flex,
  Text,
  CSSReset,
  ChakraProvider
} from "@chakra-ui/react";
import AddUserForm from "../../../components/ksin/Forms/AddUserForm";

const user_data=[
  {"login_id": "a.abe@sample.ne.jp", "password": "password", "userName": "阿部あやか", "affiliation": "株式会社サンプル / つくばサポートセンター"},
  {"login_id": "t.kanou@sample.ne.jp", "password": "password", "userName": "加納太郎", "affiliation": "株式会社サンプル / つくばサポートセンター"},
  {"login_id": "s.itou@sample.ne.jp", "password": "password", "userName": "伊藤さやか", "affiliation": "株式会社サンプル / つくばサポートセンター"},
  {"login_id": "j.ueno@sample.ne.jp", "password": "password", "userName": "上野次郎", "affiliation": "株式会社サンプル / つくばサポートセンター"},
];


function AddEdit(props: any) {
  const companyName = props.location.state.companyName;
  // Chakra color mode
  const { id }: any = useParams();
  const isAddMode = !id;
  const userInfo = isAddMode ? {} : user_data[props.location.state.id - 1];  // TODO：APIをたたいてユーザーデータを取得する

  return (
    <Flex direction="column" mt="4rem">
      <Box
        mb={{ sm: "205px", md: "75px", xl: "70px" }}
        borderRadius="15px"
        px="0px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        align="center"
        backgroundColor="white"
        paddingTop="30px"
        paddingLeft="20px"
        paddingBottom="50px"
        width="60%"
      >
        <Text color="black" textAlign="left" fontWeight="bold" marginBottom="40px" fontSize="large">
          {isAddMode === true ? "ユーザー追加" : "ユーザー設定"}
        </Text>
        
        <ChakraProvider>
          <CSSReset />
            <AddUserForm
              isAddMode={true}
              userInfo={userInfo}
              companyName={companyName}
            />
        </ChakraProvider>
      </Box>
    </Flex>
  );
}

export default AddEdit;
