// Chakra imports
import { CheckCircleIcon } from "@chakra-ui/icons";
import {
  Image,
  Flex,
  Text,
  Input,
  Button,
  Box,
  FormControl,
  Select,
  // CSSReset,
  // ChakraProvider
} from "@chakra-ui/react";
import { PersonIcon } from "components/Icons/Icons";
import ShowAffiliation from "components/ksin/Forms/parts/ShowAffiliation";
import SignageTemplateEditButtons from "components/ksin/sideContainer/SignageTemplateEditButtons";
import PixelStreamer from "components/ksin/Streamer/PixelStreamer";
import { AppContext } from "layouts/AppCommons";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useContext } from "react";
import LogoConfigurator from "components/ksin/sideContainer/LogoConfigurator";
import { FormProvider, useForm } from "react-hook-form";
import TelopConfigurator from "components/ksin/sideContainer/TelopConfigurator";
import { SignageClient } from "components/ksin/ApiClient/SignageClient";
import MediaConfigurator from "components/ksin/sideContainer/MediaConfigurator";
import DrawerSettingButton from "components/ksin/sideContainer/DrawerSettingButton/DrawerSettingButton";
import { CompanyClient } from "components/ksin/ApiClient/CompanyClient";
// import { useHistory } from 'react-router-dom';

export type SettingButtonItem = {
  id: string,
  textBtn: string,
  color: string,
  fontSize: number,
  visible: boolean
}

export type SettingButtonState = {
  data: SettingButtonItem[],
  direction: 'vertical' | 'hozirontal'
}

const signageClient = new SignageClient();

export const SignageStateList = {
  Standby: "Standby",
  Calling: "Calling",
  Active: "Active",
  Monitoring: "Monitoring",
  Crowded: "Crowded",
  Suspended: "Suspended"
}

export const SignageLayoutList = {
  mediaAndAvatar: "mediaAndAvatar",
  onlyAvatar: "onlyAvatar",
  wipe: "wipe",
  onlyMedia: "onlyMedia",
}

export type StateTemplateInfo = {
  width: number,
  height: number,
  layout: string,
  media_list?: string[],
  is_view_media?: boolean,
  logo_id?: string,
  is_view_logo?: boolean,
  telop?: string,
  is_view_telop?: boolean
};

type Signage = {
  id: string,
  companyName: string,
  image: string
}

function SignageTemplateUpdate(props: any) {
  const { appContext, } = useContext(AppContext);
  const wsc = appContext.wsc;
  
  // const history = useHistory();
  
  const [signageRawData, setSignageRawDate] = useState<Signage>({id: "", companyName: "", image: ""})


  //Cacheから情報を受取る
  const getCookieArray = () => {
    let arr = new Array();
    if (document.cookie != '') {
      var tmp = document.cookie.split('; ');
      for (let i = 0; i < tmp.length; i++) {
        let data = tmp[i].split('=');
        arr[data[0]] = decodeURIComponent(data[1]);
      }
    }
    return arr;
  };

  //webReservationUserのSaignageの情報を受取る
  const getData = async () => {
    const userId = getCookieArray()['user_id'];
    const userClient = new SignageClient();
    const companyClient = new CompanyClient()
    await userClient.get()
    .then((data: any) => {
      setSignageRawDate((prevState) => ({
        ...prevState, 
        id: data[0].id,
        image: data[0].image
      }));
    })
    .catch((err) => {
      console.log(err);
    });
    await companyClient.get(userId)
    .then((data: any) => {
      setSignageRawDate((prevState) => ({
        ...prevState,
        companyName: data.companyName
      }))
    })
    .catch((err) => {
      console.log(err);
    });
  }
  useEffect(() => {
    getData()
  }, []);
        // 対象サイネージID(管理画面からの遷移時に取得)
  const userRole =  getCookieArray()['user_role']
  
  console.log(signageRawData, "test")
  let targetId
  let companyName
  let image

  if (userRole === "webReservationUser") {
    targetId = signageRawData.id;
    companyName = signageRawData.companyName;
    image = signageRawData.image;
  } else if  (userRole === "companyAdmin" || userRole === "systemAdmin")   {
    targetId = props.location.state.id;
    companyName = props.location.state.companyName;
    image = props.location.state.image;
  }

  

  // 画面内で更新されるデータ
  const [signageInfo, setSignageInfo] = useState(null);
  const [signageState, setSignageState] = React.useState(SignageStateList.Standby);
  const [templateTable, setTemplateTable] = React.useState(null);
  const [currentTemplate, setCurrentTemplate] = React.useState(null);
  const [isOpenLogoConfigurator, setIsOpenLogoConfigurator] = React.useState(false);
  const [isOpenMediaConfigurator, setIsOpenMediaConfigurator] = React.useState(false);
  const [isOpenTelopConfigurator, setIsOpenTelopConfigurator] = React.useState(false);
  const [serverErrors, setServerErrors] = React.useState([]);
  const [uploadImageTable, setUploadImageTable] = React.useState({});
  const [shouldUpload, setShouldUpload] = React.useState({
    logo: {},
    media: {}
  });
  const [ratio, setRatio] = React.useState({
    w: 1080,
    h: 1920
  });
  const [isOpenSettingButton, setIsOpenSettingButton] = useState(false);
  const [settingButton, setSettingButton] = useState<SettingButtonState>({
    data: [],
    direction: 'vertical'
  });
  const [showButtonWithLayoutChange, setShowButtonWithLayoutChange] = useState(false);

  // Form setting
  const {
    // setError,
    formState: { isSubmitting },
  } = useForm()
  const methods = useForm();

  // サーバーからエラーが返されたときに表示する
  const addServerError = (errObj: { code: string, msg: string }) => {
    setServerErrors([...serverErrors, errObj]);
    console.log(JSON.stringify(serverErrors));
  }

  // テンプレート更新実行(サーバー送信)
  const updateSignage = (values) => {
    signageClient.update(values)
      .then((json: any) => {
        switch (json.result) {
          case "OK":
            // history.goBack();
            if (values.successMsg) {
              // alert(values.successMsg);
              if (values.image && !values.referenceTemplateId) {
                shouldUpload[values.templateTarget][values.state] = false;
                setShouldUpload({ ...shouldUpload });
              }
            }
            break;
          case "Error":
            if (parseInt(json.code.replace("SVER-", "")) >= 100) {
              break;
            } else {
              addServerError(
                {
                  code: json.code,
                  msg: json.msg
                }
              );
              break;
            }
          default:
            throw new Error();
        }
      })
      .catch((e) => {
        addServerError(
          {
            code: "SVER-Unexpected",
            msg: "未定義のサーバーエラーが発生しました"
          }
        );
      })
  }


  // 画像更新
  const uploadImage = () => {
    Object.keys(uploadImageTable).forEach((state) => {
      let values: any = {
        isUpdateTemplate: 1,
        signageId: targetId,
        state: "",
        templateId: "",
        image: null,
        templateTarget: "",
        successMsg: "",
      };

      values.state = state;
      values.templateId = uploadImageTable[state].id;
      const logo = uploadImageTable[state].logo;
      const media = uploadImageTable[state].media;

      if (logo &&  typeof logo != 'string') {
        values.image = logo.image;
        values.templateTarget = "logo"
        values.successMsg = `${state}テンプレートのロゴをアップロードしました`
        shouldUpload.logo[state] = true;
        setShouldUpload({ ...shouldUpload });
        if(logo.mode) {
          values.islibraryMedia = 1;
        }
        updateSignage(values);
      }

      if (media && typeof media != 'string') {
        values.image = media.image;
        values.templateTarget = "media"
        values.successMsg = `${state}テンプレートのメディアをアップロードしました`
        shouldUpload.media[state] = true;
        setShouldUpload({ ...shouldUpload });
        if(media.mode) {
          values.islibraryMedia = 1;
        }
        updateSignage(values);
      }
    });
  }

  // 画像ファイル実体のアップロードが完了してから参照の処理を行う
  useEffect(() => {
    let isCompleteUploadLogo = true;
    if (Object.keys(shouldUpload.logo).length) {
      Object.keys(shouldUpload.logo).forEach((state => {
        if (shouldUpload.logo[state]) {
          isCompleteUploadLogo = false;
        }
      }));
      if (isCompleteUploadLogo) {
        referenceImage("logo");
        shouldUpload.logo = {};
        setShouldUpload({ ...shouldUpload });
      }
    }

    let isCompleteUploadMedia = true;
    if (Object.keys(shouldUpload.media).length) {
      Object.keys(shouldUpload.media).forEach((state => {
        if (shouldUpload.media[state]) {
          isCompleteUploadMedia = false;
        }
      }));
      if (isCompleteUploadMedia) {
        referenceImage("media");
        shouldUpload.media = {};
        setShouldUpload({ ...shouldUpload });
      }
    }
  }, [shouldUpload]);

  const referenceImage = (templateTarget: string) => {
    let values: any = {
      isUpdateTemplate: 1,
      signageId: targetId,
      templateId: "",
      image: null,
      templateTarget: "",
      successMsg: ""
    };
    Object.keys(uploadImageTable).forEach((state) => {
      values.templateId = uploadImageTable[state].id;
      const logo = uploadImageTable[state].logo;
      const media = uploadImageTable[state].media;

      if (templateTarget === "logo") {
        if (typeof logo === "string") {
          if (logo.includes("Uploaded_")) {
            values.image = logo;
            values.templateTarget = "logo"
            values.successMsg = `${state}テンプレートのロゴ参照を更新しました`
            const referenceState = logo.replace("Uploaded_", '');
            const referenceTemplateId = uploadImageTable[referenceState].id;
            values.referenceTemplateId = referenceTemplateId;
            updateSignage(values);
          }
        }
      }

      if (templateTarget === "media") {
        if(typeof media === "string") {
          if (media.includes("Uploaded_")) {
            values.image = media;
            values.templateTarget = "media"
            values.successMsg = `${state}テンプレートのメディア参照を更新しました`
            const referenceState = media.replace("Uploaded_", '');
            const referenceTemplateId = uploadImageTable[referenceState].id;
            values.referenceTemplateId = referenceTemplateId;
            updateSignage(values);
          } 
        }
      }
    });
  }

  // 確定
  const onSubmit = (values: any) => {
    //error reset
    setServerErrors([]);

    // 画像アップロード
    uploadImage();

    delete values.image;

    // 対象サイネージIDの指定
    values.signageId = targetId;

    // テンプレート情報の設定
    values.isUpdateTemplate = 1;

    for (const st of templateTable.values()) {

      const current = st.template;
      const sendValues = { ...values };

      sendValues.templateId = current.id;
      sendValues.height = current.height;
      sendValues.width = current.width;
      sendValues.layout = current.layout;
      sendValues.is_view_media = current.is_view_media ? 1 : 0;
      sendValues.is_view_logo = current.is_view_logo ? 1 : 0;
      sendValues.telop = current.telop;
      sendValues.is_view_telop = current.is_view_telop ? 1 : 0;

      sendValues.successMsg = `${current.signage_state}テンプレートを更新しました`;
      if(st.stateName=== "Standby"){
        settingButton.data.forEach((item, index)=>{
          sendValues[`touch_buttons[${index}]`] = JSON.stringify({
            id:item.id,
            text: item.textBtn,
            font_size: item.fontSize,
            bg_color: item.color,
            is_view: item.visible
          });
        });
        sendValues.row = settingButton.direction;
      }
      updateSignage(sendValues);
    }

  }

  let stateData

  //webReservationUserで受付の制限
  if (userRole === "webReservationUser") {
    stateData = [
      {
        state: SignageStateList.Active,
        dispName: "会話中"
      }
    ]
  } else {

    // サイネージ状態別データ
    stateData = [
      {
        state: SignageStateList.Standby,
        dispName: "受付中",
      },
      {
        state: SignageStateList.Calling,
        dispName: "呼出中"
      },
      {
        state: SignageStateList.Active,
        dispName: "会話中"
      },
      {
        state: SignageStateList.Crowded,
        dispName: "混雑中"
      },
    {
      state: SignageStateList.Suspended,
      dispName: "休止中"
    },
  ] 
}
  // レイアウト名称データ名称
  const layoutData = [
    {
      name: SignageLayoutList.mediaAndAvatar,
      dispName: "メディア&アバター",
    },
    {
      name: SignageLayoutList.onlyAvatar,
      dispName: "アバターのみ"
    },
    {
      name: SignageLayoutList.wipe,
      dispName: "ワイプ"
    },
    {
      name: SignageLayoutList.onlyMedia,
      dispName: "メディアのみ"
    }
  ]

  const getStateData = (state) => {
    const filtered = stateData.filter(data => data.state === state);
    return filtered[0];
  }

  // マウント後の単発処理
  useEffect(() => {
    setSignageState(SignageStateList.Standby)

    // DBからテンプレデータの取得
    signageClient.get(targetId).then((data: any) => {    
      const signageStandBy = data.templates.find((item)=>item.signage_state === 'Standby')
      console.log('signageStandBy',signageStandBy);
      
      setShowButtonWithLayoutChange(signageStandBy.height - signageStandBy.width >= 0)
      setSettingButton({
        data: signageStandBy.touch_buttons.map((item)=> ({
          id: item.id,
          textBtn: item.text,
          color: item.bg_color,
          fontSize: item.font_size,
          visible: item.is_view
        })),
        direction: signageStandBy.row
      })
      setSignageInfo(data);
    })
      .catch((error) => {
        console.log(error);
      })

    // DBからメディアデータの取得
    // mediaClient.get().then((data: any) => {
    //   console.log(data);
    // })
    //   .catch((error) => {
    //     console.log(error);
    //   })
  }, [signageRawData]);

  // DBからテンプレート情報を取得したとき
  useEffect(() => {
    if (signageInfo) {
      const receiveTemplates = new Map();

      const uploadImageTableBase: any = {};
      const uploadImageInfo = {
        id: "",
        logo: null,
        media: null,
      }

      signageInfo.templates.forEach(data => {
        type StateInfoType = {
          stateName: string,
          template: any
        };
        const stateInfo: StateInfoType = {
          stateName: "",
          template: {}
        }

        stateInfo.stateName = data.signage_state;
        stateInfo.template = data;
        // メディアとロゴの画像パスを設定
        if (data.contents_media) {
          stateInfo.template.media_src = data.contents_media.image_url;
        } else {
          stateInfo.template.media_src = "/images/Common/ksin-logo.png";
        }

        if (data.logo_media) {
          stateInfo.template.logo_src = data.logo_media.image_url;
        } else {
          stateInfo.template.logo_src = "/images/Common/ksin-logo.png";
        }

        // レイアウトによりメディアの表示・非表示切り替え
        if ([SignageLayoutList.onlyAvatar].includes(stateInfo.template.layout)) {
          stateInfo.template.is_view_media = false;
        } else {
          stateInfo.template.is_view_media = true;
        }

        receiveTemplates.set(stateInfo.stateName, stateInfo);

        // ロゴ画像とメディアファイル変更のための準備
        uploadImageInfo.id = data.id;
        uploadImageTableBase[stateInfo.stateName] = { ...uploadImageInfo };
      });
      setTemplateTable(new Map(receiveTemplates));
      setUploadImageTable({ ...uploadImageTableBase });
      const current = receiveTemplates.get(signageState).template;
      setRatio({
        w: current.width,
        h: current.height
      });
    }
  }, [signageInfo]);

  // テンプレートまたはシーン選択が変更されたとき
  useEffect(() => {
    if (templateTable) {
      const selected = templateTable.get(signageState).template;
      setCurrentTemplate({ ...selected });
    };
  }, [signageState, templateTable]);


  // レイアウト変更時
  const handleLayoutChange = (e) => {
    const selected = templateTable.get(signageState).template;
    selected.layout = e.target.value;
    // レイアウトによりメディアの表示・非表示切り替え
    if ([SignageLayoutList.onlyAvatar].includes(selected.layout)) {
      selected.is_view_media = false;
    } else {
      selected.is_view_media = true;
    }
    setTemplateTable(new Map(templateTable));
  }

  // 縦横比変更時
  const handleRatioChange = (e) => {
    const pre = { ...ratio };
    setRatio({
      w: e.target.id === "inputWidth" ? e.target.valueAsNumber : pre.w,
      h: e.target.id === "inputHeight" ? e.target.valueAsNumber : pre.h
    });
  }

  const onClickUpdateRatio = () => {
    for (const st of templateTable.values()) {      
      st.template.width = ratio.w;
      st.template.height = ratio.h;
      if(st.stateName === 'Standby'){
        setShowButtonWithLayoutChange(st.template.height - st.template.width >= 0)
      }
    }
    
    setTemplateTable(new Map(templateTable));
  }

  const onHandleChangeSettingButton = (key: string,data: any)=>{
    setSettingButton((prev)=>{
      return {
        ...prev,
        [key]: data
      }
    })
  }
  useLayoutEffect( () => {
    if (userRole === "webReservationUser"){
      setTimeout(()=>{
        setSignageState('Active')
      }, 1000)
    }
  },[])
  return (
    <Flex
      mt="70px"
      ml="20px"
      direction="column"
    >
      <FormProvider {...methods} >
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Flex
            bgColor="white"
            p="20px"
          >
            <Flex
              id="columun01"
              flexDirection="column"
              // w="100%"
            >
              <Flex
                id="template-editor-header"
                h="75px"
                justifyContent="space-between">
                <Flex
                  id="common-config"
                >
                  <Box
                    w="50px"
                    h="50px"
                    minWidth="50px"
                    bg="teal.300"
                    color="white"
                    borderRadius="12px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {
                      image && image != "" ?
                        <Image src={image} w="100%" h="100%" objectFit="cover" borderRadius="12px" /> :
                        <PersonIcon color="inherit" w="90%" h="90%" borderRadius="12px" />
                    }
                  </Box>
                  <Flex
                    id="name-and-affiliation"
                    direction="column"
                    ml="1rem">
                    <Text
                      fontSize="md"
                      color="black"
                      fontWeight="bold"
                      minWidth="100%"
                    >
                      {signageInfo ? signageInfo.name : "サイネージ名"}
                    </Text>
                    <ShowAffiliation
                      storeId={""}
                      storeList={null}
                      companyName={companyName}
                    />
                  </Flex>
                  <Flex
                    id="disp-template-state"
                    flexDirection="column"
                    ml="1rem"
                  >
                    <Text
                      color="gray.500"
                    >選択中シーン:</Text>
                    <Text
                      fontWeight="bold"
                    >{getStateData(signageState)?.dispName}</Text>
                  </Flex>
                  <Flex
                    id="signage-view-width"
                    direction="column"
                    ml="1rem">
                    <Text
                      color="gray.500"
                    >
                      幅:
                    </Text>
                    <Input
                      id="inputWidth"
                      borderRadius="5px"
                      fontSize="sm"
                      type="number"
                      size="sm"
                      width="100px"
                      value={ratio.w}
                      onChange={handleRatioChange}
                    />
                  </Flex>
                  <Flex
                    id="signage-view-height"
                    direction="column"
                    ml="1rem">
                    <Text
                      color="gray.500"
                    >
                      高さ:
                    </Text>
                    <Input
                      id="inputHeight"
                      borderRadius="5px"
                      fontSize="sm"
                      type="number"
                      size="sm"
                      width="100px"
                      value={ratio.h}
                      onChange={handleRatioChange}
                    />
                  </Flex>
                  <Flex
                    alignItems="center"
                  >
                    <Button
                      size="sm"
                      ml="0.5rem"
                      color="white"
                      bg={"teal.300"}
                      _hover={{
                        bg: "teal.200",
                      }}
                      _active={{
                        bg: "teal.400",
                      }}
                      border={"none"}
                      colorScheme='teal'
                      onClick={onClickUpdateRatio}
                    >
                      サイズ更新
                    </Button>
                  </Flex>
                  <Flex
                    id="layout-select"
                    direction="column"
                    ml="1rem">
                    <FormControl className="select">
                      {/* <FormLabel htmlFor="audioSourceValue">レイアウト: </FormLabel> */}
                      <Text
                        color="gray.500"
                      >
                        レイアウト:
                    </Text>
                      <Select
                        id="selectLayout"
                        size="sm"
                        onChange={handleLayoutChange}
                        value={currentTemplate ? currentTemplate.layout : SignageLayoutList.onlyAvatar}
                      >
                        {layoutData.map((layout) =>
                          <option
                            value={layout.name}>{layout.dispName}
                          </option>
                        )}
                      </Select>
                    </FormControl>
                  </Flex>
                </Flex>
                <Flex
                  id="common-config-flex-end"
                  justifyContent="flex-end">
                  <Button
                    fontSize="14px"
                    bg="teal.300"
                    pl="2rem"
                    pr="2rem"
                    h="45"
                    ml="1rem"
                    mb="20px"
                    color="white"
                    mt="20px"
                    _hover={{
                      bg: "teal.200",
                    }}
                    _active={{
                      bg: "teal.400",
                    }}
                    colorScheme='teal'
                    isLoading={isSubmitting}
                    type='submit'
                  >
                    <CheckCircleIcon color="inherit" mr="2" />
              確定
            </Button>
                </Flex>
              </Flex>
              <Flex
                id="streamer-and-edit-buttons">
                {templateTable ?
                  <PixelStreamer
                    wsc={wsc}
                    inTemplateEditor={true}
                    isViewTheirVideo={false}
                    signageState={signageState}
                    createPlayerStateList={[]}
                    isViewSignageConfig={false}
                    templateTable={templateTable}
                    settingButton={settingButton}
                    disableEventTouchButton={true}
                  /> :
                  <Flex>Loading...</Flex>}
                <SignageTemplateEditButtons
                  setIsOpenLogoConfigurator={setIsOpenLogoConfigurator}
                  setIsOpenMediaConfigurator={setIsOpenMediaConfigurator}
                  setIsOpenTelopConfigurator={setIsOpenTelopConfigurator}
                  setIsOpenSettingButton={setIsOpenSettingButton}
                  isStandBy={signageState === SignageStateList.Standby}
                  showButtonWithLayoutChange={showButtonWithLayoutChange}
                />
              </Flex>
            </Flex>
            <Flex
              id="colmun02"
              justifyContent="center"
              w="200px">
              <Flex
                flexDirection="column">
                <Text
                  fontWeight="bold">シーン選択</Text>
                {stateData.map((data) =>
                  <Button
                    fontSize="14px"
                    bg={data.state !== signageState ? "teal.300" : "white"}
                    pl="2rem"
                    pr="2rem"
                    h="45"
                    mb="20px"
                    color={data.state !== signageState ? "white" : "teal.400"}
                    mt="20px"
                    _hover={{
                      bg: data.state !== signageState ? "teal.200" : "white",
                    }}
                    _active={{
                      bg: data.state !== signageState ? "teal.400" : "white",
                    }}
                    border={data.state !== signageState ? "none" : "3px solid"}
                    colorScheme='teal'
                    isDisabled={data.state === signageState}
                    onClick={() => { setSignageState(data.state) }}
                  // isLoading={isSubmitting}
                  >
                    {data.dispName}
                  </Button>)}
              </Flex>
            </Flex>
            <LogoConfigurator
              isOpen={isOpenLogoConfigurator}
              setIsOpen={setIsOpenLogoConfigurator}
              currentTemplate={currentTemplate}
              setTemplateTable={setTemplateTable}
              templateTable={templateTable}
              signageState={signageState}
              uploadImageTable={uploadImageTable}
              setUploadImageTable={setUploadImageTable}
            />
            <TelopConfigurator
              isOpen={isOpenTelopConfigurator}
              setIsOpen={setIsOpenTelopConfigurator}
              currentTemplate={currentTemplate}
              setTemplateTable={setTemplateTable}
              templateTable={templateTable}
              signageState={signageState}
            />
            <MediaConfigurator
              isOpen={isOpenMediaConfigurator}
              setIsOpen={setIsOpenMediaConfigurator}
              currentTemplate={currentTemplate}
              setTemplateTable={setTemplateTable}
              templateTable={templateTable}
              signageState={signageState}
              uploadImageTable={uploadImageTable}
              setUploadImageTable={setUploadImageTable}
            />
          </Flex>
        </form>
      </FormProvider>
      <DrawerSettingButton visible={isOpenSettingButton} onHide={() => setIsOpenSettingButton(false)} settingButton={settingButton} handleChange={onHandleChangeSettingButton} />
    </Flex>
  );
}

export default SignageTemplateUpdate;

