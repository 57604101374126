import { useForm, FormProvider } from 'react-hook-form'
import React, { useEffect } from 'react'
import {
  FormControl,
  Button,
  Spacer,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react'
import InputLoginIdMail from './parts/InputLoginIdMail';
import InputName from './parts/InputName';
import InputImage from './parts/InputImage';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import AlertDialogBasic from '../Common/AlertDialogBasic';
import InputPassword from './parts/InputPassword';
import AlertDialogConfirmPassword from '../Common/AlertDialogConfirmPassword';
import { FormsContentsManager } from '../Common/contents/FormsContentsManager';
import { CommonEscape } from '../util/CommonEscape';
import { UserClient } from '../ApiClient/UserClient';
import { ConvertFormData } from '../util/ConvertFormData';

// Assets
export default function UpdateProfileForm() {
  const history = useHistory();
  const ces = new CommonEscape();
  const [userInfo, setUserInfo] = React.useState(null);
  const [formValues, setFormValues] = React.useState(null);
  const [serverErrors, setServerErrors] = React.useState([]);
  const [isForceSubmit, setIsForceSubmit] = React.useState(false);
  const [isPasswordChange, setIsPasswordChange] = React.useState(false);
  // memo: "targetUserId: "SELF""で自分のユーザーデータを取得する
  const targetUserId: string = "SELF";
  const dialogTemplate = {
    header: "",
    body: "",
  }
  const [dialogContents, setDialogContents] = React.useState(dialogTemplate);
  const client = new UserClient();

  // Dialog
  const [isOpenConfirm, setIsOpenConfirm] = React.useState(false);
  const [isOpenConfirmPassword, setIsOpenConfirmPassword] = React.useState(false);
  const [passwordConfirmError, setPasswordConfirmError] = React.useState(null);
  // const [isOpen, onOpen ] = useDisclosure();

  useEffect(() => {
    if (isForceSubmit) {
      onSubmit(formValues);
    }
  }, [isForceSubmit])

  const getUserCurrent = async () => {
    const sendData = {
      // memo: "targetUserId: "SELF""で自分のユーザーデータを取得する
      targetUserId: targetUserId
    };

    const param = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      body: JSON.stringify(sendData)
    };

    return new Promise((resolve, reject) =>
      fetch(`/api/getUser`, param)
        .then(response => {
          return response.json()
        })
        .then(json => {
          json = ces.valuesUnescape(json);
          console.log(json);
          resolve(json);
        })
        .catch((error) => {
          console.error('Error:', error);
          reject(error);
        })
    )
  }

  useEffect(() => {
    getUserCurrent().then((u: any) => {
      console.log(`Call getUsers().then`);
      let getUserInfo =
      {
        "id": u.userId,
        "loginId": u.loginId,
        "name": u.name,
        "storeId": u.storeId ? u.storeId : 0,
        "role": u.role,
        "image": u.image,
        "isOwnConfig": u.isOwnConfig
      }
      setUserInfo(getUserInfo);
    })
  }, [])

  // 追加モード
  const isAddMode = false;

  // const [] = React.useState(false)

  const {
    // setError,
    formState: { isSubmitting },
  } = useForm()
  const methods = useForm();

  function onSubmit(values: any) {
    // saveValues
    setFormValues(values);

    //error reset
    setServerErrors([]);
    setPasswordConfirmError(null);

    // 対象ユーザーの指定
    values.targetUserId = targetUserId;

    // ログイン情報の変更時は現在のパスワードを再入力させる
    if (!isOpenConfirmPassword) {
      if (isPasswordChange || (values.login_id.length > 0 && values.login_id !== userInfo.login_id)) {
        setIsOpenConfirmPassword(true);
        return null;
      }
    }

    // 確認ダイアログからリトライしたときのフラグ設定
    values.isForceSubmit = isForceSubmit ? 1 : 0;

    // 空文字または変更されていないプロパティは削除
    if (values.login_id === userInfo.login_id || values.login_id === "") {
      delete values.login_id;
    }
    if (values.password === "" || !isPasswordChange) {
      delete values.password;
    }
    if (!values.currentPassword || values.currentPassword === "") {
      delete values.currentPassword;
    }
    if (values.name === userInfo.userName || values.name === "") {
      delete values.name;
    }
    if (values.role === userInfo.role) {
      delete values.role;
    }

    const formData = new ConvertFormData().valuesToFormData(values);
    console.log(formData);
    const param = {
      method: 'POST',
      body: formData
    };

    const addServerError = (errObj: { code: string, msg: string }) => {
      setServerErrors([...serverErrors, errObj]);
      console.log(JSON.stringify(serverErrors));
    }

    return fetch(`/api/updateUser`, param)
      .then(response => response.json())
      .then(json => {
        switch (json.result) {
          case "OK":
            console.log(`Update Success.`);
            //force mode reset
            setIsForceSubmit(false);
            setIsOpenConfirm(false);
            // ログイン情報変更時はログアウト
            if (isOpenConfirmPassword) {
              client.logout().then((result: string) => {
                if (result === "OK") {
                  history.push({ pathname: "/login" });
                }
              });

            } else {
              history.push({ pathname: "/app/service/standby" });
            }
            break;
          case "Confirm":
            console.log(`Update Retry Confirm.`);
            setDialogContents({
              header: json.code,
              body: json.msg
            });
            setIsOpenConfirm(true);
            break;
          case "Error":
            if (parseInt(json.code.replace("SVER-", "")) >= 100) {
              setPasswordConfirmError(json.msg);
              break;
            } else {
              addServerError(
                {
                  code: json.code,
                  msg: json.msg
                }
              );
              break;
            }
          default:
            addServerError(
              {
                code: "SVER-Unexpected",
                msg: "未定義のサーバーエラーが発生しました"
              }
            );
        }
      });
  }

  return (
    <FormProvider {...methods} >
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <AlertDialogBasic
          isOpenConfirm={isOpenConfirm}
          setIsOpenConfirm={setIsOpenConfirm}
          setIsForceSubmit={setIsForceSubmit}
          dialogContents={dialogContents}
        />
        <AlertDialogConfirmPassword
          isOpen={isOpenConfirmPassword}
          setIsOpen={setIsOpenConfirmPassword}
          clickSubmit={methods.handleSubmit(onSubmit)}
          serverError={passwordConfirmError}
        />
        <InputLoginIdMail isAddMode={isAddMode} userInfo={userInfo} />
        <InputPassword
          setIsPasswordChange={setIsPasswordChange}
          isInForm={true}
        />
        <InputName
          isAddMode={isAddMode}
          userInfo={userInfo}
          formInfo={new FormsContentsManager().get("User").name} />
        <Spacer h="30px" />
        <InputImage isAddMode={isAddMode} userInfo={userInfo} />
        <Spacer h="30px" />
        <FormControl
          display="flex"
          justifyContent="flex-end"
          w="600px"
        >
          <Button
            fontSize="14px"
            bg="teal.300"
            pl="2rem"
            pr="2rem"
            h="45"
            mb="20px"
            color="white"
            mt="20px"
            _hover={{
              bg: "teal.200",
            }}
            _active={{
              bg: "teal.400",
            }}
            colorScheme='teal'
            isLoading={isSubmitting}
            type='submit'
          >
            <CheckCircleIcon color="inherit" mr="2" />
          確定
        </Button>
        </FormControl>
        <Alert
          status='error'
          display={serverErrors.length ? "flex" : "none"}
        >
          <AlertIcon />
          <AlertTitle mr={2}>{serverErrors.length ? serverErrors[0].code : ""}</AlertTitle>
          <AlertDescription>{serverErrors.length ? serverErrors[0].msg : ""}</AlertDescription>
        </Alert>
      </form></FormProvider>
  )
}