export const maxGByte = 2;
export const maxSize = maxGByte*1024*1024*1024;

const KB = 1024;
const MB = Math.pow(KB, 2);
const GB = Math.pow(KB, 3);

export const getFileSize = (size: number) => {
  let target = null;
  let unit = 'byte'
  
  if (size >= GB) {
    target = size / GB;
    unit = 'GB';

  } else if (size >= MB) {
    target = size / MB;
    unit = 'MB';

  } else if (size >= KB) {
    target = size / KB;
    unit = 'KB';

  } else if (size < KB) {
    target = size;
    unit = 'Byte';

  } else {
    return '';
  }

  // NOTE: 小数点第三位を切り捨て
  return Math.floor(target * 100) / 100 + unit;
};

export const checkSize = (targetMediaSize: number) => {
  return maxSize >= targetMediaSize ? true : false;
};