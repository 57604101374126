import React, { useEffect, useState } from "react";
// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  AdobexdLogo,
  AtlassianLogo,
  InvisionLogo,
  JiraLogo,
  SlackLogo,
  SpotifyLogo,
} from "components/Icons/Icons.js";
import wsConfig from "../../ws_config.json"
// Assets
import signInImage from "assets/img/signInImage.png";
import ProfileBgImage from "assets/img/ProfileBackground.png";
import { CompanyClient } from "components/ksin/ApiClient/CompanyClient";

function Top() {
  // Chakra color mode
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.400", "white");
  const url = wsConfig.avatarChannelUrl ? wsConfig.avatarChannelUrl : "#";
  const [company, setCompany] = useState(null);

  useEffect(() => {
    // NOTE: ホーム画面起動時に企業名およびアバターch契約数をサーバーから取得する
    const companyClient = new CompanyClient();
    companyClient.get().then((data) => {
      if(data == null)  return;
      setCompany(data);
    }).catch((error) => {
      console.log(error);
    })
  },[]);

  return (
    <Flex direction="column" mt="4rem">
      <Box
        mb={{ sm: "205px", md: "75px", xl: "70px" }}
        borderRadius="15px"
        px="0px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        align="center"
        backgroundColor="white"
        paddingTop="30px"
        paddingLeft="20px"
        paddingBottom="50px"
        width="60%"
      >
          <Text color="black" fontWeight="light" textAlign="left" fontWeight="bold" marginBottom="40px">
            企業情報
          </Text>
          <FormControl textAlign="left">
            <Box ml="50px" mb="20px">
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="normal"
                textAlign="left"
                fontSize="16px"
                fontWeight="bold"
                // margin="0"
                display="inline-block"
                width="200px"
              >
                企業名
              </FormLabel>
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="normal"
                textAlign="left"
                fontSize="16px"
                // fontWeight="bold"
                // margin="0"
                display="inline-block"
                width="200px"
              >
                {company !== null && company.companyName ? company.companyName : ''}
              </FormLabel>
            </Box>
            <Box ml="50px">
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="normal"
                textAlign="left"
                fontSize="16px"
                fontWeight="bold"
                // margin="0"
                display="inline-block"
                width="200px"
              >
                アバター契約数
              </FormLabel>
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="normal"
                textAlign="left"
                fontSize="16px"
                fontWeight="bold"
                // margin="0"
                display="inline-block"
                width="200px"
              >
                {company !== null && company.avatar_channel_max != 0 ? company.avatar_channel_max : 0}ライセンス
              </FormLabel>
              <Link color="gray.300" href={url} isExternal _hover={{textDecoration: "underline"}}>
                アバター契約数を追加
              </Link>
            </Box>
          </FormControl>
      </Box>
    </Flex>
  );
}

export default Top;
