import {escape, unescape} from 'html-escaper';

export class CommonEscape {
    constructor() {
    }
    escape(input: string) {
        return escape(input);
    }
    unescape(input: string) {
        return unescape(input);
    }
    valuesEscape(values: any) {
        let escaped: any = {};
        Object.keys(values).forEach((key) => {
            if(typeof values[key] === "string") {
                escaped[key] = this.escape(values[key])
            } else {
                escaped[key] = values[key];
            }
          });
          return escaped;
    }
    valuesUnescape(values: any) {
        let unEscaped: any = {};
        Object.keys(values).forEach((key) => {
            if(typeof values[key] === "string") {
                unEscaped[key] = this.unescape(values[key])
            } else {
                unEscaped[key] = values[key];
            }
          });
          return unEscaped;
    }
}
