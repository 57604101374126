import { useForm } from 'react-hook-form'
import React, { useEffect } from 'react'
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Link,
  Switch,
  Text,
} from '@chakra-ui/react'
import { useHistory } from 'react-router-dom';

export default function LoginForm(props: any) {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm()

  const history = useHistory();
  const prevState = localStorage.getItem("reservationDataJSON")

  const [serverErrors, setServerErrors] = React.useState([]);
  const [autLoginRes, setAutLoginRes] = React.useState(null);

  const onChangeRemember = (e) => {
    setValue("isRemember", e.target.checked);
  };

  const autoLogin = async () => {
    const sendData = {};

    const param = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      body: JSON.stringify(sendData)
    };

    return new Promise((resolve, reject) =>
      fetch(`/autoLogin`, param)
        .then(response => {
          if (response.status === 200) {
            return response.json()
          } else {
            // setTimeout(autoLogin, 3000);
            return null;
          }
        })
        .then(json => {
          console.log(json);
          resolve(json);
        })
        .catch((error) => {
          console.error('Error:', error);
          reject(error);
        })
    )
  }

  //URLトークンを開く
  const searchParams = new URLSearchParams(location.search);
  const urlToken = searchParams.get('token');

  useEffect(() => {
    console.log("mounted LoginForm.");
    // localStorage.setItem("token", urlToken)
    const fetchToken = (token) => {
      console.log(`[webReservation]called fetchToken token: ${token}`);
      const sendData = { token: token };
      const param = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        body: JSON.stringify(sendData)
      };
      return fetch('/token/login', param)
        .then(response => {
          console.log(`[webReservation]fetchToken received response: ${response}.`);
          return response.json()
        })
        .then((json: any) => {
          console.log(`[webReservation]fetchToken parsed json: ${json}.`);
          if (json) {
            // 中身チェック
            Object.keys(json).forEach((key) => {
              console.log(`[webReservation]json.key: ${key}, value: ${json[key]}`);
            });

            const result = json.result
            console.log(`[webReservation]response.result: ${result}`);
            const reservDate = new Date(json.date)
            const currDate = new Date();
            if (result == "OK") {
              console.log("[webReservation]/token/login result OK.");
              if (reservDate > currDate) {
                console.log("[webReservation]move to /waiting-room");
                history.push({
                  pathname: "/waiting-room",
                  state: { token: urlToken }
                });
              } else if (currDate >= reservDate) {
                console.log("[webReservation]move to /app/signage/view");
                history.push({
                  pathname: "/app/signage/view",
                  state: { date: reservDate, token: urlToken }
                });
              }
            } else if (json.msg === "jwt expired") {
              const con = confirm("トークンの有効期限が切れました")
              con ? history.push({ pathname: "/login" }) : ""
            }
          } else {
            console.log(`[webReservation]response parse json failed.`);
            console.log(json)
          }
        }
        )
    }
    // setTimeout(() => {
    //   urlToken ? fetchToken(urlToken) : ""
    // }, 1000)
    // fetchToken(urlToken);

    const getCookieArray = () => {
      let arr = new Array();
      if (document.cookie != '') {
        var tmp = document.cookie.split('; ');
        for (let i = 0; i < tmp.length; i++) {
          let data = tmp[i].split('=');
          arr[data[0]] = decodeURIComponent(data[1]);
        }
      }
      return arr;
    };

    setValue("isRemember", true);
    // 別タブでログインされたことによる遷移であれば自動ログインしない
    if (!props.isAutoLogin) {
      // 自動ログインしない
      console.log(`not AutoLogin.`);
      // location.reload();
    } else if (urlToken) {
      fetchToken(urlToken);
    } else if (getCookieArray()['reservation_token']) {
      // 自動ログイン試行
      const tryTokenLogin = () => {
        const token = getCookieArray()['reservation_token'];
        if(token) {
          location.href = `/test/login?token=${token}`;
          setTimeout(() => {
            if(window.location.pathname.includes("/login")){
              tryTokenLogin();
            }
          }, 3000);
        }
      }
      tryTokenLogin();
    } else {
      // 自動ログイン試行
      const tryAutoLogin = () => {
        autoLogin().then(res => {
          if (res) {
            setAutLoginRes(res);
          } else {
            setTimeout(tryAutoLogin, 3000);
          }
        })
      }
      tryAutoLogin();
    }
  }, [])

useEffect(() => {
  if (autLoginRes) {
    const res = autLoginRes;
    switch (res.result) {
      case "OK":
        // ホーム画面へ遷移
        if (res.role) {
          switch (res.role) {
            case "companyAdmin":
              history.push({ pathname: "/app/service/standby", state: { isOperator: false } });
              break;
            case "operator":
              // TODO: 接客スタンバイ画面に変更
              history.push({ pathname: "/app/service/standby", state: { isOperator: true } });
              break;
            case "signage":
              history.push({ pathname: "/app/signage/view" });
              break;
            case "systemAdmin":
              history.push({ pathname: "/test/register" });
              break;
          }
        }
        break;
      case "Temporary":
        // パスワード変更画面へ遷移
        history.push({ pathname: "/test/passwordSetting" });
        break;
      case "Error":
        addServerError(
          {
            code: res.code,
            msg: res.msg
          }
        );
        break;
      default:
        addServerError(
          {
            code: "SVER-Unexpected",
            msg: "未定義のサーバーエラーが発生しました"
          }
        );
    }
  }
}, [autLoginRes]);

const addServerError = (errObj) => {
  setServerErrors([...serverErrors, errObj]);
  console.log(JSON.stringify(serverErrors));
}

function onSubmit(values) {
  //error reset
  setServerErrors([]);

  const sendData = {
    username: values.name,
    password: values.password,
    isRemember: values.isRemember
  }
  console.log(sendData);
  const param = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    body: JSON.stringify(sendData)
  };

  return fetch(`/login`, param)
    .then(response => response.json())
    .then(json => {
      switch (json.result) {
        case "OK":
          // ホーム画面へ遷移
          if (prevState) {
            history.push({ pathname: "/app/reservation/calendar" });
          }
          else if (json.role) {
            switch (json.role) {
              case "companyAdmin":
                history.push({ pathname: "/app/service/standby", state: { isOperator: false } });
                break;
              case "webReservationUser":
                history.push({ pathname: "/app/reservation/home" });
                break;
              case "operator":
                // TODO: 接客スタンバイ画面に変更
                history.push({ pathname: "/app/service/standby", state: { isOperator: true } });
                break;
              case "signage":
                history.push({ pathname: "/app/signage/view" });
                break;
              // case "webReservationSignage":
              //   history.push({ pathname: "/app/signage/view" });
              //   break;
              case "systemAdmin":
                history.push({ pathname: "/test/register" });
                break;
            }
          }
          break;
        case "Temporary":
          // パスワード変更画面へ遷移
          history.push({ pathname: "/test/passwordSetting" });
          break;
        case "Error":
          addServerError(
            {
              code: json.code,
              msg: json.msg
            }
          );
          break;
        default:
          addServerError(
            {
              code: "SVER-Unexpected",
              msg: "未定義のサーバーエラーが発生しました"
            }
          );
      }
    });
}

return (
  <form onSubmit={handleSubmit(onSubmit)}>
    <Text fontSize="lg" fontWeight="bold">
      ログイン
      </Text>
    <FormControl isInvalid={!!errors.name} mb="36px">
      <FormLabel ms="4px" fontSize="sm" fontWeight="normal" htmlFor='name'>
        ログインID
        </FormLabel>
      <Input
        borderRadius="15px"
        fontSize="sm"
        type="text"
        placeholder="Your ID"
        size="lg"
        id='name'
        {...register('name', {
          required: '必須項目です',
          // minLength: { value: 1, message: 'Minimum length should be 4' },
        })}
      />
      <FormErrorMessage>
        {errors.name && errors.name.message}
      </FormErrorMessage>
    </FormControl>
    <FormControl isInvalid={!!errors.password} mb="36px">
      <FormLabel ms="4px" fontSize="sm" fontWeight="normal" htmlFor='password'>
        パスワード
        </FormLabel>
      <Input
        borderRadius="15px"
        fontSize="sm"
        type="password"
        placeholder="Your password"
        size="lg"
        id='password'
        {...register('password', {
          required: '必須項目です',
          minLength: { value: 1, message: 'Minimum length should be 4' },
        })}
      />
      <FormErrorMessage>
        {errors.password && errors.password.message}
      </FormErrorMessage>
    </FormControl>
    <FormControl display="flex" alignItems="center">
      <Switch
        id="isRemember"
        colorScheme="teal"
        me="10px"
        defaultIsChecked={true}
        onChange={onChangeRemember}
      />
      <FormLabel
        htmlFor="isRemember"
        mb="0"
        ms="1"
        fontWeight="normal"
      >
        次回から自動ログインする
        </FormLabel>
    </FormControl>
    <FormControl>
      <Button
        fontSize="14px"
        bg="teal.300"
        w="100%"
        h="45"
        mb="20px"
        color="white"
        mt="20px"
        _hover={{
          bg: "teal.200",
        }}
        _active={{
          bg: "teal.400",
        }}
        colorScheme='teal'
        isLoading={isSubmitting}
        type='submit'
      >
        ログイン
        </Button>
      <Text
        color="red.400"
        display={serverErrors.length ? "flex" : "none"}
        mb="2rem"
      >
        {serverErrors.length ? serverErrors[0].msg : ""}
      </Text>
      <Link color="teal.500" href="/test/passwordReset" _hover={{ textDecoration: "underline" }}>
        パスワードをお忘れの方はこちら
        </Link>
      <Text
        marginBottom="20px"
        color="gray.300"
      >
        _____________________________________________________
        </Text>
      {/* <Text
        color="gray.300"
      >
        新規ユーザー登録停止中
        </Text> */}
      <Link
          color="teal.500"
          href="/test/register"
          _hover={{ textDecoration: "underline" }}
          >
          新規アカウント登録はこちら
        </Link>
    </FormControl>
  </form>
)
}