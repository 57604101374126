import {
    Link,
} from '@chakra-ui/react'
import React, { useCallback, useEffect } from 'react';
import { useHistory } from "react-router-dom";

const ShowSignageOperators = (props: {
    signageId: string,
    signageUsers: any[],
    companyName?: string,
}) => {
    const history = useHistory();
    const onClickOperators = useCallback((params) => {
        history.push({ pathname: "/app/signage/operator", state: params })},
         [history]);

    const [users, setUsers] = React.useState([]);

    const getUserNameList = (chUserList: any[]) => {
        // ch使用ユーザーを先頭から何名表示するか
        const dispUserNum = 3;
        if (chUserList.length > 0) {
            const users = chUserList.slice(0, dispUserNum);
            let userNameList = [];
            users.forEach(u => {
                userNameList.push(u.name);
            })
            let dispUsers = userNameList.join("、");
            if (chUserList.length > dispUserNum) dispUsers = `${dispUsers}...`
            return dispUsers;
        } else {
            return "なし";
        }
    }

    useEffect(() => {
        if (props.signageUsers) setUsers(props.signageUsers);
    }, [])

    return (
        <Link 
            color="teal.500" 
            textDecoration="underline" 
            onClick={() => onClickOperators({
                "id": props.signageId,
                "companyName": props.companyName ? props.companyName : '',
            })}>
            {getUserNameList(users)}
        </Link>
    )
}

export default ShowSignageOperators;
