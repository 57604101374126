import { Text, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, Button, FormControl, FormErrorMessage, Input } from '@chakra-ui/react'
import React from 'react'
// import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form';

const AlertDialogConfirmPassword = (props: any) => {
    // const [isOpen, onOpen, onClose ] = useDisclosure()
    const { register, formState: { errors } } = useFormContext();
    // const [isOpen, setIsOpen] = React.useState(false)
    const onClose = () => {
        // setIsOpen(false);
        props.setIsOpen(false);
    }
    const cancelRef = React.useRef()
    const onConfirm = () => {
        props.clickSubmit();
    }

    // useEffect(() => {
    //     if (props.isOpenConfirmPassword) {
    //         setIsOpen(true)
    //     }
    // }, [props.isOpenConfirmPassword])


    return (
        <AlertDialog
            isOpen={props.isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}

        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        パスワード再入力
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        <Text>
                            ログイン情報を変更する前に、現在のパスワードを再入力してください。
                    </Text>
                        <FormControl isInvalid={!!errors.currentPassword}>
                            <Input
                                borderRadius="15px"
                                mb="36px"
                                fontSize="sm"
                                type="password"
                                size="lg"
                                id='currentPassword'
                                placeholder='password'
                                {...register('currentPassword', {
                                    required: props.isOpen? '必須項目です' : false,
                                })}
                            />
                            <FormErrorMessage>
                                {errors.currentPassword && errors.currentPassword.message}
                            </FormErrorMessage>
                            <Text color="red">{props.serverError}</Text>
                        </FormControl>
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            キャンセル
              </Button>
                        <Button colorScheme='blue' onClick={onConfirm} ml={3}>
                            はい
              </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}

export default AlertDialogConfirmPassword