import { Box, Button, Flex, Switch } from "@chakra-ui/react";
import { SettingButtonItem } from "views/Pages/Users/SignageTemplateUpdate";
import { BiEditAlt } from 'react-icons/bi'

const ItemSettingButton = ({
  item,
  isEdited,
  handleSelectedItem,
  handleChangeSettingsItems,
  isVertical,
}:{
  item: SettingButtonItem,
  isEdited: boolean,
  isVertical: boolean,
  handleSelectedItem: (status:  boolean)=> void,
  handleChangeSettingsItems: (data: SettingButtonItem)=> void
}) => {
  const { textBtn, color, visible } = item;
  return (
    <Flex
      alignItems={"center"}
      justifyContent={"space-between"}
      direction={isVertical ? "row" : "column"}
      width={isVertical ? '240px' : 'full'}
    >
      <Button
        width={isVertical ? 150 : "full"}
        background={color}
        textColor={"white"}
        rounded="lg"
        fontSize={12}
        height={isVertical ? 30 : "auto"}
        whiteSpace="pre-line"
        paddingY={2}
        paddingX='10px'
        minHeight={isVertical ? 0 : 65}
        cursor='default'
        _hover={{background: color}}
      >
        {textBtn}
      </Button>
      <Flex marginTop={isVertical ? 0 : 2} alignItems='center'>
        <Box
          onClick={() => {
            handleSelectedItem(isEdited);
          }}
          background={isEdited && "gray.200"}
          rounded={"md"}
          p={1}
          marginRight={2}
          cursor={"pointer"}
          width={23}
          height={23}
        >
          <BiEditAlt />
        </Box>
        <Switch
          colorScheme="teal"
          defaultChecked={visible}
          marginTop={1}
          onChange={(e) => {
            handleChangeSettingsItems({
              ...item,
              visible: e.target.checked,
            });
          }}
        />
      </Flex>
    </Flex>
  );
};

export default ItemSettingButton;
