import { ApiClient } from './ApiClient';
export class CharacterClient implements ApiClient {
    constructor() {
    }
    async get(targetId?: string) {
        const sendData = {}
        const param = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(sendData)
        };
        return new Promise((resolve, reject) =>
            fetch(`/api/getCharacter`, param)
                .then(response => response.json())
                .then(json => {
                    resolve(json);
                })
                .catch((error) => {
                    console.error('Error:', error);
                    reject(error);
                })
        )
    }

    async getAll() {
        const param = {
            method: 'GET',
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            }
        };
        return  fetch(`/api/getCharacter/all`, param)
                .then(response => response.json())
                .then(json => {
                    return json.result;
                })
                .catch((error) => {
                    console.error('Error:', error);
                })
    }

    async add() {
    }
    async update(values: any) {
        const formData = new FormData();
        Object.keys(values).forEach((key) => {
            formData.append(key, values[key]);
        });
        const param = {
            method: 'POST',
            body: formData
        };

        return fetch(`/api/updateUser`, param)
            .then(response => {
                const json = response.json();
                return json;
            });
    }

    async passwordReset(values: any) {
        const param = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(values)
        };

        return fetch(`/api/pwResetUser`, param)
            .then(response => {
                const json = response.json();
                return json;
            });
    }

    async delete(targetId: string) {
    }
    async getStatus() {
        const testData = {
            14: "Logout",
            52: "Logout"
        }
        return testData;
    }
    async logout() {
        return fetch(`/api/logout`)
            .then(response => response.json())
            .then(json => {
                return json.result;
            })
            .catch((error) => {
            })
    }
}