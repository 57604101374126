import React, { useCallback, useContext, useEffect, useRef, useLayoutEffect } from 'react'
import { Text, Flex, Button, Select, FormLabel, FormControl, Box } from '@chakra-ui/react';
import Peer from "skyway-js";
import { AppContext } from 'layouts/AppCommons';
import { SignageStateList } from 'views/Pages/Users/SignageTemplateUpdate';
import { Prompt } from 'react-router'
import { CallingAlert } from '../util/CallingAlert';
import { OperatorStateList } from 'views/Pages/Users/OperatorStandby';
import { v4 as uuidv4 } from 'uuid';
import { SignageClient } from '../ApiClient/SignageClient';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import InputField from './InputField';
// import wsConfig from "../../../ws_config.json"

// import { useHistory } from 'react-router-dom';

let localStream;
let theirStream;
// 画面共有モードからの復帰用
// let cameraStream;
let shareStreamerTime = 0;
let savedTheirPeerId = "";

// 呼び出しアラート
const callingAlert = new CallingAlert();

const myVideoSize = {
    w: 640,
    h: 360
}

const saveCapture = async (requestId: string, signageId: string) => {
    const myVideo: any = document.getElementById("my-video");
    const myVideoCanvas: any = document.getElementById("my-video-canvas");
    const canvasCtx = myVideoCanvas.getContext('2d');
    canvasCtx.drawImage(myVideo, 0, 0, myVideoSize.w, myVideoSize.h);

    // サーバー送信
    return new Promise((resolve, reject) =>
        myVideoCanvas.toBlob(blob => {
            const values = {
                requestId: requestId,
                signageId: signageId,
                image: blob
            }
            new SignageClient().addCapture(values)
                .then(json => {
                    resolve(json);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    )
}

// ユーザー権限取得
const getCookieArray = () => {
    let arr = new Array();
    if (document.cookie != '') {
        var tmp = document.cookie.split('; ');
        for (let i = 0; i < tmp.length; i++) {
            let data = tmp[i].split('=');
            arr[data[0]] = decodeURIComponent(data[1]);
        }
    }
    return arr;
};
const role = getCookieArray()['user_role'];

// Assets
export default function VideoCaller(props: any) {
    const { appContext, setAppContext } = useContext(AppContext);

    // 画面内で更新されるデータ
    const [audioSourceValue, setAudioSourceValue] = React.useState("");
    const [audioSourceList, setAudioSourceList] = React.useState([]);
    const [videoSourceValue, setVideoSourceValue] = React.useState("");
    const [videoSourceList, setVideoSourceList] = React.useState([]);
    // const [localStream, setLocalStream] = React.useState(null);
    const [peer, setPeer] = React.useState(null);
    const [theirId, setTheirId] = React.useState(savedTheirPeerId);
    const [isLoadedInputAudio, setIsLoadedInputAudio] = React.useState(false);

    // 最新のtheirIdを参照
    const theirIdRef = useRef("");
    theirIdRef.current = theirId;

    const [, setTheirName] = React.useState("");
    const [myPeerId, setMyPeerId] = React.useState("");
    const [_, setRequestReceiveTime] = React.useState(0);
    const [isLocalStreamReceived, setIsLocalStreamReceived] = React.useState(false);
    const [isShareScreen, setIsShareScreen] = React.useState(false);
    const [isPeerConnected, setIsPeerConnected] = React.useState(false);
    const [closedCallTime, setClosedCallTime] = React.useState(0);
    const [isLoadingVideo, setIsLoadingVideo] = React.useState(false)
    // const [videoPermission, setVideoPermission] = React.useState(false)

    // 呼出ボタンを押された時間を記録
    const [callOfferTime, setCallOfferTime] = React.useState(0);
    const [isReceivedTheirVideo, setIsReceivedTheirVideo] = React.useState(false);

    const [translatedText, setTranslatedText] = React.useState("");

    // callAction待ちフラグ管理
    const isWaitCallActionRef = React.useRef(false);
    const isPeerOpenedRef = React.useRef(true);

    const timer = props.timer

    const sendDevState = (nextState: string) => {
        const wsc = props.wsc.getWs();
        wsc.send(JSON.stringify({
            type: 'devicePermission',
            event: {
                type: "deviceStateTransition",
                operatorState: nextState,
                senderType: "User"
            }
        }));
    }

    useEffect(() => {
        setTimeout(init, 500);

        console.log(`${new Date().toISOString()}[debug]VideoCaller mounted.`);

    }, []);


    // 翻訳後のテキストをビデオ通話相手に送信する
    useEffect(() => {
        if (translatedText && translatedText.length > 3) {
            console.log(`[debug]update translatedText: ${translatedText}`);
            const ws = props.wsc.getWs();
            ws.send(JSON.stringify({
                type: 'stateTransitionEvent',
                event: {
                    type: "talkLog",
                    locale: "en-US",
                    text: translatedText,
                    signageId: props.signageId,
                    senderType: "Signage"
                }
            }));
        }
    }, [translatedText]);

    // webから予約ユーザーの自動声掛けの関数が最後に実行するため　｜ビデオをでた後
    const handleClickVideoLoader = () => {
        setIsLoadingVideo(true)
    }

    // 機械翻訳デモ
    const onSendMessage = (translatedText: string) => {
        // console.log(`from: ${msg.user}, text: ${msg.text}`);
        console.debug(`[debug][VideoCaller]translatedText: ${translatedText}`);
        setTranslatedText(translatedText);
        
        //テスト: AI回答の反映
        props.setMainTelop(translatedText);
    }
    // 受信したtalkLogの監視
    useEffect(() => {
        if (appContext.receiveTalkLog) {
            console.log(`${new Date().toISOString()}[debug][VideoCaller]receiveTalk: ${appContext.receiveTalkLog.text}`);
            const speakerId = appContext.receiveTalkLog.speakerId;
            console.log(`speakerId: ${speakerId}`);

            const myId = getCookieArray()['user_id'];
            // オペレーター画面では自分の発言をPixelStreamer内に表示させる
            if (props.inServiceOperation) {
                if (myId === speakerId) {
                    // 自分の発言をPixelStreamer内に表示
                    props.setMainTelop(`${appContext.receiveTalkLog.text}`);
                } else {
                    // 相手の発言を別枠に表示
                    props.setTheirTalk(`${appContext.receiveTalkLog.text}`);
                }
            } else {
                // サイネージ画面では相手の発言のみ表示させる
                if (myId !== speakerId) {
                    props.setMainTelop(`${appContext.receiveTalkLog.text}`);
                }
            }
        }
    }, [appContext.receiveTalkLog]);

    useLayoutEffect(() => {
        if (role === "webReservationUser" && isLoadingVideo) {
            setTimeout(() => {
                callFromUser(props.signageId);
                console.log(`[debug]mounted useEffect.`)
            }, 2000)
        }
    }, [isLoadingVideo])

    useEffect(() => {
        if (appContext.theirPeerId && appContext.theirPeerId.length) {
            console.log(`${new Date().toISOString()}[debug]VideoCaller appContext.theirPeerId: ${appContext.theirPeerId}`);
            savedTheirPeerId = appContext.theirPeerId;
        }
    }, [appContext.theirPeerId]);

    // サイネージ表示画面での応対リクエスト発信トリガー
    const checkDiffCallOfferTime = useCallback((newTime) => {
        console.log(`${new Date().toISOString()}[debug]VideoCaller pushed call button.`);
        setCallOfferTime((prevState) => {
            console.log(`${new Date().toISOString()}[debug]VideoCaller callOfferTime: ${callOfferTime}`);
            console.log(`${new Date().toISOString()}[debug]VideoCaller props.signageState: ${props.signageState}`);
            console.log(`${new Date().toISOString()}[debug]VideoCaller appContext.signageState: ${appContext.signageState}`);
            console.log(callOfferTime);
            // 受付中以外は応対リクエストを発信しないようにする
            if (props.signageState === SignageStateList.Standby) {
                if (newTime - prevState > 1000) {
                    callOffer();
                    return newTime;
                } else {
                    console.log(`${new Date().toISOString()}[debug]VideoCaller Not transmitting due to short time elapsed since last time.`);
                    return prevState;
                }
            } else {
                console.log(`${new Date().toISOString()}[debug]VideoCaller Not transmitting due to the signage status is not in Standby.`);
                return prevState;
            }
        });
    }, [props.callOfferTime]);

    useEffect(() => {
        if (props.callOfferTime > 0) {
            checkDiffCallOfferTime(props.callOfferTime);
        }
    }, [props.callOfferTime]);

    useEffect(() => {
        console.log(`${new Date().toISOString()}[debug]VideoCaller received theirId: ${theirId}`);
        if (theirId.length) {
            // savedTheirPeerId = theirId;
        }
    }, [theirId]);

    useEffect(() => {
        if (props.inSignage) {
            if (audioSourceValue.length && videoSourceValue.length) {
                setupGetUserMedia();
            }
        } else {
            if (audioSourceValue.length && videoSourceValue.length) {
                setupGetUserMedia();
            }
        }
    }, [audioSourceValue, videoSourceValue]);

    useEffect(() => {
        console.log(myPeerId);
        if (myPeerId.length > 0) {
            informCallId();
        }
    }, [myPeerId]);


    useEffect(() => {
        // if (appContext.signageState === SignageStateList.Standby) {
        console.log(`${new Date().toISOString()}[debug]onReceiveCount appContext.signageState: ${appContext.signageState}`);
        // if (appContext.receiveCount - requestReceiveTime > 1000 && !props.inSignage) {
        if (appContext.receiveCount > 0 && !props.inSignage) {
            setRequestReceiveTime(appContext.receiveCount);

            if (appContext.signagePeerId && appContext.signagePeerId.length > 0 && appContext.signageName) {
                console.log(`${new Date().toISOString()}[debug]PointA call setTheirId(appContext.signagePeerId): ${appContext.signagePeerId}`);
                setTheirId(appContext.signagePeerId);
                setTheirName(appContext.signageName);

            } else {
                console.log(`${new Date().toISOString()}[debug]not open reception request due to appContext.signagePeerId.length is 0.`);
            }
            if (appContext.userPeerId && appContext.userPeerId.length > 0 && appContext.userName) {
                // savedTheirPeerId = appContext.userPeerId;
                console.log(`${new Date().toISOString()}[debug]PointB call setTheirId(appContext.userPeerId): ${appContext.userPeerId}`);
                setTheirId(appContext.userPeerId);
                setTheirName(appContext.userName);
            }
        } else {
            if (!appContext.receiveCount || appContext.receiveCount === 0) {
                console.log(`${new Date().toISOString()}[debug]not open reception request due to appContext.receiveCount is 0.`);
            }
        }
        // }
    }, [appContext.receiveCount]);

    useEffect(() => {
        if (props.inServiceOperation) {
            console.log(`${new Date().toISOString()}[debug]VideoCaller appContext.shouldRecept: ${appContext.shouldRecept}`);
            if (appContext.shouldRecept && appContext.isStartByReceprionRequest) {
                console.log(`${new Date().toISOString()}[debug]VideoCaller callFromUser to : ${appContext.signageId}`);
                if (appContext.signageId !== props.signageId) {
                    peer.destroy();
                    init().then((peerId: any) => {
                        console.log(`${new Date().toISOString()}[debug]VideoCaller callFromUser new peerId : ${peerId}`);
                        callFromUser(appContext.signageId, peerId);
                        props.setSignageId(appContext.signageId); // ここは通っていない
                    }).catch(() => {
                        alert("peer生成に失敗しました");
                    })

                } else {
                    console.log(`${new Date().toISOString()}[debug]VideoCaller callFromUser keep peerId : ${myPeerId}`);
                    callFromUser(appContext.signageId);
                }
            }
        }
    }, [appContext.shouldRecept, appContext.isStartByReceprionRequest,]);

    useEffect(() => {
        if (props.inSignage) {
            if (appContext.userPeerId && appContext.userPeerId.length >= 0) {
                // savedTheirPeerId = appContext.userPeerId;
                console.log(`${new Date().toISOString()}[debug]PointC call setTheirId(appContext.userPeerId): ${appContext.userPeerId}`);
                setTheirId(appContext.userPeerId);
            }
        }
    }, [appContext.userPeerId]);

    useEffect(() => {
        console.log(props.isOverlayStreamer);
    }, [props.isOverlayStreamer]);

    useEffect(() => {
        console.log(`[confirm callRequestTime]`);
        if (props.callRequestTime) {
            console.log(`[callRequestTime]`);
            console.log(`[callRequestTime] props.isMonitoring: ${props.isMonitoring}`);
            if (props.inSignage) {
                console.log(`[callRequestTime] props.signageState: ${props.signageState}`);
                if (props.signageState !== SignageStateList.Active) {
                    // if (theirId.length > 0) {
                    console.log(`[callRequestTime] appContext.changeActiveAfterCall: ${appContext.changeActiveAfterCall}`);
                    if (appContext.changeActiveAfterCall) {
                        // initReceptionStatus();
                        setTimeout(() => {
                            //Note: peer open済みのときのみcallActionを実行する
                            if (isPeerOpenedRef.current) {
                                console.log(`${new Date().toISOString()}[debug]VideoCaller callAction in pointY in already peer opened.`);
                                callAction(theirId);
                                isPeerOpenedRef.current = false;
                            } else {
                                console.log(`${new Date().toISOString()}[debug]VideoCaller set isWaitCallActionRef in pointY.`);
                                isWaitCallActionRef.current = true;
                            }

                            setAppContext((pre) => {
                                pre.isMonitoring = false;
                                pre.signageState = SignageStateList.Active;
                                return { ...pre };
                            });
                        }, 1000);
                        console.log(`[removeMute] TheirVideo`);
                        removeMuteTheirVideo();
                    } else if (appContext.isMonitoring) {
                        muteTheirVideo();
                        //Note: peer open済みのときのみcallActionを実行する
                        if (isPeerOpenedRef.current) {
                            console.log(`${new Date().toISOString()}[debug]VideoCaller callAction in pointX in already peer opened.`);
                            callAction(theirId);
                            isPeerOpenedRef.current = false;
                        } else {
                            console.log(`${new Date().toISOString()}[debug]VideoCaller set isWaitCallActionRef in pointX.`);
                            isWaitCallActionRef.current = true;
                        }
                    }
                    // }
                }
            } else {
                if (props.signageState !== SignageStateList.Active && !isPeerConnected) {
                    //Note: peer open済みのときのみcallActionを実行する
                    if (isPeerOpenedRef.current) {
                        console.log(`${new Date().toISOString()}[debug]VideoCaller callAction in pointZ in already peer opened.`);
                        callAction(theirId);
                        isPeerOpenedRef.current = false;
                    } else {
                        console.log(`${new Date().toISOString()}[debug]VideoCaller set isWaitCallActionRef in pointZ.`);
                        isWaitCallActionRef.current = true;
                    }
                }
            }
        }
    }, [props.callRequestTime, props.isMonitoring, theirId]);

    useEffect(() => {
        console.log("UPDATE localStream.");
    }, [localStream]);

    useEffect(() => {
        if (theirId.length && isReceivedTheirVideo) {
            console.log(`${new Date().toISOString()}[debug]VideoCaller received theirPeerId: ${theirId}`);
            // props.changeVideoCallerStatus(true);
        }
    }, [theirId, isReceivedTheirVideo]);

    useEffect(() => {
        if (props.inServiceOperation) {
            console.log(`signagePeerId: ${appContext.signagePeerId}`);
            if (appContext.signagePeerId && appContext.signagePeerId.length > 0) {
                console.log(`${new Date().toISOString()}[debug]PointD call setTheirId(appContext.signagePeerId): ${appContext.signagePeerId}`);
                setTheirId(appContext.signagePeerId);
            }
        }
    }, [appContext.signagePeerId]);

    const startShareInOperatorView = useCallback(() => {
        setIsShareScreen((prevState) => {
            if (props.isShareScreen) {
                shareScreen();
            } else if (prevState) {
                console.log(isShareScreen);
                closeShareScreen();
            }
            return props.isShareScreen;
        });
    }, [props.isShareScreen]);

    useEffect(() => {
        if (!props.inSignage) {
            console.log(props.isShareScreen);
            startShareInOperatorView();
        } else {
            setIsShareScreen(props.isShareScreen);
            if (!props.isShareScreen && shareStreamerTime > 0) {
                removeMuteTheirVideo();
                theirStream = null;
                shareStreamerTime = 0;
                setAppContext((pre) => {
                    pre.shareStream = null;
                    return { ...pre }
                })
            }
        }
    }, [props.isShareScreen]);

    useEffect(() => {
        if (props.inSignage && isShareScreen) {
            // setShareStreamerTime(new Date().getTime());
            shareStreamerTime = new Date().getTime();
            // 共有コンテンツ選択中はミュート
            muteTheirVideo();
            console.log(`[Mute] TheirVideo`);
        }
    }, [isShareScreen]);

    // セレクトボックスの変更ハンドラー
    const handleInputChange = (e) => {
        const target = e.target;
        const targetId = target.id;
        switch (targetId) {
            case "audioSourceValue":
                setAudioSourceValue(target.value);
                break;
            case "videoSourceValue":
                setVideoSourceValue(target.value);
                break;
        }
        // setupGetUserMedia();
    }

    // サーバ側から移植
    const GenerateId = (length) => {
        let newId = "";

        const lowerLetters = "abcdefghijklmnopqrstuvwxyz";
        const upperLetters = lowerLetters.toUpperCase();
        const numbers = '0123456789';
        const symbols = "-"
        const material = lowerLetters + upperLetters + numbers + symbols;

        for (let i = 0; i < length; i++) {
            newId += material.charAt(Math.floor(Math.random() * material.length));
        }

        console.log(`newId generated: ${newId}`);
        return newId
    }

    const setupGetUserMedia = () => {
        const videoConf = props.inSignage ? { deviceId: { exact: videoSourceValue } } : false;
        let constraints = {
            audio: { deviceId: { exact: audioSourceValue } },
            video: videoConf
        };

        // if (localStream) {
        //     setLocalStream(null);
        // }
        navigator.mediaDevices.getUserMedia(constraints)
            .then(function (stream) {
                // $('#myStream').get(0).srcObject = stream;
                localStream = stream;

                // 成功時にvideo要素にカメラ映像をセットし、再生
                const videoElm: any = document.getElementById('my-video');
                videoElm.srcObject = stream;
                videoElm.play();
            }).catch(function (error) {
                console.error('mediaDevice.getUserMedia() error in setupGetUserMedia:', error);
                return;
            });
    }

    // イベントリスナを設置する関数
    const setEventListener = (mediaConnection) => {
        mediaConnection.on('stream', stream => {
            console.log(`${new Date().toISOString()}[debug]mediaStream received.`);
            // video要素にカメラ映像をセットして再生
            const videoElm: any = document.getElementById('their-video')
            videoElm.srcObject = stream;
            videoElm.addEventListener('loadeddata', () => {
                videoElm.play();
            });

            // 接続済みフラグの設定
            setIsPeerConnected(true);
            theirStream = stream;
            if (shareStreamerTime > 0) {
                setShareStream();
            }

            // オペレーター画面の場合準備完了フラグ更新
            if (props.inServiceOperation) {
                props.changeVideoCallerStatus(true);
                setIsReceivedTheirVideo(true);
                // サイネージ状態がStandbyで応対リクエスト応答から遷移した場合は会話中に移行させる
                if (props.signageState === SignageStateList.Standby && appContext.isStartByReceprionRequest) {
                    callFromUser(props.signageId);
                }

            }
        });
        console.log(mediaConnection)

        mediaConnection.on("close", () => {
            console.log(`${new Date().toISOString()}[debug]VideoCaller mediaConnection closed.`);
        });
    }

    const setShareStream = () => {
        // 画面共有時の表示のためストリームをappContextにも格納
        console.log(`${new Date().toISOString()}[debug]VideoCaller setShareStream() called.`);
        if (props.inSignage && theirStream) {
            setAppContext((pre) => {
                pre.shareStream = theirStream;
                return { ...pre }
            })
            // appContext.shareStream = theirStream;
            // setAppContext({ ...appContext });
            // 共有コンテンツ受信時にミュート解除
            removeMuteTheirVideo();
        }
    }

    // 相手側ビデオのミュート解除する
    const removeMuteTheirVideo = () => {
        const videoElm: any = document.getElementById('their-video')
        videoElm.muted = false;
        videoElm.play();
    }

    // 相手側ビデオをミュートする
    const muteTheirVideo = () => {
        const videoElm: any = document.getElementById('their-video')
        videoElm.muted = true;
        videoElm.play();
    }


    // export default {
    const init = async () => {
        // const httpsPort = ":4438";
        setIsLocalStreamReceived(false);
        // appContext.onClosedCallTime = 0;
        // setAppContext({ ...appContext });
        const peerId = GenerateId(16);
        setMyPeerId(peerId);
        console.log(`${new Date().toISOString()}[debug]VideoCaller setMyPeerId: ${peerId}`);
        // カメラ映像取得
        const isGetVideo =
            props.inSignage
                ? {
                    width: 1280,
                    height: 720
                }
                : false;

        const audioPromise = await navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                getMediaDeviceList();
                // 着信時に相手にカメラ映像を返せるように、グローバル変数に保存しておく
                // setLocalStream(stream);
                localStream = stream;
                let audioTracks = localStream.getAudioTracks();
                console.log(`${new Date().toISOString()}[debug]inSignage: ${props.inSignage}`);
                console.log(`${new Date().toISOString()}[debug]VideoCaller their-video audioTracks.length: ${audioTracks.length}`);
                setIsLocalStreamReceived(true);
                sendDevState("update")
            }).catch(error => {
                // 失敗時にはエラーログを出力
                console.error('mediaDevice.getUserMedia() error in audioPromise:', error);
                return;
            });

        const videoPromise = await navigator.mediaDevices.getUserMedia({ video: isGetVideo, audio: true })
            .then(stream => {
                console.log(`${new Date().toISOString()}[debug]video stream received.`);
                getMediaDeviceList();
                // 着信時に相手にカメラ映像を返せるように、グローバル変数に保存しておく
                // setLocalStream(stream);
                sendDevState("update")
                localStream = stream;
                // let audioTracks = localStream.getAudioTracks();
                // console.log(`${new Date().toISOString()}[debug]inSignage: ${props.inSignage}`);
                // console.log(`${new Date().toISOString()}[debug]VideoCaller their-video audioTracks.length: ${audioTracks.length}`);
                setIsLocalStreamReceived(true);

            }).catch(error => {
                // 失敗時にはエラーログを出力
                console.error('mediaDevice.getUserMedia() error in videoPromise:', error);
                return;
            });
        console.log(videoPromise, audioPromise)

        //Peer作成
        const sendData = {
            peerId: peerId
        }

        console.log(sendData);
        const param = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(sendData)
        };

        return new Promise((resolve, reject) =>
            fetch(`/api/authVideoCall`, param)
                .then(response => response.json())
                .then(json => {
                    onGetCredential(json, peerId);
                    resolve(peerId);
                })
                .catch((error) => {
                    alert('Peer Authentication Failed');
                    reject(error);
                })
        )
    }

    const onGetCredential = (credential, peerId) => {
        console.log(`${new Date().toISOString()}[debug]VideoCaller call onGetCredential()`);
        console.log(`${new Date().toISOString()}[debug]VideoCaller credential: ${credential}`);
        console.log(`${new Date().toISOString()}[debug]VideoCaller peerId: ${peerId}`);
        setPeer(new Peer(peerId, {
            key: 'f71fe268-f9c4-494a-bd67-859101c742b4',
            credential: credential
        }));
    }

    useEffect(() => {
        console.log(`${new Date().toISOString()}[debug]VideoCaller call useEffect for [peer, isLocalStreamReceived, audioSourceList]`);
        console.log(`${new Date().toISOString()}[debug]VideoCaller peer: ${peer}`);
        console.log(`${new Date().toISOString()}[debug]VideoCaller isLocalStreamReceived: ${isLocalStreamReceived}`);
        console.log(`${new Date().toISOString()}[debug]VideoCaller audioSourceList.length: ${audioSourceList.length}`);
        if (peer && isLocalStreamReceived && audioSourceList.length) {
            setPeerEvent();
            setIsLoadedInputAudio(true);
        }
    }, [peer, isLocalStreamReceived, audioSourceList, appContext.deviceState]);

    const setPeerEvent = () => {
        console.log(`${new Date().toISOString()}[debug]VideoCaller call setPeerEvent()`);
        if (!props.inSignage && isLocalStreamReceived && audioSourceList.length) {
            setTimeout(monitoringRequest, 500);
        }
        peer.on('open', function () {
            console.log(`${new Date().toISOString()}[debug]VideoCaller peer opened.`);
            isPeerOpenedRef.current = true;

            //Note: peer open時にcallAction待ちフラグがtrueなら実行する
            if (isWaitCallActionRef.current) {
                setTheirId((pre_theirId) => {
                    console.log(`[debug]VideoCaller peer callAction(${pre_theirId}) when peer opened.`);
                    callAction(pre_theirId);
                    isWaitCallActionRef.current = false;
                    return pre_theirId;
                })
            } else {
                console.log(`[debug]VideoCaller peer opened before pointX.`);
            }
        });
        //着信処理
        peer.on('call', mediaConnection => {
            mediaConnection.answer(localStream);
            console.log(`${new Date().toISOString()}[debug]VideoCaller peer received call.`);
            if (mediaConnection) {
                setEventListener(mediaConnection);
            }
        });

        // errorイベント
        peer.on('error', err => {
            console.error(err.message);
            console.log(`${new Date().toISOString()}[debug]VideoCaller peer error.`);
            location.reload();
        });

        // closeイベント
        peer.on('close', () => {
            console.log(`${new Date().toISOString()}[debug]VideoCaller peer closed.`);
        });
    }

    const getMediaDeviceList = () => {
        navigator.mediaDevices.enumerateDevices()
            .then(function (deviceInfos) {
                const audioList = [];
                const videoList = [];
                for (let i = 0; i !== deviceInfos.length; ++i) {
                    let deviceInfo = deviceInfos[i];
                    if (deviceInfo.kind === 'audioinput') {
                        audioList.push(deviceInfo);
                    } else if (deviceInfo.kind === 'videoinput') {
                        videoList.push(deviceInfo);
                    }
                }
                setAudioSourceList(audioList);
                setAudioSourceValue(audioList[0].deviceId);
                console.log(`audio: ${audioSourceValue}`);
                if (props.inSignage) {
                    setVideoSourceList(videoList);
                    setVideoSourceValue(videoList[0].deviceId);
                    console.log(`video: ${videoSourceValue}`);
                }

                // setupGetUserMedia();
            }).catch(function (error) {
                console.error('mediaDevices.enumerateDevices() error:', error);
                return;
            });
    }

    // 画面共有
    const shareScreen = () => {
        // 画面共有開始を通知
        const ws = props.wsc.getWs();
        ws.send(JSON.stringify({
            type: 'stateTransitionEvent',
            event: {
                type: "shareScreen",
                signageId: props.signageId,
                senderType: "Signage"
            }
        }));

        const mediaDevices: any = navigator.mediaDevices;
        mediaDevices.getDisplayMedia({ video: true, audio: true })
            .then(stream => {
                // マイク音声のトラックを追加
                let audioTracks = localStream.getAudioTracks();
                stream.addTrack(audioTracks[0]);

                // 成功時にvideo要素にカメラ映像をセットし、再生
                const videoElm: any = document.getElementById('my-video');
                videoElm.srcObject = stream;
                videoElm.play();
                // this.getMediaDeviceList();


                // 着信時に相手にカメラ映像を返せるように、グローバル変数に保存しておく
                localStream = stream;

                // 画面共有時の表示のためストリームをappContextにも格納(オペレーター側)
                if (!props.inSignage) {
                    // appContext.shareStream = stream.clone();
                    setAppContext((pre) => {
                        pre.shareStream = stream;
                        return { ...pre }
                    })
                }

                // 自動で再呼び出し
                console.log(`${new Date().toISOString()}[debug]peer.call in shareScreen`);
                peer.call(savedTheirPeerId, localStream); // 通ってない
            }).catch(error => {
                // 失敗時にはエラーログを出力
                console.error('mediaDevice.getDisplayMedia() error:', error);
                return;
            });
    }

    const closeShareScreen = () => {
        localStream.getTracks().forEach((track) => {
            track.stop();
        })

        // 画面共有終了を通知
        const ws = props.wsc.getWs();
        if (ws && peer) {
            ws.send(JSON.stringify({
                type: 'stateTransitionEvent',
                event: {
                    type: "closeShareScreen",
                    signageId: props.signageId,
                    senderType: "Signage"
                }
            }))

            const videoConf = props.inSignage ? { deviceId: { exact: videoSourceValue } } : false;
            let constraints = {
                audio: { deviceId: { exact: audioSourceValue } },
                video: videoConf
            };

            // if (localStream) {
            //     setLocalStream(null);
            // }
            navigator.mediaDevices.getUserMedia(constraints)
                .then(function (stream) {
                    localStream = stream;

                    // 成功時にvideo要素にカメラ映像をセットし、再生
                    const videoElm: any = document.getElementById('my-video');
                    videoElm.srcObject = stream;
                    videoElm.play();
                    // 自動で再呼び出し
                    console.log(`${new Date().toISOString()}[debug]peer.call in closeShareScreen`);
                    peer.call(savedTheirPeerId, localStream);

                }).catch(function (error) {
                    console.error('mediaDevice.getUserMedia() error:', error);
                    return;
                });
        }
    }

    // ビデオ通話発信
    const callAction = (theirId) => {
        console.log(`${new Date().toISOString()}[debug]VideoCaller callAction(${theirId})`);
        callingAlert.stop();

        if (theirId && theirId.length && peer) {
            if (appContext.signageState !== SignageStateList.Active || !isPeerConnected) {
                console.log(`${new Date().toISOString()}[debug]VideoCaller peer.call in callAction, theirPeerId: ${theirId}`);
                const mediaConnection = peer.call(theirId, localStream);
                if (mediaConnection) {
                    setEventListener(mediaConnection);
                }
            }
        } else {
            console.log(`${new Date().toISOString()}[debug]VideoCaller RETRY callAction(${theirIdRef.current})`);
            setTimeout(() => {
                //Note: peer open済みのときのみcallActionを実行する
                if (isPeerOpenedRef.current) {
                    console.log(`${new Date().toISOString()}[debug]VideoCaller callAction in pointW in already peer opened.`);
                    callAction(theirIdRef.current);
                    isPeerOpenedRef.current = false;
                } else {
                    console.log(`${new Date().toISOString()}[debug]VideoCaller set isWaitCallActionRef in pointW.`);
                    isWaitCallActionRef.current = true;
                }
            }, 3000);
        }
    }

    // ビデオ通話終了

    const closeSignage = () => {
        if (role === "webReservationUser") {
            const ws = props.wsc.getWs();
            ws.send(JSON.stringify({
                type: 'serviceCloseForWRU',
                event: {
                    type: "serviceCloseForWRU",
                    senderType: "User",
                    signageId: props.signageId,
                    userPeerId: myPeerId
                }
            }))
        }
    }
    const closeVideoCall = (reConnect?: boolean) => {
        initReceptionStatus();
        // サイネージに通知
        if (!props.inSignage) {

            let eventType;

            if (appContext.shouldRecept === false && appContext.signageState !== SignageStateList.Active) {
                console.log(`[debug]NotRequired Reload in Signage`);
                eventType = "serviceClosedNotReloadSignage"
            } else {
                eventType = "serviceClosed";
                const ws = props.wsc.getWs();
                if (ws.readyState === WebSocket.OPEN) {
                    ws.send(JSON.stringify({
                        type: 'stateTransitionEvent',
                        event: {
                            type: eventType,
                            senderType: "User",
                            signageId: props.signageId,
                            userPeerId: myPeerId
                        }
                    }));
                } else {
                    // Handle the case where the WebSocket is not in the "OPEN" state yet.
                    console.error("WebSocket is not in the OPEN state.");
                }
            }

            // peer ? peer.destroy() : []
            if (peer) {
                peer.destroy();
            }
            if (reConnect) {
                setTimeout(() => {
                    location.reload();
                }, 2000);
            }
        } else {
            console.log(`[debug]closeVideoCall() called in Signage`);
            location.reload();
        }
    }
    //WebReservationUserの電話終了
    function closeVideoCallForWRU() {
        closeVideoCall(false)
        window.location.href = "/app/reservation/home"
        closeSignage()
    }

    useEffect(() => {
        if (timer && timer > 0) {
            //  
            setTimeout(() => {
                closeVideoCall(false)
                window.location.href = "/app/reservation/home"
            }, timer)
        }
        console.log(`[debug]point timer useEffect.`);
    }, [timer])
    // 通話状態の初期化
    const initReceptionStatus = () => {
        setIsLocalStreamReceived(false);
        setAppContext((pre) => {
            pre.shareStream = theirStream;
            pre.signagePeerId = "";
            pre.userPeerId = "";
            pre.receiveCount = 0;
            pre.receiveCallfromUser = 0;
            if (role !== "webReservationUser") {
                pre.signageState = SignageStateList.Standby;
            }
            pre.isStartByReceprionRequest = false;
            pre.monitoringSignageId = "";
            pre.lastUseCharacter = null;
            // pre.shouldRecept = false;
            return { ...pre };
        })

        // 接続済みフラグの設定
        setIsPeerConnected(false);
    }

    // 応答キャンセル
    // const sendRejectRequest = () => {
    //     setIsLocalStreamReceived(false);
    //     setAppContext((pre) => {
    //         pre.shareStream = null;
    //         pre.signagePeerId = "";
    //         pre.userPeerId = "";
    //         pre.receiveCount = 0;
    //         pre.receiveCallfromUser = 0;
    //         pre.signageState = SignageStateList.Standby;
    //         return {...pre};
    //     })

    //     // 接続済みフラグの設定
    //     setIsPeerConnected(false);

    //     // サイネージに通知
    //     if (!props.inSignage) {
    //         const ws = props.wsc.getWs();
    //         ws.send(JSON.stringify({
    //             type: 'stateTransitionEvent',
    //             event: {
    //                 type: "rejectRequest",
    //                 senderType: "User",
    //                 signageId: props.signageId,
    //                 userPeerId: myPeerId
    //             }
    //         }));
    //         peer.destroy();
    //         // if (reConnect) {
    //         //     setTimeout(() => {
    //         //         location.reload();
    //         //     }, 2000);
    //         // }
    //     } else {
    //             // location.reload();
    //     }
    // }




    const updateClosedCallTime = useCallback(() => {
        if (props.inSignage && appContext.onClosedCallTime >= 0) {
            setClosedCallTime((prevState) => {
                console.log(closedCallTime);
                if (appContext.onClosedCallTime > prevState) {
                    closeVideoCall();
                    muteTheirVideo();
                    console.log(`[Mute] TheirVideo`);
                }
                return appContext.onClosedCallTime;
            });
        }
    }, [appContext.onClosedCallTime]);

    useEffect(() => {
        updateClosedCallTime();
    }, [appContext.onClosedCallTime]);

    // 応対リクエスト応答


    // 応対リクエスト(サイネージからオペレーター)
    const callOffer = async () => {
        console.log(`${new Date().toISOString()}[debug]VideoCaller callOffer()`);
        const requestId = `req_${uuidv4()}`;
        await saveCapture(requestId, props.signageId);

        const ws = props.wsc.getWs();
        ws.send(JSON.stringify({
            type: 'stateTransitionEvent',
            event: {
                type: "receptionRequest",
                senderType: "Signage",
                signagePeerId: myPeerId,
                signageId: props.signageId,
                requestId: requestId,
                touchButton: props.button
            }
        }))
        setAppContext((pre) => {
            pre.signageState = SignageStateList.Calling;
            return { ...pre };
        })
    }

    // 応対リクエスト時カメラ映像をサーバーに送信
    const myVideoRef = useRef<HTMLVideoElement>(null);


    useEffect(() => {
        if (props.isViewConfigurator) {
            myVideoRef.current?.load();
            myVideoRef.current?.play();
        }
    }, [props.isViewConfigurator]);

    // モニタリング開始要求(オペレーターからサイネージ)
    const monitoringRequest = () => {
        console.log(`${new Date().toISOString()}[debug]VideoCaller call monitoringRequest()`);
        const ws = props.wsc.getWs();
        if (ws && ws.readyState === 1) {
            ws.send(JSON.stringify({
                type: 'stateTransitionEvent',
                event: {
                    type: "monitoringRequest",
                    senderType: "Signage",
                    signageId: props.signageId,
                    userPeerId: myPeerId
                }
            }));
            props.setIsMonitoring(true);
        } else {
            console.log(`${new Date().toISOString()}[debug]VideoCaller call monitoringRequest(), but ws isn't standby yet.`);
        }
        setAppContext((pre) => {
            pre.monitoringSignageId = props.signageId;
            return { ...pre };
        });
    }

    // 呼びかけ(オペレーターからサイネージ)
    const callFromUser = (signageId: string, _myPeerId?: string) => {
        //Note: 「KSIN-262 SkyWayビデオ接続完了前に「呼びかけ」を押下するとビデオ通話が繋がらない」件の修正
        const executeCallFromUser = () => {
            console.log(`${new Date().toISOString()}[debug]VideoCaller in callFromUser() signageId: ${signageId}`);

            const ws = props.wsc.getWs();
            if (ws.readyState === WebSocket.OPEN) {
                ws.send(JSON.stringify({
                    type: 'stateTransitionEvent',
                    event: {
                        type: "callFromUser",
                        senderType: "User",
                        signageId: signageId,
                        userPeerId: _myPeerId ? _myPeerId : myPeerId,
                        operatorState: OperatorStateList.Active
                    }
                }));
            }
            props.setIsMonitoring(false);
            setAppContext((pre) => {
                pre.signageState = SignageStateList.Active;
                // pre.shouldRecept = false;
                return { ...pre }
            })
            const createPlayer = () => {
                console.log(`${new Date().toISOString()}[debug]call removePlayer in pointC`);
                props.wsc.removePlayer("reCreate");
            }
            setTimeout(createPlayer, 300);
        }
        //Note: Standby時以外はpeer未OpenのときのみcallActionを実行する
        if (props.signageState === SignageStateList.Standby || !isPeerOpenedRef.current) {
            console.log(`${new Date().toISOString()}[debug]callFromUser execute.`);
            executeCallFromUser();
        } else {
            console.log(`${new Date().toISOString()}[debug]callFromUser is already executed.`);
        }
    }

    const informCallId = () => {
        const ws = props.wsc.getWs();
        const timeout = props.inSignage ? 500 : 500;
        // const timeout = 1000;
        if (ws && ws.readyState === 1) {
            console.log(`inform myPeerId: ${myPeerId}`)
            ws.send(JSON.stringify({
                type: 'informCallId',
                event: {
                    type: "informCallId",
                    peerId: myPeerId,
                    senderType: "Signage",
                    signageId: props.signageId
                }
            }))
        } else {
            setTimeout(informCallId, timeout);
        }
    }

    return (
        // <Flex>
        //     <Text>myPeer: {myPeerId}</Text>
        <Flex
            bgColor="white"
            maxW={props.isViewSignageConfig ? "100%" : "50%"}
            flexDirection="column"
            display={props.isViewTheirVideo || props.isViewSignageConfig ? "flex" : "none"}
            // display="flex"
            p="1rem"
        // justifyContent={props.isViewSignageConfig ? "center" : "flex-start"}
        >

            {/* ページ遷移時に警告 */}
            <Prompt
                message={() => {
                    // if (props.signageState === SignageStateList.Active) {
                    closeVideoCall();
                    // }

                    return true;
                }}
            />
            <Text display="none">{appContext.wsState}</Text>

            <Flex
                id="video-caller-container"
                flexDirection="row"
                justifyContent={props.isViewSignageConfig ? "center" : "flex-start"}
            >
                <Flex
                    flexDirection="column"
                    h="10rem"
                    display={props.isViewSignageConfig ? "inline-block" : "none"}
                    justifyContent={props.isViewSignageConfig ? "center" : "flex-start"}
                >
                    <FormControl className="select">
                        <FormLabel htmlFor="audioSourceValue">マイク選択: </FormLabel>
                        <Select
                            id="audioSourceValue"
                            onChange={handleInputChange}
                            value={audioSourceValue}>
                            {audioSourceList.map((source) =>
                                <option
                                    value={source.deviceId}>{source.label}
                                </option>
                            )}
                        </Select>
                    </FormControl>

                    <FormControl className="select">
                        <FormLabel htmlFor="videoSourceValue">カメラ選択: </FormLabel>
                        <Select
                            id="videoSourceValue"
                            onChange={handleInputChange}
                            value={videoSourceValue}
                        >
                            {videoSourceList.map((source) =>
                                <option
                                    value={source.deviceId}>{source.label}
                                </option>
                            )}
                        </Select>
                    </FormControl>
                    {appContext.signageDecodeInfo.isErrorOccured ?
                        <Box
                            color="red"
                            bgColor="yellow"
                            fontWeight="bold"
                            fontSize="1.125rem"
                            textAlign="left"
                            margin="0.5rem 0"
                            padding="1rem"
                        >
                            <Flex>
                                <BsFillExclamationTriangleFill />　サイネージ異常発生中
                            </Flex>
                            <Text>
                                「再接続」後に「呼びかけ」してください
                            </Text>
                        </Box> :
                        <></>}
                </Flex>
                <Flex w="150px" flexDirection="column" display={props.isViewSignageConfig ? "flex" : "none"}>
                    <Button
                        onClick={() => callFromUser(props.signageId)}
                        bgColor="teal.300"
                        color="white"
                        mt="2rem"
                        ml="1rem"
                        mr="1rem"
                    // disabled={!props.isStandbyComplete}
                    >呼びかけ</Button>
                    <Button
                        id="close-calling-btn"
                        onClick={() => role === "webReservationUser" ? closeVideoCallForWRU() : closeVideoCall(true)}
                        bgColor="red.300"
                        color="white"
                        mt="2rem"
                        ml="1rem"
                        mr="1rem"
                    >会話終了</Button>
                    <Button
                        onClick={() => closeVideoCall(true)}
                        bgColor="blue.300"
                        color="white"
                        mt="2rem"
                        ml="1rem"
                        mr="1rem"
                        mb="2rem"
                    >再接続</Button>
                </Flex>
                <InputField
                    onSendMessage={onSendMessage}
                    isLoadedInputAudio={isLoadedInputAudio}
                    inSignage={props.inSignage}
                    setSystemTalkLatest={props.setSystemTalkLatest}
                    setUserTalkLatest={props.setUserTalkLatest}
                />
            </Flex>
            {/* ビデオを表示した後、サイネージ側と接続する */}
            <video
                id="their-video"
                playsInline style={{ objectFit: 'contain', width: '40vw', height: '40vh' }}
                onLoadedMetadata={handleClickVideoLoader}
            >
            </video>
            <video
                id="my-video"
                width={myVideoSize.w}
                height={myVideoSize.h}
                muted ref={myVideoRef}
                style={{ display: 'none' }}>

            </video>
            {props.inSignage ? <canvas id="my-video-canvas" width={myVideoSize.w} height={myVideoSize.h} /> : <></>}
        </Flex>
        // </Flex>
    )
}