// Chakra imports
import {
  Box,
  Flex,
  Text,
  CSSReset,
  ChakraProvider
} from "@chakra-ui/react";
import UpdateUserForm from "../../../components/ksin/Forms/UpdateUserForm";


function UserUpdate(props: any) {
  // console.log(`target_user_id: ${props.location.state.id}`);
  const targetUserId = props.location.state.id;
  const companyName = props.location.state.companyName;

  return (
    <Flex direction="column" mt="4rem">
      <Box
        mb={{ sm: "205px", md: "75px", xl: "70px" }}
        borderRadius="15px"
        px="0px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        align="center"
        backgroundColor="white"
        paddingTop="30px"
        paddingLeft="20px"
        paddingBottom="50px"
        width="60%"
      >
          <Text color="black" textAlign="left" fontWeight="bold" marginBottom="40px" fontSize="large">
            ユーザー設定
          </Text>
          
          <ChakraProvider>
            <CSSReset />
              <UpdateUserForm 
                targetUserId={targetUserId}
                channelList={props.location.state.channelList}
                companyName={companyName}
              />
          </ChakraProvider>
      </Box>
    </Flex>
  );
}

export default UserUpdate;
