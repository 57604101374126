import { baseStyle } from "@chakra-ui/react";

export const modalStyles = {
    components: {
        Modal: {
            sizes: {
                // xl: {width: '50rem'},
                // container: {
                //     sm: '640px',
                //     md: '768px',
                //     lg: '1024px',
                //     xl: '1280px',
                //   },
            }
        }
    }
}