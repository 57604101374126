import { useForm, FormProvider } from 'react-hook-form'
import React, { useEffect } from 'react'
import {
  FormControl,
  Button,
  Spacer,
  Box,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  FormLabel,
} from '@chakra-ui/react'
import InputLoginIdMail from './parts/InputLoginIdMail';
import InputName from './parts/InputName';
import InputKana from './parts/InputKana';
import InputAffiliation from './parts/InputAffiliation';
import InputRole from './parts/InputRole';
import InputImage from './parts/InputImage';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import AlertDialogBasic from '../Common/AlertDialogBasic';
import { FormsContentsManager } from '../Common/contents/FormsContentsManager';
import { UserClient } from '../ApiClient/UserClient';
import { ConvertFormData } from '../util/ConvertFormData';
import ShowCharacter from './parts/ShowCharacter';
import { StoreClient } from 'components/ksin/ApiClient/StoreClient';
import { AppContext } from 'layouts/AppCommons';
import { useContext } from 'react'
// Assets
export default function UpdateUserForm(props: {
   targetUserId: string,
   channelList?: any[],
   companyName?: string,
   }) {
  const history = useHistory();
  const { appContext } = useContext(AppContext);
  const wsc = appContext.wsc;
  const ws = wsc.getWs();
  const [userInfo, setUserInfo] = React.useState(null);
  const [formValues, setFormValues] = React.useState(null);
  const [serverErrors, setServerErrors] = React.useState([]);
  const [isForceSubmit, setIsForceSubmit] = React.useState(false);
  const dialogTemplate = {
    header: "",
    body: "",
  }
  const [dialogContents, setDialogContents] = React.useState(dialogTemplate);
  const [characterId, setCharacterId] = React.useState("");
  const [role, setRole] = React.useState(null);
  const [storeList, setStoreList] = React.useState(null);

  // Dialog
  const [isOpenConfirm, setIsOpenConfirm] = React.useState(false)
  // const [isOpen, onOpen ] = useDisclosure();
  useEffect(() => {
    if (isForceSubmit) {
      onSubmit(formValues);
    }
  }, [isForceSubmit])

  const client = new UserClient();

  const resetPassword = () => {
    client.update(
      {
        targetUserId: props.targetUserId,
        passwordReset: true
      }
    ).then(()=>{
      ws.send(JSON.stringify({
        type: 'requestLogout',
        objectId: userInfo.userId
      }));
    });
  }

  const getUserCurrent = async () => {
    const sendData = {
      targetUserId: props.targetUserId
    };

    const param = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      body: JSON.stringify(sendData)
    };

    return new Promise((resolve, reject) =>
      fetch(`/api/getUser`, param)
        .then(response => response.json())
        .then(json => {
          console.log(json);
          resolve(json);
        })
        .catch((error) => {
          console.error('Error:', error);
          reject(error);
        })
    )
  }


  const deleteUser = async () => {
    const sendData = {
      targetUserId: props.targetUserId
    };

    const param = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      body: JSON.stringify(sendData)
    };

    return fetch(`/api/deleteUser`, param)
      .then(response => response.json())
      .then(json => {
        console.log(json.msg);
        history.push({ pathname: "/app/users/list" });
      })
      .catch((error) => {
        console.error('Error:', error);
      })
  }

  const getTargetCompanyStoreName = (targetStoreId: string) => {
    let targetCompanyStoreName = props.companyName;
    for(let index=0; index<storeList.length; index+=1){
      if(storeList[index].id == targetStoreId){
        targetCompanyStoreName = `${props.companyName} / ${storeList[index].name}`;
        break;
      }
    }

    return targetCompanyStoreName;
  };

  useEffect(() => {
    getUserCurrent().then((u: any) => {
      console.log(`Call getUsers().then`);
      setUserInfo(u);
    })

    // NOTE: 店舗名表示のため店舗一覧を取得する
    const storeClient = new StoreClient();
    storeClient.get().then((data: any) => {
      setStoreList(data);
    })
    .catch((error) => {
    })
  }, [])

  // 追加モード
  const isAddMode = false;

  // const [] = React.useState(false)

  const {
    // setError,
    formState: { isSubmitting },
  } = useForm()
  const methods = useForm();

  function onSubmit(values: any) {
    if (characterId !== "") values.character_id = characterId;
    if(role != null)  values.role = role;

    // saveValues
    setFormValues(values);

    //error reset
    setServerErrors([]);

    // 対象ユーザーの指定
    values.targetUserId = props.targetUserId;

    // 確認ダイアログからリトライしたときのフラグ設定
    values.isForceSubmit = isForceSubmit ? 1 : 0;

    // 空文字または変更されていないプロパティは削除
    if (values.login_id === userInfo.loginId || values.login_id === "") {
      delete values.login_id;
    }
    if (values.name === userInfo.userName || values.name === "") {
      delete values.name;
    }
    if (values.kana === userInfo.kana || values.kana === "") {
      delete values.kana;
    }
    if (values.role === userInfo.role) {
      delete values.role;
    }

    const formData = new ConvertFormData().valuesToFormData(values);
    const param = {
      method: 'POST',
      body: formData
    };

    const addServerError = (errObj: { code: string, msg: string }) => {
      setServerErrors([...serverErrors, errObj]);
      console.log(JSON.stringify(serverErrors));
    }

    return fetch(`/api/updateUser`, param)
      .then(response => response.json())
      .then(json => {
        switch (json.result) {
          case "OK":
            console.log(`Update Success.`);
            //force mode reset
            setIsForceSubmit(false);
            setIsOpenConfirm(false);
            // リスト画面へ遷移
            history.push({ pathname: "/app/users/list" });
            if (values.login_id !== userInfo.loginId && values.login_id?.length > 0) {              
              ws.send(JSON.stringify({
                type: 'requestLogout',
                objectId: userInfo.userId
              }));
            }
            break;
          case "Confirm":
            console.log(`Update Retry Confirm.`);
            setDialogContents({
              header: json.code,
              body: json.msg
            });
            setIsOpenConfirm(true);
            break;
          case "Error":
            addServerError(
              {
                code: json.code,
                msg: json.msg
              }
            );
            break;
          default:
            addServerError(
              {
                code: "SVER-Unexpected",
                msg: "未定義のサーバーエラーが発生しました"
              }
            );
        }
      });
  }

  return (
    <FormProvider {...methods} >
      <AlertDialogBasic
        isOpenConfirm={isOpenConfirm}
        setIsOpenConfirm={setIsOpenConfirm}
        setIsForceSubmit={setIsForceSubmit}
        dialogContents={dialogContents}
      />
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <InputLoginIdMail
          isAddMode={isAddMode}
          userInfo={userInfo}
        />
        <Box ml="0px" mb="24px" display="flex" justifyContent="flex-start">
          <FormLabel
              ms="4px"
              fontSize="sm"
              textAlign="left"
              fontWeight="bold"
              display="flex"
              width="200px"
              alignItems="center"
          >
              パスワード
          </FormLabel>
          <Button
            display="block"
            fontSize="14px"
            bg="teal.300"
            pl="2rem"
            pr="2rem"
            // mb="20px"
            color="white"
            // mt="20px"
            h="30px"
            _hover={{
              bg: "teal.200",
            }}
            _active={{
              bg: "teal.400",
            }}
            colorScheme='teal'
            isLoading={isSubmitting}
            onClick={resetPassword}
          >
            パスワードリセット
          </Button>
        </Box>
        <InputName
          isAddMode={isAddMode}
          userInfo={userInfo}
          formInfo={new FormsContentsManager().get("User").name}
        />
        <InputKana
          isAddMode={isAddMode} 
          userInfo={userInfo} 
          formInfo={new FormsContentsManager().get("User").kana}
        />
        <InputAffiliation
          isAddMode={isAddMode}
          userInfo={userInfo}
          companyName={props.companyName}
        />
        <InputRole
          isAddMode={isAddMode}
          userInfo={userInfo}
          role={role}
          setRole={setRole}
        />
        <Spacer h="30px" />
        <ShowCharacter
          row={userInfo}
          isForm={true}
          isAddMode={false}
          setFormCharacterId={setCharacterId}
          companyName={props.companyName && userInfo != null && storeList != null ? getTargetCompanyStoreName(userInfo.storeId) : ''}
        />
        <Spacer h="30px" />
        <Spacer h="30px" />
        <InputImage
          isAddMode={isAddMode}
          userInfo={userInfo}
          fileType={"image"}
        />
        <Spacer h="30px" />
        <FormControl
          display="flex"
          position="relative"
          height="60px"
        >
          <Button
            position="absolute"
            right="0"
            fontSize="14px"
            bg="teal.300"
            pl="2rem"
            pr="2rem"
            h="30px"
            w="130px"
            mb="20px"
            color="white"
            mt="20px"
            _hover={{
              bg: "teal.200",
            }}
            _active={{
              bg: "teal.400",
            }}
            colorScheme='teal'
            isLoading={isSubmitting}
            type='submit'
          >
            <CheckCircleIcon color="inherit" mr="2" />
            確定
          </Button>
          <Button
            position="absolute"
            left="0"
            fontSize="14px"
            bg="red.600"
            w="130px"
            h="30px"
            mb="10px"
            mr="8"
            color="white"
            borderRadius="5px"
            mt="20px"
            _hover={
              userInfo && userInfo.isOwnConfig ?
                {
                  bg: "red.600",
                } : {
                  bg: "red.200",
                }
            }
            _active={{
              bg: "red.400",
            }}
            onClick={deleteUser}
            isDisabled={userInfo && userInfo.isOwnConfig}
          >
            ユーザー削除
          </Button>
        </FormControl>
        <Alert
          status='error'
          display={serverErrors.length ? "flex" : "none"}
        >
          <AlertIcon />
          <AlertTitle mr={2}>{serverErrors.length ? serverErrors[0].code : ""}</AlertTitle>
          <AlertDescription>{serverErrors.length ? serverErrors[0].msg : ""}</AlertDescription>
        </Alert>
      </form>
    </FormProvider>
  )
}